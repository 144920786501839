import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, MenuItem, Nav, NavItem, Overlay, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommitReminder from '../CommitReminder';
import { ROUTES, ECOSYSTEM_STATUS } from '../../enum';
import { NAVBAR_NOTIFICATION_FAKE, NAVBAR_SEARCH, NAVBAR_USER } from '../../assets/Icons';
import { logout, acceptCommit, acceptRollback } from '../../store/common-scenario-actions';
import history from '../../history';
import './header-links.scss';

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    const { showCommit } = this.props;

    this.state = {
      show: false,
      showCommit,
      popTarget: null,
      showReminder: false,
    };
  }

  componentDidMount() {
    this.setState({ showReminder: localStorage.getItem('commit-reminder') === 'true' });
  }

  componentDidUpdate(prevProps) {
    const { showCommit, ecosystem } = this.props;

    if (
      showCommit !== prevProps.showCommit ||
      (prevProps.ecosystem && ecosystem && ecosystem.status !== prevProps.ecosystem.status)
    ) {
      this.setState({
        showCommit: showCommit && ecosystem.status === ECOSYSTEM_STATUS.PENDING_CHANGES,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.ecosystem &&
      props.ecosystem.status === ECOSYSTEM_STATUS.PENDING_CHANGES &&
      props.showCommit &&
      state.showCommit
    ) {
      return {
        showCommit: true,
      };
    }
    return null;
  }

  handleAcceptCommit = () => {
    const { acceptCommit } = this.props;

    this.setState(
      {
        show: false,
        showCommit: false,
      },
      () => {
        acceptCommit();
      },
    );
  };

  handleAcceptRollback = () => {
    const { acceptRollback } = this.props;

    this.setState(
      {
        show: false,
        showCommit: false,
      },
      () => {
        acceptRollback();
      },
    );
  };

  onCommit = (event) => {
    const { target } = event;
    this.setState({ show: true, popTarget: target });
  };

  handleCloseReminder = (checked) => {
    if (checked) {
      localStorage.setItem('commit-reminder', 'false');
    }
    this.setState({ showReminder: false });
  };

  handleCommitFromReminder = (checked) => {
    const { acceptCommit } = this.props;
    if (checked) {
      localStorage.setItem('commit-reminder', 'false');
    }
    this.setState({ showReminder: false }, () => {
      acceptCommit();
    });
  };

  render() {
    const { showCommit, showReminder } = this.state;
    const {
      selectedCustomer,
      location: { pathname },
      ecosystem,
    } = this.props;
    const commitShow = showCommit && ecosystem && ecosystem.status === 'pending_changes';
    const commitDisabled =
      this.props.showCommit && (!showCommit || (ecosystem && ecosystem.status !== 'pending_changes'));
    return (
      <div>
        <Nav pullRight>
          {this.props.showSearch && (
            <NavItem eventKey={0} href="#" className="hidehide component navbar-item component-coming-soon">
              <div>
                <img src={NAVBAR_SEARCH} alt="navbar-search" className="navbar-search" />
              </div>
            </NavItem>
          )}
          <NavItem eventKey={1} href="#" className="hide-component navbar-item component-coming-soon">
            <div>
              <img src={NAVBAR_NOTIFICATION_FAKE} alt="navbar-notification" className="navbar-notification" />
            </div>
          </NavItem>
          {commitDisabled && (
            <NavItem eventKey={0} href="#" className="navbar-item">
              <div className="nav-profile commit-container">
                <div className="commit-box disabled">
                  <span>Apply Changes</span>
                </div>
              </div>
            </NavItem>
          )}
          {commitShow && (
            <>
              <NavItem eventKey={0} href="#" className="navbar-item">
                <div className="nav-profile commit-container">
                  {commitShow && <div className="tooltip">Changes not in effect until applied</div>}
                  <div className="commit-box" onClick={this.onCommit}>
                    <span>Apply Changes</span>
                    <svg height="18" width="18">
                      <circle cx="9" cy="9" r="7" stroke="white" strokeWidth="2" fill="#25C8EA" />
                      <circle cx="9" cy="9" r="5" fill="#016BE6" />
                    </svg>
                    <Overlay
                      show={this.state.show}
                      target={this.state.popTarget}
                      placement="bottom"
                      rootClose
                      onHide={() => this.setState({ show: false })}
                    >
                      <Popover id="popover-commit">
                        <div className="commit-actions">
                          <button type="button" className="revert-button" onClick={this.handleAcceptRollback}>
                            Revert
                          </button>
                          <button type="button" className="commit-button" onClick={this.handleAcceptCommit}>
                            Commit
                          </button>
                        </div>
                      </Popover>
                    </Overlay>
                  </div>
                </div>
              </NavItem>
              {showReminder && (
                <CommitReminder onClose={this.handleCloseReminder} onCommit={this.handleCommitFromReminder} />
              )}
            </>
          )}
          <NavItem href="#" className="navbar-item last">
            <div className="flex-row nav-profile">
              <Dropdown id="dropdown-profile-options">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={NAVBAR_USER} alt="navbar-user" className="navbar-user" />
                  <p>{this.props.username}</p>
                  <i className="pe-7s-angle-down" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <MenuItem className="wedge-menu-item" onSelect={() => history.push(ROUTES.PROFILE)}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    className="wedge-menu-item"
                    onSelect={() => history.push({ pathname: ROUTES.CUSTOMERS, state: { from: pathname } })}
                  >
                    {selectedCustomer ? `My Organization (${selectedCustomer.name})` : 'My Organization'}
                  </MenuItem>
                  <MenuItem
                    className="wedge-menu-item"
                    onSelect={() => window.open(process.env.REACT_APP_KB_URL, '_blank')}
                  >
                    Help / Knowledge Base
                  </MenuItem>
                  <MenuItem className="wedge-menu-item" onSelect={this.props.logout}>
                    Logout
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

HeaderLinks.defaultProps = {
  showSearch: false,
  showCommit: false,
};

HeaderLinks.propTypes = {
  showSearch: PropTypes.bool,
  showCommit: PropTypes.bool,
  username: PropTypes.string.isRequired,
  selectedCustomer: PropTypes.object,
  ecosystem: PropTypes.object,
  logout: PropTypes.func.isRequired,
  acceptCommit: PropTypes.func.isRequired,
  acceptRollback: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectedCustomer: state.auth.selectedCustomer,
  username: state.auth.username,
  ecosystem: state.ecosystems.currentEcosystem,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  acceptCommit: () => dispatch(acceptCommit()),
  acceptRollback: () => dispatch(acceptRollback()),
});

const ConnectedHeaderLinks = connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);

export default withRouter(ConnectedHeaderLinks);
