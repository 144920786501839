/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from '../../enum';
import { selectCustomer } from '../../views/CustomersForm/scenario-actions';
import {
  customersSelector,
  isAuthenticatedSelector,
  isEulaAcceptedSelector,
  selectedCustomerSelector,
} from '../../store/user/selectors';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const {
    isAuthenticated,
    customers,
    selectedCustomer,
    location,
    computedMatch,
    currentCustomer,
    selectCustomer,
  } = rest;
  const eulaAccepted = localStorage.getItem('eulaAccepted') === 'true';
  const { params } = computedMatch;
  if (isAuthenticated && params.cusID) {
    if (!selectedCustomer || selectedCustomer.uuid !== params.cusID || currentCustomer.uuid !== params.cusID) {
      const customer = customers.find((cust) => cust.uuid === params.cusID);
      if (customer) {
        selectCustomer(customer);
      } else {
        return <Route {...rest} render={() => <Redirect push to={ROUTES.CUSTOMERS} />} />;
      }
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (!eulaAccepted) {
            if (location.pathname === ROUTES.EULA) {
              return <Component {...props} />;
            }
            return (
              <Redirect
                push
                to={{
                  pathname: ROUTES.EULA,
                  state: { from: props.location },
                }}
              />
            );
          }
          if (!customers || !customers.length) {
            return <Redirect push to={ROUTES.ORG_INFO} />;
          }
          return <Component {...props} />;
        }
        return <Redirect push to={{ pathname: ROUTES.LOGIN, state: { from: props.location, warning: true } }} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  selectedCustomer: selectedCustomerSelector(state),
  customers: customersSelector(state),
  eulaAccepted: isEulaAcceptedSelector(state),
  currentCustomer: state.customers.currentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  selectCustomer: (customer) => dispatch(selectCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
