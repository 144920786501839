/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';

import Card from '../components/Card';
import Form from '../components/Form';
import { FILE_IMPORT, FILE_IMPORT_OPTIONS } from '../enum/object';
import UserValidationSchema from '../validationSchemas/userValidation';
import populator from '../utils/populator';

import ContextHelpPopOver from '../components/ContextHelpPopOver';

import { StepFooter, UsersTable } from './commons';

import './modals.scss';

class NewUserCSVImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      importStage: 0,
      importMethod: FILE_IMPORT_OPTIONS[0],
      fileContent: '',
      includeColHeaders: true,
      addedUsers: [],
      errors: [],
    };
  }

  handleSubmit = async () => {
    const { importStage, fileContent, importMethod, addedUsers } = this.state;
    if (!importStage) {
      if (!fileContent) {
        if (importMethod === FILE_IMPORT_OPTIONS[0]) {
          this.setState({
            errors: ['File Content is empty.'],
          });
        } else {
          this.setState({
            errors: ['Please upload a valid file with some users'],
          });
        }
      } else {
        this.processData(fileContent);
      }
    } else {
      try {
        this.props.onFinish({ type: 'user', data: addedUsers });
      } catch (err) {
        this.setState({
          errors: ['Failed to import Users'],
        });
      }
    }
  };

  goBack = () => {
    if (this.state.importStage === 1) {
      this.setState({ importStage: 0, errors: [] });
    } else {
      this.props.navigateBack();
    }
  };

  removeUser = (index) => {
    const { addedUsers } = this.state;
    this.setState({
      addedUsers: addedUsers.filter((vl, ind) => ind !== index),
      ...(addedUsers.length === 1 ? { fileContent: '' } : {}),
    });
  };

  changeField = (key, val) => {
    if (key === 'importMethod') {
      this.setState({
        [key]: val,
        fileContent: '',
        errors: [],
      });
    } else {
      this.setState({
        [key]: val,
      });
    }
  };

  handleFiles = (e) => {
    if (window.FileReader) {
      this.getAsText(e.target.files[0]);
    } else {
      alert('FileReader are not supported in this browser.');
    }
  };

  getAsText = (fileToRead) => {
    const reader = new FileReader();
    reader.readAsText(fileToRead);
    reader.onload = this.fileReadingFinished;
    reader.onerror = this.errorHandler;
  };

  processData = (content) => {
    const { roles } = this.props;
    let lines = content
      .split(/\r\n|\n/)
      .filter((data) => data !== '')
      .map((data) => data.split(';'));
    if (this.state.includeColHeaders) {
      lines = lines.slice(1);
    }
    const users = lines
      .map((row) => row[0].split(','))
      .filter((p) => p[0].length === 5)
      .map((data) => ({
        firstName: data[0],
        lastName: data[1],
        role: data[2],
        email: data[3],
        password: data[4],
        status: 'Pending',
        securityGroup: '',
      }))
      .filter((user) => {
        if (roles.map(populator.role).find((role) => role.label === user.role)) {
          return true;
        }
        return false;
      })
      .map((user) => ({
        ...user,
        role: roles.map(populator.role).find((r) => r.label === user.role),
      }));
    if (users.length === 0) {
      this.setState({
        addedUsers: [],
        errors: ['No users to import'],
      });
    } else {
      this.setState({
        addedUsers: users,
        importStage: 1,
      });
    }
  };

  fileReadingFinished = (event) => {
    this.setState({ fileContent: event.target.result }, () => {
      this.processData(this.state.fileContent);
    });
  };

  errorHandler = (event) => {
    if (event.target.error.name === 'NotReadableError') {
      this.setState({ errors: ['Cannot read file!'] });
    }
  };

  validate(newUserData) {
    try {
      UserValidationSchema.validateSync(newUserData, { abortEarly: false });
      this.setState({
        errors: [],
      });
      return true;
    } catch (err) {
      this.setState({
        errors: err.errors,
      });
      return false;
    }
  }

  renderConfig() {
    const { errors } = this.state;

    return (
      <div className="modal__content padded ldap-config new-user-survey-csv">
        {errors.map((err, index) => (
          <p className="error" key={index}>
            {err}
          </p>
        ))}
        <Card header={false}>
          <div className="ldap-config-connection">
            <h5 className="form-header desc-header">
              Add users to your ecosystem to let them connect using their access credentials.
            </h5>
          </div>
          <div className="form-body">
            <div className="header-block">
              <h5>
                Comma Seperated Value(CSV) file must match our format requirements.&nbsp;
                <a
                  href={`${process.env.REACT_APP_KB_URL}/how-to/quickstart/import-users/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See Docs
                </a>
              </h5>
            </div>
            <div className="form-row gateway-config">
              <Grid>
                <Row className="centered-row baseline" noGutters>
                  <Col md="auto">
                    <p className="custom-header">Import Method</p>
                  </Col>
                  <Col md={4}>
                    <Form.Group horizontal>
                      <Form.Select
                        value={this.state.importMethod}
                        placeholder=""
                        onChange={(e) => {
                          this.changeField('importMethod', e);
                        }}
                        options={FILE_IMPORT_OPTIONS}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <ContextHelpPopOver helpId="USER_IMPORT_CSV" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <>
                      {this.state.importMethod.value === FILE_IMPORT.FILE_CONTENT ? (
                        <>
                          <Form.Group horizontal>
                            <Form.Text
                              value={this.state.fileContent}
                              onChange={(val) => this.changeField('fileContent', val)}
                              placeholder={`First name;Last name;Role;Email;Password;
${this.state.includeColHeaders ? 'John;Doe;Role name;v@v.com;somepassword' : ''}`}
                              multiline
                            />
                          </Form.Group>
                        </>
                      ) : (
                        <div>
                          <input type="file" onChange={this.handleFiles} accept=".csv" />
                        </div>
                      )}
                    </>
                    <Form.Group full extraClass="headerCheckbox">
                      <Form.Checkbox
                        checked={this.state.includeColHeaders}
                        onChange={(ev) => {
                          this.changeField('includeColHeaders', ev);
                        }}
                        label="First line includes column Headers"
                        extraClass="startTLS"
                      ></Form.Checkbox>
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  renderTable() {
    const { addedUsers, errors } = this.state;
    return (
      <div className="modal__content padded ldap-config new-user-survey-csv">
        {errors.map((err, index) => (
          <p className="error" key={index}>
            {err}
          </p>
        ))}
        <Card header={false}>
          <div className="ldap-config-connection">
            <h5 className="form-header desc-header">Users to be Imported</h5>
          </div>
          <div className="form-body">
            <div className="form-row gateway-config">
              <Grid>
                <Row className="centered-row" noGutters>
                  <Col md={12}>
                    <UsersTable users={addedUsers} removeUser={this.removeUser}></UsersTable>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfig()}
        {this.state.addedUsers.length ? this.renderTable() : null}
        <div className="wedge-modal__footer">
          <StepFooter edit={false} submit onClick={this.handleSubmit} onBack={this.goBack} />
        </div>
      </div>
    );
  }
}

NewUserCSVImport.propTypes = {
  onFinish: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

NewUserCSVImport.Footer = StepFooter;
const mapStateToProps = (state) => ({
  roles: state.ecosystems.dictionaries.groups,
});

export default connect(mapStateToProps)(NewUserCSVImport);
