export const FETCHING_DNATS_REQUESTED = 'FETCHING_DNATS_REQUESTED';
export const FETCHING_DNATS_SUCCESS = 'FETCHING_DNATS_SUCCESS';
export const FETCHING_DNATS_FAILURE = 'FETCHING_DNATS_FAILURE';

export const CREATION_DNAT_FAILURE = 'CREATION_DNAT_FAILURE';
export const CREATION_DNAT_REQUESTED = 'CREATION_DNAT_REQUESTED';
export const CREATION_DNAT_SUCCESS = 'CREATION_DNAT_SUCCESS';

export const UPDATE_DNAT_FAILURE = 'UPDATE_DNAT_FAILURE';
export const UPDATE_DNAT_REQUESTED = 'UPDATE_DNAT_REQUESTED';
export const UPDATE_DNAT_SUCCESS = 'UPDATE_DNAT_SUCCESS';

export const DELETE_DNAT_FAILURE = 'DELETE_DNAT_FAILURE';
export const DELETE_DNAT_REQUESTED = 'DELETE_DNAT_REQUESTED';
export const DELETE_DNAT_SUCCESS = 'DELETE_DNAT_SUCCESS';
