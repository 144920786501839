import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import Loader from '../../components/Loader/Loader';
import WedgeModal from '../../components/WedgeModal';

import AddEcosystem from './components/AddEcosystem/AddEcosystem';
import EcosystemCard from './components/EcosystemCard';
import PendingEcosystemItem from './components/PendingEcosystemItem/PendingEcosystemItem';

import ConfirmAction from '../../Modals/ConfirmAction';
import UpgradeEcosystem from '../../Modals/UpgradeEcosystem';
import RecoverEcosystem from '../../Modals/RecoverEcosystem';

import { createErrorMessageSelector, createLoadingSelector } from '../../store/utils/selectors';
import {
  createEcosystem,
  removeEcosystem,
  upgradeEcosystem,
  recoverEcosystem,
  fetchEcosystems,
  fetchNSPs,
  removeCurrent,
} from './scenario-actions';
import { ECOSYSTEM_OPTIONS } from '../../enum';

import './ecosystems.scss';

Modal.setAppElement('#modal-root');

const newEcosystemSchema = {
  name: 'New Ecosystem',
  regions: [],
};
class Ecosystems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatingNew: false,
      fetchFinished: false,
      selectedOption: '',
      selectedItem: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { selectedItem } = state;
    const { ecosystems } = props;
    if (selectedItem && ecosystems.length) {
      const ecosystem = props.ecosystems.find((eco) => eco.uuid === selectedItem.uuid);
      if (selectedItem !== ecosystem) {
        return { selectedItem: ecosystem };
      }
    }
    return null;
  }

  async componentDidMount() {
    const { fetchEcosystems, fetchNSPs, removeCurrent } = this.props;
    await fetchNSPs();
    await fetchEcosystems();
    this.setState({ fetchFinished: true });
    removeCurrent();
  }

  componentDidUpdate(prevProps) {
    const { ecosystems } = this.props;
    if (prevProps.ecosystems !== ecosystems && !ecosystems.length) {
      this.setState({ creatingNew: true });
    }
  }

  handleNewOpen = () => {
    this.setState({ creatingNew: true });
  };

  handleCreateEcosystem = (entity) => {
    this.setState({ creatingNew: false });
    this.props.createEcosystem(entity);
  };

  openModal = async (ecosystem, option) => {
    this.setState({ selectedOption: option });
    this.setState({ selectedItem: ecosystem });
  };

  closeModal = () => {
    this.setState({ selectedOption: '' });
    this.setState({ selectedItem: null });
  };

  handleRemove = async () => {
    const { selectedItem } = this.state;
    await this.props.removeEcosystem(selectedItem.uuid);
    const { removeError } = this.props;
    if (!removeError) {
      this.closeModal();
      this.props.fetchEcosystems();
    }
  };

  handleUpgrade = async () => {
    const { selectedItem } = this.state;
    await this.props.upgradeEcosystem(selectedItem.uuid);
    const { upgradeError } = this.props;
    if (!upgradeError) {
      this.closeModal();
      this.props.fetchEcosystems();
    }
  };

  handleRecover = async () => {
    const { selectedItem } = this.state;
    await this.props.recoverEcosystem(selectedItem.uuid);
    const { recoverError } = this.props;
    if (!recoverError) {
      this.closeModal();
      this.props.fetchEcosystems();
    }
  };

  renderLoader = () => (
    <div className="loader-container">
      <Loader />
    </div>
  );

  handleRefresh = async () => {
    this.setState({ fetchFinished: false });
    await this.props.fetchEcosystems();
    this.setState({ fetchFinished: true });
  };

  renderEcosystems = () => {
    const { ecosystems, createLoading } = this.props;
    const { creatingNew, selectedItem, selectedOption } = this.state;

    return (
      <>
        <div className="ecosystem-refresh">
          <Button bsStyle="primary" onClick={this.handleRefresh}>
            Refresh Ecosystems
          </Button>
        </div>
        <div className="ecosystems">
          {createLoading && <PendingEcosystemItem />}
          {ecosystems.map((eco) => (
            <EcosystemCard
              data={eco}
              key={`ecosystems-list-index-${eco.uuid}`}
              onRemove={() => this.openModal(eco, ECOSYSTEM_OPTIONS.REMOVE)}
              onUpgrade={() => this.openModal(eco, ECOSYSTEM_OPTIONS.UPGRADE)}
              onRecover={() => this.openModal(eco, ECOSYSTEM_OPTIONS.RECOVER)}
            />
          ))}
          {creatingNew && (
            <EcosystemCard
              data={newEcosystemSchema}
              isNew
              onCancel={() => this.setState({ creatingNew: false })}
              onSave={this.handleCreateEcosystem}
            />
          )}
          {!creatingNew && <AddEcosystem onClick={() => this.setState({ creatingNew: true })} />}
          {selectedItem && selectedOption === ECOSYSTEM_OPTIONS.REMOVE && (
            <WedgeModal
              title="Are you sure?"
              onClose={this.closeModal}
              isOpen={selectedOption === ECOSYSTEM_OPTIONS.REMOVE}
              size="tiny"
            >
              <ConfirmAction
                item={{ type: 'ecosystem', name: selectedItem.name }}
                action="delete"
                onYes={this.handleRemove}
                onNo={this.closeModal}
                confirmByTyping
              />
            </WedgeModal>
          )}
          {selectedItem && selectedOption === ECOSYSTEM_OPTIONS.UPGRADE && (
            <WedgeModal
              title={`${selectedItem.name} - Upgrade`}
              onClose={this.closeModal}
              isOpen={selectedOption === ECOSYSTEM_OPTIONS.UPGRADE}
              size="small"
            >
              <UpgradeEcosystem onYes={this.handleUpgrade} onNo={this.closeModal} />
            </WedgeModal>
          )}
          {selectedItem && selectedOption === ECOSYSTEM_OPTIONS.RECOVER && (
            <WedgeModal
              title={`${selectedItem.name} - Recover`}
              onClose={this.closeModal}
              isOpen={selectedOption === ECOSYSTEM_OPTIONS.RECOVER}
              size="small"
            >
              <RecoverEcosystem onYes={this.handleRecover} onNo={this.closeModal} />
            </WedgeModal>
          )}
        </div>
      </>
    );
  };

  render() {
    const { fetchLoading } = this.props;
    const { fetchFinished } = this.state;

    return <div>{fetchLoading || !fetchFinished ? this.renderLoader() : this.renderEcosystems()}</div>;
  }
}

Ecosystems.defaultProps = {
  ecosystems: [],
};

Ecosystems.propTypes = {
  fetchLoading: PropTypes.bool.isRequired,
  createLoading: PropTypes.bool.isRequired,
  ecosystems: PropTypes.array,
  fetchEcosystems: PropTypes.func.isRequired,
  createEcosystem: PropTypes.func.isRequired,
  upgradeEcosystem: PropTypes.func.isRequired,
  recoverEcosystem: PropTypes.func.isRequired,
  removeEcosystem: PropTypes.func.isRequired,
  fetchNSPs: PropTypes.func.isRequired,
  removeCurrent: PropTypes.func.isRequired,
  removeError: PropTypes.any,
  upgradeError: PropTypes.any,
  recoverError: PropTypes.any,
};

const fetchLoadingSelector = createLoadingSelector(['FETCHING_ECOSYSTEMS']);
const createEcosystemLoadingSelector = createLoadingSelector(['CREATE_ECOSYSTEM']);

const upgradeErrorSelector = createErrorMessageSelector(['UPGRADE_ECOSYSTEM']);
const recoverErrorSelector = createErrorMessageSelector(['RECOVER_ECOSYSTEM']);
const removeErrorSelector = createErrorMessageSelector(['REMOVE_ECOSYSTEM']);

const mapStateToProps = (state) => ({
  fetchLoading: fetchLoadingSelector(state),
  createLoading: createEcosystemLoadingSelector(state),
  ecosystems: state.ecosystems.items,
  removeError: removeErrorSelector(state),
  upgradeError: upgradeErrorSelector(state),
  recoverError: recoverErrorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchEcosystems: () => dispatch(fetchEcosystems()),
  fetchNSPs: () => dispatch(fetchNSPs()),
  createEcosystem: (entity) => dispatch(createEcosystem(entity)),
  upgradeEcosystem: (uuid) => dispatch(upgradeEcosystem(uuid)),
  recoverEcosystem: (uuid) => dispatch(recoverEcosystem(uuid)),
  removeEcosystem: (uuid) => dispatch(removeEcosystem(uuid)),
  removeCurrent: () => dispatch(removeCurrent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ecosystems);
