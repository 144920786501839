import { object, string } from 'yup';

const roleValidationSchema = object({
  name: string('Enter role name')
    .max(35, 'Name should be less than 36 characters.')
    .min(3, 'Name should be more than 3 characters.')
    .required('Name is required.'),
});

export default roleValidationSchema;
