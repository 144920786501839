import React, { Component } from 'react';
import ContractIdRegistration from '../../../components/ContractIdRegistration/ContractIdRegistration';
import StripeSourceRegistration from '../../../components/StripeSourceRegistration/StripeSourceRegistration';
import TabsHeader from '../../../components/TabsHeader/TabsHeader';

import '../sign-up-form.scss';

const tabs = [
  {
    name: 'Credit Card',
    iconClassName: 'icon-card',
  },
  {
    name: 'Contract ID',
    iconClassName: 'icon-suer',
  },
];
class BillingSubForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  selectTab = (index) => {
    this.setState(() => ({
      selectedIndex: index,
    }));
  };

  render() {
    const { selectedIndex } = this.state;
    return (
      <div className="form-container">
        <TabsHeader selectedIndex={selectedIndex} onSelect={this.selectTab} tabs={tabs} />
        <StripeSourceRegistration selectedIndex={selectedIndex} componentIndex={0} />
        <ContractIdRegistration selectedIndex={selectedIndex} componentIndex={1} />
      </div>
    );
  }
}
export default BillingSubForm;
