import { CONSTANTS } from '../../enum';
import * as REST from '../../api/rest';
import {
  loadEcosystemStarted,
  loadEcosystemSuccess,
  refreshEcosystemStarted,
  refreshEcosystemSuccess,
  fetchingEcosystemsSuccess,
  setCurrentEcosystem,
  setEcosystemStatus,
  loadEcosystemFailed,
} from '../../store/ecosystems/actions';
import { SET_ECOSYSTEM_CODE } from '../../store/ecosystems/action-types';
import history from '../../history';

export function loadEcosystem(ecosystemUUID) {
  return async (dispatch, getState) => {
    try {
      dispatch(loadEcosystemStarted());
      let ecosystems = getState().ecosystems.items;
      if (!ecosystems.length) {
        ecosystems = await REST.fetchEcosystems({
          customer: getState().auth.selectedCustomer,
        });
        dispatch(fetchingEcosystemsSuccess(ecosystems));
      }
      let ecosystem = ecosystems.find((eco) => eco.uuid === ecosystemUUID);
      if (ecosystem) {
        dispatch(setCurrentEcosystem(ecosystem));
      } else if (getState().ecosystems.currentEcosystem.uuid === ecosystemUUID) {
        ecosystem = getState().ecosystems.currentEcosystem;
        dispatch(setCurrentEcosystem(ecosystem));
      } else {
        ecosystem = {
          status: CONSTANTS.INACCESSIBLE,
        };
        await dispatch(setCurrentEcosystem(ecosystem));
        return;
      }
      const params = {
        ecosystem,
        customer: getState().auth.selectedCustomer,
      };
      const [nsps, groups, services, applications = []] = await Promise.all([
        REST.fetchNSPs(),
        REST.fetchGroups(params),
        REST.fetchServices(params),
        REST.fetchApplications(params),
      ]);
      dispatch(loadEcosystemSuccess({ groups, applications, services, nsps }));

      const code = await REST.fetchEcosystemCode(params);
      dispatch({ type: SET_ECOSYSTEM_CODE, payload: code });
    } catch (err) {
      loadEcosystemFailed(err);
    }
  };
}
export function refreshEcosystemStatus(ecosystemUUID) {
  return async (dispatch, getState) => {
    try {
      dispatch(refreshEcosystemStarted());
      const params = {
        ecosystem: ecosystemUUID,
        customer: getState().auth.selectedCustomer,
      };
      const { ecosystemStatus } = getState().ecosystems;
      const ecosystem = await REST.fetchEcosystemStatus(params);
      if (ecosystemStatus !== ecosystem.status) {
        dispatch(setEcosystemStatus(ecosystem.status));
      }
      dispatch(refreshEcosystemSuccess(ecosystem));
    } catch (err) {
      history.replace('/');
    }
  };
}
