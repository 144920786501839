import React from 'react';
import './overlayLoader.scss';

export default function OverlayLoader() {
  return (
    <div className="loader-overlay">
      <div className="loader-spinner" />
    </div>
  );
}
