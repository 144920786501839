/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { findDOMNode } from 'react-dom';

import './field.scss';

const FieldGroup = ({
  children,
  label,
  secondaryLabel,
  center = false,
  full = false,
  self = false,
  ...rest
}) => {
  const renderSecondLabel = () => (
    <React.Fragment>
      <div className="divider--small" />
      <span className="field__label secondary">{secondaryLabel}</span>
    </React.Fragment>
  );

  return (
    <div
      className={`field__group${center ? '-center' : ''} ${
        full ? 'full' : ''
      } ${self ? 'self' : ''} ${rest.extraClass || ''}`}
    >
      <div className="field__label">
        <span>{label}</span>
        {secondaryLabel && renderSecondLabel()}
      </div>
      {children}
    </div>
  );
};

FieldGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  label: PropTypes.string,
  center: PropTypes.bool,
  full: PropTypes.bool,
  self: PropTypes.bool,
  secondaryLabel: PropTypes.string,
};

const TextField = ({
  to = '',
  text = '',
  full = false,
  copy = false,
  extraClass,
}) => {
  const tokenRef = useRef(null);
  if (copy) {
    return (
      <div className={`field__copyfield ${extraClass || ''}`}>
        <div className="copy-button">
          <span className="copy__textfield">
            {to ? (
              <a href={to} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            ) : (
              text
            )}
          </span>
          <span
            className="tool-tip__copied"
            ref={tokenRef}
            data-tip="Copied!"
            data-for="tooltip"
          />
          <CopyToClipboard
            text={text}
            onCopy={() => {
              ReactTooltip.show(findDOMNode(tokenRef.current));
            }}
          >
            <i className="copy__icon fa fa-clone fa-flip-horizontal" />
          </CopyToClipboard>
        </div>
        <ReactTooltip
          id="tooltip"
          delayHide={1000}
          type="info"
          afterShow={() => ReactTooltip.hide(findDOMNode(tokenRef.current))}
        />
      </div>
    );
  }

  return (
    <p className={`primary field__textfield ${full ? 'full' : ''}`}>{text}</p>
  );
};

TextField.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.string,
  full: PropTypes.bool,
  copy: PropTypes.bool,
  extraClass: PropTypes.string,
};

export default {
  Group: FieldGroup,
  Text: TextField,
};
