import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const AddButton = ({ className, children, onClick }) => (
  <div className={`add-button ${className || ''}`} onClick={onClick}>
    <div className="square">+</div>
    {children && <p className="text">{children}</p>}
  </div>
);

export default AddButton;

AddButton.defaultProps = {
  children: '',
};

AddButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
