import React from 'react';
import PropTypes from 'prop-types';

import { LOCATION_TYPE_OPTIONS, LOCATION_TYPE } from '../../enum/location';
import Card from '../Card';
import Form from '../Form';
import CustomMap from '../CustomMap';
import SelectWithSearch from '../SelectWithSearch/SelectWithSearch';

import './style.scss';

class Descriptor extends React.Component {
  onChange = (event) => {
    if (
      (event.target.name === 'lat' && Math.abs(event.target.value) > 90) ||
      (event.target.name === 'lng' && Math.abs(event.target.value) > 180)
    ) {
      return;
    }
    if (event.target.name === 'lat' || event.target.name === 'lng') {
      this.props.onChange(event.target.name, Number(event.target.value));
      return;
    }
    this.props.onChange(event.target.name, event.target.value);
  };

  handleCenterChanged = (center) => {
    this.props.onChange('lat', Number(center.lat().toFixed(6)));
    this.props.onChange('lng', Number(center.lng().toFixed(6)));
  };

  render() {
    const { location } = this.props;

    return (
      <Card header={false}>
        <div className="form-row">
          <Form.Group label="Description" full>
            <Form.Text
              value={this.props.desc}
              onChange={(val) => this.props.onChange('desc', val)}
              placeholder="Description"
            />
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group label="Location">
            <SelectWithSearch
              selected={this.props.location}
              onChange={(val) => this.props.onChange('location', val)}
              placeholder="Select Location Type"
              groupCreative={false}
              options={LOCATION_TYPE_OPTIONS}
            />
          </Form.Group>
        </div>
        {location.value !== LOCATION_TYPE.UNDEFINED && (
          <React.Fragment>
            <div className="form-row latng__input">
              <Form.Group label="Latitude">
                <input
                  name="lat"
                  className="coordinates__input"
                  placeholder="Latitude"
                  type="number"
                  min="-90"
                  max="90"
                  value={this.props.lat}
                  onChange={this.onChange}
                />
              </Form.Group>
              <Form.Group label="Longitude">
                <Form.Text
                  name="lng"
                  className="coordinates__input"
                  placeholder="Longitude"
                  type="number"
                  min="-180"
                  max="180"
                  value={this.props.lng}
                  onChange={(val) => this.props.onChange('lng', val)}
                />
              </Form.Group>
            </div>
            <div className="space-above">
              <CustomMap
                center={{ lat: this.props.lat, lng: this.props.lng }}
                onCenterChange={this.handleCenterChanged}
              />
            </div>
          </React.Fragment>
        )}
      </Card>
    );
  }
}

Descriptor.propTypes = {
  desc: PropTypes.string,
  location: PropTypes.object.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Descriptor;
