import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field, Form, Formik } from 'formik';
import { createGroup } from '../../store/creational-scenario-actions';
import groupValidationSchema from '../../validationSchemas/groupValidationSchema';
import './create-group-form.scss';

const CreateGroupFrom = (props) => (
  <Formik
    initialValues={{
      name: '',
    }}
    validationSchema={groupValidationSchema}
    onSubmit={(values, { resetForm }) => {
      props.createGroup({
        groupName: values.name,
        field: props.field,
      });
      props.closeField();
      resetForm();
    }}
  >
    {({ errors }) => (
      <Form className="create-group-form">
        <div className="group-input-field">
          <Field
            name="name"
            placeholder="New Profile Group"
            onSelect={(e) => e.stopPropagation()}
            onFocus={(e) => e.preventDefault()}
            className="form__group-input"
          />
          <p className="error-text">{errors.name}</p>
        </div>
        <button
          disabled={!_.isEmpty(errors)}
          type="submit"
          className="save-button btn"
        >
          Save
        </button>
      </Form>
    )}
  </Formik>
);

CreateGroupFrom.propTypes = {
  createGroup: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  closeField: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createGroup: (group) => dispatch(createGroup(group)),
});

export default connect(null, mapDispatchToProps)(memo(CreateGroupFrom));
