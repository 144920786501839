import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Col, Grid, Row } from 'react-bootstrap';
import { withFormik } from 'formik';
import Spinner from 'react-spinner-material';
import { number, func, bool, object, string } from 'prop-types';

import { registerCustomerWithContractId, clearContractIdError, setStripe } from '../../store/payment/actions';
import { contractIdErrorSelector } from '../../store/payment/selectors';
import ErrorPanel from '../ErrorPanel/ErrorPanel';
import contractIdRegistrationValidationSchema, {
  COMPANY_NAME,
  ACRETO_CONTRACT_ID,
} from '../../validationSchemas/contractIdRegistrationValidationSchema';

import './contract-id-registration.scss';
import 'react-flags-select/scss/react-flags-select.scss';

import { isLoadingSelector, initialCompanyNameSelector } from '../../store/user/selectors';

class ContractIdRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldErrorBeDisplayed: false,
      isAgreed: false,
    };
  }

  componentDidMount() {
    this.props.setStripe();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      shouldErrorBeDisplayed: true,
    });
    const { isValid, values, registerCustomerWithContractId } = this.props;
    if (!isValid) {
      return;
    }
    const companyData = {
      name: values[COMPANY_NAME],
      payment_ref: values[ACRETO_CONTRACT_ID],
      payment_channel: 'contract',
    };
    registerCustomerWithContractId(companyData);
  };

  handleBlur = ({ target: { name } }) => {
    const { setFieldTouched } = this.props;
    setFieldTouched(name);
  };

  handleInputChange = (event) => {
    const { handleChange } = this.props;
    this.cleanUpError();
    handleChange(event);
  };

  cleanUpError = () => {
    const { shouldErrorBeDisplayed } = this.state;
    if (shouldErrorBeDisplayed === true) {
      this.setState(({ shouldErrorBeDisplayed }) => ({
        shouldErrorBeDisplayed: !shouldErrorBeDisplayed,
      }));
    }
    const { contractIdError, clearContractIdError } = this.props;
    if (contractIdError) clearContractIdError();
  };

  render() {
    const { selectedIndex, componentIndex, values, handleChange, errors, contractIdError, isLoading } = this.props;
    const { shouldErrorBeDisplayed, isAgreed } = this.state;
    if (selectedIndex !== componentIndex) return null;
    return (
      <div className="row">
        <form className="form-container-contract" autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid>
            <Row>
              {!!contractIdError && (
                <Row>
                  <Col xs={12} className="col">
                    <ErrorPanel message={contractIdError} buttonClickHandler={this.cleanUpError} />
                  </Col>
                </Row>
              )}
            </Row>
            {shouldErrorBeDisplayed &&
              Object.values(errors).map((error) => (
                <Row key={error}>
                  <Col xs={12} className="col">
                    <ErrorPanel message={error} buttonClickHandler={this.cleanUpError} />
                  </Col>
                </Row>
              ))}
            <Row>
              <Col xs={12} md={6} className="col">
                <label>
                  <span className="labelForm">Legal company name</span>
                  <input
                    type="text"
                    placeholder="Enter"
                    className="inputField"
                    name={COMPANY_NAME}
                    onChange={handleChange}
                    onBlur={this.handleBlur}
                    value={values[COMPANY_NAME]}
                  />
                </label>
              </Col>
              <Col xs={12} md={6} className="col">
                <label>
                  <span className="labelForm">Acreto Contract ID</span>
                  <input
                    value={values[ACRETO_CONTRACT_ID]}
                    type="text"
                    placeholder="Enter"
                    className="inputField"
                    name={ACRETO_CONTRACT_ID}
                    onChange={handleChange}
                    onBlur={this.handleBlur}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <label className="checkbox-label wedge-checkbox-container">
                  <input
                    type="checkbox"
                    checked={isAgreed}
                    onChange={(ev) => this.setState({ isAgreed: ev.target.checked })}
                  />
                  <span className="checkmark" />
                  <p className="check_title">I am authorized by my organization to use this contract ID</p>
                </label>
              </Col>
              <Col xs={12} className="submitHolder">
                {isLoading ? (
                  <Spinner spinnerColor="#4a0b96" />
                ) : (
                  <input type="submit" value="Submit" disabled={!isAgreed} className="submit" />
                )}
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    );
  }
}

ContractIdRegistration.propTypes = {
  selectedIndex: number.isRequired,
  componentIndex: number.isRequired,
  isLoading: bool.isRequired,
  setStripe: func.isRequired,
  registerCustomerWithContractId: func.isRequired,
  clearContractIdError: func.isRequired,
  isValid: bool.isRequired,
  values: object.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  contractIdError: string.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = (state) => ({
  contractIdError: contractIdErrorSelector(state),
  isLoading: isLoadingSelector(state),
  initialCompanyName: initialCompanyNameSelector(state),
});

const mapDispatchToProps = (dispatch, { stripe }) => ({
  registerCustomerWithContractId: (payload) => dispatch(registerCustomerWithContractId(payload)),
  clearContractIdError: () => dispatch(clearContractIdError()),
  setStripe: () => dispatch(setStripe(stripe)),
});

export default compose(
  injectStripe,
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ initialCompanyName }) => ({
      [ACRETO_CONTRACT_ID]: '',
      [COMPANY_NAME]: initialCompanyName,
    }),
    validationSchema: contractIdRegistrationValidationSchema,
    displayName: 'contractIdRegistrationForm',
  }),
)(ContractIdRegistration);
