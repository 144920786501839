import { FINISH_STARTUP, HIDE_LOADER, SHOW_LOADER, WS_CONNECTED, WS_DISCONNECTED } from './action-types';

const initialState = {
  startupFinished: false,
  isLoading: false,
  wsConnected: false,
};

export function globalReducer(state = initialState, { type }) {
  switch (type) {
    case WS_CONNECTED:
      return {
        ...state,
        wsConnected: true,
      };
    case WS_DISCONNECTED:
      return {
        ...state,
        wsConnected: false,
      };
    case FINISH_STARTUP:
      return {
        ...state,
        startupFinished: true,
      };
    case SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
