import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import Files from 'react-files';
import ReactPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Row, Col, Button, Dropdown, MenuItem } from 'react-bootstrap';

import WedgeModal from '../../../components/WedgeModal';
import { clearPasswordError, configOTP } from '../../../store/user/actions';
import { errorSelector, pwdErrorSelector } from '../../../store/user/selectors';
import { createErrorMessageSelector } from '../../../store/utils/selectors';
import history from '../../../history';
import Active2FA from '../../../Modals/Active2FA';
import Deactive2FA from '../../../Modals/Deactive2FA';
import Confirm2FA from '../../../Modals/Confirm2FA';
import { updateUserProfile, updateUserPassword, activate2FA, deactivate2FA, logout2FA } from '../senario-actions';

import 'react-phone-number-input/style.css';
import '../user-profile.scss';

const TIMEOUT_OPTIONS = [300, 600, 1800];

class User extends React.Component {
  constructor(props) {
    super(props);
    const { user, pwdError } = this.props;
    const userForm = this.setUserForm(user);
    this.state = {
      userForm,
      originalForm: { ...userForm },
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      unitedError: '',
      passwordError: pwdError,
      open2FA: false,
      openDisable2FA: false,
      openEnableConfirm: false,
      openDisableConfirm: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { userError, pwdError } = this.props;

    if (userError !== prevProps.userError) {
      this.setState({ unitedError: this.props.userError });
    }
    if (pwdError !== prevProps.pwdError) {
      this.setState({ passwordError: this.props.pwdError });
    }
  }

  componentWillUnmount() {
    this.props.clearPasswordError();
  }

  setUserForm = (user) => ({
    firstName: user.firstName,
    lastName: user.lastName,
    mobilePhone: user.mobilePhone,
    email: user.email,
    avatar: user.avatar || '',
    tokenTTL: user.tokenTTL || 10,
  });

  isFormChanged = () => {
    const { originalForm, userForm } = this.state;
    return JSON.stringify(originalForm) !== JSON.stringify(userForm);
  };

  isPWDFormChanged = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    return currentPassword || newPassword || confirmPassword;
  };

  handleChange = (event) => {
    const { userForm } = this.state;
    this.setState(
      {
        userForm: {
          ...userForm,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.checkFormChanged();
      },
    );
    if (event.target.type === 'password') {
      this.setState({ passwordError: '' });
    } else {
      this.setState({ unitedError: '' });
    }
  };

  handlePWDChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.checkFormChanged();
    });
    if (event.target.type === 'password') {
      this.setState({ passwordError: '' });
    } else {
      this.setState({ unitedError: '' });
    }
  };

  handleTimeoutChange = (ttl) => {
    const { userForm } = this.state;
    userForm.tokenTTL = ttl;
    this.setState({ userForm }, this.checkFormChanged());
  };

  isValid = () => {
    const { firstName, lastName, mobilePhone } = this.state.userForm;
    const regex = /^\+\d+\s*(\(\d+\))?[- \d]+$/;

    if (!firstName) {
      this.setState({
        unitedError: 'First Name should contain at least 1 character.',
      });
      return false;
    }
    if (!lastName) {
      this.setState({
        unitedError: 'Last Name should contain at least 1 character.',
      });
      return false;
    }
    if (!mobilePhone) {
      this.setState({ unitedError: 'Phone Number is required' });
      return false;
    }
    if (!regex.test(mobilePhone) || !isValidPhoneNumber(mobilePhone)) {
      this.setState({ unitedError: 'Invalid Phone Number' });
      return false;
    }
    return true;
  };

  isValidPassword = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (!currentPassword) {
      this.setState({ passwordError: 'Please input current password' });
      return false;
    }
    if (newPassword !== confirmPassword) {
      this.setState({ passwordError: 'Passwords do not match' });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    if (!this.isValid()) {
      return;
    }
    if (!this.isFormChanged()) {
      this.handleCancel();
      return;
    }
    const { userForm } = this.state;
    const payload = {
      avatar: userForm.avatar,
      email: userForm.email,
      firstName: userForm.firstName,
      lastName: userForm.lastName,
      mobilePhone: userForm.mobilePhone,
      tokenTTL: userForm.tokenTTL,
    };
    this.props.updateUserProfile(payload);
  };

  handleCancel = () => {
    history.goBack();
  };

  handleUpdatePWD = (event) => {
    event.preventDefault();
    if (!this.isValidPassword()) {
      return;
    }
    const {
      userForm: { email },
      currentPassword,
      newPassword,
    } = this.state;
    this.props.updateUserPassword({
      email,
      currentPassword,
      newPassword,
    });
  };

  getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      this.setState({ unitedError: error });
    };
  };

  handleFileChange = (files) => {
    const { userForm } = this.state;
    this.getBase64(files[files.length - 1], (result) => {
      this.setState({ userForm: { ...userForm, avatar: result } }, () => {
        this.checkFormChanged();
      });
    });
  };

  handleFileError = (err) => {
    this.setState({ unitedError: err });
  };

  handleOpen2FA = async () => {
    const {
      user: { mfa },
    } = this.props;
    if (mfa) {
      this.setState({ openDisable2FA: true });
    } else {
      await this.props.configOTP();
      this.setState({ open2FA: true });
    }
  };

  handleActivate2FA = async (code) => {
    await this.props.activate2FA(code);
    const { active2FAError } = this.props;
    if (!active2FAError) {
      this.setState({ open2FA: false, openEnableConfirm: true });
    }
  };

  handleDeactivate2FA = async (code) => {
    await this.props.deactivate2FA(code);
    const { deactive2FAError } = this.props;
    if (!deactive2FAError) {
      this.setState({ openDisable2FA: false, openDisableConfirm: true });
    }
  };

  handleLogout = () => {
    const { openEnableConfirm } = this.state;
    this.props.logout(
      openEnableConfirm
        ? 'Please login again with Two-Factor Authenticaion'
        : 'Two-Factor Authentication disabled. Please login again.',
    );
  };

  checkFormChanged() {
    this.props.onFormChanged(this.isFormChanged() || this.isPWDFormChanged());
  }

  render() {
    const {
      user: { mfa },
      deactive2FAError,
      active2FAError,
    } = this.props;
    const {
      userForm,
      userForm: {
        firstName,
        lastName,
        email,
        mobilePhone,
        currentPassword,
        newPassword,
        confirmPassword,
        avatar,
        tokenTTL,
      },
      unitedError,
      passwordError,
      open2FA,
      openDisable2FA,
      openEnableConfirm,
      openDisableConfirm,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row user-profile-container">
          <div className="col col-xs-12 col-sm-3 col-md-2 avatar-container">
            <div className="left-align">
              <Avatar src={avatar} size="132" round />
              <Files
                className="files-dropzone"
                onChange={this.handleFileChange}
                onError={this.handleFileError}
                accepts={['image/*']}
                multiple={false}
              >
                <Button className="button button-change button-change-avatar">Change</Button>
              </Files>
              <div
                className="button-remove"
                onClick={() =>
                  this.setState({ userForm: { ...userForm, avatar: '' } }, () => {
                    this.checkFormChanged();
                  })
                }
              >
                Remove
              </div>
            </div>
          </div>
          <div className="col col-xs-12 col-sm-9 col-md-10 form-container">
            <div className="user-info-form">
              <Row>
                {unitedError && (
                  <Col md={12}>
                    <div className="error-msg">{unitedError}</div>
                  </Col>
                )}
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">FIRST NAME</span>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="inputField"
                      onChange={this.handleChange}
                      name="firstName"
                      value={firstName}
                    />
                  </label>
                </Col>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">LAST NAME</span>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="inputField"
                      onChange={this.handleChange}
                      name="lastName"
                      value={lastName}
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">EMAIL</span>
                    <input
                      type="email"
                      className="inputField"
                      onChange={this.handleChange}
                      name="email"
                      value={email}
                      disabled
                    />
                  </label>
                </Col>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">MOBILE NUMBER</span>
                    <ReactPhoneInput
                      country="US"
                      placeholder="Enter Mobile Number"
                      className="inputField"
                      onChange={(value) =>
                        this.setState(
                          {
                            userForm: {
                              ...userForm,
                              mobilePhone: value,
                            },
                          },
                          () => {
                            this.checkFormChanged();
                          },
                        )
                      }
                      name="mobilePhone"
                      value={mobilePhone}
                      required
                    />
                  </label>
                </Col>
              </Row>
            </div>
            <form className="password-form" onSubmit={this.handleUpdatePWD}>
              <h4 className="title">Change Password</h4>
              <div className="divider" />
              <Row>
                {passwordError && (
                  <Col md={12}>
                    <div className="error-msg">{passwordError}</div>
                  </Col>
                )}
                <Col md={12}>
                  <label>
                    <span className="labelForm">CURRENT PASSWORD</span>
                    <input
                      type="password"
                      placeholder="Enter Current Password"
                      className="inputField"
                      onChange={this.handlePWDChange}
                      name="currentPassword"
                      value={currentPassword}
                      required
                    />
                  </label>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <span className="labelForm">NEW PASSWORD</span>
                    <input
                      type="password"
                      placeholder="Enter New Password"
                      className="inputField"
                      onChange={this.handlePWDChange}
                      pattern=".{8,}"
                      title="Password should contain at least 8 chars."
                      name="newPassword"
                      value={newPassword}
                      required
                    />
                  </label>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <span className="labelForm">CONFIRM PASSWORD</span>
                    <input
                      type="password"
                      placeholder="Enter Confirm Password"
                      className="inputField"
                      onChange={this.handlePWDChange}
                      pattern=".{8,}"
                      title="Password should contain at least 8 chars."
                      name="confirmPassword"
                      value={confirmPassword}
                      required
                    />
                  </label>
                </Col>
              </Row>
              <div className="button-container">
                <Button type="submit" className="button button-update">
                  Update Password
                </Button>
              </div>
            </form>
            <form className="mfa-form">
              <h4 className="title">Two-Factor Authentication (2FA)</h4>
              <div className="divider" />
              <div className="mfa-setting">
                <div>
                  <span className="mfa-label">Two-Factor Authentication status:</span>
                  <span className={`mfa-status ${mfa ? 'enabled' : 'disabled'}`}>{mfa ? 'ENABLED' : 'DISABLED'}</span>
                </div>
                <div className="button-container">
                  <Button className={`button button-${mfa ? 'disable' : 'update'}`} onClick={this.handleOpen2FA}>
                    {mfa ? 'Disable' : 'Enable'}
                  </Button>
                </div>
              </div>
            </form>
            <form className="setting-form">
              <h4 className="title">Settings</h4>
              <div className="divider" />
              <Row>
                <Col xs={12} md={6}>
                  <label>
                    <span className="labelForm">INACTIVITY TIMEOUT</span>
                    <Dropdown id="user-idle-timeout">
                      <Dropdown.Toggle>{`${Math.round(tokenTTL / 60)} min`}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {TIMEOUT_OPTIONS.map((option) => (
                          <MenuItem
                            key={`idle-timeout-${option}`}
                            active={option === tokenTTL}
                            onSelect={() => this.handleTimeoutChange(option)}
                          >
                            {`${option / 60} min`}
                          </MenuItem>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </label>
                </Col>
              </Row>
            </form>
          </div>
        </div>
        <div className="divider" />
        <div className="button-container">
          <Button className="button button-cancel" onClick={() => this.handleCancel()}>
            Cancel
          </Button>
          <Button className="button button-update" onClick={() => this.handleSubmit()}>
            Update
          </Button>
        </div>
        <WedgeModal
          title="Enable Two-Factor Authentication"
          onClose={() => this.setState({ open2FA: false })}
          isOpen={open2FA}
          size="small"
        >
          <Active2FA apiError={active2FAError} onSubmit={(code) => this.handleActivate2FA(code)} />
        </WedgeModal>
        <WedgeModal
          title="Disable Two-Factor Authentication"
          onClose={() => this.setState({ openDisable2FA: false })}
          isOpen={openDisable2FA}
          size="tiny"
        >
          <Deactive2FA apiError={deactive2FAError} onSubmit={(code) => this.handleDeactivate2FA(code)} />
        </WedgeModal>
        <WedgeModal
          title={openEnableConfirm ? 'Enable Two-Factor Authentication' : 'Disable Two-Factor Authentication'}
          onClose={this.handleLogout}
          isOpen={openDisableConfirm || openEnableConfirm}
          size="tiny"
        >
          <Confirm2FA action={openEnableConfirm ? 'enable' : 'disable'} onClick={this.handleLogout} />
        </WedgeModal>
      </React.Fragment>
    );
  }
}

const updateUserError = createErrorMessageSelector(['UPDATE_USER']);
const active2FAError = createErrorMessageSelector(['ACTIVATE_2FA']);
const deactive2FAError = createErrorMessageSelector(['DEACTIVATE_2FA']);

User.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  userError: PropTypes.string.isRequired,
  pwdError: PropTypes.string.isRequired,
  clearPasswordError: PropTypes.func.isRequired,
  onFormChanged: PropTypes.func.isRequired,
  configOTP: PropTypes.func.isRequired,
  activate2FA: PropTypes.func.isRequired,
  deactivate2FA: PropTypes.func.isRequired,
  active2FAError: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
  deactive2FAError: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  userError: errorSelector(state),
  pwdError: pwdErrorSelector(state),
  updateError: updateUserError(state),
  active2FAError: active2FAError(state),
  deactive2FAError: deactive2FAError(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (payload) => dispatch(updateUserProfile(payload)),
  updateUserPassword: (payload) => dispatch(updateUserPassword(payload)),
  clearPasswordError: () => dispatch(clearPasswordError()),
  configOTP: () => dispatch(configOTP()),
  activate2FA: (code) => dispatch(activate2FA(code)),
  deactivate2FA: (code) => dispatch(deactivate2FA(code)),
  logout: (message) => dispatch(logout2FA(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
