import React from 'react';

export const LDAP_EXTERNAL_SYSTEM = {
  AZURE_AD: 'ldap-azure',
  WINDOWS_ON_PREM: 'ldap-ad',
  OKTA: 'ldap-okta',
  GENERIC: 'ldap-generic',
};

export const LDAP_TYPES_CONFIG = {
  [LDAP_EXTERNAL_SYSTEM.WINDOWS_ON_PREM]: {
    userDNPlaceholder: 'CN=Users, DC=SOMEDOMAIN, DC=com',
    connTitle: 'Windows On-Premise Connection',
  },
  [LDAP_EXTERNAL_SYSTEM.AZURE_AD]: {
    userDNPlaceholder: 'OU=AADDC Users, DC=somedomain, DC=onmicrosoft, DC=com',
    connTitle: 'Azure Connection',
  },
  [LDAP_EXTERNAL_SYSTEM.OKTA]: {
    userDNPlaceholder: 'OU=users, DC=somedomain, DC=okta, DC=com',
    connTitle: 'OKTA Connection',
  },
  [LDAP_EXTERNAL_SYSTEM.GENERIC]: {
    userDNPlaceholder: 'OU=users, DC=somedomain, DC=com',
    connTitle: 'Generic LDAP Connection',
  },
};

export const EXTERNAL_SYSTEM_OPTIONS = [
  {
    value: LDAP_EXTERNAL_SYSTEM.WINDOWS_ON_PREM,
    label: <>Active Directory (LDAP interface) - On-premise Windows Server</>,
    helpId: '/how-to/identity-providers/active-directory-ldap/',
    sup: true,
  },
  {
    value: LDAP_EXTERNAL_SYSTEM.AZURE_AD,
    label: <>Active Directory (LDAP interface) - Microsoft Azure</>,
    helpId: '/how-to/identity-providers/azure-active-directory-ldap/',
    sup: true,
  },
  {
    value: LDAP_EXTERNAL_SYSTEM.OKTA,
    label: <>OKTA (LDAP interface)</>,
    helpId: '/how-to/identity-providers/okta-ldap/',
    sup: true,
  },
  {
    value: LDAP_EXTERNAL_SYSTEM.GENERIC,
    label: <>Generic LDAP</>,
    helpId: '/how-to/identity-providers/generic-ldap/',
    sup: true,
  },
];
