import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import './expiry-warning.scss';
import IdleTimer from 'react-idle-timer';
import { logout } from '../../store/common-scenario-actions';

class ExpiryWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: false,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState((prev) => ({ switch: !prev.switch }));
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      user: { tokenTTL },
    } = this.props;
    const remain = this.idleTimer && this.idleTimer.getRemainingTime() / 1000;
    const show = remain < 59;

    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.props.logout}
          timeout={1000 * tokenTTL}
          debounce={1000}
        />
        <div className={`expiry-warning ${show ? 'visible' : 'hidden'}`}>
          {`You have been inactive for almost ${Math.round(
            tokenTTL / 60,
          )} minutes and you will be signed out in ${Math.round(
            remain,
          )} seconds. You can change that in `}
          <a href="/v1/auth/profile">Profile Setting</a>
        </div>
      </div>
    );
  }
}

ExpiryWarning.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpiryWarning);
