import * as REST from '../../api/rest';
import { clearData, logoutUser } from '../auth/actions';
import { DELETE_ERROR } from './action-types';
const initialState = {
  error: null,
  errorKey: '',
};
const errorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  if (!matches) {
    if (type === DELETE_ERROR) {
      return initialState;
    }
    return state;
  }

  const [, requestName, requestState] = matches;

  if (requestState === 'FAILURE') {
    if (payload.message.response && payload.message.response.status === 401) {
      REST.logout();
      action.asyncDispatch(logoutUser());
      action.asyncDispatch(clearData());
    }
    return {
      ...state,
      errorKey: requestName,
      error: payload.message,
    };
  }
  if (state.errorKey === requestName) {
    return initialState;
  }
  return state;
};

export default errorReducer;
