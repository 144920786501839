import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ROUTES } from '../../enum';
import Header from '../../components/Header/Header';
import ExpiryWarning from '../../components/ExpiryWarning/ExpiryWarning';
import CustomersForm from '../../views/CustomersForm/CustomersForm';
import history from '../../history';
import { removeCurrentCustomer } from '../../store/customers/actions';
import { customersSelector } from '../../store/user/selectors';
import './organizations.scss';

class Organizations extends Component {
  componentDidMount() {
    const { location, customers } = this.props;
    if ((location.state && location.state.from) || customers.length !== 1) {
      this.props.removeCurrentCustomer();
    } else {
      history.push(`${ROUTES.CUSTOMERS}/${customers[0].uuid}/ecosystems`);
    }
  }

  renderCustomers() {
    return (
      <div>
        <div style={{ width: '100%' }}>
          <Switch>
            <Route path="/" component={CustomersForm} />
          </Switch>
        </div>
        <div className="d-flex">
          <Link to={ROUTES.ORG_INFO} className="button">
            {'Add new '}
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="wrapper organizations-wrapper">
          <ExpiryWarning />
          <Header brandText="All Organizations" {...this.props} />
          {this.renderCustomers()}
        </div>
      </div>
    );
  }
}

Organizations.defaultProps = {
  customers: [],
};

Organizations.propTypes = {
  removeCurrentCustomer: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  customers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  customers: customersSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeCurrentCustomer: () => dispatch(removeCurrentCustomer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
