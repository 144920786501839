import { createAction } from 'redux-actions';
import {
  SET_USER,
  SET_EMAIL,
  START_LOADING,
  FINISH_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  SET_PASSWORD_ERROR,
  CLEAR_PASSWORD_ERROR,
  SET_TOKEN,
  CONFIRM_EMAIL,
  SET_INIT_COMPANY_NAME,
  COMPLETE_USER,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  EMAIL_CONFIRM_FAILURE,
  RESET_PASSWORD,
  CONFIG_OTP,
  SET_USER_OTP,
} from './actionTypes';
import { CLEAR_DATA } from '../common-action-types';

export const setUser = createAction(SET_USER);
export const setEmail = createAction(SET_EMAIL);
export const startLoading = createAction(START_LOADING);
export const finishLoading = createAction(FINISH_LOADING);
export const setError = createAction(SET_ERROR);
export const clearError = createAction(CLEAR_ERROR);
export const setPasswordError = createAction(SET_PASSWORD_ERROR);
export const clearPasswordError = createAction(CLEAR_PASSWORD_ERROR);
export const setToken = createAction(SET_TOKEN);
export const confirmEmail = createAction(CONFIRM_EMAIL);
export const setInitCompanyName = createAction(SET_INIT_COMPANY_NAME);
export const completeUser = createAction(COMPLETE_USER);
export const updateUser = createAction(UPDATE_USER);
export const resetPasswordUser = createAction(RESET_PASSWORD);
export const configOTP = createAction(CONFIG_OTP);
export const setUserOTP = createAction(SET_USER_OTP);
export const clearUserData = createAction(CLEAR_DATA);

export function updateUserFailed(error) {
  return {
    type: UPDATE_USER_FAILURE,
    payload: {
      message: error,
    },
  };
}

export function emailConfirmFailed(error) {
  return {
    type: EMAIL_CONFIRM_FAILURE,
    payload: {
      message: error,
    },
  };
}
