import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './style.scss';

const Deactive2FA = ({ apiError, ...props }) => {
  const [code, setCode] = useState('');

  const handleSubmit = () => {
    props.onSubmit(code);
  };

  return (
    <div className="modal__content padded deactive-2fa">
      {!!apiError && <p className="error">Invalid Code</p>}
      <div className="description">
        <p>Enter the six-digit code you see in your Two-Factor Authentication app to confirm this operation.</p>
      </div>
      <div className="code-input">
        <ReactCodeInput
          fields={6}
          type="number"
          onChange={(value) => setCode(value)}
          inputStyle={{ borderBottom: 'solid 2px #C3D5FF' }}
        />
      </div>
      <div className="button-container">
        <Button bsStyle="primary" bsSize="large" disabled={code.length !== 6} onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

Deactive2FA.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  apiError: PropTypes.any,
};

export default Deactive2FA;
