import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';

import Form from '../Form';

import './styles.scss';

const CommitReminder = ({ onClose, onCommit }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="commit-reminder">
      <div className="commit-reminder-dialog">
        <div className="description">
          Changes not in effect until applied. You can apply changes now, or save for later and apply using &quot;Apply
          Changes&quot; button on top right.
        </div>
        <ButtonToolbar className="buttons">
          <Button bsSize="large" onClick={() => onClose(checked)}>
            Save for later
          </Button>
          <Button bsSize="large" bsStyle="primary" onClick={() => onCommit(checked)}>
            Apply Changes now
          </Button>
        </ButtonToolbar>
        <Form.Checkbox
          checked={checked}
          onChange={(ev) => setChecked(ev)}
          label="Don't show me this message in the future"
        />
      </div>
    </div>
  );
};

CommitReminder.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
};

export default CommitReminder;
