import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CustomRadio extends Component {
  render() {
    const { value, label, ...rest } = this.props;

    return (
      <div className="radio">
        <input id={value} name={value} type="radio" value={value} {...rest} />
        <label htmlFor={value}>{label}</label>
      </div>
    );
  }
}
CustomRadio.propTypes = {
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default CustomRadio;
