/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';

import Card from '../components/Card';
import Descriptor from '../components/Descriptor';
import Form from '../components/Form';
import SelectWithSearch from '../components/SelectWithSearch/SelectWithSearch';

import { ADDRESS_TYPE } from '../enum/address';
import { EXPIRATION_TYPE } from '../enum/expiration';
import { IP_TYPE, IP_TYPE_OPTIONS } from '../enum/ip';
import { LOCATION_TYPE_OPTIONS, DEFAULT_LOCATION } from '../enum/location';
import { OBJECT_CATEGORIES, OBJECT_TYPE_OPTIONS } from '../enum/object';

import Translator from '../utils/enumTranslator';
import IPValidator from '../utils/ipValidator';
import addressValidationSchema from '../validationSchemas/addressValidationSchema';
import { Footer } from './commons';

import 'react-datepicker/dist/react-datepicker.css';
import './modals.scss';

const ExpiryInput = ({ value, onClick }) => <input className="expiry-input" value={value} onClick={onClick} readOnly />;

class NewAddressSurvey extends React.Component {
  constructor(props) {
    super(props);
    if (props.edit) {
      this.state = {
        name: props.item.name,
        category: Translator.category(props.item.category),
        type: OBJECT_TYPE_OPTIONS[1],
        protocolType: props.item.type,
        addressType: ADDRESS_TYPE.INTERNAL,
        address: props.item.address,
        expiryTime: props.item.expiry && props.item.expiry.time ? new Date(props.item.expiry.time) : null,
        expiryType: props.item.expiry && props.item.expiry.type ? props.item.expiry.type : EXPIRATION_TYPE.SOFT,
        desc: props.item.description || '',
        location: props.item.geolocation ? LOCATION_TYPE_OPTIONS[0] : LOCATION_TYPE_OPTIONS[1],
        lat: (!!props.item.geolocation && props.item.geolocation.latitude) || DEFAULT_LOCATION.LAT,
        lng: (!!props.item.geolocation && props.item.geolocation.longitude) || DEFAULT_LOCATION.LNG,
        errors: [],
        isSubmitted: false,
        tabKey: 1,
      };
    } else {
      this.state = {
        name: '',
        addressType: ADDRESS_TYPE.INTERNAL,
        address: '',
        category: null,
        protocolType: IP_TYPE.IPv4,
        type: OBJECT_TYPE_OPTIONS[1],
        expiryTime: null,
        expiryType: EXPIRATION_TYPE.SOFT,
        desc: '',
        location: LOCATION_TYPE_OPTIONS[0],
        lng: DEFAULT_LOCATION.LNG,
        lat: DEFAULT_LOCATION.LAT,
        errors: [],
        isSubmitted: false,
        tabKey: 1,
      };
    }
  }

  changeAddressField = (value) => {
    const { name, address } = this.state;
    if (!this.props.edit && (address === name || name === '')) {
      this.setState({
        address: value,
        name: value,
      });
    } else {
      this.changeField('address', value);
    }
  };

  changeField = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onFinish = () => {
    this.setState(
      {
        errors: [],
        isSubmitted: true,
      },
      () => {
        if (this.validate()) {
          try {
            this.props.onFinish(this.state);
          } catch (err) {
            this.setState({
              errors: [this.props.edit ? 'Failed to Update Address' : 'Failed to Add Address'],
            });
          }
        }
      },
    );
  };

  validOptions = () => {
    const { address, errors, protocolType } = this.state;
    if (!address) {
      return true;
    }
    if (
      !IPValidator(address, protocolType === IP_TYPE.IPv4 ? '4' : protocolType === IP_TYPE.IPv6 ? '6' : IP_TYPE.FQDN)
    ) {
      errors.push('Invalid address');
      this.setState({ errors });
      return false;
    }
    return true;
  };

  validate() {
    if (!this.state.isSubmitted) {
      return true;
    }
    if (!this.validOptions()) {
      return false;
    }
    try {
      addressValidationSchema.validateSync(this.state, { abortEarly: false });
      this.setState({
        errors: [],
      });
      return true;
    } catch (err) {
      this.contentElement.scrollIntoView({
        behavior: 'smooth',
      });
      this.setState({
        errors: err.errors,
      });
      return false;
    }
  }

  renderConfig() {
    const { tabKey, errors } = this.state;

    return (
      <div className="modal__content padded new-address-survey">
        {tabKey === 1 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Card header={false}>
          <Form.Group center label="">
            <Form.Toggle
              selected={this.state.protocolType}
              selectedClass="toggle-selected"
              onChange={(val) => this.changeField('protocolType', val)}
              options={IP_TYPE_OPTIONS}
            />
          </Form.Group>
          <Form.Group required label="Name">
            <Form.Text value={this.state.name} onChange={(val) => this.changeField('name', val)} placeholder="Name" />
          </Form.Group>
          <Form.Group required label="Address">
            <Form.Text value={this.state.address} onChange={this.changeAddressField} placeholder="Address" />
          </Form.Group>
          <div className="form-row">
            <Form.Group required label="Category">
              <SelectWithSearch
                selected={this.state.category}
                onChange={(val) => this.changeField('category', val)}
                placeholder="Select category"
                groupCreative={false}
                options={OBJECT_CATEGORIES}
              />
            </Form.Group>
            <Form.Group label="Type">
              <p className="medium strong">{this.state.type.label}</p>
            </Form.Group>
          </div>
          {/* Hide until API is ready
							<div className={'form-row'}>
								<Form.Group label={'Expiry'}>
									<DatePicker
										isClearable
										popperPlacement={'top-end'}
										dateFormat={'MMM dd, yyyy'}
										selected={this.state.expiryTime}
										onChange={val => this.changeField('expiryTime', val)}
										customInput={<ExpiryInput />}
									/>
								</Form.Group>
							</div> */}
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { tabKey, errors } = this.state;

    return (
      <div className="modal__content padded new-address-survey">
        {tabKey === 2 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Descriptor
          desc={this.state.desc}
          location={this.state.location}
          lat={this.state.lat}
          lng={this.state.lng}
          onChange={(field, value) => this.changeField(field, value)}
        />
      </div>
    );
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.contentElement = node;
        }}
      >
        <Tabs defaultActiveKey={1} id="object__tabs" onSelect={(key) => this.setState({ tabKey: key })}>
          <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
            {this.renderConfig()}
          </Tab>
          <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
            {this.renderDesc()}
          </Tab>
        </Tabs>
        <div className="wedge-modal__footer">
          <Footer onClick={this.onFinish} edit={this.props.edit} onDelete={this.props.onDelete} />
        </div>
      </div>
    );
  }
}

NewAddressSurvey.defaultProps = {
  edit: false,
};

NewAddressSurvey.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
};

ExpiryInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

NewAddressSurvey.Footer = Footer;

export default NewAddressSurvey;
