import jwt from 'jsonwebtoken';
import unique from 'lodash/uniqBy';
import { OBJECT_MENU_CONFIG } from '../enum/object';

export function extractCustomers(roles) {
  if (!roles || roles === ' ') {
    return [];
  }
  const split = roles.split(' ');
  const customers = split.map((part) => ({
    name: part.split('.')[0],
    uuid: part.split('.')[1],
  }));

  return unique(customers, 'uuid');
}

export function extractCustomerFromToken(token) {
  const decodedToken = jwt.decode(token, { json: true });
  return extractCustomers(decodedToken.roles);
}

export function extractCustomer(customers) {
  return (
    customers &&
    customers.map((customer) => ({
      uuid: customer.uuid,
      name: customer.name,
    }))
  );
}

export function extractUsernameFromToken(token) {
  const decodedToken = jwt.decode(token, { json: true });
  return decodedToken.username;
}

export function pathSlugToPageName(pathname) {
  const splittedPathName = pathname.split('/');
  let slug = splittedPathName[6];
  if (splittedPathName[6] === 'objects') {
    slug =
      splittedPathName.length > 7 && OBJECT_MENU_CONFIG.map((obj) => obj.url).includes(splittedPathName[7])
        ? splittedPathName[7]
        : 'objects';
  }
  if (splittedPathName[6] === 'security') {
    slug = splittedPathName[7] && splittedPathName[7] === 'web-filter' ? 'Website Filtering' : 'Security Policies';
  }
  if (splittedPathName[6] === 'logs') {
    slug = splittedPathName.length > 7 ? splittedPathName[7] : 'logs';
  }
  if (splittedPathName[6] === 'settings') slug = splittedPathName[7];

  switch (slug) {
    case 'objects':
      return 'Objects';
    case 'addresses':
      return 'Addresses';
    case 'gateways':
      return 'Gateways';
    case 'things':
      return 'Things';
    case 'publicAddresses':
      return 'Allocated IPs';
    case 'users':
      return 'Users';
    case 'servers':
      return 'Identity Providers';
    case 'addresstranslations':
      return 'Address Translation';
    case 'profile':
      return 'Edit Profile';
    case 'security':
      return 'Security';
    case 'logs':
      return 'Logs';
    case 'traffic':
      return 'Traffic Logs';
    case 'gateway':
      return 'Gateway Logs';
    case 'usersandthings':
      return 'User and Thing Logs';
    case 'settings':
      return 'Log Settings';
    case 'admin-access':
      return 'Admin Access';
    default:
      return slug;
  }
}

export function parseResponseError(error, errorsArray = {}) {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        return errorsArray['400'] || 'Wrong request';
      case 502:
        return errorsArray['502'] || 'Bad Gateway';
      case 424:
        return errorsArray['424'] || 'This operation requires multi-factor authentication.';
      default:
        return 'Something went wrong. Please try again later.';
    }
  }
  return 'Cannot connect to our servers.';
}

export function uuidValidation(items, uuid) {
  const valid = items.find((item) => item.uuid === uuid);

  if (!valid) return false;
  return true;
}

export function calcLatng([lat, lng]) {
  const numLat = Number(lat) % 90;
  const numLng = Number(lng) % 180;
  return {
    lat: Math.floor((Math.abs(lat) / 90) % 2)
      ? numLat - 90 * (numLat === 0 ? -Math.sign(lat) : Math.sign(lat))
      : numLat,
    lng: Math.floor((Math.abs(lng) / 180) % 2)
      ? numLng - 180 * (numLng === 0 ? -Math.sign(lng) : Math.sign(lng))
      : numLng,
  };
}

export function downloadFile(downloadLink) {
  const link = document.createElement('a');
  const paths = downloadLink.split('/');
  link.download = paths[paths.length - 1];
  link.href = downloadLink;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const getReverseKeyMappingForObject = (obj) =>
  Object.keys(obj).reduce((accObject, key) => {
    const newObj = accObject;
    newObj[obj[key]] = key;
    return newObj;
  }, {});

export const escapeDSLCharacters = (str) => str.replace(/:/g, '\\:');

export const getErrorConfigs = ({ code = 400, message = '', headers = null, status = 0 }) => ({
  errorCode: code,
  errorMsg: message,
  errorHeader: headers,
  errorStatus: status,
});

export const serviceLabel = (service) => {
  const { name, protocol, dest, type, code } = service;
  if (dest && dest.end) {
    if (dest.begin === dest.end) return `${protocol}/${dest.end} (${name})`;
    return `${protocol}/${dest.begin}-${dest.end} (${name})`;
  }
  if (type) return `${protocol}/T:${type} C:${code}`;
  return `${protocol} (${name})`;
};
