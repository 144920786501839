import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './style.scss';

const ContextHelp = ({ helpLink }) => (
  <a href={`${process.env.REACT_APP_KB_URL}${helpLink}`} target="_blank" rel="noopener noreferrer">
    <Button bsClass="help-icon">?</Button>
  </a>
);

ContextHelp.propTypes = {
  helpLink: PropTypes.string.isRequired,
};

export default ContextHelp;
