import * as ACTION_TYPES from './action-types';
import { CLEAR_DATA } from '../common-action-types';

const initialState = {
  currentCustomer: {
    address: {
      city: '',
      country: 'us',
      line1: '',
      state: '',
      postal_code: '',
    },
    contact: {
      name: '',
      phone: '',
    },
    name: '',
  },
  fetchingCustomer: false,
};

export function customersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        fetchingCustomer: true,
      };
    case ACTION_TYPES.FETCH_CUSTOMER_SUCCESS:
    case ACTION_TYPES.UPDATE_CUSTOMER_SUCCESS:
    case ACTION_TYPES.SET_CUR_CUSTOMER:
      return {
        ...state,
        currentCustomer: { ...initialState.currentCustomer, ...payload },
        fetchingCustomer: false,
      };
    case CLEAR_DATA:
    case ACTION_TYPES.REMOVE_CUR_CUSTOMER:
      return {
        ...state,
        currentCustomer: initialState.currentCustomer,
      };
    case ACTION_TYPES.FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        fetchingCustomer: false,
      };
    default:
      return {
        ...state,
      };
  }
}
