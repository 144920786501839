import React from 'react';
import 'Modals/modals.scss';

export default function PrivacyPolicy() {
  return (
    <div className="modal__content privacy-policy">
      <div className="main header">
        <h4>Effective Date: November 19, 2019</h4>
      </div>
      <div className="encloser">
        <div className="main content">
          <div className="basic">
            <p>
              <span className="head">Acreto Cloud Corp. (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;)</span>
              &nbsp;operates the&nbsp;
              <a href="https://acreto.io" className="head">
                https://acreto.io
              </a>
              &nbsp;website and the Acreto Security platform (hereinafter referred to as the &quot;Service&quot; ).
            </p>
            <p>
              This page informs you of our policies regarding the collection, use and disclosure of personal data when
              you use our Service and the choices you have associated with that data.
            </p>
            <p>
              We use your data to provide and improve the Service. By using the Service, you agree to the collection and
              use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the
              terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </p>
          </div>
          <div className="section">
            <h4 className="header">Definitions</h4>
            <div className="content">
              <div className="menu">
                <h5 className="header black">Service</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>
                        Service means the&nbsp;
                        <a href="https://acreto.io" className="head">
                          https://acreto.io
                        </a>
                        &nbsp;website and the Acreto Security platform operated by Acreto Cloud Corp.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <h5 className="header black">Personal Data</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>
                        Personal Data means data about a living individual who can be identified from those data (or
                        from those and other information either in our possession or likely to come into our
                        possession).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <h5 className="header black">Usage Data</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>
                        Usage Data is data collected automatically either generated by the use of the Service or from
                        the Service infrastructure itself (for example, the duration of a page visit).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <h5 className="header black">Cookies</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>Cookies are small files stored on your device (computer or mobile device).</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <h5 className="header black">Data Controller</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>
                        Data Controller means the natural or legal person who (either alone or jointly or in common with
                        other persons) determines the purposes for which and the manner in which any personal
                        information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data
                        Controller of your Personal Data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <h5 className="header black">Data Processors (or Service Providers)</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>
                        Data Processor (or Service Provider) means any natural or legal person who processes the data on
                        behalf of the Data Controller.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <h5 className="header black">Data Subject (or User)</h5>
                <div className="content">
                  <div className="topic">
                    <div className="content">
                      <p>
                        Data Subject is any living individual who is using our Service and is the subject of Personal
                        Data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header"> Information Collection and Use</h4>
            <div className="menu">
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      We collect several different types of information for various purposes to provide and improve our
                      Service to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu">
              <h5 className="header">Types of Data Collected</h5>
              <div className="content">
                <div className="topic">
                  <h6 className="header">Personal Data</h6>
                  <div className="content">
                    <p>
                      While using our Service, we may ask you to provide us with certain personally identifiable
                      information that can be used to contact or identify you as a subscriber ( &quot;Personal
                      Data&quot;). Personally identifiable information may include, but is not limited to:
                    </p>
                    <ul className="dashed">
                      <li>Email address</li>
                      <li>First name and last name</li>
                      <li>Phone number</li>
                      <li>Address, State, Province, ZIP/Postal code, City</li>
                      <li>Usage Data</li>
                    </ul>
                    <p>
                      We may use your Personal Data to contact you with important information related to maintenance
                      updates and other operational updates.
                    </p>
                  </div>
                </div>
                <div className="topic">
                  <h6 className="header">Usage and Security Data</h6>
                  <div className="content">
                    <p>
                      We may also collect information that your browser sends whenever you visit our Service or when you
                      access the Service by or through a mobile device (&quot;Usage Data&quot;).
                    </p>
                    <p>
                      This Usage Data may include information such as your computer&apos;s Internet Protocol address
                      (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the
                      time and date of your visit, the time spent on those pages, unique device identifiers and other
                      diagnostic data.
                    </p>
                    <p>
                      When you access the Service with a mobile device, this Usage Data may include information such as
                      the type of mobile device you use, your mobile device unique ID, the IP address of your mobile
                      device, your mobile operating system, the type of mobile Internet browser you use, unique device
                      identifiers and other diagnostic data.
                    </p>
                  </div>
                </div>
                <div className="topic">
                  <h6 className="header">Location Data</h6>
                  <div className="content">
                    <p>
                      We may use and store information about your location if you give us permission to do so
                      (&quot;Location Data&quot;). We use this data to provide features of our Service, to improve and
                      customise our Service.
                    </p>
                    <p>
                      You can enable or disable location services when you use our Service at any time by way of your
                      device settings.
                    </p>
                  </div>
                </div>
                <div className="topic">
                  <h6 className="header">Tracking Cookies Data</h6>
                  <div className="content">
                    <p>
                      We use cookies and similar tracking technologies to track the activity on our Service and we hold
                      certain information.
                    </p>
                    <p>
                      Cookies are files with a small amount of data which may include an anonymous unique identifier.
                      Cookies are sent to your browser from a website and stored on your device. Other tracking
                      technologies are also used such as beacons, tags and scripts to collect and track information and
                      to improve and analyse our Service.
                    </p>
                    <p>
                      You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                      However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    </p>
                  </div>
                </div>
                <div className="topic">
                  <h6 className="header">Tracking Cookies Data</h6>
                  <div className="content">
                    <p>
                      We use cookies and similar tracking technologies to track the activity on our Service and we hold
                      certain information.
                    </p>
                    <p>
                      Cookies are files with a small amount of data which may include an anonymous unique identifier.
                      Cookies are sent to your browser from a website and stored on your device. Other tracking
                      technologies are also used such as beacons, tags and scripts to collect and track information and
                      to improve and analyse our Service.
                    </p>
                    <p>
                      You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                      However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Use of Data</h4>
            <div className="menu">
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>Acreto Cloud Corp. uses the collected data for various purposes:</p>
                    <ul className="dashed">
                      <li>To determine the identity of connected users and devices</li>
                      <li>To provide and maintain our Service</li>
                      <li>To notify you about changes to our Service</li>
                      <li>
                        To allow you to participate in interactive features of our Service when you choose to do so
                      </li>
                      <li>To provide customer support</li>
                      <li>To gather analysis or valuable information so that we can improve our Service</li>
                      <li>To monitor the usage of our Service</li>
                      <li>To detect, prevent and address technical issues</li>
                    </ul>
                    <p>
                      We may use your Personal Data to contact you with important information related to maintenance
                      updates and other operational updates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">
              Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)
            </h4>
            <div className="menu">
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      If you are from the European Economic Area (EEA), Acreto Cloud Corp. legal basis for collecting
                      and using the personal information described in this Privacy Policy depends on the Personal Data
                      we collect and the specific context in which we collect it.
                    </p>
                    <p>Acreto Cloud Corp. may process your Personal Data because:</p>
                    <ul className="dashed">
                      <li>We need to perform a contract with you</li>
                      <li>You have given us permission to do so</li>
                      <li>The processing is in our legitimate interests and it is not overridden by your rights</li>
                      <li>For payment processing purposes</li>
                      <li>To comply with the law</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Retention of Data</h4>
            <div className="menu">
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      Acreto Cloud Corp. will retain your Personal Data only for as long as is necessary for the
                      purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent
                      necessary to comply with our legal obligations (for example, if we are required to retain your
                      data to comply with applicable laws), resolve disputes and enforce our legal agreements and
                      policies.
                    </p>
                    <p>
                      Acreto Cloud Corp. will also retain Usage Data for billing and internal analysis purposes. Usage
                      Data is generally retained for a shorter period of time, except when this data is used to
                      strengthen the security or to improve the functionality of our Service, or we are legally
                      obligated to retain this data for longer periods.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Transfer of Data</h4>
            <div className="menu">
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      Your information, including Personal Data, may be transferred to - and maintained on - computers
                      located outside of your state, province, country or other governmental jurisdiction where the data
                      protection laws may differ from those of your jurisdiction.
                    </p>
                    <p>
                      If you are located outside United States and choose to provide information to us, please note that
                      we may transfer the data, including Personal Data, to United States and process it there.
                    </p>
                    <p>
                      Your consent to this Privacy Policy followed by your submission of such information represents
                      your agreement to that transfer.
                    </p>
                    <p>
                      Acreto Cloud Corp. will take all the steps reasonably necessary to ensure that your data is
                      treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data
                      will take place to an organisation or a country unless there are adequate controls in place
                      including the security of your data and other personal information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Disclosure of Data</h4>
            <div className="menu">
              <h5 className="header">Business Transaction</h5>
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      If Acreto Cloud Corp. is involved in a merger, acquisition or asset sale, your Personal Data may
                      be transferred. We will provide notice before your Personal Data is transferred and becomes
                      subject to a different Privacy Policy.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="menu">
              <h5 className="header">Disclosure for Law Enforcement</h5>
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      Under certain circumstances, Acreto Cloud Corp. may be required to disclose your Personal Data if
                      required to do so by law or in response to valid requests by public authorities (e.g. a court or a
                      government agency).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu">
              <h5 className="header">Legal Requirements</h5>
              <div className="content">
                <div className="topic">
                  <div className="content">
                    <p>
                      Acreto Cloud Corp. may disclose your Personal Data in the good faith belief that such action is
                      necessary to:
                    </p>
                    <ul className="dashed">
                      <li>To comply with a legal obligation</li>
                      <li>To protect and defend the rights or property of Acreto Cloud Corp.</li>
                      <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                      <li>To protect the personal safety of users of the Service or the public</li>
                      <li>To protect against legal liability</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Security of Data</h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>
                    The security of your data is important to us and we use commercially acceptable means to protect
                    your Personal Data.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">
              Our Policy on &quot;Do Not Track&quot; Signals under the California Online Protection Act (CalOPPA)
            </h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>
                    Do Not Track is a preference you can set in your web browser to inform websites that you do not want
                    to be tracked.
                  </p>
                  <p>
                    You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
                    browser.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>
                    If you are a resident of the European Economic Area (EEA), you have certain data protection rights.
                    Acreto Cloud Corp. aims to take reasonable steps to allow you to correct, amend, delete or limit the
                    use of your Personal Data. Such corrections, amendments and deletions may impact your ability to
                    continue to receiver Acreto Services.
                  </p>
                  <p>
                    If you wish to be informed about what Personal Data we hold about you and if you want it to be
                    removed from our systems, please contact us.
                  </p>
                  <p>In certain circumstances, you have the following data protection rights:</p>
                </div>
                <div className="content">
                  <p>
                    <span className="head">The right to access, update or delete the information we have on you.</span>
                    Whenever made possible, you can access, update or request deletion of your Personal Data directly
                    within your account settings section. If you are unable to perform these actions yourself, please
                    contact us to assist you.
                  </p>
                  <p>
                    <span className="head">The right of rectification.</span>
                    &nbsp;You have the right to have your information rectified if that information is inaccurate or
                    incomplete.
                  </p>
                  <p>
                    <span className="head">The right to object.</span> You have the right to have your information
                    rectified if that information is inaccurate or incomplete.
                  </p>
                  <p>
                    <span className="head">The right of restriction. </span>You have the right to request that we
                    restrict the processing of your personal information. Such restriction may impact Acreto&apos;s
                    ability to deliver Services
                  </p>
                  <p>
                    <span className="head">The right to data portability.</span>
                    You have the right to be provided with a copy of the information we have on you in a structured,
                    machine-readable and commonly used format.
                  </p>
                  <p>
                    <span className="head">The right to withdraw consent.&nbsp;</span>
                    You also have the right to withdraw your consent at any time where Acreto Cloud Corp. relied on your
                    consent to process your personal information.
                  </p>
                </div>
                <div className="content">
                  <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
                  <p>
                    You have the right to complain to a Data Protection Authority about our collection and use of your
                    Personal Data. For more information, please contact your local data protection authority in the
                    European Economic Area (EEA).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Service Providers</h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>
                    We may employ third party companies and individuals to facilitate our Service (&quot;Service
                    Providers&quot;).
                  </p>
                  <p>
                    These third parties have access to your Personal Data only to perform these tasks on our behalf and
                    are obligated not to disclose or use it for any other purpose.
                  </p>
                </div>
              </div>
            </div>
            <div className="menu">
              <h5 className="header">Payments</h5>
              <div className="topic">
                <div className="content">
                  <p>
                    We provide paid products and/or services within the Service and use third-party services for payment
                    processing (e.g. payment processors).
                  </p>
                  <p>
                    We will not store or collect your payment card details. That information is provided directly to our
                    third-party payment processors whose use of your personal information is governed by their Privacy
                    Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American
                    Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                  </p>
                  <p>The payment processors we work with are:</p>
                </div>
                <div className="content">
                  <h6>Stripe</h6>
                  <p>
                    Their Privacy Policy can be viewed at&nbsp;
                    <a href="https://stripe.com/us/privacy" className="head">
                      https://stripe.com/us/privacy
                    </a>
                  </p>
                  <h6>PayPal / Braintree</h6>
                  <p>
                    Their Privacy Policy can be viewed at&nbsp;
                    <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" className="head">
                      https://www.paypal.com/webapps/mpp/ua/privacy-full
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Children&apos;s Privacy</h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>Our Service does not address anyone under the age of 18 ( &quot;Children&quot;).</p>
                  <p>
                    We do not knowingly collect personally identifiable information from anyone under the age of 18. If
                    you are a parent or guardian and you are aware that your Child has provided us with Personal Data,
                    please contact us. If we become aware that we have collected Personal Data from children without
                    verification of parental consent, we take steps to remove that information from our servers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Changes to This Privacy Policy</h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
                    new Privacy Policy on this page.
                  </p>
                  <p>
                    We will let you know via email and/or a prominent notice on our Service, prior to the change
                    becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.
                  </p>
                  <p>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                    Policy are effective when they are posted on this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4 className="header">Contact Us</h4>
            <div className="menu">
              <div className="topic">
                <div className="content">
                  <p>If you have any questions about this Privacy Policy, please contact us:</p>
                  <p>
                    By visiting this page on our website:&nbsp;
                    <a href="https://acreto.io/contact" className="head">
                      https://acreto.io/contact
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
