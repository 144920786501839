import Cookie from 'js-cookie';
import sortBy from 'lodash/sortBy';
import { ECOSYSTEM_STATUS } from '../../enum';
import { CLEAR_DATA } from '../common-action-types';
import {
  CREATE_ECOSYSTEM_SUCCESS,
  CREATE_ECOSYSTEM_GROUP_SUCCESS,
  CREATE_SERVICE_SUCCESS,
  FETCHING_ECOSYSTEMS_SUCCESS,
  FETCHING_NSP_SUCCESS,
  LOAD_ECOSYSTEM_SUCCESS,
  REFRESH_ECOSYSTEM_SUCCESS,
  RESET_REQUEST_ID,
  SAVE_COMMIT_REQUEST,
  SAVE_REVERT_REQUEST,
  SET_CURRENT_ECOSYSTEM,
  SET_ECOSYSTEM_STATUS,
  GET_ACCESS_USER_SUCCESS,
  CREATE_ECOSYSTEM_ROLE_SUCCESS,
  GET_ECOSYSTEM_USER_REQUESTED,
  GET_ECOSYSTEM_USER_SUCCESS,
  SET_ECOSYSTEM_CODE,
} from './action-types';

const initialState = {
  items: [],
  ecoUsers: [],
  currentEcosystem: Cookie.get('currentEcosystem') ? JSON.parse(Cookie.get('currentEcosystem')) : null,
  dictionaries: {
    groups: [],
    roles: [],
    nsps: [],
    applications: [],
    services: [],
  },
  createdService: null,
  ecosystemStatus: ECOSYSTEM_STATUS.OK,
  commitID: '',
  revertID: '',
};

export function ecosystemsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_ECOSYSTEM_SUCCESS: {
      return {
        ...state,
        items: sortBy([payload, ...state.items], (eco) => eco.name),
      };
    }
    case CREATE_ECOSYSTEM_GROUP_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          groups: payload,
        },
      };
    }
    case CREATE_SERVICE_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          services: [...state.dictionaries.services, payload],
        },
        createdService: payload,
      };
    }
    case CLEAR_DATA: {
      Cookie.remove('currentEcosystem');
      return initialState;
    }
    case FETCHING_ECOSYSTEMS_SUCCESS: {
      return {
        ...state,
        items: sortBy(payload, (eco) => eco.name),
      };
    }
    case FETCHING_NSP_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          nsps: payload,
        },
      };
    }
    case LOAD_ECOSYSTEM_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          groups: payload.groups,
          applications: payload.applications,
          services: payload.services,
          nsps: payload.nsps,
        },
      };
    }
    case REFRESH_ECOSYSTEM_SUCCESS: {
      return {
        ...state,
        currentEcosystem: {
          ...state.currentEcosystem,
          status: payload.status,
        },
      };
    }
    case RESET_REQUEST_ID: {
      return {
        ...state,
        commitID: '',
        revertID: '',
      };
    }
    case SAVE_COMMIT_REQUEST: {
      return {
        ...state,
        commitID: payload.reqID,
        ecosystemStatus: payload.status,
      };
    }
    case SAVE_REVERT_REQUEST: {
      return {
        ...state,
        revertID: payload.reqID,
        ecosystemStatus: payload.status,
      };
    }
    case SET_CURRENT_ECOSYSTEM: {
      return {
        ...state,
        currentEcosystem: payload,
      };
    }
    case SET_ECOSYSTEM_STATUS: {
      return {
        ...state,
        ecosystemStatus: payload,
      };
    }
    case GET_ECOSYSTEM_USER_REQUESTED: {
      return { ...state, ecoUsers: [] };
    }
    case GET_ECOSYSTEM_USER_SUCCESS: {
      return { ...state, ecoUsers: payload };
    }
    case GET_ACCESS_USER_SUCCESS:
      return {
        ...state,
        currentEcosystem: { ...state.currentEcosystem, users: payload.users },
      };
    case CREATE_ECOSYSTEM_ROLE_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          roles: [...state.dictionaries.roles, payload],
        },
      };
    }
    case SET_ECOSYSTEM_CODE: {
      return {
        ...state,
        currentEcosystem: {
          ...state.currentEcosystem,
          ecosystemCode: payload.code,
        },
      };
    }
    default:
      return state;
  }
}
