import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel, Collapse } from 'react-bootstrap';

import ConfigOptions from './ConfigOptions';
import { GATEWAY_DOWNLOADS } from '../../enum/gateway';
import './gateway-devices.scss';

const GatewayDevices = ({ data }) => {
  const [searchText, setSearchText] = useState('');
  const [isCollapsed, setIsCollapsed] = useState({
    'Software Clients with Config': false,
    'Appliance Configurations': false,
    'VM and Cloud Images': false,
    'x86 Hardware Images': false,
    'IoT Hardware Images': false,
  });

  const handleSearchChange = (event) => {
    event.preventDefault();
    setSearchText(event.target.value);
    Object.keys(isCollapsed).forEach((key) => {
      isCollapsed[key] = !!event.target.value;
    });
    setIsCollapsed({ ...isCollapsed });
  };

  const openCollapse = (title) => (event) => {
    event.preventDefault();
    isCollapsed[title] = !isCollapsed[title];
    setIsCollapsed({ ...isCollapsed });
  };

  const searchOptions = (label) => !searchText || label.toLowerCase().includes(searchText.toLowerCase());

  return (
    <div className="panel__downloads">
      <Panel>
        <Panel.Heading>
          <input placeholder="Search" onChange={handleSearchChange} />
          <i className="fa fa-search" />
        </Panel.Heading>
        <Panel.Body>
          {GATEWAY_DOWNLOADS.map(
            (downOption) =>
              downOption[data.gateway_type] && (
                <div key={`key-${downOption.title}`} className="download-list">
                  <span className="download-list__label" onClick={openCollapse(downOption.title)}>
                    <i className="material-icons">
                      {isCollapsed[downOption.title] ? 'arrow_drop_down' : 'arrow_right'}
                    </i>
                    {downOption.title}
                  </span>
                  <Collapse in={isCollapsed[downOption.title]}>
                    <div className="download-list__collapse">
                      {downOption.options.map(
                        (option) =>
                          searchOptions(option.label) && (
                            <ConfigOptions key={option.value} option={option} gateway={data} />
                          ),
                      )}
                    </div>
                  </Collapse>
                </div>
              ),
          )}
        </Panel.Body>
      </Panel>
    </div>
  );
};

GatewayDevices.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GatewayDevices;
