import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmFooter } from './commons';

const UpgradeEcosystem = ({ onYes, onNo }) => (
  <div>
    <div className="action__confirm">
      <p>Your ecosystem is outdated and requires an upgrade.</p>
      <p>
        The upgrade process can take several minutes. During the upgrade, the ecosystem will be temporarily unavailable.
        It will also disconnect all your Things and Gateways.
      </p>
      <p>Do you want to perform the upgrade now?</p>
    </div>

    <div className="wedge-modal__footer">
      <ConfirmFooter onYes={onYes} onNo={onNo} />
    </div>
  </div>
);

UpgradeEcosystem.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};

export default UpgradeEcosystem;
