/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tabs, Tab } from 'react-bootstrap';

import AddButton from '../components/AddButton';
import Card from '../components/Card';
import Descriptor from '../components/Descriptor';
import Form from '../components/Form';
import SelectWithSearch from '../components/SelectWithSearch/SelectWithSearch';

import { EXPIRATION_TYPE } from '../enum/expiration';
import { GATEWAY_TYPE, GATEWAY_TYPE_OPTIONS, MODEL_TYPE, MODEL_TYPE_OPTIONS } from '../enum/gateway';
import { IP_MODES, IP_MODES_OPTIONS } from '../enum/ip';
import { LOCATION_TYPE_OPTIONS, DEFAULT_LOCATION } from '../enum/location';
import { OBJECT_CATEGORIES } from '../enum/object';

import Translator from '../utils/enumTranslator';
import IPValidator from '../utils/ipValidator';
import gatewayValidateSchema from '../validationSchemas/gatewayValidationSchema';
import { Footer } from './commons';

import './modals.scss';
import 'react-datepicker/dist/react-datepicker.css';

function parseAdditionalNetworks(networks) {
  if (!networks) return [];
  return networks.map((net) => ({
    network: net.network,
    hop: net.gateway,
  }));
}

const ExpiryInput = ({ value, onClick }) => <input className="expiry-input" value={value} onClick={onClick} readOnly />;

class NewGatewaySurvey extends React.Component {
  constructor(props) {
    super(props);
    const gatewayForm = this.setGatewayForm();
    this.state = {
      gatewayForm,
      errors: [],
      newNetwork: '',
      newHop: '',
      tabKey: 1,
    };
  }

  setGatewayForm() {
    const { edit, item } = this.props;
    return {
      name: edit ? item.name : '',
      category: edit ? Translator.category(item.category) : null,
      model: edit ? item.model : MODEL_TYPE.GENERIC,
      gateway_type: edit ? item.gateway_type : GATEWAY_TYPE.IPSEC,
      expiryTime: edit && item.expiry && item.expiry.time ? new Date(item.expiry.time) : null,
      expiryType: edit && item.expiry && item.expiry.type ? item.expiry.type : EXPIRATION_TYPE.SOFT,
      desc: edit ? item.description : '',
      location: edit && !item.geolocation ? LOCATION_TYPE_OPTIONS[1] : LOCATION_TYPE_OPTIONS[0],
      lat: edit ? (!!item.geolocation && item.geolocation.latitude) || DEFAULT_LOCATION.LAT : DEFAULT_LOCATION.LAT,
      lng: edit ? (!!item.geolocation && item.geolocation.longitude) || DEFAULT_LOCATION.LNG : DEFAULT_LOCATION.LNG,
      gateway_internet_gw: edit ? item.gateway_internet_gw || '' : '',
      gateway_internet_ip: edit ? item.gateway_internet_ip || '' : '',
      gateway_internet_proto: edit ? item.gateway_internet_proto || '' : '',
      gateway_local_ip: edit ? item.gateway_local_ip || '' : '',
      peer_address: edit ? item.peer_address || '' : '',
      peer_id: edit ? item.peer_id : '',
      peer_networks: edit ? parseAdditionalNetworks(item.peer_networks) : [],
      allow_all_ciphers: edit ? item.allow_all_ciphers : true,
    };
  }

  changeField = (field, value) => {
    const { gatewayForm } = this.state;
    gatewayForm[field] = value;
    if (value === GATEWAY_TYPE.IPSEC) {
      gatewayForm.gateway_internet_proto = '';
    } else if (value === GATEWAY_TYPE.VGATEWAY && !gatewayForm.gateway_internet_proto) {
      gatewayForm.gateway_internet_proto = IP_MODES.STATIC;
    }
    this.setState({
      gatewayForm,
      errors: [],
    });
  };

  handleSubmit = async () => {
    if (!(await this.handleAddNetwork())) {
      return;
    }
    if (this.validate()) {
      try {
        const { gatewayForm } = this.state;
        const paylaod = {
          ...gatewayForm,
          acreto_id: this.props.addresses.find((addr) => addr.name === 'Default Tunnel').address,
        };
        if (gatewayForm.model === MODEL_TYPE.AWS_SITE) {
          paylaod.peer_id = gatewayForm.peer_address;
        }
        this.props.onFinish(paylaod);
      } catch (err) {
        this.setState({
          errors: [this.props.edit ? 'Failed to Update Gateway' : 'Failed to Add Gateway'],
        });
      }
    }
  };

  validIp = (ip, field) => {
    const { errors } = this.state;
    if (!IPValidator(ip, '4')) {
      errors.push(`${field} is invalid`);
      this.setState({ errors });
      return false;
    }
    return true;
  };

  handleAddNetwork = () => {
    const { newNetwork, newHop, gatewayForm } = this.state;

    if (!newNetwork && !newHop) return true;
    if (!this.validIp(newNetwork, 'Network IP')) return false;
    if (newHop && !this.validIp(newHop, 'Next Hop IP')) return false;

    const addNetwork = {
      network: newNetwork,
      hop: newHop,
    };
    this.setState({
      gatewayForm: {
        ...gatewayForm,
        peer_networks: gatewayForm.peer_networks.some((net) => net.network === newNetwork && net.hop === newHop)
          ? gatewayForm.peer_networks
          : [...gatewayForm.peer_networks, addNetwork],
      },
      newNetwork: '',
      newHop: '',
    });
    return true;
  };

  handleRemoveNetwork = (index) => {
    const { gatewayForm } = this.state;
    this.setState({
      gatewayForm: {
        ...gatewayForm,
        peer_networks: gatewayForm.peer_networks.filter((val, ind) => ind !== index),
      },
    });
  };

  validate = () => {
    const {
      gatewayForm: {
        gateway_type,
        gateway_internet_proto,
        gateway_internet_gw,
        gateway_internet_ip,
        gateway_local_ip,
        peer_address,
      },
    } = this.state;
    try {
      gatewayValidateSchema.validateSync(this.state.gatewayForm, {
        abortEarly: false,
      });
      this.setState({ errors: [] });
      if (peer_address && !this.validIp(peer_address, 'IP Address')) {
        return false;
      }
      if (gateway_type === GATEWAY_TYPE.IPSEC) return true;
      return gateway_internet_proto === IP_MODES.STATIC
        ? this.validIp(gateway_internet_ip, 'Gateway Internet IP') &&
            this.validIp(gateway_internet_gw, 'Default Route') &&
            this.validIp(gateway_local_ip, 'Gateway Local IP')
        : this.validIp(gateway_local_ip, 'Gateway Local IP');
    } catch (err) {
      this.contentElement.scrollIntoView({
        behavior: 'smooth',
      });
      this.setState({ errors: err.errors });
      return false;
    }
  };

  getLabel = () => {
    if (this.state.gatewayForm.model === MODEL_TYPE.AWS_SITE) {
      return 'AWS Tunnel Outside IP Address';
    }
    return 'Allow connections from (optional)';
  };

  renderConfig() {
    const {
      gatewayForm: {
        gateway_type,
        gateway_internet_gw,
        gateway_internet_ip,
        gateway_internet_proto,
        gateway_local_ip,
        peer_address,
        peer_networks,
        allow_all_ciphers,
        ...otherForm
      },
      errors,
      tabKey,
    } = this.state;

    return (
      <div className="modal__content padded new-gateway-survey">
        {tabKey === 1 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Card header={false} className="basic">
          <div className="form-row">
            <Form.Group required label="Name">
              <Form.Text value={otherForm.name} onChange={(val) => this.changeField('name', val)} placeholder="Name" />
            </Form.Group>
            <Form.Group>
              <Form.Toggle
                selected={gateway_type}
                selectedClass="toggle-selected"
                onChange={(val) => this.changeField('gateway_type', val)}
                options={GATEWAY_TYPE_OPTIONS}
              />
            </Form.Group>
          </div>
          <div className="form-row">
            <Form.Group required label="Category">
              <SelectWithSearch
                selected={otherForm.category}
                onChange={(val) => this.changeField('category', val)}
                placeholder="Select category"
                groupCreative={false}
                options={OBJECT_CATEGORIES}
              />
            </Form.Group>
            {gateway_type === GATEWAY_TYPE.IPSEC && (
              <Form.Group required label="Model">
                <div className="minWidth">
                  {MODEL_TYPE_OPTIONS.map((option) => (
                    <div className="centered-row" key={option.value}>
                      <div className="form__input idpOptions">
                        <Form.RadioButton
                          {...option}
                          selected={otherForm.model}
                          onChange={(val) => {
                            this.changeField('model', val);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Form.Group>
            )}
          </div>
          {/* Hide until API is ready
					<div className={'form-row'}>
						<Form.Group label={'Expiry'}>
							<DatePicker
								isClearable
								dateFormat={'MMM dd, yyyy'}
								selected={otherForm.expiryTime}
								onChange={val => this.changeField('expiryTime', val)}
								customInput={<ExpiryInput />}
							/>
						</Form.Group>
					</div> */}
        </Card>

        <Card header={false}>
          <div className="form-row gateway-config">
            <div className="gateway-config__ips">
              {gateway_type === GATEWAY_TYPE.IPSEC && (
                <Form.Group
                  full
                  label={this.getLabel()}
                  required={this.state.gatewayForm.model === MODEL_TYPE.AWS_SITE}
                >
                  <Form.Text
                    value={peer_address}
                    onChange={(val) => this.changeField('peer_address', val)}
                    placeholder="IP address"
                  />
                </Form.Group>
              )}
              {gateway_type === GATEWAY_TYPE.VGATEWAY && (
                <Form.Group>
                  <Form.Toggle
                    selected={gateway_internet_proto}
                    selectedClass="toggle-selected"
                    onChange={(val) => this.changeField('gateway_internet_proto', val)}
                    options={IP_MODES_OPTIONS}
                  />
                </Form.Group>
              )}
              {gateway_type === GATEWAY_TYPE.VGATEWAY && gateway_internet_proto === IP_MODES.STATIC && (
                <React.Fragment>
                  <Form.Group required full label="vGateway Internet IP">
                    <Form.Text
                      value={gateway_internet_ip}
                      onChange={(val) => this.changeField('gateway_internet_ip', val)}
                      placeholder="Static IP / 32"
                    />
                  </Form.Group>
                  <Form.Group required full label="vGateway Default Route">
                    <Form.Text
                      value={gateway_internet_gw}
                      onChange={(val) => this.changeField('gateway_internet_gw', val)}
                      placeholder="Enter Default Route"
                    />
                  </Form.Group>
                </React.Fragment>
              )}
              {gateway_type === GATEWAY_TYPE.VGATEWAY && (
                <Form.Group required full label="vGateway Local IP">
                  <Form.Text
                    value={gateway_local_ip}
                    onChange={(val) => this.changeField('gateway_local_ip', val)}
                    placeholder="Local IP / 32"
                  />
                </Form.Group>
              )}
            </div>
            <div className="gateway-config__networks">
              {gateway_type === GATEWAY_TYPE.VGATEWAY && (
                <Form.Group full label="Allow connections from (optional)">
                  <Form.Text
                    value={peer_address}
                    onChange={(val) => this.changeField('peer_address', val)}
                    placeholder="IP address"
                  />
                </Form.Group>
              )}
              <Form.Group required label="Local Networks">
                <div className="local-networks--container">
                  <table>
                    <tbody>
                      <tr>
                        <th className="network">Network</th>
                        {gateway_type === GATEWAY_TYPE.VGATEWAY && <th className="hop">Next Hop</th>}
                      </tr>
                      {peer_networks &&
                        peer_networks.map((net, index) => (
                          <tr key={`additional-network-index-${index}`}>
                            <td className="network">{net.network}</td>
                            {gateway_type === GATEWAY_TYPE.VGATEWAY && <td className="hop">{net.hop}</td>}
                            <td className="last">
                              <i
                                className="pe-7s-close red-delete-icon"
                                onClick={() => this.handleRemoveNetwork(index)}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr className="new-entry">
                        <td>
                          <Form.Text
                            value={this.state.newNetwork}
                            onChange={(val) => this.setState({ newNetwork: val })}
                            extraClass="light"
                            placeholder="Network"
                          />
                        </td>
                        {gateway_type === GATEWAY_TYPE.VGATEWAY && (
                          <td>
                            <Form.Text
                              value={this.state.newHop}
                              onChange={(val) => this.setState({ newHop: val })}
                              extraClass="light"
                              placeholder="Next Hop"
                            />
                          </td>
                        )}
                        <td className="last">
                          <AddButton onClick={this.handleAddNetwork} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form.Group>
              <Form.Group extraClass="space-above">
                <label className="checkbox-label wedge-checkbox-container">
                  <input
                    type="checkbox"
                    checked={allow_all_ciphers}
                    onChange={(ev) => this.changeField('allow_all_ciphers', ev.target.checked)}
                  />
                  <span className="checkmark" />
                  <p className="check_title">Allow all supported ciphers</p>
                  <p className="check_subtitle">(This may have performance impact)</p>
                </label>
              </Form.Group>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { gatewayForm, errors, tabKey } = this.state;
    return (
      <div className="modal__content padded new-gateway-survey">
        {tabKey === 2 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Descriptor
          desc={gatewayForm.desc}
          location={gatewayForm.location}
          lat={gatewayForm.lat}
          lng={gatewayForm.lng}
          onChange={(field, value) => this.changeField(field, value)}
        />
      </div>
    );
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.contentElement = node;
        }}
      >
        <Tabs defaultActiveKey={1} id="object__tabs" onSelect={(key) => this.setState({ tabKey: key })}>
          <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
            {this.renderConfig()}
          </Tab>
          <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
            {this.renderDesc()}
          </Tab>
        </Tabs>

        <div className="wedge-modal__footer">
          <Footer onClick={this.handleSubmit} edit={this.props.edit} onDelete={this.props.onDelete} />
        </div>
      </div>
    );
  }
}
const addressSelector = (state) => {
  const ecosystem = state.ecosystems.currentEcosystem;
  return ecosystem && state.objects[ecosystem.uuid]
    ? state.objects[ecosystem.uuid].objects.filter((obj) => obj.element === 'address' && obj.scope === 'public')
    : [];
};

NewGatewaySurvey.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
  addresses: [],
};
ExpiryInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  addresses: addressSelector(state),
});
NewGatewaySurvey.Footer = Footer;

export default connect(mapStateToProps)(NewGatewaySurvey);
