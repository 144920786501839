import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { USER_MODAL_TYPE, CREATE_USER_MODAL_TITLE_MAPPING, OBJECT_TYPES } from 'enum/object';
import NewUserManual from './NewUserManual';
import NewUserCSVImport from './NewUserCSVImport';
import UserCreationSelection from './UserCreationSelection';
import { Footer } from './commons';

import './modals.scss';

const NewUser = (props) => {
  const { pathname } = useLocation();
  const splittedPathName = pathname.split('/');
  const type = splittedPathName[7] === 'users' ? OBJECT_TYPES.USER : OBJECT_TYPES.SERVER;
  const header = `Create New ${type === OBJECT_TYPES.USER ? 'Users' : 'Identity Provider'}`;

  const [modalStage, setModalStage] = useState('');
  const [title, setTitle] = useState('');

  const setStage = (stage, info) => {
    setModalStage(stage);
    let title = stage in CREATE_USER_MODAL_TITLE_MAPPING ? CREATE_USER_MODAL_TITLE_MAPPING[stage] : 'Add New Users';
    if (stage === USER_MODAL_TYPE.LDAP_CONF) {
      if (info.extSysType) {
        title = CREATE_USER_MODAL_TITLE_MAPPING[info.extSysType];
      }
    }
    setTitle(title);
  };

  return (
    <div className="modal__content new-user-survey">
      <h3 className="header">{header}</h3>
      <UserCreationSelection onNext={setStage} navigateBack={() => props.navigateBack()} type={type} />
      <div className="form-body">
        <h3>{title}</h3>
        {modalStage === USER_MODAL_TYPE.CREATE_USER ? (
          <NewUserManual navigateBack={() => props.navigateBack()} {...props} />
        ) : (
          <NewUserCSVImport navigateBack={() => props.navigateBack()} {...props} />
        )}
      </div>
    </div>
  );
};

NewUser.defaultProps = {
  edit: false,
};

NewUser.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onMethodSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
};

NewUser.Footer = Footer;

export default NewUser;
