import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-spinner-material';
import qs from 'query-string';

import ErrorPanel from '../../../components/ErrorPanel/ErrorPanel';
import { checkIfTheTokenIsValid } from '../scenario-actions';
import { clearError } from '../../../store/user/actions';
import { errorSelector, emailSelector } from '../../../store/user/selectors';
import '../sign-up-form.scss';

class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = props;
    const { token, username } = qs.parse(search);
    this.token = token;
    this.username = username.replace(' ', '+');
    this.shouldCheckToken = this.token && this.username;
    this.error = this.shouldCheckToken ? '' : 'Incorrect link';
  }

  async componentDidMount() {
    const { checkIfTheTokenIsValid } = this.props;
    if (this.shouldCheckToken) {
      await checkIfTheTokenIsValid({
        token: this.token,
        username: this.username,
      });
    }
  }

  componentWillUnmount() {
    const { clearError, error } = this.props;
    if (error) clearError();
  }

  handleErrorClose = () => {
    const { clearError, history } = this.props;
    clearError();
    history.replace('auth/sign-up');
  };

  render() {
    const error = this.error || this.props.error;
    return (
      <div className="form-container">
        {error ? (
          <ErrorPanel type="warning" message={error} buttonClickHandler={this.handleErrorClose} />
        ) : (
          <Spinner spinnerColor="#4a0b96" />
        )}
      </div>
    );
  }
}

EmailConfirmation.propTypes = {
  error: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  checkIfTheTokenIsValid: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  email: emailSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearError,
      checkIfTheTokenIsValid,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
