/**
 * Ecosystem Status
 */

export default {
  COMMIT_ERROR: 'commit_error',
  COMMIT_IN_PROGRESS: 'commit_in_progress',
  DISABLED: 'disabled',
  ECOSYSTEM_ERROR: 'ecosystem_error',
  OK: 'ok',
  PENDING_CHANGES: 'pending_changes',
  OUTDATED: 'ecosystem_outdated',
  DELETED: 'deleted',
};
