/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '../components/Card';
import Form from '../components/Form';
import { IP_TYPE } from '../enum/ip';
import { Footer } from './commons';
import Translator from '../utils/enumTranslator';
import publicAddressValidationSchema from '../validationSchemas/publicAddressValidationSchema';

import './modals.scss';

class NewAllocatedIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.edit ? props.item.name : '',
      type: IP_TYPE.IPv4,
      desc: props.edit ? props.item.description || '' : '',
      category: props.edit ? Translator.category(props.item.category) : null,
      nsp: props.nsps.length ? props.nsps[0].name : '',
      errors: [],
      isSubmitted: false,
    };
  }

  changeField = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onFinish = () => {
    this.setState(
      {
        errors: [],
        isSubmitted: true,
      },
      () => {
        if (this.validate()) {
          try {
            this.props.onFinish(this.state);
          } catch (err) {
            this.setState({
              errors: [this.props.edit ? 'Failed to Update Allocated IP' : 'Failed to Add Allocated IP'],
            });
          }
        }
      },
    );
  };

  validate() {
    if (!this.state.isSubmitted) {
      return true;
    }
    try {
      publicAddressValidationSchema.validateSync(this.state, {
        abortEarly: false,
      });
      this.setState({
        errors: [],
      });
      return true;
    } catch (error) {
      this.contentElement.scrollIntoView({
        behavior: 'smooth',
      });
      this.setState({
        errors: error.errors,
      });
      return false;
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div
        ref={(node) => {
          this.contentElement = node;
        }}
      >
        <div className="modal__content padded new-address-survey">
          {errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
          <Card header={false}>
            <div className="form-row">
              <Form.Group required label="Name">
                <Form.Text
                  value={this.state.name}
                  onChange={(val) => this.changeField('name', val)}
                  placeholder="Name"
                />
              </Form.Group>
              <Form.Group label="Associated region">
                <div className="nsp-container">US East</div>
              </Form.Group>
            </div>
            <div className="form-row">
              <Form.Group label="Description" full>
                <Form.Text
                  value={this.state.desc}
                  onChange={(val) => this.changeField('desc', val)}
                  placeholder="Descripition"
                />
              </Form.Group>
            </div>
          </Card>
        </div>
        <div className="wedge-modal__footer">
          <Footer
            onClick={this.onFinish}
            edit={this.props.edit}
            disabled={this.props.edit}
            onDelete={this.props.onDelete}
          />
        </div>
      </div>
    );
  }
}

NewAllocatedIP.defaultProps = {
  edit: false,
};

NewAllocatedIP.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
  nsps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  nsps: state.ecosystems.currentEcosystem.nsps,
});

NewAllocatedIP.Footer = Footer;

export default connect(mapStateToProps)(NewAllocatedIP);
