export { default as TYPE_IOT } from './img/PNG/group-21.png';
export { default as INFO } from './img/PNG/group-24.png';
export { default as COPY } from './img/PNG/Acreto_Icon 26.png';
export { default as CONNECT_STATUS } from './img/PNG/oval.png';
export { default as COG } from './img/PNG/shape.png';
export { default as US_EAST_RED } from './img/SVG/us-east-1-map-red.svg';
export { default as US_EAST_GREEN } from './img/SVG/us-east-1-map-green.svg';
export { default as ARROW_TOP_GREEN } from './img/SVG/green-arrow.svg';
export { default as ARROW_BOTTOM_RED } from './img/SVG/red-arrow.svg';
export { default as UNDEFINED_STATUS } from './img/PNG/group-33.png';
export { default as CLOSE } from './img/PNG/group-6.png';
export { default as CLOSE_BLACK } from './img/PNG/close-btn-black.png';
export { default as MENU_GOVERNANCE } from './img/PNG/group-12.png';
export { default as MENU_REPORT } from './img/PNG/group-11.png';
export { default as MENU_ADDRESS } from './img/PNG/group-10.png';
export { default as MENU_POLICIES } from './img/PNG/group-8.png';
export { default as MENU_USERS } from './img/PNG/group-7.png';
export { default as MENU_CONTENT } from './img/PNG/group-5.png';
export { default as MENU_ELEMENTS } from './img/PNG/group-3.png';
export { default as MENU_OBJECTS } from './img/PNG/menu-objects.png';
export { default as NAVBAR_SEARCH } from './img/SVG/zoom-icon.svg';
export { default as NAVBAR_NOTIFICATION_FAKE } from './img/PNG/group-44-fake.png';
export { default as NAVBAR_USER } from './img/SVG/profile-icon.svg';
export { default as ICON_MORE } from './img/SVG/menu_vert.svg';
export { default as ICON_VERT_MORE_GRAY } from './img/PNG/menu_vert_dots_gray.png';
export { default as FAKE_CHART_UTIL } from './img/PNG/chart-1.png';
export { default as FAKE_CHART_THREAT } from './img/PNG/chart-2.png';
export { default as FAKE_CHART_DOWN } from './img/PNG/chart-3.png';
export { default as LOGIN_FOOTER } from './img/PNG/login_bottom.png';
export { default as LOGIN_EMAIL } from './img/PNG/email_icon.png';
export { default as LOGIN_PASSWORD } from './img/PNG/password_icon.png';
export { default as BLUE_FORWARD_ARROW } from './img/PNG/forward-blue-arrow.png';
export { default as BACK_ARROW } from './img/PNG/back-arrow.png';
export { default as MENU_PLUS_RECT } from './img/PNG/plus-rectangle.png';
export { default as MENU_DOWN_ARROW } from './img/PNG/down-arrow.png';
export { default as MENU_BADGE_CONTAINER } from './img/PNG/badge-container.png';
export { default as FAKE_CHART_SIDEBAR } from './img/PNG/sidebar-mock-graph.png';
export { default as GREEN_TICK } from './img/PNG/green_tick.png';
export { default as RED_TICK } from './img/PNG/red_tick.png';
export { default as DOWNLOAD } from './img/PNG/download.png';
export { default as FULL_SIZE_ICON } from './img/PNG/fullsize-icon.png';
export { default as CHEVRON_DOWN_WHITE } from './img/PNG/chevron-down.png';
export { default as NOTIFICATIONS_ARROW_RIGHT } from './img/PNG/arrow-right.png';
export { default as INCREASE_ARROW } from './img/PNG/increase-arrow.png';
export { default as ACRETO_LOGO } from './img/SVG/logo.svg';
export { default as LOGO_CIRCLE } from './img/SVG/circle_logo.png';
export { default as ECOSYSTEM_OWNER } from './img/SVG/ecosystem-user.svg';
export { default as COMMIT_ICON } from './img/SVG/commit-icon.svg';
export { default as PERSON } from './img/PNG/Acreto_Icon 04.png';
export { default as SUCCESS } from './img/PNG/success.png';
export { default as CROSS } from './img/SVG/cross.svg';
export { default as EXPIRY_SOFT } from './img/SVG/expiry-soft.svg';
export { default as EXPIRY_HARD } from './img/SVG/expiry-hard.svg';
export { default as NOT_FOUND_404 } from './img/PNG/404.png';
export { default as BACK_ARROW_LINK } from './img/PNG/path-4@3x.png';
export { default as MENU_SECURITY } from './img/SVG/security.svg';
export { default as MENU_POLICY } from './img/SVG/policy.svg';
export { default as MENU_PROFILE } from './img/SVG/add_moderator.svg';
export { default as MENU_WEBFILTER } from './img/SVG/lock.svg';
