import React from 'react';
import EmailEntered from '../../EmailEntered/EmailEntered';

const PWDResetEmailEntered = () => (
  <div className="login-form-page--content">
    <div className="login-form">
      <div className="form-container">
        <EmailEntered message="If there is an account associated with this email address, you should receive password reset email soon" />
      </div>
    </div>
  </div>
);

export default PWDResetEmailEntered;
