import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Col, Grid, Row } from 'react-bootstrap';
import { withFormik } from 'formik';
import { func, number, object, string, bool } from 'prop-types';
import Spinner from 'react-spinner-material';
import 'react-flags-select/scss/react-flags-select.scss';

import { CARD_NAME } from '../../enum/Billing';
import { stripeSourceRegistrationValidationSchema } from '../../validationSchemas';
import { createStripeSource, clearStripeError, setStripe } from '../../store/payment/actions';
import './stripe-souce-registration.css';
import { stripeErrorSelector } from '../../store/payment/selectors';
import ErrorPanel from '../ErrorPanel/ErrorPanel';
import { emailSelector, isLoadingSelector, initialCompanyNameSelector } from '../../store/user/selectors';

const styleCustomFields = {
  fontSize: '18px',
  color: '#6d8994',
  '::-webkit-input-placeholder': {
    color: '#c5c8c9',
    fontStyle: 'normal',
  },
  '::placeholder': {
    color: '#c5c8c9',
    fontStyle: 'normal',
  },
};
class StripeSourceRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldErrorBeDisplayed: false,
    };
  }

  componentDidMount() {
    this.props.setStripe();
  }

  handleInputChange = (event) => {
    event.persist();
    const { handleChange } = this.props;
    this.cleanUpError();
    handleChange(event);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      shouldErrorBeDisplayed: true,
    });
    const { isValid, values, email, createStripeSource } = this.props;
    if (!isValid) {
      return;
    }
    createStripeSource({
      type: 'card',
      owner: {
        name: values[CARD_NAME],
        email,
      },
    });
  };

  cleanUpError = () => {
    const { shouldErrorBeDisplayed } = this.state;
    if (shouldErrorBeDisplayed === true) {
      this.setState(({ shouldErrorBeDisplayed }) => ({
        shouldErrorBeDisplayed: !shouldErrorBeDisplayed,
      }));
    }
    const { stripeError, clearStripeError } = this.props;
    if (stripeError) clearStripeError();
  };

  handleBlur = ({ target: { name } }) => {
    const { setFieldTouched } = this.props;
    setFieldTouched(name);
  };

  render() {
    const { componentIndex, selectedIndex, handleChange, values, errors, stripeError, isLoading } = this.props;
    const isFormEmpty = Object.values(values).some((entry) => entry === '');
    const { shouldErrorBeDisplayed } = this.state;

    if (componentIndex !== selectedIndex) return null;
    return (
      <div className="row signUp-registration">
        <form onSubmit={this.handleSubmit} autoComplete="off" className="form-container-stripe">
          <Grid>
            {isFormEmpty && shouldErrorBeDisplayed && (
              <Row>
                <Col xs={12} className="col">
                  <ErrorPanel message="Please Enter All The Fields" buttonClickHandler={this.cleanUpError} />
                </Col>
              </Row>
            )}
            {!!stripeError && (
              <Row>
                <Col xs={12} className="col">
                  <ErrorPanel message={stripeError} buttonClickHandler={this.cleanUpError} />
                </Col>
              </Row>
            )}
            {shouldErrorBeDisplayed &&
              Object.values(errors).map((error) => (
                <Row key={error}>
                  <Col xs={12} className="col">
                    <ErrorPanel message={error} buttonClickHandler={this.cleanUpError} />
                  </Col>
                </Row>
              ))}
            <Row>
              <Col xs={12} md={6} className="col">
                <label>
                  <span className="labelForm">Name on Card</span>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="inputField"
                    onChange={handleChange}
                    onBlur={this.handleBlur}
                    name={CARD_NAME}
                    value={values[CARD_NAME]}
                  />
                </label>
              </Col>
              <Col xs={12} md={6} className="col">
                <label>
                  <span className="labelForm">Card Number</span>
                  <div onClick={this.cleanUpError}>
                    <CardNumberElement
                      className="inputField"
                      style={{ base: styleCustomFields }}
                      onFocus={this.cleanUpError}
                    />
                  </div>
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="col">
                <label>
                  <span className="labelForm">Expiry Date</span>
                  <CardExpiryElement
                    className="inputField"
                    style={{ base: styleCustomFields }}
                    onFocus={this.cleanUpError}
                  />
                </label>
              </Col>
              <Col xs={12} md={6} className="col">
                <label>
                  <span className="labelForm">CVV</span>
                  <CardCVCElement
                    className="inputField"
                    placeholder="xxx"
                    style={{ base: styleCustomFields }}
                    onFocus={this.cleanUpError}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="submitHolder">
                {isLoading ? (
                  <Spinner spinnerColor="#4a0b96" />
                ) : (
                  <div className="submit-container">
                    <input type="submit" value="Submit" />
                  </div>
                )}
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    );
  }
}

StripeSourceRegistration.propTypes = {
  createStripeSource: func.isRequired,
  setStripe: func.isRequired,
  selectedIndex: number.isRequired,
  componentIndex: number.isRequired,
  stripeError: string.isRequired,
  email: string.isRequired,
  values: object.isRequired,
  isValid: bool.isRequired,
  clearStripeError: func.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  errors: object.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = (state) => ({
  stripeError: stripeErrorSelector(state),
  email: emailSelector(state),
  isLoading: isLoadingSelector(state),
  initialCompanyName: initialCompanyNameSelector(state),
});

const mapDispatchToProps = (dispatch, { stripe }) => ({
  createStripeSource: (payload) => dispatch(createStripeSource(payload)),
  clearStripeError: () => dispatch(clearStripeError()),
  setStripe: () => dispatch(setStripe(stripe)),
});

export default compose(
  injectStripe,
  withFormik({
    mapPropsToValues: () => ({
      [CARD_NAME]: '',
    }),
    validationSchema: stripeSourceRegistrationValidationSchema,
    displayName: 'stripeSourceRegistrationForm',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(StripeSourceRegistration);
