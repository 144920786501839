import moment from 'moment';
import { createTransform } from 'redux-persist';
import { CONSTANTS } from './enum';
import { extractUsernameFromToken } from './utils/utils';

export const authTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => {
    let flag = true;
    const tokenFromStorage = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
    if (tokenFromStorage) {
      try {
        // eslint-disable-next-line no-unused-vars
        const username = extractUsernameFromToken(tokenFromStorage);
      } catch {
        flag = false;
      }
    }
    if (flag) {
      flag = outboundState.tokenExpireAt !== null && moment(outboundState.tokenExpireAt).isAfter();
    }
    return {
      ...outboundState,
      isAuthenticated: flag,
      tokenExpireAt: flag ? outboundState.tokenExpireAt : '',
    };
  },
  { whitelist: ['auth'] },
);
