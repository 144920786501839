import Notification from '../../components/Notification';
import * as REST from '../../api/rest';
import { setCustomer, removeCustomer, removeCustomerStarted, removeCustomerFailed } from '../../store/auth/actions';
import { fecthCustomerStarted, fetchCustomerFailed, fetchCustomerSuccess } from '../../store/customers/actions';

export function selectCustomer(customer) {
  return async (dispatch) => {
    try {
      dispatch(setCustomer(customer));
      dispatch(fecthCustomerStarted());
      const result = await REST.fecthCustomerDetail({ customer: customer.uuid });
      const logo = await REST.getCustomerLogo(customer.uuid);
      result.logo = logo;
      dispatch(fetchCustomerSuccess(result));
    } catch (error) {
      dispatch(fetchCustomerFailed(error));
    }
  };
}

export function deleteCustomer(customer) {
  return async (dispatch) => {
    try {
      dispatch(removeCustomerStarted());
      await REST.removeCustomer(customer.uuid);
      dispatch(removeCustomer(customer));
      Notification({
        message: `${customer.name} has been removed`,
      });
    } catch (error) {
      dispatch(removeCustomerFailed({ message: error }));
    }
  };
}
