/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { call, take, put, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import { wsConnected, wsDisconnected } from '../global/actions';
import { generateImageSuccess } from '../objects/actions';
import Notification from '../../components/Notification';
import { CONSTANTS, SOCKET_CONST } from '../../enum';

export const WS_URL = process.env.REACT_APP_WS_URL;

function createEventChannel(socket) {
  return eventChannel((emit) => {
    socket.onopen = () => {
      console.log('WS: On Open');
    };
    socket.onclose = () => {
      console.log('WS: onClose');
    };
    socket.onerror = (error) => {
      console.log(`WS: WebSocket error ${error}`);
    };
    socket.onmessage = (m) => {
      emit(m.data);
    };
    return () => {
      socket.close();
    };
  });
}

function* websocketChannelSaga() {
  try {
    const globalState = yield select();
    if (!globalState.global.wsConnected && globalState.auth.isAuthenticated) {
      const accessToken = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      const url = `${WS_URL}?token=${accessToken}`;
      const socket = new WebSocket(url);
      const channel = yield call(createEventChannel, socket);
      yield put(wsConnected());
      while (true) {
        const msg = yield take(channel);
        const jsonMsg = JSON.parse(msg);

        yield checkMsgAndPut(jsonMsg);
      }
    }
  } catch (e) {
    console.log(e);
    yield put(wsDisconnected());
  }
}

// Handle socket message
const checkMsgAndPut = (msg) => {
  if (msg.Topic === SOCKET_CONST.IMAGE_GENERATED) {
    const storeGateway = JSON.parse(localStorage.getItem(msg.Payload.uuid));
    storeGateway[msg.Payload.platform] = {
      ...storeGateway[msg.Payload.platform],
      token: msg.Payload.token,
      url: msg.Payload.url,
      updatedAt: new Date(),
    };
    if (msg.Payload.url) {
      Notification({
        message: `${storeGateway[msg.Payload.platform].label} is ready to download from ${storeGateway.name}`,
      });
    }
    localStorage.setItem(msg.Payload.uuid, JSON.stringify(storeGateway));
    return put(generateImageSuccess(msg.Payload));
  }
  return null;
};

export default websocketChannelSaga;
