/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Spinner from 'react-spinner-material';
import ReactFlagsSelect from 'react-flags-select';
import CountryList from 'country-list';
import ReactPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import ErrorPanel from '../../../components/ErrorPanel/ErrorPanel';
import { ROUTES } from '../../../enum';
import { setCurrentCustomer } from '../../../store/customers/actions';
import { clearError } from '../../../store/user/actions';
import { isLoadingSelector } from '../../../store/user/selectors';

import './orgInfoSubForm.scss';

const countries = CountryList.getData();

class OrgInfoSubForm extends Component {
  constructor(props) {
    super(props);
    const { curCustomer } = this.props;
    this.state = {
      companyName: curCustomer.name,
      address: curCustomer.address.line1,
      country: CountryList.getName(curCustomer.address.country.toUpperCase()),
      city: curCustomer.address.city,
      zipCode: curCustomer.address.postal_code,
      state: curCustomer.address.state,
      name: curCustomer.contact.name,
      phone: curCustomer.contact.phone,
      unitedError: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (!this.isValid()) {
      return;
    }

    const customer = {
      address: {
        city: this.state.city,
        country: CountryList.getCode(this.state.country).toLowerCase(),
        line1: this.state.address,
        postal_code: this.state.zipCode,
        state: this.state.state,
      },
      contact: {
        name: this.state.name,
        phone: this.state.phone,
      },
      name: this.state.companyName,
    };
    this.props.setCurrentCustomer(customer);
    this.props.history.push(ROUTES.BILLING);
  };

  onSelectFlag = (countryCode) => {
    this.setState({
      country: countries.find((country) => country.code === countryCode).name,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      unitedError: '',
    });
  };

  closeErrorPanel = () => {
    this.setState({
      unitedError: '',
    });
  };

  isValid() {
    const { phone } = this.state;
    const regex = /^\+\d+\s*(\(\d+\))?[- \d]+$/;
    if (!phone) {
      this.setState({ unitedError: 'Phone Number Is Required' });
      return false;
    }
    if (!regex.test(phone) || !isValidPhoneNumber(phone)) {
      this.setState({ unitedError: 'Invalid Phone Number' });
      return false;
    }
    return true;
  }

  render() {
    const { isLoading } = this.props;
    const { unitedError, companyName, address, city, zipCode, state, name, phone } = this.state;

    return (
      <form onSubmit={this.onSubmit} className="form-container">
        {unitedError && (
          <ErrorPanel message={unitedError} buttonClickHandler={this.closeErrorPanel} key={unitedError} />
        )}
        <div className="company-info-form">
          <Row>
            <Col md={12} className="col">
              <label>
                <span className="labelForm">COMPANY NAME</span>
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  className="inputField"
                  onChange={this.handleChange}
                  name="companyName"
                  value={companyName}
                  maxLength={250}
                  required
                />
              </label>
            </Col>
            <Col md={12} className="col">
              <label>
                <span className="labelForm">STREET ADDRESS</span>
                <input
                  type="text"
                  placeholder="Enter Street Address"
                  className="inputField"
                  onChange={this.handleChange}
                  name="address"
                  value={address}
                  required
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="col">
              <label>
                <span className="labelForm">COUNTRY</span>
                <ReactFlagsSelect
                  defaultCountry="US"
                  searchable
                  onSelect={this.onSelectFlag}
                  className="inputField countrySelect"
                />
              </label>
            </Col>
            <Col xs={12} md={6} className="col">
              <label>
                <span className="labelForm">CITY</span>
                <input
                  type="text"
                  placeholder="Enter City"
                  className="inputField"
                  onChange={this.handleChange}
                  name="city"
                  value={city}
                  required
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="col">
              <label>
                <span className="labelForm">STATE</span>
                <input
                  type="text"
                  placeholder="Enter state"
                  className="inputField"
                  onChange={this.handleChange}
                  name="state"
                  value={state}
                  required
                />
              </label>
            </Col>
            <Col xs={12} md={6} className="col">
              <label>
                <span className="labelForm">ZIPCODE</span>
                <input
                  type="text"
                  placeholder="Enter Zipcode"
                  className="inputField"
                  onChange={this.handleChange}
                  minLength={5}
                  maxLength={6}
                  name="zipCode"
                  value={zipCode}
                  required
                />
              </label>
            </Col>
          </Row>
        </div>
        <div className="contact-form">
          <h4 className="title">Administrative Contact</h4>
          <div className="divider" />
          <Row>
            <Col xs={12} md={6}>
              <label>
                <span className="labelForm">NAME</span>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="inputField"
                  onChange={this.handleChange}
                  name="name"
                  value={name}
                  required
                />
              </label>
            </Col>
            <Col xs={12} md={6}>
              <label>
                <span className="labelForm">PHONE</span>
                <OverlayTrigger
                  placement="right"
                  delay={250}
                  overlay={
                    <Tooltip id="tooltip-right">
                      Please enter your phone number in the international format, for example:&nbsp;
                      <strong> +1 123-456-7890 </strong>
                    </Tooltip>
                  }
                >
                  <div className="input-container">
                    <ReactPhoneInput
                      defaultCountry="US"
                      name="phone"
                      value={phone}
                      placeholder="Enter Number"
                      onChange={(value) => this.setState({ phone: value })}
                      required
                    />
                  </div>
                </OverlayTrigger>
              </label>
            </Col>
          </Row>
        </div>

        {isLoading ? (
          <Spinner spinnerColor="#4a0b96" className="spinner" />
        ) : (
          <div className="submit-button-container">
            <input type="submit" className="signup-button" value="Next" />
          </div>
        )}
      </form>
    );
  }
}

OrgInfoSubForm.defaultProps = {
  isLoading: true,
};

OrgInfoSubForm.propTypes = {
  curCustomer: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  setCurrentCustomer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  curCustomer: state.customers.currentCustomer,
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearError,
      setCurrentCustomer,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrgInfoSubForm);
