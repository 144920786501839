import moment from 'moment';
import { GATEWAY_TYPE } from '../enum/gateway';
import { IP_MODES } from '../enum/ip';
import { LOCATION_TYPE } from '../enum/location';

export default {
  role: ({ uuid, name, element }) => ({
    value: uuid || '',
    label: name || '',
    type: element || 'role',
  }),
  group: ({ uuid, name, element }) => ({
    value: uuid || '',
    label: name || '',
    type: element || 'group',
  }),
  thing: (entity) => ({
    name: entity.name,
    uuid: entity.uuid,
    assetValue: entity.asset,
    category: entity.category.value,
    ...(entity.expiryTime
      ? {
          expiry: {
            time: moment(entity.expiryTime).toISOString(),
            type: entity.expiryType,
          },
        }
      : {}),
    ...(entity.location.value !== LOCATION_TYPE.UNDEFINED
      ? {
          geolocation: {
            latitude: parseFloat(entity.lat),
            longitude: parseFloat(entity.lng),
          },
        }
      : {}),
    ...(entity.desc ? { description: entity.desc } : {}),
    profileGroup: entity.profile.value,
    type: 'device',
  }),
  address: (entity) => ({
    address: entity.address,
    category: entity.category.value,
    name: entity.name,
    type: entity.protocolType,
    ...(entity.expiryTime
      ? {
          expiry: {
            time: moment(entity.expiryTime).toISOString(),
            type: entity.expiryType,
          },
        }
      : {}),
    ...(entity.location.value !== LOCATION_TYPE.UNDEFINED
      ? {
          geolocation: {
            latitude: parseFloat(entity.lat),
            longitude: parseFloat(entity.lng),
          },
        }
      : {}),
    ...(entity.desc ? { description: entity.desc } : {}),
  }),
  publicAddress: (entity) => ({
    name: entity.name,
    ...(entity.desc ? { description: entity.desc } : {}),
    type: entity.type,
    nsp: entity.nsp,
  }),
  user: (entity) => ({
    users: entity.map((user) => ({
      ...user,
      role: user.role.value,
    })),
  }),
  server: (entity) => ({ ...entity }),
  gateway: (entity) => ({
    name: entity.name,
    category: entity.category.value,
    gateway_type: entity.gateway_type,
    allow_all_ciphers: entity.allow_all_ciphers,
    ...(entity.expiryTime
      ? {
          expiry: {
            time: moment(entity.expiryTime).toISOString(),
            type: entity.expiryType,
          },
        }
      : {}),
    ...(entity.location.value !== LOCATION_TYPE.UNDEFINED
      ? {
          geolocation: {
            latitude: parseFloat(entity.lat),
            longitude: parseFloat(entity.lng),
          },
        }
      : {}),
    ...(entity.desc ? { description: entity.desc } : {}),
    ...(entity.gateway_internet_proto === IP_MODES.STATIC ? { gateway_internet_gw: entity.gateway_internet_gw } : {}),
    ...(entity.gateway_internet_proto === IP_MODES.STATIC
      ? { gateway_internet_ip: checkMask(entity.gateway_internet_ip) }
      : {}),
    ...(entity.gateway_type === GATEWAY_TYPE.VGATEWAY ? { gateway_internet_proto: entity.gateway_internet_proto } : {}),
    ...(entity.gateway_type === GATEWAY_TYPE.VGATEWAY ? { gateway_local_ip: checkMask(entity.gateway_local_ip) } : {}),
    peer_address: removeMask(entity.peer_address),
    peer_id: removeMask(entity.peer_id || ''),
    acreto_id: removeMask(entity.acreto_id),
    model: entity.model,
    peer_networks: entity.peer_networks.map((net) => ({
      network: checkMask(net.network),
      ...(entity.gateway_type === GATEWAY_TYPE.VGATEWAY ? { gateway: net.hop } : {}),
    })),
  }),
};

function checkMask(ip) {
  if (ip) {
    return ip.split('/')[1] ? ip : `${ip}/32`;
  }
  return null;
}
function removeMask(ip) {
  return ip.split('/')[0];
}
