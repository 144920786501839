export const FETCHING_OBJECT_REQUESTED = 'FETCHING_OBJECT_REQUESTED';
export const FETCHING_OBJECT_SUCCESS = 'FETCHING_OBJECT_SUCCESS';
export const FETCHING_OBJECT_FAILURE = 'FETCHING_OBJECT_FAILURE';

export const CREATION_OBJECT_FAILURE = 'CREATION_OBJECT_FAILURE';
export const CREATION_OBJECT_REQUESTED = 'CREATION_OBJECT_REQUESTED';
export const CREATION_OBJECT_SUCCESS = 'CREATION_OBJECT_SUCCESS';
export const CREATE_POLICY_OBJECT = 'CREATE_POLICY_OBJECT';

export const UPDATE_OBJECT_FAILURE = 'UPDATE_OBJECT_FAILURE';
export const UPDATE_OBJECT_REQUESTED = 'UPDATE_OBJECT_REQUESTED';
export const UPDATE_OBJECT_SUCCESS = 'UPDATE_OBJECT_SUCCESS';

export const FETCHING_ADDRESS_REQUESTED = 'FETCHING_ADDRESS_REQUESTED';
export const FETCHING_ADDRESS_SUCCESS = 'FETCHING_ADDRESS_SUCCESS';
export const FETCHING_ADDRESS_FAILURE = 'FETCHING_ADDRESS_FAILURE';

export const DELETE_OBJECT_FAILURE = 'DELETE_OBJECT_FAILURE';
export const DELETE_OBJECT_REQUESTED = 'DELETE_OBJECT_REQUESTED';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';

export const GENERATE_TOKEN_FAILURE = 'GENERATE_TOKEN_FAILURE';
export const GENERATE_TOKEN_REQUESTED = 'GENERATE_TOKEN_REQUESTED';
export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS';

export const THING_CONF_FAILURE = 'THING_CONF_FAILURE';
export const THING_CONF_REQUESTED = 'THING_CONF_REQUESTED';
export const THING_CONF_SUCCESS = 'THING_CONF_SUCCESS';

export const REGEN_PSK_FAILURE = 'REGEN_PSK_FAILURE';
export const REGEN_PSK_REQUESTED = 'REGEN_PSK_REQUESTED';
export const REGEN_PSK_SUCCESS = 'REGEN_PSK_SUCCESS';

export const READ_GATEWAY_REQUESTED = 'READ_GATEWAY_REQUESTED';
export const READ_GATEWAY_SUCCESS = 'READ_GATEWAY_SUCCESS';
export const READ_GATEWAY_FAILURE = 'READ_GATEWAY_FAILURE';

export const GET_THING_TOKEN = 'GET_THING_TOKEN';
export const GET_LDAP_TOKEN = 'GET_LDAP_TOKEN';
export const GET_LDAP_TOKEN_SUCCESS = 'GET_LDAP_TOKEN_SUCCESS';
export const GET_LDAP_TOKEN_FAILURE = 'GET_LDAP_TOKEN_FAILURE';

export const UPDATE_LDAP_PASSOWRD_REQUESTED = 'UPDATE_LDAP_PASSOWRD_REQUESTED';
export const UPDATE_LDAP_PASSOWRD_SUCCESS = 'UPDATE_LDAP_PASSOWRD_SUCCESS';
export const UPDATE_LDAP_PASSOWRD_FAILURE = 'UPDATE_LDAP_PASSOWRD_FAILURE';

export const GENERATE_IMAGE_STARTED = 'GENERATE_IMAGE_STARTED';
export const GENERATE_IMAGE_SUCCESS = 'GENERATE_IMAGE_SUCCESS';
export const GENERATE_IMAGE_FAILURE = 'GENERATE_IMAGE_FAILURE';

export const USER_INVITATION_FAILURE = 'USER_INVITATION_FAILURE';
export const USER_INVITATION_REQUESTED = 'USER_INVITATION_REQUESTED';
export const USER_INVITATION_SUCCESS = 'USER_INVITATION_SUCCESS';

export const LDAP_STATUS_FAILURE = 'LDAP_STATUS_FAILURE';
export const LDAP_STATUS_REQUESTED = 'LDAP_STATUS_REQUESTED';
export const LDAP_STATUS_SUCCESS = 'LDAP_STATUS_SUCCESS';
