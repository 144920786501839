import validator from 'validator';

const isValidMask = (mask, version) => validator.isInt(mask, { min: 0, max: 2 ** (version + 1) });

const IPValidator = (addr, version) => {
  if (!addr) return false;
  if (version === 'fqdn') return validator.isFQDN(addr);
  const ip = addr.split('/');
  return (
    validator.isIP(ip[0], version) && (ip.length === 1 || (ip.length === 2 && isValidMask(ip[1], Number(version))))
  );
};

export default IPValidator;
