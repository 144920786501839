import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { convertFirstNUppercased } from '../../utils/capitalize';
import { deleteError } from '../../store/errors/actions';
import './fetch-error-message.scss';

class FetchErrorMessage extends React.PureComponent {
  getHelp = () => {
    const {
      errors: { error },
      user,
    } = this.props;
    const {
      data: { code },
      status,
      headers: header,
    } = error.response;
    const { selectedCustomer } = this.props.auth;
    const { currentEcosystem } = this.props.ecosystems;

    const customerLink = selectedCustomer ? `customer=${selectedCustomer.uuid}&` : '';
    const ecosystemLink = currentEcosystem ? `ecosystem=${currentEcosystem.uuid}&` : '';
    const reqLink = `x-request-id=${header['x-request-id']}`;
    // eslint-disable-next-line max-len
    const helpLink = `${process.env.REACT_APP_KB_URL}/err/${code}#${customerLink}${ecosystemLink}user=${user.uuid}&${reqLink}&status=${status}&code=${code}`;

    return helpLink;
  };

  render() {
    const {
      errors: { errorKey, error },
      deleteError,
    } = this.props;
    if (errorKey && error && error.response) {
      const {
        data: { code, message, debug_info: debugInfo },
      } = error.response;
      const msg = message || debugInfo;
      return (
        <div className="fetch-error-message">
          <div className="fetch-error-message--container">
            <div className="d-flex">
              <i className="fa fa-exclamation-circle" />
              <p className="fetch-error-message--code">{`Error ${code}:`}</p>
              <p className="fetch-error-message--text">{convertFirstNUppercased(msg)}</p>
            </div>
            <div className="d-flex">
              <a href={this.getHelp()} target="_blank" rel="noopener noreferrer">
                <div className="fetch-error-message--help">Get Help Here</div>
              </a>
              <div className="divider">|</div>
              <i className="fa fa-close" onClick={() => deleteError()} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

FetchErrorMessage.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ecosystems: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  deleteError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  ecosystems: state.ecosystems,
  errors: state.errors,
});
const mapDispatchToProps = (dispatch) => ({
  deleteError: () => dispatch(deleteError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FetchErrorMessage);
