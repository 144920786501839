import React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { func, bool } from 'prop-types';

import { ROUTES } from './enum';
import ProtectedRoute from './components/ProtectedRoute';
import history from './history';
import FetchErrorMessage from './components/FetchErrorMessage/FetchErrorMessage';

import Dashboard from './layouts/Dashboard/Dashboard';
import Ecosystems from './layouts/Ecosystems/Ecosystems';
import Organizations from './layouts/Organizations/Organizations';
import Login from './layouts/Login/Login';
import { startup } from './store/common-scenario-actions';
import GlobalLoader from './components/GlobalLoader/GlobalLoader';
import NotFound from './views/NotFound/NotFound';
import UserProfile from './views/UserProfile/UserProfile';
import Eula from './views/LoginForm/Eula/eula';
import OverlayLoader from './components/OverlayLoader/OverlayLoader';
import { isAuthenticatedSelector } from './store/user/selectors';

import 'react-toastify/dist/ReactToastify.css';
import './reset.scss';

class App extends React.Component {
  componentDidMount() {
    const { startup } = this.props;
    startup();
  }

  render() {
    const { startupFinished, isLoading, isAuthenticated } = this.props;
    const LoadingIndicator = (isLoading) => (isLoading ? <OverlayLoader /> : null);
    return (
      <React.Fragment>
        <FetchErrorMessage />
        {startupFinished ? (
          <Router history={history}>
            <Switch>
              <Redirect exact from="/" to={ROUTES.CUSTOMERS} />
              <Redirect exact from="/v1" to={ROUTES.CUSTOMERS} />
              <ProtectedRoute exact path={ROUTES.CUSTOMERS} component={Organizations} />
              <Redirect exact from={`${ROUTES.CUSTOMERS}/:cusID`} to={`${ROUTES.CUSTOMERS}/:cusID/ecosystems`} />
              <ProtectedRoute exact path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems`} component={Ecosystems} />
              <ProtectedRoute path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID`} component={Dashboard} />
              <ProtectedRoute exact path={ROUTES.PROFILE} component={UserProfile} />
              <ProtectedRoute exact path={ROUTES.EULA} component={Eula} />
              <Route path="/v1/auth" component={Login} />
              <Route path={ROUTES.NOT_FOUND} component={NotFound} />
              <Route path="*" component={!isAuthenticated ? Login : NotFound} />
            </Switch>
          </Router>
        ) : (
          <GlobalLoader />
        )}
        <ToastContainer />
        {LoadingIndicator(isLoading)}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  startup: func.isRequired,
  startupFinished: bool.isRequired,
  isLoading: bool,
  isAuthenticated: bool,
};

const mapStateToProps = (state) => ({
  startupFinished: state.global.startupFinished,
  isLoading: state.global.isLoading,
  isAuthenticated: isAuthenticatedSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(startup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
