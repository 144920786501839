import _ from 'lodash';

export const createLoadingSelector = (actions) => (state) =>
  _(actions).some((action) => _.get(state, `loading.${action}`));

export const createErrorMessageSelector = (actions) => (state) =>
  _(actions)
    .map((action) => (state.errors.errorKey === action ? state.errors.error : ''))
    .compact()
    .first() || '';

export const needsLoginMessageSelector = (state) => {
  const needsLoginMessage = _.get(state, 'auth.needsLogInMessage');
  return needsLoginMessage;
};
