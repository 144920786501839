/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, Collapse } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { findDOMNode } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { REMOTE_DEVICES } from '../../enum/thing';

import { getTlsVpnLink, getOpenVpnLink } from '../../api/rest';

import './thing-devices.scss';

class ThingDevices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      token: '',
      tlsvpn: '',
      openvpn: '',
      isCollapsed: {
        'Mobile Devices': false,
        'Personal Computers and Servers': false,
        'Acreto Connect Client Installer': false,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.token && props.token !== state.token) {
      const tlsvpn = getTlsVpnLink(props.token);
      const openvpn = getOpenVpnLink(props.token);
      return {
        token: props.token,
        tlsvpn,
        openvpn,
      };
    }
    return null;
  }

  handleSearchChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    this.setState((state) => ({
      ...state,
      searchText: value,
      isCollapsed: {
        ...state.isCollapsed,
        ...Object.keys(state.isCollapsed).reduce((acc, key) => {
          acc[key] = !!value;
          return acc;
        }, {}),
      },
    }));
  };

  openCollapse = (title) => (event) => {
    event.preventDefault();

    this.setState((state) => ({
      ...state,
      isCollapsed: {
        ...state.isCollapsed,
        [title]: !state.isCollapsed[title],
      },
    }));
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({
      searchText: event.target.value,
    });
  };

  onDownload = (vpn) => {
    const link = document.createElement('a');
    link.download = this.props.data.name;
    link.href = this.state[vpn];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  searchOptions = (label) =>
    !this.state.searchText || label.toLowerCase().includes(this.state.searchText.toLowerCase());

  render() {
    const { isCollapsed } = this.state;

    return (
      <div className="panel__devices">
        <Panel>
          <Panel.Heading>
            <input placeholder="Search" onChange={this.handleSearchChange} />
            <i className="fa fa-search" />
          </Panel.Heading>
          <Panel.Body>
            {REMOTE_DEVICES.map(({ title, options }) => (
              <div key={`key-${title}`} className="download-list">
                <span className="download-list__label" onClick={this.openCollapse(title)}>
                  <i className="material-icons">{isCollapsed[title] ? 'arrow_drop_down' : 'arrow_right'}</i>
                  {title}
                </span>
                <Collapse in={isCollapsed[title]}>
                  <div className="download-list__collapse">
                    {options.map(
                      ({ label, value }) =>
                        this.searchOptions(label) && (
                          <div key={`thing-config-${label}`} className="download__option">
                            <span>{label}</span>
                            <div className="download__functions">
                              <i
                                className="fa fa-download"
                                onClick={() => this.onDownload(value)}
                                data-tip="Download"
                              />
                              <CopyToClipboard
                                text={this.state[value]}
                                onCopy={() => {
                                  ReactTooltip.show(findDOMNode(this.refs[label]));
                                }}
                              >
                                <i className="config-copy fa fa-clone" data-tip="Copy URL" />
                              </CopyToClipboard>
                              <ReactTooltip />
                              <span ref={label} data-tip="Copied!" data-for={`${label}-download`} />
                              <ReactTooltip
                                id={`${label}-download`}
                                delayHide={1000}
                                type="info"
                                afterShow={() => ReactTooltip.hide(findDOMNode(this.refs[value]))}
                              />
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </Collapse>
              </div>
            ))}
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

ThingDevices.propTypes = {
  token: PropTypes.string,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const ecosystemId = state.ecosystems.currentEcosystem.uuid;
  const { token } = state.objects[ecosystemId].objects.find((obj) => obj.uuid === ownProps.data.uuid);

  return {
    token,
  };
};

export default connect(mapStateToProps)(ThingDevices);
