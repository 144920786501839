import { FINISH_STARTUP, HIDE_LOADER, SHOW_LOADER, WS_CONNECTED, WS_DISCONNECTED } from './action-types';

export function finishStartup() {
  return {
    type: FINISH_STARTUP,
  };
}

export function loading(isLoading) {
  return {
    type: isLoading ? SHOW_LOADER : HIDE_LOADER,
  };
}

export function wsConnected() {
  return {
    type: WS_CONNECTED,
  };
}

export function wsDisconnected() {
  return {
    type: WS_DISCONNECTED,
  };
}
