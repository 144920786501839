import { ROUTES } from '../../enum';
import * as REST from '../../api/rest';
import history from '../../history';
import { loginFailed, loginStarted, loginSuccess, setCustomer } from '../../store/auth/actions';
import { readUserAvatar, readUserData } from '../../store/sagas/apiCalls';
import { setUser } from '../../store/user/actions';
import { parseResponseError } from '../../utils/utils';

export function login(credentials, redirect) {
  return async (dispatch) => {
    try {
      dispatch(loginStarted());
      let result = null;
      if (credentials.code) {
        result = await REST.loginMFA(
          {
            username: credentials.email,
            password: credentials.password,
          },
          credentials.code,
        );
      } else {
        result = await REST.login({
          username: credentials.email,
          password: credentials.password,
        });
      }
      dispatch(
        loginSuccess({
          accessToken: result.accessToken,
          customers: result.customers,
        }),
      );
      const { data } = await readUserData(credentials.email);
      const { data: avatar } = await readUserAvatar(credentials.email);
      dispatch(setUser({ ...data, avatar }));

      if (result.customers && result.customers.length > 0) {
        if (result.customers.length === 1) {
          dispatch(setCustomer(result.customers[0]));
          history.push(
            redirect && !['/', '/v1', ROUTES.CUSTOMERS].includes(redirect.pathname)
              ? redirect
              : `${ROUTES.CUSTOMERS}/${result.customers[0].uuid}/ecosystems`,
          );
        } else if (redirect.pathname === '/' || redirect.pathname === '/v1') {
          history.push(ROUTES.CUSTOMERS);
        } else {
          const splittedPathname = redirect.pathname.split('/');
          if (splittedPathname.length > 3) {
            const customer = result.customers.find((cust) => cust.uuid === splittedPathname[3]);
            if (customer) {
              dispatch(setCustomer(customer));
              history.push(redirect || ROUTES.CUSTOMERS);
            } else {
              history.push(ROUTES.CUSTOMERS);
            }
          } else {
            history.push(ROUTES.CUSTOMERS);
          }
        }
      } else {
        history.push(redirect || ROUTES.CUSTOMERS);
      }
    } catch (error) {
      const errorMessage = parseResponseError(error, {
        400: 'Your email or password is incorrect!',
        424: credentials.code ? 'Invalid Code' : 'MFA required',
      });
      dispatch(loginFailed({ message: errorMessage }));
    }
  };
}

export function eulaAccepted(redirect) {
  localStorage.setItem('eulaAccepted', 'true');
  localStorage.setItem('commit-reminder', 'true');
  return async () => {
    history.push(redirect || ROUTES.CUSTOMERS, null);
  };
}

export const removeAuthError = () => (dispatch) => {
  dispatch(loginFailed({ message: '' }));
};
