export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RENEW_TOKEN = 'RENEW_TOKEN';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';

export const REMOVE_CUSTOMER_REQUESTED = 'REMOVE_CUSTOMER_REQUESTED';
export const REMOVE_CUSTOMER_FAILURE = 'REMOVE_CUSTOMER_FAILURE';
export const CLEAR_LOG_IN_MESSAGE = 'CLEAR_LOG_IN_MESSAGE';

export const ACTIVATE_2FA_REQUEST = 'ACTIVATE_2FA_REQUEST';
export const ACTIVATE_2FA_SUCCESS = 'ACTIVATE_2FA_SUCCESS';
export const ACTIVATE_2FA_FAILURE = 'ACTIVATE_2FA_FAILURE';

export const DEACTIVATE_2FA_REQUEST = 'DEACTIVATE_2FA_REQUEST';
export const DEACTIVATE_2FA_SUCCESS = 'DEACTIVATE_2FA_SUCCESS';
export const DEACTIVATE_2FA_FAILURE = 'DEACTIVATE_2FA_FAILURE';

export const SET_CUSTOMER = 'SET_CUSTOMER';
