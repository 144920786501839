import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { object, func, array } from 'prop-types';
import findIndex from 'lodash/findIndex';
import cx from 'classnames';

import { ROUTES } from '../../enum';
import UnauthorizedRoute from '../../components/UnauthorizedRoute';
import Stepper from '../../components/Stepper/Stepper';
import EmailSubForm from './EmailSubForm/EmailSubForm';
import BillingSubForm from './BillingInfoSubForm/BillingInfoSubForm';
import PersonalInfoSubForm from './PersonalInfoSubForm/PersonalInfoSubForm';
import OrgInfoSubForm from './OrgInfoSubForm/OrgInfoSubForm';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation';
import EmailEntered from '../EmailEntered/EmailEntered';
import { extractLastValueFromPathname } from '../../utils/routeLocationParsers';
import { removeAuthError } from './scenario-actions';

import './sign-up-form.scss';

const steps = [
  { title: 'USER PROFILE', endpoint: '/personal-info' },
  { title: 'ORGANIZATION PROFILE', endpoint: '/org-info' },
  { title: 'BILLING', endpoint: '/billing' },
];
const EMAIL_PATH = '/email';
const EMAIL_ENTERED_PATH = '/email-entered';
class SignUpForm extends Component {
  componentDidMount() {
    this.props.removeAuthError();
  }

  render() {
    const { location, customers } = this.props;
    const endpoint = `/${extractLastValueFromPathname(location)}`;
    const stepIndex = findIndex(steps, (step) => step.endpoint === endpoint);
    const shouldStepsBeDisplayed = stepIndex >= 0;
    const isEmail = ['/', '/sign-up', EMAIL_PATH, EMAIL_ENTERED_PATH].includes(endpoint);
    const loginFormWrapperClasses = cx('sign-up-form', {
      'personalInfo-form': stepIndex === 0,
      'orgInfo-form': stepIndex === 1,
      'payment-form': stepIndex === 2,
    });
    const isStepper = shouldStepsBeDisplayed && (!customers || !customers.length);
    return (
      <div className={cx('signup-form-page--content', { 'info-form': shouldStepsBeDisplayed })}>
        {isStepper && <Stepper steps={steps} activeStepIndex={stepIndex} />}
        <div className={loginFormWrapperClasses}>
          {isEmail && <h2 className="signup-title">Sign up</h2>}
          <Switch>
            <UnauthorizedRoute exact path={ROUTES.PERSONAL_INFO} component={PersonalInfoSubForm} />
            <UnauthorizedRoute exact path={ROUTES.ORG_INFO} component={OrgInfoSubForm} />
            <UnauthorizedRoute exact path={ROUTES.BILLING} component={BillingSubForm} />
            <UnauthorizedRoute exact path={ROUTES.SIGN_UP_EMAIL_CONFIRMATION} component={EmailConfirmation} />
            <UnauthorizedRoute exact path={ROUTES.SIGN_UP_EMAIL_ENTERED} component={EmailEntered} />
            <UnauthorizedRoute exact path={ROUTES.SIGN_UP_EMAIL} component={EmailSubForm} />
            <UnauthorizedRoute exact path={`${ROUTES.SIGN_UP}/`} component={EmailSubForm} />
            <Redirect to={ROUTES.LOGIN} />
          </Switch>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  location: object.isRequired,
  removeAuthError: func.isRequired,
  customers: array,
};

const mapStateToProps = (state) => ({
  customers: state.auth.customers,
});

const mapDispatchToProps = (dispatch) => ({
  removeAuthError: () => dispatch(removeAuthError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpForm));
