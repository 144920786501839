export const GATEWAY_PLATFORM = {
  STRONGSWAN: 'strongswan',
  KVM: 'kvm',
  AWS: 'aws',
  ORANGEPI: 'orangepi_r1',
  RASPBERRYPI: 'raspberrypi_3',
};

export const GATEWAY_TYPE = {
  VGATEWAY: 'vGateway',
  IPSEC: 'ipsec',
};

export const GATEWAY_TYPE_OPTIONS = [
  { label: 'vGateway', value: GATEWAY_TYPE.VGATEWAY },
  { label: 'IPSec', value: GATEWAY_TYPE.IPSEC },
];

export const MODEL_TYPE = {
  GENERIC: 'generic',
  AWS_SITE: 'aws_site2site_vpn',
};

export const MODEL_TYPE_OPTIONS = [
  { label: 'Generic IPsec', value: MODEL_TYPE.GENERIC },
  { label: 'AWS site-to-site VPN', value: MODEL_TYPE.AWS_SITE },
];

export const GATEWAY_DOWNLOADS = [
  {
    title: 'Software Clients with Config',
    ipsec: true,
    vGateway: true,
    options: [
      {
        label: 'Other OS - strongSwan - Config only',
        value: 'strongswan',
        help: '/how-to/connect/ipsec/ipsec-gateway-auto-config/',
      },
    ],
  },
  {
    title: 'VM and Cloud Images',
    vGateway: true,
    options: [
      {
        label: 'KVM .qcow2 vGateway Image',
        value: 'kvm_image',
        help: '/l/gateway/vm-and-cloud/kvm_image',
      },
      {
        label: 'VMware .vmdk vGateway Image',
        value: 'vmware_vmdk_image',
        help: '/l/gateway/vm-and-cloud/vmware_vmdk_image',
      },
      {
        label: 'VirtualBox .vdi vGateway Image',
        value: 'vbox_vdi_image',
        help: '/l/gateway/vm-and-cloud/vbox_vdi_image',
      },
      {
        label: 'Microsoft Hyper-V .vhdx vGateway Image',
        value: 'ms_hyperv_image',
        help: '/l/gateway/vm-and-cloud/ms_hyperv_image',
      },
      {
        label: 'Microsoft Azure .vhd vGateway Image',
        value: 'azure_vhd_image',
        help: '/l/gateway/vm-and-cloud/azure_vhd_image',
      },
    ],
  },
  {
    title: 'IoT Hardware Images',
    vGateway: true,
    options: [
      {
        label: 'Raspberry Pi 3 64-bit vGateway Image',
        value: 'raspberry_3_64bit_image',
        help: '/how-to/connect/vgateway/raspberry/',
      },
      {
        label: 'Raspberry Pi 4 64-bit vGateway Image',
        value: 'raspberry_4_64bit_image',
        help: '/how-to/connect/vgateway/raspberry/',
      },
    ],
  },
];
