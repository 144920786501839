/**
 * Ecosystem Options
 */

export default {
  EDIT: 'edit',
  UPGRADE: 'upgrade',
  RECOVER: 'recover',
  REMOVE: 'remove',
};
