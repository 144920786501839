import React from 'react';
import PropTypes from 'prop-types';

import { NSP_REGIONS } from '../enum';

const NSPMap = ({ regions, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="699" height="368" viewBox="0 0 699 368">
    <g id="Group_251" data-name="Group 251" transform="translate(-792 -220)">
      <circle
        id="Ellipse_1303"
        data-name="Ellipse 1303"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1000 586)"
        fill="#cdcdcd"
      />
      <circle
        id="Ellipse_1306"
        data-name="Ellipse 1306"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1000 582)"
        fill="#cdcdcd"
      />
      <circle
        id="Ellipse_1393"
        data-name="Ellipse 1393"
        cx="1"
        cy="1"
        r="1"
        transform="translate(996 582)"
        fill="#cdcdcd"
      />
      <circle
        id="Ellipse_1304"
        data-name="Ellipse 1304"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1004 586)"
        fill="#cdcdcd"
      />
      <circle
        id="Ellipse_1307"
        data-name="Ellipse 1307"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1004 582)"
        fill="#cdcdcd"
      />
      <circle
        id="Ellipse_1305"
        data-name="Ellipse 1305"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1008 586)"
        fill="#cdcdcd"
      />
      <g id="Group_250" data-name="Group 250">
        <circle
          id="Ellipse_369"
          data-name="Ellipse 369"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1942"
          data-name="Ellipse 1942"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_434"
          data-name="Ellipse 434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_438"
          data-name="Ellipse 438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_648"
          data-name="Ellipse 648"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_473"
          data-name="Ellipse 473"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1323"
          data-name="Ellipse 1323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 562)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1376"
          data-name="Ellipse 1376"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 562)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1379"
          data-name="Ellipse 1379"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 570)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_633"
          data-name="Ellipse 633"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_543"
          data-name="Ellipse 543"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_572"
          data-name="Ellipse 572"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_490"
          data-name="Ellipse 490"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1349"
          data-name="Ellipse 1349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 542)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_573"
          data-name="Ellipse 573"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_505"
          data-name="Ellipse 505"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6035"
          data-name="Ellipse 6035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_689"
          data-name="Ellipse 689"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_551"
          data-name="Ellipse 551"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_453"
          data-name="Ellipse 453"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1210"
          data-name="Ellipse 1210"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_643"
          data-name="Ellipse 643"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_460"
          data-name="Ellipse 460"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1333"
          data-name="Ellipse 1333"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 554)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1397"
          data-name="Ellipse 1397"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 554)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_613"
          data-name="Ellipse 613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_527"
          data-name="Ellipse 527"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_692"
          data-name="Ellipse 692"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_552"
          data-name="Ellipse 552"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_480"
          data-name="Ellipse 480"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1361"
          data-name="Ellipse 1361"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1435"
          data-name="Ellipse 1435"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1420"
          data-name="Ellipse 1420"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1471"
          data-name="Ellipse 1471"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1538"
          data-name="Ellipse 1538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1617"
          data-name="Ellipse 1617"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1495"
          data-name="Ellipse 1495"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1516"
          data-name="Ellipse 1516"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1618"
          data-name="Ellipse 1618"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2104"
          data-name="Ellipse 2104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6727"
          data-name="Ellipse 6727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7012"
          data-name="Ellipse 7012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6790"
          data-name="Ellipse 6790"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7089"
          data-name="Ellipse 7089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7305"
          data-name="Ellipse 7305"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6850"
          data-name="Ellipse 6850"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3055"
          data-name="Ellipse 3055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3056"
          data-name="Ellipse 3056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3579"
          data-name="Ellipse 3579"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2267"
          data-name="Ellipse 2267"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6269"
          data-name="Ellipse 6269"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2930"
          data-name="Ellipse 2930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4288"
          data-name="Ellipse 4288"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4404"
          data-name="Ellipse 4404"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2738"
          data-name="Ellipse 2738"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2193"
          data-name="Ellipse 2193"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3755"
          data-name="Ellipse 3755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3775"
          data-name="Ellipse 3775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3822"
          data-name="Ellipse 3822"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4432"
          data-name="Ellipse 4432"
          cx="1"
          cy="1"
          r="1"
          transform="translate(971 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4453"
          data-name="Ellipse 4453"
          cx="1"
          cy="1"
          r="1"
          transform="translate(963 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3830"
          data-name="Ellipse 3830"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4434"
          data-name="Ellipse 4434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(979 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3838"
          data-name="Ellipse 3838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3795"
          data-name="Ellipse 3795"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3823"
          data-name="Ellipse 3823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3831"
          data-name="Ellipse 3831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3853"
          data-name="Ellipse 3853"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3839"
          data-name="Ellipse 3839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3854"
          data-name="Ellipse 3854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3859"
          data-name="Ellipse 3859"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3299"
          data-name="Ellipse 3299"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6270"
          data-name="Ellipse 6270"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9237"
          data-name="Ellipse 9237"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9253"
          data-name="Ellipse 9253"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3582"
          data-name="Ellipse 3582"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2360"
          data-name="Ellipse 2360"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6313"
          data-name="Ellipse 6313"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6617"
          data-name="Ellipse 6617"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7013"
          data-name="Ellipse 7013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2931"
          data-name="Ellipse 2931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2804"
          data-name="Ellipse 2804"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3584"
          data-name="Ellipse 3584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1670"
          data-name="Ellipse 1670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2042"
          data-name="Ellipse 2042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6389"
          data-name="Ellipse 6389"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6621"
          data-name="Ellipse 6621"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7014"
          data-name="Ellipse 7014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2932"
          data-name="Ellipse 2932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2898"
          data-name="Ellipse 2898"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3586"
          data-name="Ellipse 3586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2250"
          data-name="Ellipse 2250"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2933"
          data-name="Ellipse 2933"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4263"
          data-name="Ellipse 4263"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3587"
          data-name="Ellipse 3587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2162"
          data-name="Ellipse 2162"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6757"
          data-name="Ellipse 6757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7015"
          data-name="Ellipse 7015"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7231"
          data-name="Ellipse 7231"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6789"
          data-name="Ellipse 6789"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6865"
          data-name="Ellipse 6865"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6912"
          data-name="Ellipse 6912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3110"
          data-name="Ellipse 3110"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3111"
          data-name="Ellipse 3111"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2286"
          data-name="Ellipse 2286"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6272"
          data-name="Ellipse 6272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6618"
          data-name="Ellipse 6618"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7016"
          data-name="Ellipse 7016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2934"
          data-name="Ellipse 2934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2774"
          data-name="Ellipse 2774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1719"
          data-name="Ellipse 1719"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1799"
          data-name="Ellipse 1799"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1436"
          data-name="Ellipse 1436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1472"
          data-name="Ellipse 1472"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1539"
          data-name="Ellipse 1539"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1629"
          data-name="Ellipse 1629"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1496"
          data-name="Ellipse 1496"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1517"
          data-name="Ellipse 1517"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1630"
          data-name="Ellipse 1630"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2105"
          data-name="Ellipse 2105"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6505"
          data-name="Ellipse 6505"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6728"
          data-name="Ellipse 6728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7067"
          data-name="Ellipse 7067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6791"
          data-name="Ellipse 6791"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7090"
          data-name="Ellipse 7090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7306"
          data-name="Ellipse 7306"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6851"
          data-name="Ellipse 6851"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3057"
          data-name="Ellipse 3057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3058"
          data-name="Ellipse 3058"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2268"
          data-name="Ellipse 2268"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2935"
          data-name="Ellipse 2935"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4289"
          data-name="Ellipse 4289"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4405"
          data-name="Ellipse 4405"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2739"
          data-name="Ellipse 2739"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2194"
          data-name="Ellipse 2194"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3744"
          data-name="Ellipse 3744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3764"
          data-name="Ellipse 3764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3301"
          data-name="Ellipse 3301"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2361"
          data-name="Ellipse 2361"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6314"
          data-name="Ellipse 6314"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6597"
          data-name="Ellipse 6597"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7068"
          data-name="Ellipse 7068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2936"
          data-name="Ellipse 2936"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2805"
          data-name="Ellipse 2805"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1676"
          data-name="Ellipse 1676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2043"
          data-name="Ellipse 2043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6390"
          data-name="Ellipse 6390"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6622"
          data-name="Ellipse 6622"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7069"
          data-name="Ellipse 7069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2937"
          data-name="Ellipse 2937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2899"
          data-name="Ellipse 2899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2234"
          data-name="Ellipse 2234"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2938"
          data-name="Ellipse 2938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4246"
          data-name="Ellipse 4246"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2154"
          data-name="Ellipse 2154"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6758"
          data-name="Ellipse 6758"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7070"
          data-name="Ellipse 7070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6792"
          data-name="Ellipse 6792"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6866"
          data-name="Ellipse 6866"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3112"
          data-name="Ellipse 3112"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3113"
          data-name="Ellipse 3113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2287"
          data-name="Ellipse 2287"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6273"
          data-name="Ellipse 6273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6598"
          data-name="Ellipse 6598"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7071"
          data-name="Ellipse 7071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2939"
          data-name="Ellipse 2939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2775"
          data-name="Ellipse 2775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1725"
          data-name="Ellipse 1725"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1801"
          data-name="Ellipse 1801"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1446"
          data-name="Ellipse 1446"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1473"
          data-name="Ellipse 1473"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1540"
          data-name="Ellipse 1540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1601"
          data-name="Ellipse 1601"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1565"
          data-name="Ellipse 1565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1602"
          data-name="Ellipse 1602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1497"
          data-name="Ellipse 1497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1524"
          data-name="Ellipse 1524"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1595"
          data-name="Ellipse 1595"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2106"
          data-name="Ellipse 2106"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6506"
          data-name="Ellipse 6506"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6729"
          data-name="Ellipse 6729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7576"
          data-name="Ellipse 7576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7966"
          data-name="Ellipse 7966"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8356"
          data-name="Ellipse 8356"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8746"
          data-name="Ellipse 8746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6976"
          data-name="Ellipse 6976"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7577"
          data-name="Ellipse 7577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7967"
          data-name="Ellipse 7967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8357"
          data-name="Ellipse 8357"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8747"
          data-name="Ellipse 8747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7192"
          data-name="Ellipse 7192"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7578"
          data-name="Ellipse 7578"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7968"
          data-name="Ellipse 7968"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8358"
          data-name="Ellipse 8358"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8748"
          data-name="Ellipse 8748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6793"
          data-name="Ellipse 6793"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7625"
          data-name="Ellipse 7625"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9400"
          data-name="Ellipse 9400"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9520"
          data-name="Ellipse 9520"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9640"
          data-name="Ellipse 9640"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8015"
          data-name="Ellipse 8015"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9401"
          data-name="Ellipse 9401"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9521"
          data-name="Ellipse 9521"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9641"
          data-name="Ellipse 9641"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9761"
          data-name="Ellipse 9761"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9881"
          data-name="Ellipse 9881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8405"
          data-name="Ellipse 8405"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9484"
          data-name="Ellipse 9484"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9604"
          data-name="Ellipse 9604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10028"
          data-name="Ellipse 10028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9724"
          data-name="Ellipse 9724"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10029"
          data-name="Ellipse 10029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10051"
          data-name="Ellipse 10051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10030"
          data-name="Ellipse 10030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10052"
          data-name="Ellipse 10052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9964"
          data-name="Ellipse 9964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10065"
          data-name="Ellipse 10065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10073"
          data-name="Ellipse 10073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7091"
          data-name="Ellipse 7091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7579"
          data-name="Ellipse 7579"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7969"
          data-name="Ellipse 7969"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8359"
          data-name="Ellipse 8359"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8749"
          data-name="Ellipse 8749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7307"
          data-name="Ellipse 7307"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7580"
          data-name="Ellipse 7580"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7970"
          data-name="Ellipse 7970"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8360"
          data-name="Ellipse 8360"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8750"
          data-name="Ellipse 8750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8974"
          data-name="Ellipse 8974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6860"
          data-name="Ellipse 6860"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7669"
          data-name="Ellipse 7669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9428"
          data-name="Ellipse 9428"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9548"
          data-name="Ellipse 9548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9668"
          data-name="Ellipse 9668"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8059"
          data-name="Ellipse 8059"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9429"
          data-name="Ellipse 9429"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9549"
          data-name="Ellipse 9549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9669"
          data-name="Ellipse 9669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9789"
          data-name="Ellipse 9789"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9909"
          data-name="Ellipse 9909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8449"
          data-name="Ellipse 8449"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9485"
          data-name="Ellipse 9485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9605"
          data-name="Ellipse 9605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10031"
          data-name="Ellipse 10031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9725"
          data-name="Ellipse 9725"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10032"
          data-name="Ellipse 10032"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10054"
          data-name="Ellipse 10054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10033"
          data-name="Ellipse 10033"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9965"
          data-name="Ellipse 9965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10066"
          data-name="Ellipse 10066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10074"
          data-name="Ellipse 10074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3098"
          data-name="Ellipse 3098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3059"
          data-name="Ellipse 3059"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2269"
          data-name="Ellipse 2269"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6315"
          data-name="Ellipse 6315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2740"
          data-name="Ellipse 2740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2195"
          data-name="Ellipse 2195"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3303"
          data-name="Ellipse 3303"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6316"
          data-name="Ellipse 6316"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9238"
          data-name="Ellipse 9238"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9254"
          data-name="Ellipse 9254"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9270"
          data-name="Ellipse 9270"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9286"
          data-name="Ellipse 9286"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3304"
          data-name="Ellipse 3304"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2362"
          data-name="Ellipse 2362"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6317"
          data-name="Ellipse 6317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6663"
          data-name="Ellipse 6663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7581"
          data-name="Ellipse 7581"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7971"
          data-name="Ellipse 7971"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8361"
          data-name="Ellipse 8361"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8751"
          data-name="Ellipse 8751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6955"
          data-name="Ellipse 6955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7582"
          data-name="Ellipse 7582"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7972"
          data-name="Ellipse 7972"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8362"
          data-name="Ellipse 8362"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8752"
          data-name="Ellipse 8752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8975"
          data-name="Ellipse 8975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7973"
          data-name="Ellipse 7973"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8753"
          data-name="Ellipse 8753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3100"
          data-name="Ellipse 3100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2806"
          data-name="Ellipse 2806"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1660"
          data-name="Ellipse 1660"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2044"
          data-name="Ellipse 2044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6664"
          data-name="Ellipse 6664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7584"
          data-name="Ellipse 7584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7974"
          data-name="Ellipse 7974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8364"
          data-name="Ellipse 8364"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8754"
          data-name="Ellipse 8754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6956"
          data-name="Ellipse 6956"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7585"
          data-name="Ellipse 7585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7975"
          data-name="Ellipse 7975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8365"
          data-name="Ellipse 8365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8755"
          data-name="Ellipse 8755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8976"
          data-name="Ellipse 8976"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7976"
          data-name="Ellipse 7976"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8756"
          data-name="Ellipse 8756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3101"
          data-name="Ellipse 3101"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2900"
          data-name="Ellipse 2900"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2270"
          data-name="Ellipse 2270"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6318"
          data-name="Ellipse 6318"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3102"
          data-name="Ellipse 3102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2532"
          data-name="Ellipse 2532"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2174"
          data-name="Ellipse 2174"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6542"
          data-name="Ellipse 6542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6759"
          data-name="Ellipse 6759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7587"
          data-name="Ellipse 7587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7977"
          data-name="Ellipse 7977"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8367"
          data-name="Ellipse 8367"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8757"
          data-name="Ellipse 8757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6993"
          data-name="Ellipse 6993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7588"
          data-name="Ellipse 7588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7978"
          data-name="Ellipse 7978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8368"
          data-name="Ellipse 8368"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7209"
          data-name="Ellipse 7209"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7589"
          data-name="Ellipse 7589"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7979"
          data-name="Ellipse 7979"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8369"
          data-name="Ellipse 8369"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8759"
          data-name="Ellipse 8759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6778"
          data-name="Ellipse 6778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7670"
          data-name="Ellipse 7670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9430"
          data-name="Ellipse 9430"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9550"
          data-name="Ellipse 9550"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9670"
          data-name="Ellipse 9670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8060"
          data-name="Ellipse 8060"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9431"
          data-name="Ellipse 9431"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9551"
          data-name="Ellipse 9551"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9671"
          data-name="Ellipse 9671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9791"
          data-name="Ellipse 9791"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9911"
          data-name="Ellipse 9911"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8450"
          data-name="Ellipse 8450"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9486"
          data-name="Ellipse 9486"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9606"
          data-name="Ellipse 9606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10034"
          data-name="Ellipse 10034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9726"
          data-name="Ellipse 9726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10035"
          data-name="Ellipse 10035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10057"
          data-name="Ellipse 10057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10036"
          data-name="Ellipse 10036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9966"
          data-name="Ellipse 9966"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10067"
          data-name="Ellipse 10067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10075"
          data-name="Ellipse 10075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6867"
          data-name="Ellipse 6867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7687"
          data-name="Ellipse 7687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9487"
          data-name="Ellipse 9487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9607"
          data-name="Ellipse 9607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9727"
          data-name="Ellipse 9727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8077"
          data-name="Ellipse 8077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9488"
          data-name="Ellipse 9488"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9608"
          data-name="Ellipse 9608"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9728"
          data-name="Ellipse 9728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9848"
          data-name="Ellipse 9848"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8467"
          data-name="Ellipse 8467"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9609"
          data-name="Ellipse 9609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10037"
          data-name="Ellipse 10037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10038"
          data-name="Ellipse 10038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10060"
          data-name="Ellipse 10060"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9969"
          data-name="Ellipse 9969"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10081"
          data-name="Ellipse 10081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10068"
          data-name="Ellipse 10068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10079"
          data-name="Ellipse 10079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10076"
          data-name="Ellipse 10076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10077"
          data-name="Ellipse 10077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3114"
          data-name="Ellipse 3114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3115"
          data-name="Ellipse 3115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2288"
          data-name="Ellipse 2288"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6319"
          data-name="Ellipse 6319"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6665"
          data-name="Ellipse 6665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7590"
          data-name="Ellipse 7590"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7980"
          data-name="Ellipse 7980"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8370"
          data-name="Ellipse 8370"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8760"
          data-name="Ellipse 8760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6957"
          data-name="Ellipse 6957"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7591"
          data-name="Ellipse 7591"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7981"
          data-name="Ellipse 7981"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8371"
          data-name="Ellipse 8371"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8761"
          data-name="Ellipse 8761"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8978"
          data-name="Ellipse 8978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7982"
          data-name="Ellipse 7982"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3103"
          data-name="Ellipse 3103"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2776"
          data-name="Ellipse 2776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1711"
          data-name="Ellipse 1711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1747"
          data-name="Ellipse 1747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1875"
          data-name="Ellipse 1875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2062"
          data-name="Ellipse 2062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6429"
          data-name="Ellipse 6429"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2940"
          data-name="Ellipse 2940"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2941"
          data-name="Ellipse 2941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2241"
          data-name="Ellipse 2241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2807"
          data-name="Ellipse 2807"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4249"
          data-name="Ellipse 4249"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2163"
          data-name="Ellipse 2163"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3182"
          data-name="Ellipse 3182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3183"
          data-name="Ellipse 3183"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2309"
          data-name="Ellipse 2309"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6574"
          data-name="Ellipse 6574"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2809"
          data-name="Ellipse 2809"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1919"
          data-name="Ellipse 1919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1804"
          data-name="Ellipse 1804"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1562"
          data-name="Ellipse 1562"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1578"
          data-name="Ellipse 1578"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2121"
          data-name="Ellipse 2121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6507"
          data-name="Ellipse 6507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6730"
          data-name="Ellipse 6730"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7519"
          data-name="Ellipse 7519"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7909"
          data-name="Ellipse 7909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8299"
          data-name="Ellipse 8299"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6977"
          data-name="Ellipse 6977"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7381"
          data-name="Ellipse 7381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7771"
          data-name="Ellipse 7771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8161"
          data-name="Ellipse 8161"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7193"
          data-name="Ellipse 7193"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7382"
          data-name="Ellipse 7382"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7772"
          data-name="Ellipse 7772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8162"
          data-name="Ellipse 8162"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6794"
          data-name="Ellipse 6794"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9402"
          data-name="Ellipse 9402"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7626"
          data-name="Ellipse 7626"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9403"
          data-name="Ellipse 9403"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9523"
          data-name="Ellipse 9523"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8016"
          data-name="Ellipse 8016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9422"
          data-name="Ellipse 9422"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9542"
          data-name="Ellipse 9542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9662"
          data-name="Ellipse 9662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7092"
          data-name="Ellipse 7092"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7408"
          data-name="Ellipse 7408"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7798"
          data-name="Ellipse 7798"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8188"
          data-name="Ellipse 8188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7308"
          data-name="Ellipse 7308"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7383"
          data-name="Ellipse 7383"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7773"
          data-name="Ellipse 7773"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8163"
          data-name="Ellipse 8163"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9036"
          data-name="Ellipse 9036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8553"
          data-name="Ellipse 8553"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6880"
          data-name="Ellipse 6880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7671"
          data-name="Ellipse 7671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9433"
          data-name="Ellipse 9433"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8061"
          data-name="Ellipse 8061"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9434"
          data-name="Ellipse 9434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9554"
          data-name="Ellipse 9554"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9674"
          data-name="Ellipse 9674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8451"
          data-name="Ellipse 8451"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3232"
          data-name="Ellipse 3232"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3926"
          data-name="Ellipse 3926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2666"
          data-name="Ellipse 2666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3060"
          data-name="Ellipse 3060"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2353"
          data-name="Ellipse 2353"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6481"
          data-name="Ellipse 6481"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3927"
          data-name="Ellipse 3927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4113"
          data-name="Ellipse 4113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3233"
          data-name="Ellipse 3233"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4326"
          data-name="Ellipse 4326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2667"
          data-name="Ellipse 2667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2777"
          data-name="Ellipse 2777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4292"
          data-name="Ellipse 4292"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2222"
          data-name="Ellipse 2222"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3738"
          data-name="Ellipse 3738"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3929"
          data-name="Ellipse 3929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3306"
          data-name="Ellipse 3306"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2363"
          data-name="Ellipse 2363"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6482"
          data-name="Ellipse 6482"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6713"
          data-name="Ellipse 6713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7480"
          data-name="Ellipse 7480"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7870"
          data-name="Ellipse 7870"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8260"
          data-name="Ellipse 8260"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6922"
          data-name="Ellipse 6922"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7384"
          data-name="Ellipse 7384"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7774"
          data-name="Ellipse 7774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8164"
          data-name="Ellipse 8164"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9037"
          data-name="Ellipse 9037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8554"
          data-name="Ellipse 8554"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7138"
          data-name="Ellipse 7138"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7775"
          data-name="Ellipse 7775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3931"
          data-name="Ellipse 3931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2669"
          data-name="Ellipse 2669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2810"
          data-name="Ellipse 2810"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1649"
          data-name="Ellipse 1649"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2122"
          data-name="Ellipse 2122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6483"
          data-name="Ellipse 6483"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6714"
          data-name="Ellipse 6714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7513"
          data-name="Ellipse 7513"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7903"
          data-name="Ellipse 7903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8293"
          data-name="Ellipse 8293"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6929"
          data-name="Ellipse 6929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7386"
          data-name="Ellipse 7386"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7776"
          data-name="Ellipse 7776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8166"
          data-name="Ellipse 8166"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9038"
          data-name="Ellipse 9038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8556"
          data-name="Ellipse 8556"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7145"
          data-name="Ellipse 7145"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7777"
          data-name="Ellipse 7777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3235"
          data-name="Ellipse 3235"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3933"
          data-name="Ellipse 3933"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2670"
          data-name="Ellipse 2670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2901"
          data-name="Ellipse 2901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2354"
          data-name="Ellipse 2354"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6484"
          data-name="Ellipse 6484"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3934"
          data-name="Ellipse 3934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4120"
          data-name="Ellipse 4120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3236"
          data-name="Ellipse 3236"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2671"
          data-name="Ellipse 2671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2778"
          data-name="Ellipse 2778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2223"
          data-name="Ellipse 2223"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6543"
          data-name="Ellipse 6543"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6760"
          data-name="Ellipse 6760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7569"
          data-name="Ellipse 7569"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7959"
          data-name="Ellipse 7959"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8349"
          data-name="Ellipse 8349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6994"
          data-name="Ellipse 6994"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7388"
          data-name="Ellipse 7388"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7778"
          data-name="Ellipse 7778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8168"
          data-name="Ellipse 8168"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7210"
          data-name="Ellipse 7210"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7389"
          data-name="Ellipse 7389"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7779"
          data-name="Ellipse 7779"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8169"
          data-name="Ellipse 8169"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8559"
          data-name="Ellipse 8559"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6779"
          data-name="Ellipse 6779"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7672"
          data-name="Ellipse 7672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9436"
          data-name="Ellipse 9436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8062"
          data-name="Ellipse 8062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9437"
          data-name="Ellipse 9437"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9557"
          data-name="Ellipse 9557"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9677"
          data-name="Ellipse 9677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8452"
          data-name="Ellipse 8452"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6881"
          data-name="Ellipse 6881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7688"
          data-name="Ellipse 7688"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9491"
          data-name="Ellipse 9491"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8078"
          data-name="Ellipse 8078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9492"
          data-name="Ellipse 9492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9612"
          data-name="Ellipse 9612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9972"
          data-name="Ellipse 9972"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10003"
          data-name="Ellipse 10003"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10007"
          data-name="Ellipse 10007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10013"
          data-name="Ellipse 10013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10017"
          data-name="Ellipse 10017"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10011"
          data-name="Ellipse 10011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10014"
          data-name="Ellipse 10014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10018"
          data-name="Ellipse 10018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8468"
          data-name="Ellipse 8468"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3937"
          data-name="Ellipse 3937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3116"
          data-name="Ellipse 3116"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2355"
          data-name="Ellipse 2355"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6485"
          data-name="Ellipse 6485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6715"
          data-name="Ellipse 6715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7409"
          data-name="Ellipse 7409"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7799"
          data-name="Ellipse 7799"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8189"
          data-name="Ellipse 8189"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6917"
          data-name="Ellipse 6917"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7390"
          data-name="Ellipse 7390"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7780"
          data-name="Ellipse 7780"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8170"
          data-name="Ellipse 8170"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9040"
          data-name="Ellipse 9040"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8560"
          data-name="Ellipse 8560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8916"
          data-name="Ellipse 8916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1481 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3938"
          data-name="Ellipse 3938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4124"
          data-name="Ellipse 4124"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3238"
          data-name="Ellipse 3238"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3939"
          data-name="Ellipse 3939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2673"
          data-name="Ellipse 2673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2779"
          data-name="Ellipse 2779"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1640"
          data-name="Ellipse 1640"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1048 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1650"
          data-name="Ellipse 1650"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1048 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_574"
          data-name="Ellipse 574"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_504"
          data-name="Ellipse 504"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6036"
          data-name="Ellipse 6036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4693"
          data-name="Ellipse 4693"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1182"
          data-name="Ellipse 1182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6037"
          data-name="Ellipse 6037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4643"
          data-name="Ellipse 4643"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5289"
          data-name="Ellipse 5289"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_694"
          data-name="Ellipse 694"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5956"
          data-name="Ellipse 5956"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4694"
          data-name="Ellipse 4694"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1183"
          data-name="Ellipse 1183"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5957"
          data-name="Ellipse 5957"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4573"
          data-name="Ellipse 4573"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5291"
          data-name="Ellipse 5291"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_548"
          data-name="Ellipse 548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4736"
          data-name="Ellipse 4736"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5752"
          data-name="Ellipse 5752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1171 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1184"
          data-name="Ellipse 1184"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6130"
          data-name="Ellipse 6130"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10084"
          data-name="Ellipse 10084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1447 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10099"
          data-name="Ellipse 10099"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1464 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10107"
          data-name="Ellipse 10107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1464 543)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10123"
          data-name="Ellipse 10123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1452 555)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10090"
          data-name="Ellipse 10090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1451 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10100"
          data-name="Ellipse 10100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1468 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10108"
          data-name="Ellipse 10108"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1468 543)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10124"
          data-name="Ellipse 10124"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1456 555)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4737"
          data-name="Ellipse 4737"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_794"
          data-name="Ellipse 794"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5931"
          data-name="Ellipse 5931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4695"
          data-name="Ellipse 4695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5586"
          data-name="Ellipse 5586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5618"
          data-name="Ellipse 5618"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5682"
          data-name="Ellipse 5682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5714"
          data-name="Ellipse 5714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(946 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5495"
          data-name="Ellipse 5495"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 304)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_443"
          data-name="Ellipse 443"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_649"
          data-name="Ellipse 649"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_466"
          data-name="Ellipse 466"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_614"
          data-name="Ellipse 614"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_538"
          data-name="Ellipse 538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_553"
          data-name="Ellipse 553"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_482"
          data-name="Ellipse 482"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1355"
          data-name="Ellipse 1355"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_575"
          data-name="Ellipse 575"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_506"
          data-name="Ellipse 506"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6038"
          data-name="Ellipse 6038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4748"
          data-name="Ellipse 4748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5775"
          data-name="Ellipse 5775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1151 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_698"
          data-name="Ellipse 698"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4749"
          data-name="Ellipse 4749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_549"
          data-name="Ellipse 549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4750"
          data-name="Ellipse 4750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_806"
          data-name="Ellipse 806"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4751"
          data-name="Ellipse 4751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5590"
          data-name="Ellipse 5590"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5622"
          data-name="Ellipse 5622"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_448"
          data-name="Ellipse 448"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1319"
          data-name="Ellipse 1319"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 566)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1377"
          data-name="Ellipse 1377"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 566)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1384"
          data-name="Ellipse 1384"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 574)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1209"
          data-name="Ellipse 1209"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1320"
          data-name="Ellipse 1320"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 566)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1385"
          data-name="Ellipse 1385"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 574)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_634"
          data-name="Ellipse 634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_494"
          data-name="Ellipse 494"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1343"
          data-name="Ellipse 1343"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 546)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_615"
          data-name="Ellipse 615"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_511"
          data-name="Ellipse 511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6039"
          data-name="Ellipse 6039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_701"
          data-name="Ellipse 701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_554"
          data-name="Ellipse 554"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_458"
          data-name="Ellipse 458"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1338"
          data-name="Ellipse 1338"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 550)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1394"
          data-name="Ellipse 1394"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 550)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_616"
          data-name="Ellipse 616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_518"
          data-name="Ellipse 518"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_703"
          data-name="Ellipse 703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_555"
          data-name="Ellipse 555"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_478"
          data-name="Ellipse 478"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1366"
          data-name="Ellipse 1366"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1437"
          data-name="Ellipse 1437"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1455"
          data-name="Ellipse 1455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1418"
          data-name="Ellipse 1418"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1462"
          data-name="Ellipse 1462"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1526"
          data-name="Ellipse 1526"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1619"
          data-name="Ellipse 1619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1678"
          data-name="Ellipse 1678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2063"
          data-name="Ellipse 2063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6430"
          data-name="Ellipse 6430"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6649"
          data-name="Ellipse 6649"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7017"
          data-name="Ellipse 7017"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6832"
          data-name="Ellipse 6832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7018"
          data-name="Ellipse 7018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7234"
          data-name="Ellipse 7234"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2942"
          data-name="Ellipse 2942"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2943"
          data-name="Ellipse 2943"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3595"
          data-name="Ellipse 3595"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2251"
          data-name="Ellipse 2251"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2944"
          data-name="Ellipse 2944"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4264"
          data-name="Ellipse 4264"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3596"
          data-name="Ellipse 3596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2164"
          data-name="Ellipse 2164"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3184"
          data-name="Ellipse 3184"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2310"
          data-name="Ellipse 2310"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6277"
          data-name="Ellipse 6277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6619"
          data-name="Ellipse 6619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7019"
          data-name="Ellipse 7019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2945"
          data-name="Ellipse 2945"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2811"
          data-name="Ellipse 2811"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3600"
          data-name="Ellipse 3600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1727"
          data-name="Ellipse 1727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1821"
          data-name="Ellipse 1821"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1485"
          data-name="Ellipse 1485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1508"
          data-name="Ellipse 1508"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1620"
          data-name="Ellipse 1620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2083"
          data-name="Ellipse 2083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6460"
          data-name="Ellipse 6460"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6681"
          data-name="Ellipse 6681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7020"
          data-name="Ellipse 7020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6810"
          data-name="Ellipse 6810"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7053"
          data-name="Ellipse 7053"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7269"
          data-name="Ellipse 7269"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2946"
          data-name="Ellipse 2946"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2947"
          data-name="Ellipse 2947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3602"
          data-name="Ellipse 3602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2252"
          data-name="Ellipse 2252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6278"
          data-name="Ellipse 6278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2948"
          data-name="Ellipse 2948"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4265"
          data-name="Ellipse 4265"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4381"
          data-name="Ellipse 4381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2175"
          data-name="Ellipse 2175"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3756"
          data-name="Ellipse 3756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3776"
          data-name="Ellipse 3776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3796"
          data-name="Ellipse 3796"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3824"
          data-name="Ellipse 3824"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4436"
          data-name="Ellipse 4436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(971 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4448"
          data-name="Ellipse 4448"
          cx="1"
          cy="1"
          r="1"
          transform="translate(971 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3832"
          data-name="Ellipse 3832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4437"
          data-name="Ellipse 4437"
          cx="1"
          cy="1"
          r="1"
          transform="translate(979 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4449"
          data-name="Ellipse 4449"
          cx="1"
          cy="1"
          r="1"
          transform="translate(979 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3840"
          data-name="Ellipse 3840"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4441"
          data-name="Ellipse 4441"
          cx="1"
          cy="1"
          r="1"
          transform="translate(987 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3816"
          data-name="Ellipse 3816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3825"
          data-name="Ellipse 3825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3833"
          data-name="Ellipse 3833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3841"
          data-name="Ellipse 3841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3844"
          data-name="Ellipse 3844"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3861"
          data-name="Ellipse 3861"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3865"
          data-name="Ellipse 3865"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3246"
          data-name="Ellipse 3246"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6279"
          data-name="Ellipse 6279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9230"
          data-name="Ellipse 9230"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2336"
          data-name="Ellipse 2336"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6321"
          data-name="Ellipse 6321"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6620"
          data-name="Ellipse 6620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7021"
          data-name="Ellipse 7021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2949"
          data-name="Ellipse 2949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(928 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2812"
          data-name="Ellipse 2812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(848 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3607"
          data-name="Ellipse 3607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(800 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1671"
          data-name="Ellipse 1671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1720"
          data-name="Ellipse 1720"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1778"
          data-name="Ellipse 1778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1438"
          data-name="Ellipse 1438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1463"
          data-name="Ellipse 1463"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1527"
          data-name="Ellipse 1527"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1631"
          data-name="Ellipse 1631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1679"
          data-name="Ellipse 1679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2064"
          data-name="Ellipse 2064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6431"
          data-name="Ellipse 6431"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6650"
          data-name="Ellipse 6650"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7072"
          data-name="Ellipse 7072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6833"
          data-name="Ellipse 6833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7073"
          data-name="Ellipse 7073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2950"
          data-name="Ellipse 2950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2951"
          data-name="Ellipse 2951"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2242"
          data-name="Ellipse 2242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2952"
          data-name="Ellipse 2952"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4250"
          data-name="Ellipse 4250"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2165"
          data-name="Ellipse 2165"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3186"
          data-name="Ellipse 3186"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2311"
          data-name="Ellipse 2311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6280"
          data-name="Ellipse 6280"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6600"
          data-name="Ellipse 6600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7074"
          data-name="Ellipse 7074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2953"
          data-name="Ellipse 2953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2813"
          data-name="Ellipse 2813"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1728"
          data-name="Ellipse 1728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1823"
          data-name="Ellipse 1823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1486"
          data-name="Ellipse 1486"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1509"
          data-name="Ellipse 1509"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1632"
          data-name="Ellipse 1632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2084"
          data-name="Ellipse 2084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6461"
          data-name="Ellipse 6461"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6682"
          data-name="Ellipse 6682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7075"
          data-name="Ellipse 7075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6811"
          data-name="Ellipse 6811"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7076"
          data-name="Ellipse 7076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2954"
          data-name="Ellipse 2954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2955"
          data-name="Ellipse 2955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2253"
          data-name="Ellipse 2253"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2956"
          data-name="Ellipse 2956"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4266"
          data-name="Ellipse 4266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2176"
          data-name="Ellipse 2176"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3745"
          data-name="Ellipse 3745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3765"
          data-name="Ellipse 3765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3785"
          data-name="Ellipse 3785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3248"
          data-name="Ellipse 3248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2337"
          data-name="Ellipse 2337"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6322"
          data-name="Ellipse 6322"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6601"
          data-name="Ellipse 6601"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7077"
          data-name="Ellipse 7077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2957"
          data-name="Ellipse 2957"
          cx="1"
          cy="1"
          r="1"
          transform="translate(916 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2814"
          data-name="Ellipse 2814"
          cx="1"
          cy="1"
          r="1"
          transform="translate(836 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1677"
          data-name="Ellipse 1677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1726"
          data-name="Ellipse 1726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1780"
          data-name="Ellipse 1780"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1934"
          data-name="Ellipse 1934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1440"
          data-name="Ellipse 1440"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1470"
          data-name="Ellipse 1470"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1528"
          data-name="Ellipse 1528"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1596"
          data-name="Ellipse 1596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1680"
          data-name="Ellipse 1680"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2065"
          data-name="Ellipse 2065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6666"
          data-name="Ellipse 6666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7593"
          data-name="Ellipse 7593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7983"
          data-name="Ellipse 7983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8373"
          data-name="Ellipse 8373"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8763"
          data-name="Ellipse 8763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6958"
          data-name="Ellipse 6958"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7594"
          data-name="Ellipse 7594"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7984"
          data-name="Ellipse 7984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8374"
          data-name="Ellipse 8374"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8764"
          data-name="Ellipse 8764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7174"
          data-name="Ellipse 7174"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7985"
          data-name="Ellipse 7985"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8765"
          data-name="Ellipse 8765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6834"
          data-name="Ellipse 6834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7609"
          data-name="Ellipse 7609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7999"
          data-name="Ellipse 7999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8389"
          data-name="Ellipse 8389"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8779"
          data-name="Ellipse 8779"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7022"
          data-name="Ellipse 7022"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7596"
          data-name="Ellipse 7596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7986"
          data-name="Ellipse 7986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8376"
          data-name="Ellipse 8376"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8766"
          data-name="Ellipse 8766"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7238"
          data-name="Ellipse 7238"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7597"
          data-name="Ellipse 7597"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7987"
          data-name="Ellipse 7987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8377"
          data-name="Ellipse 8377"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8767"
          data-name="Ellipse 8767"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8980"
          data-name="Ellipse 8980"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3104"
          data-name="Ellipse 3104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2958"
          data-name="Ellipse 2958"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2271"
          data-name="Ellipse 2271"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6323"
          data-name="Ellipse 6323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3105"
          data-name="Ellipse 3105"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2177"
          data-name="Ellipse 2177"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3188"
          data-name="Ellipse 3188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3189"
          data-name="Ellipse 3189"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2312"
          data-name="Ellipse 2312"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6324"
          data-name="Ellipse 6324"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6667"
          data-name="Ellipse 6667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7598"
          data-name="Ellipse 7598"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7988"
          data-name="Ellipse 7988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8378"
          data-name="Ellipse 8378"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8768"
          data-name="Ellipse 8768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6959"
          data-name="Ellipse 6959"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7599"
          data-name="Ellipse 7599"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7989"
          data-name="Ellipse 7989"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8379"
          data-name="Ellipse 8379"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8769"
          data-name="Ellipse 8769"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8981"
          data-name="Ellipse 8981"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7990"
          data-name="Ellipse 7990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 259)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9229"
          data-name="Ellipse 9229"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 251)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3106"
          data-name="Ellipse 3106"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2815"
          data-name="Ellipse 2815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1729"
          data-name="Ellipse 1729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1825"
          data-name="Ellipse 1825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1759"
          data-name="Ellipse 1759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1876"
          data-name="Ellipse 1876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2085"
          data-name="Ellipse 2085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6462"
          data-name="Ellipse 6462"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6683"
          data-name="Ellipse 6683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2959"
          data-name="Ellipse 2959"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2960"
          data-name="Ellipse 2960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2254"
          data-name="Ellipse 2254"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2816"
          data-name="Ellipse 2816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4267"
          data-name="Ellipse 4267"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2745"
          data-name="Ellipse 2745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2178"
          data-name="Ellipse 2178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3740"
          data-name="Ellipse 3740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3250"
          data-name="Ellipse 3250"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3251"
          data-name="Ellipse 3251"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2338"
          data-name="Ellipse 2338"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6583"
          data-name="Ellipse 6583"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2818"
          data-name="Ellipse 2818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1826"
          data-name="Ellipse 1826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1877"
          data-name="Ellipse 1877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1921"
          data-name="Ellipse 1921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2107"
          data-name="Ellipse 2107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6509"
          data-name="Ellipse 6509"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6732"
          data-name="Ellipse 6732"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3061"
          data-name="Ellipse 3061"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3062"
          data-name="Ellipse 3062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2272"
          data-name="Ellipse 2272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2819"
          data-name="Ellipse 2819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4294"
          data-name="Ellipse 4294"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2746"
          data-name="Ellipse 2746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2196"
          data-name="Ellipse 2196"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3307"
          data-name="Ellipse 3307"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2364"
          data-name="Ellipse 2364"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6602"
          data-name="Ellipse 6602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2820"
          data-name="Ellipse 2820"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2821"
          data-name="Ellipse 2821"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1563"
          data-name="Ellipse 1563"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1580"
          data-name="Ellipse 1580"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1681"
          data-name="Ellipse 1681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2123"
          data-name="Ellipse 2123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6486"
          data-name="Ellipse 6486"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6716"
          data-name="Ellipse 6716"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7520"
          data-name="Ellipse 7520"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7910"
          data-name="Ellipse 7910"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8300"
          data-name="Ellipse 8300"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6938"
          data-name="Ellipse 6938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7392"
          data-name="Ellipse 7392"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7782"
          data-name="Ellipse 7782"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8172"
          data-name="Ellipse 8172"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9041"
          data-name="Ellipse 9041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8562"
          data-name="Ellipse 8562"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7154"
          data-name="Ellipse 7154"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7393"
          data-name="Ellipse 7393"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7783"
          data-name="Ellipse 7783"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8563"
          data-name="Ellipse 8563"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6835"
          data-name="Ellipse 6835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7610"
          data-name="Ellipse 7610"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8000"
          data-name="Ellipse 8000"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8390"
          data-name="Ellipse 8390"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7023"
          data-name="Ellipse 7023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7410"
          data-name="Ellipse 7410"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7800"
          data-name="Ellipse 7800"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8190"
          data-name="Ellipse 8190"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7239"
          data-name="Ellipse 7239"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7394"
          data-name="Ellipse 7394"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7784"
          data-name="Ellipse 7784"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8174"
          data-name="Ellipse 8174"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9042"
          data-name="Ellipse 9042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8564"
          data-name="Ellipse 8564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3239"
          data-name="Ellipse 3239"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3941"
          data-name="Ellipse 3941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2674"
          data-name="Ellipse 2674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2961"
          data-name="Ellipse 2961"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2356"
          data-name="Ellipse 2356"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6487"
          data-name="Ellipse 6487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3942"
          data-name="Ellipse 3942"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4128"
          data-name="Ellipse 4128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3240"
          data-name="Ellipse 3240"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2675"
          data-name="Ellipse 2675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2780"
          data-name="Ellipse 2780"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4251"
          data-name="Ellipse 4251"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2224"
          data-name="Ellipse 2224"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3945"
          data-name="Ellipse 3945"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3190"
          data-name="Ellipse 3190"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2357"
          data-name="Ellipse 2357"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6488"
          data-name="Ellipse 6488"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6717"
          data-name="Ellipse 6717"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7470"
          data-name="Ellipse 7470"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7860"
          data-name="Ellipse 7860"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8250"
          data-name="Ellipse 8250"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6918"
          data-name="Ellipse 6918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7395"
          data-name="Ellipse 7395"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7785"
          data-name="Ellipse 7785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8175"
          data-name="Ellipse 8175"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9043"
          data-name="Ellipse 9043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8565"
          data-name="Ellipse 8565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8918"
          data-name="Ellipse 8918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1481 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3946"
          data-name="Ellipse 3946"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3242"
          data-name="Ellipse 3242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3947"
          data-name="Ellipse 3947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2677"
          data-name="Ellipse 2677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2822"
          data-name="Ellipse 2822"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1644"
          data-name="Ellipse 1644"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1048 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1682"
          data-name="Ellipse 1682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1048 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1487"
          data-name="Ellipse 1487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1525"
          data-name="Ellipse 1525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1597"
          data-name="Ellipse 1597"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2086"
          data-name="Ellipse 2086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6463"
          data-name="Ellipse 6463"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6684"
          data-name="Ellipse 6684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7601"
          data-name="Ellipse 7601"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7991"
          data-name="Ellipse 7991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8381"
          data-name="Ellipse 8381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8771"
          data-name="Ellipse 8771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6960"
          data-name="Ellipse 6960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7602"
          data-name="Ellipse 7602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7992"
          data-name="Ellipse 7992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8382"
          data-name="Ellipse 8382"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8772"
          data-name="Ellipse 8772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7176"
          data-name="Ellipse 7176"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7603"
          data-name="Ellipse 7603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7993"
          data-name="Ellipse 7993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8383"
          data-name="Ellipse 8383"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8773"
          data-name="Ellipse 8773"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6812"
          data-name="Ellipse 6812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7617"
          data-name="Ellipse 7617"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8007"
          data-name="Ellipse 8007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8397"
          data-name="Ellipse 8397"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8787"
          data-name="Ellipse 8787"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7054"
          data-name="Ellipse 7054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7604"
          data-name="Ellipse 7604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7994"
          data-name="Ellipse 7994"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8384"
          data-name="Ellipse 8384"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8774"
          data-name="Ellipse 8774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7270"
          data-name="Ellipse 7270"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7605"
          data-name="Ellipse 7605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7995"
          data-name="Ellipse 7995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8385"
          data-name="Ellipse 8385"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8775"
          data-name="Ellipse 8775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8983"
          data-name="Ellipse 8983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3107"
          data-name="Ellipse 3107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2962"
          data-name="Ellipse 2962"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2273"
          data-name="Ellipse 2273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6327"
          data-name="Ellipse 6327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3108"
          data-name="Ellipse 3108"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4295"
          data-name="Ellipse 4295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2179"
          data-name="Ellipse 2179"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3252"
          data-name="Ellipse 3252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6328"
          data-name="Ellipse 6328"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9231"
          data-name="Ellipse 9231"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9247"
          data-name="Ellipse 9247"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9263"
          data-name="Ellipse 9263"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3253"
          data-name="Ellipse 3253"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2339"
          data-name="Ellipse 2339"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6329"
          data-name="Ellipse 6329"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6668"
          data-name="Ellipse 6668"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7606"
          data-name="Ellipse 7606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7996"
          data-name="Ellipse 7996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8386"
          data-name="Ellipse 8386"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8776"
          data-name="Ellipse 8776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6961"
          data-name="Ellipse 6961"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7607"
          data-name="Ellipse 7607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1273 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7997"
          data-name="Ellipse 7997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1313 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8387"
          data-name="Ellipse 8387"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8777"
          data-name="Ellipse 8777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8984"
          data-name="Ellipse 8984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1461 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3109"
          data-name="Ellipse 3109"
          cx="1"
          cy="1"
          r="1"
          transform="translate(944 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2823"
          data-name="Ellipse 2823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(864 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1661"
          data-name="Ellipse 1661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1712"
          data-name="Ellipse 1712"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1746"
          data-name="Ellipse 1746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1878"
          data-name="Ellipse 1878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2045"
          data-name="Ellipse 2045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6393"
          data-name="Ellipse 6393"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6624"
          data-name="Ellipse 6624"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2902"
          data-name="Ellipse 2902"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2903"
          data-name="Ellipse 2903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2235"
          data-name="Ellipse 2235"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2824"
          data-name="Ellipse 2824"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4239"
          data-name="Ellipse 4239"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2155"
          data-name="Ellipse 2155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6544"
          data-name="Ellipse 6544"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6761"
          data-name="Ellipse 6761"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3117"
          data-name="Ellipse 3117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3118"
          data-name="Ellipse 3118"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2289"
          data-name="Ellipse 2289"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6576"
          data-name="Ellipse 6576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2825"
          data-name="Ellipse 2825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(900 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2781"
          data-name="Ellipse 2781"
          cx="1"
          cy="1"
          r="1"
          transform="translate(820 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1922"
          data-name="Ellipse 1922"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1782"
          data-name="Ellipse 1782"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1938"
          data-name="Ellipse 1938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1564"
          data-name="Ellipse 1564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1579"
          data-name="Ellipse 1579"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2124"
          data-name="Ellipse 2124"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6489"
          data-name="Ellipse 6489"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6718"
          data-name="Ellipse 6718"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7521"
          data-name="Ellipse 7521"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7911"
          data-name="Ellipse 7911"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8301"
          data-name="Ellipse 8301"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6962"
          data-name="Ellipse 6962"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7397"
          data-name="Ellipse 7397"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7787"
          data-name="Ellipse 7787"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8177"
          data-name="Ellipse 8177"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9044"
          data-name="Ellipse 9044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8567"
          data-name="Ellipse 8567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7398"
          data-name="Ellipse 7398"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7788"
          data-name="Ellipse 7788"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8178"
          data-name="Ellipse 8178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8568"
          data-name="Ellipse 8568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6813"
          data-name="Ellipse 6813"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7618"
          data-name="Ellipse 7618"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8008"
          data-name="Ellipse 8008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8398"
          data-name="Ellipse 8398"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7055"
          data-name="Ellipse 7055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7411"
          data-name="Ellipse 7411"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7801"
          data-name="Ellipse 7801"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8191"
          data-name="Ellipse 8191"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7271"
          data-name="Ellipse 7271"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7399"
          data-name="Ellipse 7399"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7789"
          data-name="Ellipse 7789"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8179"
          data-name="Ellipse 8179"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9045"
          data-name="Ellipse 9045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8569"
          data-name="Ellipse 8569"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3243"
          data-name="Ellipse 3243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3949"
          data-name="Ellipse 3949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2678"
          data-name="Ellipse 2678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2963"
          data-name="Ellipse 2963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2358"
          data-name="Ellipse 2358"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6490"
          data-name="Ellipse 6490"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3950"
          data-name="Ellipse 3950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4136"
          data-name="Ellipse 4136"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3244"
          data-name="Ellipse 3244"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2679"
          data-name="Ellipse 2679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2782"
          data-name="Ellipse 2782"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4268"
          data-name="Ellipse 4268"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2225"
          data-name="Ellipse 2225"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3953"
          data-name="Ellipse 3953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3255"
          data-name="Ellipse 3255"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2359"
          data-name="Ellipse 2359"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6491"
          data-name="Ellipse 6491"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6719"
          data-name="Ellipse 6719"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7471"
          data-name="Ellipse 7471"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7861"
          data-name="Ellipse 7861"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8251"
          data-name="Ellipse 8251"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6919"
          data-name="Ellipse 6919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1253 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7400"
          data-name="Ellipse 7400"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1293 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7790"
          data-name="Ellipse 7790"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1333 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8180"
          data-name="Ellipse 8180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9046"
          data-name="Ellipse 9046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1441 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8570"
          data-name="Ellipse 8570"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8923"
          data-name="Ellipse 8923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1481 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3954"
          data-name="Ellipse 3954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3955"
          data-name="Ellipse 3955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2681"
          data-name="Ellipse 2681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2826"
          data-name="Ellipse 2826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(884 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1651"
          data-name="Ellipse 1651"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1036 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1638"
          data-name="Ellipse 1638"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1048 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_576"
          data-name="Ellipse 576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_503"
          data-name="Ellipse 503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6041"
          data-name="Ellipse 6041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4653"
          data-name="Ellipse 4653"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1162"
          data-name="Ellipse 1162"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6042"
          data-name="Ellipse 6042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4644"
          data-name="Ellipse 4644"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5155"
          data-name="Ellipse 5155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_953"
          data-name="Ellipse 953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6043"
          data-name="Ellipse 6043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4645"
          data-name="Ellipse 4645"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1128"
          data-name="Ellipse 1128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6044"
          data-name="Ellipse 6044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4646"
          data-name="Ellipse 4646"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5157"
          data-name="Ellipse 5157"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_920"
          data-name="Ellipse 920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6045"
          data-name="Ellipse 6045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4647"
          data-name="Ellipse 4647"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1129"
          data-name="Ellipse 1129"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6046"
          data-name="Ellipse 6046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4648"
          data-name="Ellipse 4648"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5159"
          data-name="Ellipse 5159"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_948"
          data-name="Ellipse 948"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6047"
          data-name="Ellipse 6047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4649"
          data-name="Ellipse 4649"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5329"
          data-name="Ellipse 5329"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1130"
          data-name="Ellipse 1130"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6048"
          data-name="Ellipse 6048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5082"
          data-name="Ellipse 5082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_705"
          data-name="Ellipse 705"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5960"
          data-name="Ellipse 5960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4654"
          data-name="Ellipse 4654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1163"
          data-name="Ellipse 1163"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5961"
          data-name="Ellipse 5961"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4574"
          data-name="Ellipse 4574"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5161"
          data-name="Ellipse 5161"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_954"
          data-name="Ellipse 954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5962"
          data-name="Ellipse 5962"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4593"
          data-name="Ellipse 4593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5371"
          data-name="Ellipse 5371"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1116"
          data-name="Ellipse 1116"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5963"
          data-name="Ellipse 5963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4575"
          data-name="Ellipse 4575"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5163"
          data-name="Ellipse 5163"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_924"
          data-name="Ellipse 924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5964"
          data-name="Ellipse 5964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4618"
          data-name="Ellipse 4618"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5411"
          data-name="Ellipse 5411"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1131"
          data-name="Ellipse 1131"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5965"
          data-name="Ellipse 5965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4576"
          data-name="Ellipse 4576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5165"
          data-name="Ellipse 5165"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_952"
          data-name="Ellipse 952"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5966"
          data-name="Ellipse 5966"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4577"
          data-name="Ellipse 4577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5331"
          data-name="Ellipse 5331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1117"
          data-name="Ellipse 1117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5967"
          data-name="Ellipse 5967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5084"
          data-name="Ellipse 5084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_550"
          data-name="Ellipse 550"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4738"
          data-name="Ellipse 4738"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5751"
          data-name="Ellipse 5751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1171 250)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1164"
          data-name="Ellipse 1164"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4739"
          data-name="Ellipse 4739"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_955"
          data-name="Ellipse 955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6136"
          data-name="Ellipse 6136"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4740"
          data-name="Ellipse 4740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1150"
          data-name="Ellipse 1150"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_915"
          data-name="Ellipse 915"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6138"
          data-name="Ellipse 6138"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4742"
          data-name="Ellipse 4742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1151"
          data-name="Ellipse 1151"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4743"
          data-name="Ellipse 4743"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_941"
          data-name="Ellipse 941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6140"
          data-name="Ellipse 6140"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10085"
          data-name="Ellipse 10085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1447 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10102"
          data-name="Ellipse 10102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1464 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10110"
          data-name="Ellipse 10110"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1464 547)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10126"
          data-name="Ellipse 10126"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1452 559)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10096"
          data-name="Ellipse 10096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1447 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10101"
          data-name="Ellipse 10101"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1464 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10109"
          data-name="Ellipse 10109"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1464 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10133"
          data-name="Ellipse 10133"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1472 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10125"
          data-name="Ellipse 10125"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1452 551)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10091"
          data-name="Ellipse 10091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1451 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10103"
          data-name="Ellipse 10103"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1468 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4744"
          data-name="Ellipse 4744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1152"
          data-name="Ellipse 1152"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_795"
          data-name="Ellipse 795"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5908"
          data-name="Ellipse 5908"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4655"
          data-name="Ellipse 4655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5582"
          data-name="Ellipse 5582"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5614"
          data-name="Ellipse 5614"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5678"
          data-name="Ellipse 5678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5710"
          data-name="Ellipse 5710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(946 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5455"
          data-name="Ellipse 5455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 300)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_982"
          data-name="Ellipse 982"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5865"
          data-name="Ellipse 5865"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4594"
          data-name="Ellipse 4594"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5572"
          data-name="Ellipse 5572"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5604"
          data-name="Ellipse 5604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5636"
          data-name="Ellipse 5636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(978 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5668"
          data-name="Ellipse 5668"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5700"
          data-name="Ellipse 5700"
          cx="1"
          cy="1"
          r="1"
          transform="translate(946 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5375"
          data-name="Ellipse 5375"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1037"
          data-name="Ellipse 1037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5832"
          data-name="Ellipse 5832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5174"
          data-name="Ellipse 5174"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1067"
          data-name="Ellipse 1067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5833"
          data-name="Ellipse 5833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5176"
          data-name="Ellipse 5176"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1083"
          data-name="Ellipse 1083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5087"
          data-name="Ellipse 5087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1098"
          data-name="Ellipse 1098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5835"
          data-name="Ellipse 5835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_930"
          data-name="Ellipse 930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5866"
          data-name="Ellipse 5866"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4619"
          data-name="Ellipse 4619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5575"
          data-name="Ellipse 5575"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5607"
          data-name="Ellipse 5607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5639"
          data-name="Ellipse 5639"
          cx="1"
          cy="1"
          r="1"
          transform="translate(978 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5671"
          data-name="Ellipse 5671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5703"
          data-name="Ellipse 5703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(946 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5415"
          data-name="Ellipse 5415"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1045"
          data-name="Ellipse 1045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5836"
          data-name="Ellipse 5836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4540"
          data-name="Ellipse 4540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5573"
          data-name="Ellipse 5573"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5605"
          data-name="Ellipse 5605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5734"
          data-name="Ellipse 5734"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5637"
          data-name="Ellipse 5637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(978 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5726"
          data-name="Ellipse 5726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(978 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5669"
          data-name="Ellipse 5669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5295"
          data-name="Ellipse 5295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4846"
          data-name="Ellipse 4846"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1106 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_983"
          data-name="Ellipse 983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5837"
          data-name="Ellipse 5837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4541"
          data-name="Ellipse 4541"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5574"
          data-name="Ellipse 5574"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1010 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5606"
          data-name="Ellipse 5606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5737"
          data-name="Ellipse 5737"
          cx="1"
          cy="1"
          r="1"
          transform="translate(994 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5638"
          data-name="Ellipse 5638"
          cx="1"
          cy="1"
          r="1"
          transform="translate(978 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5738"
          data-name="Ellipse 5738"
          cx="1"
          cy="1"
          r="1"
          transform="translate(978 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5670"
          data-name="Ellipse 5670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5739"
          data-name="Ellipse 5739"
          cx="1"
          cy="1"
          r="1"
          transform="translate(962 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5335"
          data-name="Ellipse 5335"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1034"
          data-name="Ellipse 1034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5838"
          data-name="Ellipse 5838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5178"
          data-name="Ellipse 5178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1065"
          data-name="Ellipse 1065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5839"
          data-name="Ellipse 5839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5089"
          data-name="Ellipse 5089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1082"
          data-name="Ellipse 1082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5051"
          data-name="Ellipse 5051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1026 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1096"
          data-name="Ellipse 1096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5841"
          data-name="Ellipse 5841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_375"
          data-name="Ellipse 375"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_435"
          data-name="Ellipse 435"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_439"
          data-name="Ellipse 439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_474"
          data-name="Ellipse 474"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_635"
          data-name="Ellipse 635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_544"
          data-name="Ellipse 544"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_577"
          data-name="Ellipse 577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_491"
          data-name="Ellipse 491"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1350"
          data-name="Ellipse 1350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 542)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1412"
          data-name="Ellipse 1412"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 542)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_715"
          data-name="Ellipse 715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1351"
          data-name="Ellipse 1351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 542)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_578"
          data-name="Ellipse 578"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_507"
          data-name="Ellipse 507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6056"
          data-name="Ellipse 6056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_716"
          data-name="Ellipse 716"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_556"
          data-name="Ellipse 556"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_454"
          data-name="Ellipse 454"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_644"
          data-name="Ellipse 644"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_461"
          data-name="Ellipse 461"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_617"
          data-name="Ellipse 617"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_528"
          data-name="Ellipse 528"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_719"
          data-name="Ellipse 719"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_557"
          data-name="Ellipse 557"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_481"
          data-name="Ellipse 481"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1362"
          data-name="Ellipse 1362"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1421"
          data-name="Ellipse 1421"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1474"
          data-name="Ellipse 1474"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1541"
          data-name="Ellipse 1541"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1613"
          data-name="Ellipse 1613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1498"
          data-name="Ellipse 1498"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1518"
          data-name="Ellipse 1518"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1614"
          data-name="Ellipse 1614"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2108"
          data-name="Ellipse 2108"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6733"
          data-name="Ellipse 6733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7003"
          data-name="Ellipse 7003"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6795"
          data-name="Ellipse 6795"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7093"
          data-name="Ellipse 7093"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7309"
          data-name="Ellipse 7309"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6852"
          data-name="Ellipse 6852"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3063"
          data-name="Ellipse 3063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3064"
          data-name="Ellipse 3064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3637"
          data-name="Ellipse 3637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2274"
          data-name="Ellipse 2274"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6331"
          data-name="Ellipse 6331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3033"
          data-name="Ellipse 3033"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4296"
          data-name="Ellipse 4296"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4412"
          data-name="Ellipse 4412"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2748"
          data-name="Ellipse 2748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2197"
          data-name="Ellipse 2197"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3757"
          data-name="Ellipse 3757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3777"
          data-name="Ellipse 3777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3819"
          data-name="Ellipse 3819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4433"
          data-name="Ellipse 4433"
          cx="1"
          cy="1"
          r="1"
          transform="translate(975 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4454"
          data-name="Ellipse 4454"
          cx="1"
          cy="1"
          r="1"
          transform="translate(967 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3827"
          data-name="Ellipse 3827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4435"
          data-name="Ellipse 4435"
          cx="1"
          cy="1"
          r="1"
          transform="translate(983 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3797"
          data-name="Ellipse 3797"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3821"
          data-name="Ellipse 3821"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3829"
          data-name="Ellipse 3829"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3857"
          data-name="Ellipse 3857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3860"
          data-name="Ellipse 3860"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3837"
          data-name="Ellipse 3837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3309"
          data-name="Ellipse 3309"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6332"
          data-name="Ellipse 6332"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9239"
          data-name="Ellipse 9239"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9255"
          data-name="Ellipse 9255"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3641"
          data-name="Ellipse 3641"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2365"
          data-name="Ellipse 2365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6333"
          data-name="Ellipse 6333"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6633"
          data-name="Ellipse 6633"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7004"
          data-name="Ellipse 7004"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3034"
          data-name="Ellipse 3034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2827"
          data-name="Ellipse 2827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3643"
          data-name="Ellipse 3643"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1668"
          data-name="Ellipse 1668"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2046"
          data-name="Ellipse 2046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6394"
          data-name="Ellipse 6394"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6634"
          data-name="Ellipse 6634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7005"
          data-name="Ellipse 7005"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3035"
          data-name="Ellipse 3035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2904"
          data-name="Ellipse 2904"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3645"
          data-name="Ellipse 3645"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2255"
          data-name="Ellipse 2255"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6334"
          data-name="Ellipse 6334"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3036"
          data-name="Ellipse 3036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4269"
          data-name="Ellipse 4269"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3646"
          data-name="Ellipse 3646"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2166"
          data-name="Ellipse 2166"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6762"
          data-name="Ellipse 6762"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7006"
          data-name="Ellipse 7006"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7222"
          data-name="Ellipse 7222"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6788"
          data-name="Ellipse 6788"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3119"
          data-name="Ellipse 3119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3120"
          data-name="Ellipse 3120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2290"
          data-name="Ellipse 2290"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6335"
          data-name="Ellipse 6335"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6635"
          data-name="Ellipse 6635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7007"
          data-name="Ellipse 7007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3037"
          data-name="Ellipse 3037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2783"
          data-name="Ellipse 2783"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3651"
          data-name="Ellipse 3651"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1717"
          data-name="Ellipse 1717"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1805"
          data-name="Ellipse 1805"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1857"
          data-name="Ellipse 1857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_579"
          data-name="Ellipse 579"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_508"
          data-name="Ellipse 508"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6058"
          data-name="Ellipse 6058"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4696"
          data-name="Ellipse 4696"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5770"
          data-name="Ellipse 5770"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1155 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1185"
          data-name="Ellipse 1185"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6059"
          data-name="Ellipse 6059"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4669"
          data-name="Ellipse 4669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5297"
          data-name="Ellipse 5297"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_796"
          data-name="Ellipse 796"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5932"
          data-name="Ellipse 5932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1302"
          data-name="Ellipse 1302"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_721"
          data-name="Ellipse 721"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5983"
          data-name="Ellipse 5983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4699"
          data-name="Ellipse 4699"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1186"
          data-name="Ellipse 1186"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5984"
          data-name="Ellipse 5984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4584"
          data-name="Ellipse 4584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5299"
          data-name="Ellipse 5299"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_558"
          data-name="Ellipse 558"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4767"
          data-name="Ellipse 4767"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5753"
          data-name="Ellipse 5753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1175 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1187"
          data-name="Ellipse 1187"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4768"
          data-name="Ellipse 4768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_807"
          data-name="Ellipse 807"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4700"
          data-name="Ellipse 4700"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5587"
          data-name="Ellipse 5587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5619"
          data-name="Ellipse 5619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5683"
          data-name="Ellipse 5683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5715"
          data-name="Ellipse 5715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(950 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5503"
          data-name="Ellipse 5503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 304)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_444"
          data-name="Ellipse 444"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_651"
          data-name="Ellipse 651"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_467"
          data-name="Ellipse 467"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_618"
          data-name="Ellipse 618"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_539"
          data-name="Ellipse 539"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_724"
          data-name="Ellipse 724"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_559"
          data-name="Ellipse 559"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_483"
          data-name="Ellipse 483"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1356"
          data-name="Ellipse 1356"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1413"
          data-name="Ellipse 1413"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1439"
          data-name="Ellipse 1439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_725"
          data-name="Ellipse 725"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1357"
          data-name="Ellipse 1357"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_580"
          data-name="Ellipse 580"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_509"
          data-name="Ellipse 509"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6060"
          data-name="Ellipse 6060"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4752"
          data-name="Ellipse 4752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5771"
          data-name="Ellipse 5771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1155 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_726"
          data-name="Ellipse 726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5985"
          data-name="Ellipse 5985"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4753"
          data-name="Ellipse 4753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_560"
          data-name="Ellipse 560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4769"
          data-name="Ellipse 4769"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_810"
          data-name="Ellipse 810"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4754"
          data-name="Ellipse 4754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5591"
          data-name="Ellipse 5591"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5623"
          data-name="Ellipse 5623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_449"
          data-name="Ellipse 449"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_636"
          data-name="Ellipse 636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_495"
          data-name="Ellipse 495"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1344"
          data-name="Ellipse 1344"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 546)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1414"
          data-name="Ellipse 1414"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 546)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_728"
          data-name="Ellipse 728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1345"
          data-name="Ellipse 1345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 546)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_619"
          data-name="Ellipse 619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_512"
          data-name="Ellipse 512"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6061"
          data-name="Ellipse 6061"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_729"
          data-name="Ellipse 729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_561"
          data-name="Ellipse 561"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_459"
          data-name="Ellipse 459"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_620"
          data-name="Ellipse 620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_519"
          data-name="Ellipse 519"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6062"
          data-name="Ellipse 6062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 543)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_731"
          data-name="Ellipse 731"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_562"
          data-name="Ellipse 562"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_479"
          data-name="Ellipse 479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1367"
          data-name="Ellipse 1367"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1419"
          data-name="Ellipse 1419"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1464"
          data-name="Ellipse 1464"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1529"
          data-name="Ellipse 1529"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1615"
          data-name="Ellipse 1615"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1683"
          data-name="Ellipse 1683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2067"
          data-name="Ellipse 2067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6434"
          data-name="Ellipse 6434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6652"
          data-name="Ellipse 6652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7008"
          data-name="Ellipse 7008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6836"
          data-name="Ellipse 6836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7024"
          data-name="Ellipse 7024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7240"
          data-name="Ellipse 7240"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3038"
          data-name="Ellipse 3038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2964"
          data-name="Ellipse 2964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3653"
          data-name="Ellipse 3653"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2256"
          data-name="Ellipse 2256"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6336"
          data-name="Ellipse 6336"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3039"
          data-name="Ellipse 3039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4270"
          data-name="Ellipse 4270"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2167"
          data-name="Ellipse 2167"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3191"
          data-name="Ellipse 3191"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3192"
          data-name="Ellipse 3192"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2313"
          data-name="Ellipse 2313"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6337"
          data-name="Ellipse 6337"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6636"
          data-name="Ellipse 6636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7009"
          data-name="Ellipse 7009"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3040"
          data-name="Ellipse 3040"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2828"
          data-name="Ellipse 2828"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3659"
          data-name="Ellipse 3659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1731"
          data-name="Ellipse 1731"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1827"
          data-name="Ellipse 1827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1760"
          data-name="Ellipse 1760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1488"
          data-name="Ellipse 1488"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1513"
          data-name="Ellipse 1513"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1616"
          data-name="Ellipse 1616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2087"
          data-name="Ellipse 2087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6465"
          data-name="Ellipse 6465"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6686"
          data-name="Ellipse 6686"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7010"
          data-name="Ellipse 7010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6814"
          data-name="Ellipse 6814"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7056"
          data-name="Ellipse 7056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7272"
          data-name="Ellipse 7272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3041"
          data-name="Ellipse 3041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2965"
          data-name="Ellipse 2965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3661"
          data-name="Ellipse 3661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2257"
          data-name="Ellipse 2257"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6338"
          data-name="Ellipse 6338"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3042"
          data-name="Ellipse 3042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4271"
          data-name="Ellipse 4271"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2180"
          data-name="Ellipse 2180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3758"
          data-name="Ellipse 3758"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3778"
          data-name="Ellipse 3778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3798"
          data-name="Ellipse 3798"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3820"
          data-name="Ellipse 3820"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4438"
          data-name="Ellipse 4438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(975 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4457"
          data-name="Ellipse 4457"
          cx="1"
          cy="1"
          r="1"
          transform="translate(967 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4451"
          data-name="Ellipse 4451"
          cx="1"
          cy="1"
          r="1"
          transform="translate(975 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4455"
          data-name="Ellipse 4455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(967 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3828"
          data-name="Ellipse 3828"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4439"
          data-name="Ellipse 4439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(983 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3836"
          data-name="Ellipse 3836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4442"
          data-name="Ellipse 4442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(991 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3818"
          data-name="Ellipse 3818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3826"
          data-name="Ellipse 3826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3834"
          data-name="Ellipse 3834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3846"
          data-name="Ellipse 3846"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3862"
          data-name="Ellipse 3862"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3842"
          data-name="Ellipse 3842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 430)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3864"
          data-name="Ellipse 3864"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3866"
          data-name="Ellipse 3866"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3256"
          data-name="Ellipse 3256"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6339"
          data-name="Ellipse 6339"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9232"
          data-name="Ellipse 9232"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9248"
          data-name="Ellipse 9248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3257"
          data-name="Ellipse 3257"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2340"
          data-name="Ellipse 2340"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6340"
          data-name="Ellipse 6340"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6637"
          data-name="Ellipse 6637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7011"
          data-name="Ellipse 7011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3043"
          data-name="Ellipse 3043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(932 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2829"
          data-name="Ellipse 2829"
          cx="1"
          cy="1"
          r="1"
          transform="translate(852 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3667"
          data-name="Ellipse 3667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(804 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1669"
          data-name="Ellipse 1669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1718"
          data-name="Ellipse 1718"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1854"
          data-name="Ellipse 1854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_581"
          data-name="Ellipse 581"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_510"
          data-name="Ellipse 510"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6063"
          data-name="Ellipse 6063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4670"
          data-name="Ellipse 4670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5772"
          data-name="Ellipse 5772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1155 250)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1165"
          data-name="Ellipse 1165"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6064"
          data-name="Ellipse 6064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4671"
          data-name="Ellipse 4671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5180"
          data-name="Ellipse 5180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_956"
          data-name="Ellipse 956"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6065"
          data-name="Ellipse 6065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4672"
          data-name="Ellipse 4672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1138"
          data-name="Ellipse 1138"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6066"
          data-name="Ellipse 6066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4673"
          data-name="Ellipse 4673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5182"
          data-name="Ellipse 5182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_919"
          data-name="Ellipse 919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6067"
          data-name="Ellipse 6067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4674"
          data-name="Ellipse 4674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1139"
          data-name="Ellipse 1139"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6068"
          data-name="Ellipse 6068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4675"
          data-name="Ellipse 4675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5184"
          data-name="Ellipse 5184"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_947"
          data-name="Ellipse 947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6069"
          data-name="Ellipse 6069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4676"
          data-name="Ellipse 4676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1140"
          data-name="Ellipse 1140"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6070"
          data-name="Ellipse 6070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5091"
          data-name="Ellipse 5091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_786"
          data-name="Ellipse 786"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5909"
          data-name="Ellipse 5909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1301"
          data-name="Ellipse 1301"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_990"
          data-name="Ellipse 990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5867"
          data-name="Ellipse 5867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1038"
          data-name="Ellipse 1038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5801"
          data-name="Ellipse 5801"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1068"
          data-name="Ellipse 1068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_992"
          data-name="Ellipse 992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5868"
          data-name="Ellipse 5868"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1297"
          data-name="Ellipse 1297"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1300"
          data-name="Ellipse 1300"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1085 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1039"
          data-name="Ellipse 1039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5803"
          data-name="Ellipse 5803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1069"
          data-name="Ellipse 1069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1296"
          data-name="Ellipse 1296"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1054"
          data-name="Ellipse 1054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1056"
          data-name="Ellipse 1056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1085 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_934"
          data-name="Ellipse 934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5871"
          data-name="Ellipse 5871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1046"
          data-name="Ellipse 1046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5842"
          data-name="Ellipse 5842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_994"
          data-name="Ellipse 994"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1298"
          data-name="Ellipse 1298"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1047"
          data-name="Ellipse 1047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5843"
          data-name="Ellipse 5843"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1055"
          data-name="Ellipse 1055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_991"
          data-name="Ellipse 991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5845"
          data-name="Ellipse 5845"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1029"
          data-name="Ellipse 1029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5808"
          data-name="Ellipse 5808"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1058"
          data-name="Ellipse 1058"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1295"
          data-name="Ellipse 1295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_993"
          data-name="Ellipse 993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5846"
          data-name="Ellipse 5846"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1299"
          data-name="Ellipse 1299"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1030"
          data-name="Ellipse 1030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1093 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5809"
          data-name="Ellipse 5809"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1053"
          data-name="Ellipse 1053"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1089 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1057"
          data-name="Ellipse 1057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1085 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_733"
          data-name="Ellipse 733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5986"
          data-name="Ellipse 5986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4658"
          data-name="Ellipse 4658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1166"
          data-name="Ellipse 1166"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5987"
          data-name="Ellipse 5987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4585"
          data-name="Ellipse 4585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5186"
          data-name="Ellipse 5186"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_957"
          data-name="Ellipse 957"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5988"
          data-name="Ellipse 5988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4599"
          data-name="Ellipse 4599"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5379"
          data-name="Ellipse 5379"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1122"
          data-name="Ellipse 1122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5989"
          data-name="Ellipse 5989"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4586"
          data-name="Ellipse 4586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5188"
          data-name="Ellipse 5188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_923"
          data-name="Ellipse 923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5990"
          data-name="Ellipse 5990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4623"
          data-name="Ellipse 4623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1132"
          data-name="Ellipse 1132"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5991"
          data-name="Ellipse 5991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4587"
          data-name="Ellipse 4587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5190"
          data-name="Ellipse 5190"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_951"
          data-name="Ellipse 951"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5992"
          data-name="Ellipse 5992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4588"
          data-name="Ellipse 4588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5339"
          data-name="Ellipse 5339"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1123"
          data-name="Ellipse 1123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5993"
          data-name="Ellipse 5993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5093"
          data-name="Ellipse 5093"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_563"
          data-name="Ellipse 563"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4770"
          data-name="Ellipse 4770"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5750"
          data-name="Ellipse 5750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1175 250)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1167"
          data-name="Ellipse 1167"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4771"
          data-name="Ellipse 4771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_958"
          data-name="Ellipse 958"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4772"
          data-name="Ellipse 4772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1153"
          data-name="Ellipse 1153"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_911"
          data-name="Ellipse 911"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4774"
          data-name="Ellipse 4774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1154"
          data-name="Ellipse 1154"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4775"
          data-name="Ellipse 4775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_942"
          data-name="Ellipse 942"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4776"
          data-name="Ellipse 4776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1155"
          data-name="Ellipse 1155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_811"
          data-name="Ellipse 811"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5911"
          data-name="Ellipse 5911"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4659"
          data-name="Ellipse 4659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5583"
          data-name="Ellipse 5583"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5615"
          data-name="Ellipse 5615"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5679"
          data-name="Ellipse 5679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5711"
          data-name="Ellipse 5711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(950 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5463"
          data-name="Ellipse 5463"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 300)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_980"
          data-name="Ellipse 980"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5874"
          data-name="Ellipse 5874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4600"
          data-name="Ellipse 4600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5578"
          data-name="Ellipse 5578"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5610"
          data-name="Ellipse 5610"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5642"
          data-name="Ellipse 5642"
          cx="1"
          cy="1"
          r="1"
          transform="translate(982 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5674"
          data-name="Ellipse 5674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5706"
          data-name="Ellipse 5706"
          cx="1"
          cy="1"
          r="1"
          transform="translate(950 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5383"
          data-name="Ellipse 5383"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1040"
          data-name="Ellipse 1040"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5848"
          data-name="Ellipse 5848"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4492"
          data-name="Ellipse 4492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5199"
          data-name="Ellipse 5199"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1072"
          data-name="Ellipse 1072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5849"
          data-name="Ellipse 5849"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5201"
          data-name="Ellipse 5201"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1089"
          data-name="Ellipse 1089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5850"
          data-name="Ellipse 5850"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5096"
          data-name="Ellipse 5096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1099"
          data-name="Ellipse 1099"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1111"
          data-name="Ellipse 1111"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5852"
          data-name="Ellipse 5852"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_929"
          data-name="Ellipse 929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5875"
          data-name="Ellipse 5875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4624"
          data-name="Ellipse 4624"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5579"
          data-name="Ellipse 5579"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5611"
          data-name="Ellipse 5611"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5675"
          data-name="Ellipse 5675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5707"
          data-name="Ellipse 5707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(950 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5423"
          data-name="Ellipse 5423"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1048"
          data-name="Ellipse 1048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5853"
          data-name="Ellipse 5853"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4548"
          data-name="Ellipse 4548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5580"
          data-name="Ellipse 5580"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5612"
          data-name="Ellipse 5612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5735"
          data-name="Ellipse 5735"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5644"
          data-name="Ellipse 5644"
          cx="1"
          cy="1"
          r="1"
          transform="translate(982 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5728"
          data-name="Ellipse 5728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(982 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5676"
          data-name="Ellipse 5676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5729"
          data-name="Ellipse 5729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5303"
          data-name="Ellipse 5303"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_981"
          data-name="Ellipse 981"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5854"
          data-name="Ellipse 5854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4549"
          data-name="Ellipse 4549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5581"
          data-name="Ellipse 5581"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5613"
          data-name="Ellipse 5613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5740"
          data-name="Ellipse 5740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(998 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5645"
          data-name="Ellipse 5645"
          cx="1"
          cy="1"
          r="1"
          transform="translate(982 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5741"
          data-name="Ellipse 5741"
          cx="1"
          cy="1"
          r="1"
          transform="translate(982 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5677"
          data-name="Ellipse 5677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5742"
          data-name="Ellipse 5742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(966 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5343"
          data-name="Ellipse 5343"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1035"
          data-name="Ellipse 1035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5855"
          data-name="Ellipse 5855"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4497"
          data-name="Ellipse 4497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5203"
          data-name="Ellipse 5203"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1066"
          data-name="Ellipse 1066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5856"
          data-name="Ellipse 5856"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5098"
          data-name="Ellipse 5098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1090"
          data-name="Ellipse 1090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5857"
          data-name="Ellipse 5857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5053"
          data-name="Ellipse 5053"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1030 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5595"
          data-name="Ellipse 5595"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1014 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1097"
          data-name="Ellipse 1097"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5858"
          data-name="Ellipse 5858"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_381"
          data-name="Ellipse 381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_436"
          data-name="Ellipse 436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_440"
          data-name="Ellipse 440"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_475"
          data-name="Ellipse 475"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_740"
          data-name="Ellipse 740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1326"
          data-name="Ellipse 1326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 562)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1380"
          data-name="Ellipse 1380"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 570)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1388"
          data-name="Ellipse 1388"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 578)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1378"
          data-name="Ellipse 1378"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 566)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1386"
          data-name="Ellipse 1386"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 574)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_637"
          data-name="Ellipse 637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 508)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_664"
          data-name="Ellipse 664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1203 508)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_545"
          data-name="Ellipse 545"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_741"
          data-name="Ellipse 741"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_582"
          data-name="Ellipse 582"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 472)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_492"
          data-name="Ellipse 492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_742"
          data-name="Ellipse 742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1353"
          data-name="Ellipse 1353"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 542)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_583"
          data-name="Ellipse 583"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_513"
          data-name="Ellipse 513"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6074"
          data-name="Ellipse 6074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_743"
          data-name="Ellipse 743"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_564"
          data-name="Ellipse 564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_892"
          data-name="Ellipse 892"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_455"
          data-name="Ellipse 455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_462"
          data-name="Ellipse 462"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_745"
          data-name="Ellipse 745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1336"
          data-name="Ellipse 1336"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 554)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_621"
          data-name="Ellipse 621"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 500)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_658"
          data-name="Ellipse 658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1203 500)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1212"
          data-name="Ellipse 1212"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1199 500)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_529"
          data-name="Ellipse 529"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_746"
          data-name="Ellipse 746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_565"
          data-name="Ellipse 565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 464)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_484"
          data-name="Ellipse 484"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1363"
          data-name="Ellipse 1363"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1424"
          data-name="Ellipse 1424"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1475"
          data-name="Ellipse 1475"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1542"
          data-name="Ellipse 1542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1609"
          data-name="Ellipse 1609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1499"
          data-name="Ellipse 1499"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1519"
          data-name="Ellipse 1519"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1610"
          data-name="Ellipse 1610"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2109"
          data-name="Ellipse 2109"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6511"
          data-name="Ellipse 6511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6734"
          data-name="Ellipse 6734"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7696"
          data-name="Ellipse 7696"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8086"
          data-name="Ellipse 8086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8476"
          data-name="Ellipse 8476"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6985"
          data-name="Ellipse 6985"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7697"
          data-name="Ellipse 7697"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8087"
          data-name="Ellipse 8087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8477"
          data-name="Ellipse 8477"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8867"
          data-name="Ellipse 8867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7201"
          data-name="Ellipse 7201"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7698"
          data-name="Ellipse 7698"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8088"
          data-name="Ellipse 8088"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8478"
          data-name="Ellipse 8478"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6796"
          data-name="Ellipse 6796"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7699"
          data-name="Ellipse 7699"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9404"
          data-name="Ellipse 9404"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9524"
          data-name="Ellipse 9524"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9644"
          data-name="Ellipse 9644"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8089"
          data-name="Ellipse 8089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9405"
          data-name="Ellipse 9405"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9525"
          data-name="Ellipse 9525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8479"
          data-name="Ellipse 8479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9425"
          data-name="Ellipse 9425"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9905"
          data-name="Ellipse 9905"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7094"
          data-name="Ellipse 7094"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7700"
          data-name="Ellipse 7700"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8090"
          data-name="Ellipse 8090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8480"
          data-name="Ellipse 8480"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8870"
          data-name="Ellipse 8870"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7310"
          data-name="Ellipse 7310"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7701"
          data-name="Ellipse 7701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8091"
          data-name="Ellipse 8091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8481"
          data-name="Ellipse 8481"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8871"
          data-name="Ellipse 8871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8986"
          data-name="Ellipse 8986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6858"
          data-name="Ellipse 6858"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7702"
          data-name="Ellipse 7702"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9438"
          data-name="Ellipse 9438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9558"
          data-name="Ellipse 9558"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9678"
          data-name="Ellipse 9678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8092"
          data-name="Ellipse 8092"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9439"
          data-name="Ellipse 9439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9799"
          data-name="Ellipse 9799"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8482"
          data-name="Ellipse 8482"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9440"
          data-name="Ellipse 9440"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9800"
          data-name="Ellipse 9800"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9920"
          data-name="Ellipse 9920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3065"
          data-name="Ellipse 3065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3066"
          data-name="Ellipse 3066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3675"
          data-name="Ellipse 3675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2275"
          data-name="Ellipse 2275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6341"
          data-name="Ellipse 6341"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3044"
          data-name="Ellipse 3044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4297"
          data-name="Ellipse 4297"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3677"
          data-name="Ellipse 3677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2198"
          data-name="Ellipse 2198"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3311"
          data-name="Ellipse 3311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6342"
          data-name="Ellipse 6342"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9240"
          data-name="Ellipse 9240"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9256"
          data-name="Ellipse 9256"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9272"
          data-name="Ellipse 9272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3312"
          data-name="Ellipse 3312"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3679"
          data-name="Ellipse 3679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2366"
          data-name="Ellipse 2366"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6343"
          data-name="Ellipse 6343"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6638"
          data-name="Ellipse 6638"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7703"
          data-name="Ellipse 7703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8093"
          data-name="Ellipse 8093"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8483"
          data-name="Ellipse 8483"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8873"
          data-name="Ellipse 8873"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6986"
          data-name="Ellipse 6986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7704"
          data-name="Ellipse 7704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8094"
          data-name="Ellipse 8094"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8484"
          data-name="Ellipse 8484"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8874"
          data-name="Ellipse 8874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8987"
          data-name="Ellipse 8987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8095"
          data-name="Ellipse 8095"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8485"
          data-name="Ellipse 8485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3045"
          data-name="Ellipse 3045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2830"
          data-name="Ellipse 2830"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3681"
          data-name="Ellipse 3681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1666"
          data-name="Ellipse 1666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2048"
          data-name="Ellipse 2048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6395"
          data-name="Ellipse 6395"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6639"
          data-name="Ellipse 6639"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7706"
          data-name="Ellipse 7706"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8096"
          data-name="Ellipse 8096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8486"
          data-name="Ellipse 8486"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8876"
          data-name="Ellipse 8876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6987"
          data-name="Ellipse 6987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7707"
          data-name="Ellipse 7707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8097"
          data-name="Ellipse 8097"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8487"
          data-name="Ellipse 8487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8877"
          data-name="Ellipse 8877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8988"
          data-name="Ellipse 8988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8098"
          data-name="Ellipse 8098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8488"
          data-name="Ellipse 8488"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3046"
          data-name="Ellipse 3046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2905"
          data-name="Ellipse 2905"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3683"
          data-name="Ellipse 3683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2264"
          data-name="Ellipse 2264"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6344"
          data-name="Ellipse 6344"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3047"
          data-name="Ellipse 3047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4272"
          data-name="Ellipse 4272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2491"
          data-name="Ellipse 2491"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3684"
          data-name="Ellipse 3684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2181"
          data-name="Ellipse 2181"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6546"
          data-name="Ellipse 6546"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6763"
          data-name="Ellipse 6763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7709"
          data-name="Ellipse 7709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8099"
          data-name="Ellipse 8099"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8489"
          data-name="Ellipse 8489"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8879"
          data-name="Ellipse 8879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6995"
          data-name="Ellipse 6995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7710"
          data-name="Ellipse 7710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8100"
          data-name="Ellipse 8100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8490"
          data-name="Ellipse 8490"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8880"
          data-name="Ellipse 8880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7211"
          data-name="Ellipse 7211"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7711"
          data-name="Ellipse 7711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8101"
          data-name="Ellipse 8101"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8491"
          data-name="Ellipse 8491"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8881"
          data-name="Ellipse 8881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6780"
          data-name="Ellipse 6780"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7712"
          data-name="Ellipse 7712"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9441"
          data-name="Ellipse 9441"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9561"
          data-name="Ellipse 9561"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9681"
          data-name="Ellipse 9681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8102"
          data-name="Ellipse 8102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9442"
          data-name="Ellipse 9442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8492"
          data-name="Ellipse 8492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9443"
          data-name="Ellipse 9443"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9803"
          data-name="Ellipse 9803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9923"
          data-name="Ellipse 9923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6869"
          data-name="Ellipse 6869"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7713"
          data-name="Ellipse 7713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9493"
          data-name="Ellipse 9493"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9613"
          data-name="Ellipse 9613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8103"
          data-name="Ellipse 8103"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9494"
          data-name="Ellipse 9494"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9854"
          data-name="Ellipse 9854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8493"
          data-name="Ellipse 8493"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9855"
          data-name="Ellipse 9855"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9975"
          data-name="Ellipse 9975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3121"
          data-name="Ellipse 3121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3122"
          data-name="Ellipse 3122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3687"
          data-name="Ellipse 3687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2291"
          data-name="Ellipse 2291"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6345"
          data-name="Ellipse 6345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6640"
          data-name="Ellipse 6640"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7714"
          data-name="Ellipse 7714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8104"
          data-name="Ellipse 8104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8494"
          data-name="Ellipse 8494"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8884"
          data-name="Ellipse 8884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6988"
          data-name="Ellipse 6988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7715"
          data-name="Ellipse 7715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8105"
          data-name="Ellipse 8105"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8495"
          data-name="Ellipse 8495"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8885"
          data-name="Ellipse 8885"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8990"
          data-name="Ellipse 8990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8106"
          data-name="Ellipse 8106"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9220"
          data-name="Ellipse 9220"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 247)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3048"
          data-name="Ellipse 3048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2784"
          data-name="Ellipse 2784"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3689"
          data-name="Ellipse 3689"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1715"
          data-name="Ellipse 1715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1748"
          data-name="Ellipse 1748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1861"
          data-name="Ellipse 1861"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1371"
          data-name="Ellipse 1371"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1426"
          data-name="Ellipse 1426"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1476"
          data-name="Ellipse 1476"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1543"
          data-name="Ellipse 1543"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1603"
          data-name="Ellipse 1603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1568"
          data-name="Ellipse 1568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1604"
          data-name="Ellipse 1604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1500"
          data-name="Ellipse 1500"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1520"
          data-name="Ellipse 1520"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1598"
          data-name="Ellipse 1598"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2110"
          data-name="Ellipse 2110"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6512"
          data-name="Ellipse 6512"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6735"
          data-name="Ellipse 6735"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7633"
          data-name="Ellipse 7633"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8023"
          data-name="Ellipse 8023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8413"
          data-name="Ellipse 8413"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8803"
          data-name="Ellipse 8803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6978"
          data-name="Ellipse 6978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7634"
          data-name="Ellipse 7634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8024"
          data-name="Ellipse 8024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8414"
          data-name="Ellipse 8414"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8804"
          data-name="Ellipse 8804"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7194"
          data-name="Ellipse 7194"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7635"
          data-name="Ellipse 7635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8025"
          data-name="Ellipse 8025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8415"
          data-name="Ellipse 8415"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8805"
          data-name="Ellipse 8805"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6797"
          data-name="Ellipse 6797"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7636"
          data-name="Ellipse 7636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9406"
          data-name="Ellipse 9406"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9526"
          data-name="Ellipse 9526"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9646"
          data-name="Ellipse 9646"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8026"
          data-name="Ellipse 8026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9407"
          data-name="Ellipse 9407"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9527"
          data-name="Ellipse 9527"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9887"
          data-name="Ellipse 9887"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8416"
          data-name="Ellipse 8416"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9427"
          data-name="Ellipse 9427"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9787"
          data-name="Ellipse 9787"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9907"
          data-name="Ellipse 9907"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7095"
          data-name="Ellipse 7095"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7637"
          data-name="Ellipse 7637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8027"
          data-name="Ellipse 8027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8417"
          data-name="Ellipse 8417"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8807"
          data-name="Ellipse 8807"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7311"
          data-name="Ellipse 7311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7638"
          data-name="Ellipse 7638"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8028"
          data-name="Ellipse 8028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8418"
          data-name="Ellipse 8418"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8808"
          data-name="Ellipse 8808"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8991"
          data-name="Ellipse 8991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6859"
          data-name="Ellipse 6859"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7673"
          data-name="Ellipse 7673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9444"
          data-name="Ellipse 9444"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9564"
          data-name="Ellipse 9564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9684"
          data-name="Ellipse 9684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8063"
          data-name="Ellipse 8063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9445"
          data-name="Ellipse 9445"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9565"
          data-name="Ellipse 9565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9805"
          data-name="Ellipse 9805"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8453"
          data-name="Ellipse 8453"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9446"
          data-name="Ellipse 9446"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9806"
          data-name="Ellipse 9806"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9926"
          data-name="Ellipse 9926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3067"
          data-name="Ellipse 3067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3068"
          data-name="Ellipse 3068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3707"
          data-name="Ellipse 3707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2276"
          data-name="Ellipse 2276"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6346"
          data-name="Ellipse 6346"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3709"
          data-name="Ellipse 3709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2199"
          data-name="Ellipse 2199"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3313"
          data-name="Ellipse 3313"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6347"
          data-name="Ellipse 6347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9241"
          data-name="Ellipse 9241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9257"
          data-name="Ellipse 9257"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9273"
          data-name="Ellipse 9273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3314"
          data-name="Ellipse 3314"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3711"
          data-name="Ellipse 3711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2367"
          data-name="Ellipse 2367"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6348"
          data-name="Ellipse 6348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6643"
          data-name="Ellipse 6643"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7639"
          data-name="Ellipse 7639"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8029"
          data-name="Ellipse 8029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8419"
          data-name="Ellipse 8419"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8809"
          data-name="Ellipse 8809"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6969"
          data-name="Ellipse 6969"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7640"
          data-name="Ellipse 7640"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8030"
          data-name="Ellipse 8030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8420"
          data-name="Ellipse 8420"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8810"
          data-name="Ellipse 8810"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8992"
          data-name="Ellipse 8992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8031"
          data-name="Ellipse 8031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8811"
          data-name="Ellipse 8811"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3070"
          data-name="Ellipse 3070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2831"
          data-name="Ellipse 2831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3713"
          data-name="Ellipse 3713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1664"
          data-name="Ellipse 1664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2049"
          data-name="Ellipse 2049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6396"
          data-name="Ellipse 6396"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6644"
          data-name="Ellipse 6644"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7642"
          data-name="Ellipse 7642"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8032"
          data-name="Ellipse 8032"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8422"
          data-name="Ellipse 8422"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8812"
          data-name="Ellipse 8812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6970"
          data-name="Ellipse 6970"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7643"
          data-name="Ellipse 7643"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8033"
          data-name="Ellipse 8033"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8423"
          data-name="Ellipse 8423"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8813"
          data-name="Ellipse 8813"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8993"
          data-name="Ellipse 8993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8034"
          data-name="Ellipse 8034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8814"
          data-name="Ellipse 8814"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3071"
          data-name="Ellipse 3071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2906"
          data-name="Ellipse 2906"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3715"
          data-name="Ellipse 3715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2277"
          data-name="Ellipse 2277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6349"
          data-name="Ellipse 6349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3072"
          data-name="Ellipse 3072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2507"
          data-name="Ellipse 2507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2182"
          data-name="Ellipse 2182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6547"
          data-name="Ellipse 6547"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6764"
          data-name="Ellipse 6764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7645"
          data-name="Ellipse 7645"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8035"
          data-name="Ellipse 8035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8425"
          data-name="Ellipse 8425"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8815"
          data-name="Ellipse 8815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6996"
          data-name="Ellipse 6996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7646"
          data-name="Ellipse 7646"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8036"
          data-name="Ellipse 8036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8426"
          data-name="Ellipse 8426"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8816"
          data-name="Ellipse 8816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7212"
          data-name="Ellipse 7212"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7647"
          data-name="Ellipse 7647"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8037"
          data-name="Ellipse 8037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8427"
          data-name="Ellipse 8427"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8817"
          data-name="Ellipse 8817"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6781"
          data-name="Ellipse 6781"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7674"
          data-name="Ellipse 7674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9447"
          data-name="Ellipse 9447"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9567"
          data-name="Ellipse 9567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9687"
          data-name="Ellipse 9687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8064"
          data-name="Ellipse 8064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9448"
          data-name="Ellipse 9448"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9568"
          data-name="Ellipse 9568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9808"
          data-name="Ellipse 9808"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8454"
          data-name="Ellipse 8454"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9449"
          data-name="Ellipse 9449"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9809"
          data-name="Ellipse 9809"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9929"
          data-name="Ellipse 9929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6870"
          data-name="Ellipse 6870"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7689"
          data-name="Ellipse 7689"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9496"
          data-name="Ellipse 9496"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9616"
          data-name="Ellipse 9616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9736"
          data-name="Ellipse 9736"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8079"
          data-name="Ellipse 8079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9497"
          data-name="Ellipse 9497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9857"
          data-name="Ellipse 9857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8469"
          data-name="Ellipse 8469"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9858"
          data-name="Ellipse 9858"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9978"
          data-name="Ellipse 9978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3123"
          data-name="Ellipse 3123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3124"
          data-name="Ellipse 3124"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3719"
          data-name="Ellipse 3719"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2292"
          data-name="Ellipse 2292"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6350"
          data-name="Ellipse 6350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6645"
          data-name="Ellipse 6645"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7648"
          data-name="Ellipse 7648"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8038"
          data-name="Ellipse 8038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8428"
          data-name="Ellipse 8428"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8818"
          data-name="Ellipse 8818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6971"
          data-name="Ellipse 6971"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7649"
          data-name="Ellipse 7649"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8039"
          data-name="Ellipse 8039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8429"
          data-name="Ellipse 8429"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8819"
          data-name="Ellipse 8819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8995"
          data-name="Ellipse 8995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8040"
          data-name="Ellipse 8040"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9223"
          data-name="Ellipse 9223"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 247)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3073"
          data-name="Ellipse 3073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2785"
          data-name="Ellipse 2785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3721"
          data-name="Ellipse 3721"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1713"
          data-name="Ellipse 1713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1749"
          data-name="Ellipse 1749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1867"
          data-name="Ellipse 1867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2069"
          data-name="Ellipse 2069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6435"
          data-name="Ellipse 6435"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2966"
          data-name="Ellipse 2966"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2967"
          data-name="Ellipse 2967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2243"
          data-name="Ellipse 2243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2832"
          data-name="Ellipse 2832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4252"
          data-name="Ellipse 4252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2168"
          data-name="Ellipse 2168"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3193"
          data-name="Ellipse 3193"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3194"
          data-name="Ellipse 3194"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2314"
          data-name="Ellipse 2314"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6577"
          data-name="Ellipse 6577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2833"
          data-name="Ellipse 2833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2834"
          data-name="Ellipse 2834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1810"
          data-name="Ellipse 1810"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1569"
          data-name="Ellipse 1569"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1581"
          data-name="Ellipse 1581"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2111"
          data-name="Ellipse 2111"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6513"
          data-name="Ellipse 6513"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6736"
          data-name="Ellipse 6736"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7522"
          data-name="Ellipse 7522"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7912"
          data-name="Ellipse 7912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8302"
          data-name="Ellipse 8302"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6979"
          data-name="Ellipse 6979"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7412"
          data-name="Ellipse 7412"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7802"
          data-name="Ellipse 7802"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8192"
          data-name="Ellipse 8192"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7413"
          data-name="Ellipse 7413"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7803"
          data-name="Ellipse 7803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8193"
          data-name="Ellipse 8193"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6798"
          data-name="Ellipse 6798"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9408"
          data-name="Ellipse 9408"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7627"
          data-name="Ellipse 7627"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9409"
          data-name="Ellipse 9409"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9529"
          data-name="Ellipse 9529"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8017"
          data-name="Ellipse 8017"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9423"
          data-name="Ellipse 9423"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9543"
          data-name="Ellipse 9543"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9663"
          data-name="Ellipse 9663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9903"
          data-name="Ellipse 9903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7096"
          data-name="Ellipse 7096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7414"
          data-name="Ellipse 7414"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7804"
          data-name="Ellipse 7804"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8194"
          data-name="Ellipse 8194"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7312"
          data-name="Ellipse 7312"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7415"
          data-name="Ellipse 7415"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7805"
          data-name="Ellipse 7805"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8195"
          data-name="Ellipse 8195"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9047"
          data-name="Ellipse 9047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8585"
          data-name="Ellipse 8585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8924"
          data-name="Ellipse 8924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1477 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6864"
          data-name="Ellipse 6864"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7675"
          data-name="Ellipse 7675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9451"
          data-name="Ellipse 9451"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8065"
          data-name="Ellipse 8065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9452"
          data-name="Ellipse 9452"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9572"
          data-name="Ellipse 9572"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9692"
          data-name="Ellipse 9692"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9932"
          data-name="Ellipse 9932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3958"
          data-name="Ellipse 3958"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3074"
          data-name="Ellipse 3074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2330"
          data-name="Ellipse 2330"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6449"
          data-name="Ellipse 6449"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3959"
          data-name="Ellipse 3959"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4145"
          data-name="Ellipse 4145"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3219"
          data-name="Ellipse 3219"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4322"
          data-name="Ellipse 4322"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3960"
          data-name="Ellipse 3960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2651"
          data-name="Ellipse 2651"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2786"
          data-name="Ellipse 2786"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4298"
          data-name="Ellipse 4298"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2218"
          data-name="Ellipse 2218"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3315"
          data-name="Ellipse 3315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6450"
          data-name="Ellipse 6450"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3962"
          data-name="Ellipse 3962"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4148"
          data-name="Ellipse 4148"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3316"
          data-name="Ellipse 3316"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2368"
          data-name="Ellipse 2368"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6451"
          data-name="Ellipse 6451"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6706"
          data-name="Ellipse 6706"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7481"
          data-name="Ellipse 7481"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7871"
          data-name="Ellipse 7871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8261"
          data-name="Ellipse 8261"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6923"
          data-name="Ellipse 6923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7416"
          data-name="Ellipse 7416"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7806"
          data-name="Ellipse 7806"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8196"
          data-name="Ellipse 8196"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9048"
          data-name="Ellipse 9048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9147"
          data-name="Ellipse 9147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8586"
          data-name="Ellipse 8586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7139"
          data-name="Ellipse 7139"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7807"
          data-name="Ellipse 7807"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3963"
          data-name="Ellipse 3963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3220"
          data-name="Ellipse 3220"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3964"
          data-name="Ellipse 3964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2653"
          data-name="Ellipse 2653"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2835"
          data-name="Ellipse 2835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1652"
          data-name="Ellipse 1652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2100"
          data-name="Ellipse 2100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6452"
          data-name="Ellipse 6452"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6707"
          data-name="Ellipse 6707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7514"
          data-name="Ellipse 7514"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7904"
          data-name="Ellipse 7904"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8294"
          data-name="Ellipse 8294"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6930"
          data-name="Ellipse 6930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7418"
          data-name="Ellipse 7418"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7808"
          data-name="Ellipse 7808"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8198"
          data-name="Ellipse 8198"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9049"
          data-name="Ellipse 9049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9128"
          data-name="Ellipse 9128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8588"
          data-name="Ellipse 8588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7146"
          data-name="Ellipse 7146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7809"
          data-name="Ellipse 7809"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3221"
          data-name="Ellipse 3221"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3966"
          data-name="Ellipse 3966"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2654"
          data-name="Ellipse 2654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2907"
          data-name="Ellipse 2907"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2331"
          data-name="Ellipse 2331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6453"
          data-name="Ellipse 6453"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3967"
          data-name="Ellipse 3967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4153"
          data-name="Ellipse 4153"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3222"
          data-name="Ellipse 3222"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2655"
          data-name="Ellipse 2655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2787"
          data-name="Ellipse 2787"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2219"
          data-name="Ellipse 2219"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6548"
          data-name="Ellipse 6548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6765"
          data-name="Ellipse 6765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7570"
          data-name="Ellipse 7570"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7960"
          data-name="Ellipse 7960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8350"
          data-name="Ellipse 8350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6997"
          data-name="Ellipse 6997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7420"
          data-name="Ellipse 7420"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7810"
          data-name="Ellipse 7810"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8200"
          data-name="Ellipse 8200"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7213"
          data-name="Ellipse 7213"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7421"
          data-name="Ellipse 7421"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7811"
          data-name="Ellipse 7811"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8201"
          data-name="Ellipse 8201"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8591"
          data-name="Ellipse 8591"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8926"
          data-name="Ellipse 8926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1477 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6782"
          data-name="Ellipse 6782"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7676"
          data-name="Ellipse 7676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9454"
          data-name="Ellipse 9454"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8066"
          data-name="Ellipse 8066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9455"
          data-name="Ellipse 9455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9575"
          data-name="Ellipse 9575"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9695"
          data-name="Ellipse 9695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9935"
          data-name="Ellipse 9935"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6871"
          data-name="Ellipse 6871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7690"
          data-name="Ellipse 7690"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9500"
          data-name="Ellipse 9500"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8080"
          data-name="Ellipse 8080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9501"
          data-name="Ellipse 9501"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9621"
          data-name="Ellipse 9621"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9741"
          data-name="Ellipse 9741"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9981"
          data-name="Ellipse 9981"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10000"
          data-name="Ellipse 10000"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10004"
          data-name="Ellipse 10004"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10012"
          data-name="Ellipse 10012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10016"
          data-name="Ellipse 10016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10008"
          data-name="Ellipse 10008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10015"
          data-name="Ellipse 10015"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10019"
          data-name="Ellipse 10019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8470"
          data-name="Ellipse 8470"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3970"
          data-name="Ellipse 3970"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4156"
          data-name="Ellipse 4156"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3125"
          data-name="Ellipse 3125"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2332"
          data-name="Ellipse 2332"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6454"
          data-name="Ellipse 6454"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6708"
          data-name="Ellipse 6708"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7422"
          data-name="Ellipse 7422"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7812"
          data-name="Ellipse 7812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8202"
          data-name="Ellipse 8202"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6924"
          data-name="Ellipse 6924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7423"
          data-name="Ellipse 7423"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7813"
          data-name="Ellipse 7813"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8203"
          data-name="Ellipse 8203"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9051"
          data-name="Ellipse 9051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8593"
          data-name="Ellipse 8593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8927"
          data-name="Ellipse 8927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1477 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3971"
          data-name="Ellipse 3971"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4157"
          data-name="Ellipse 4157"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3224"
          data-name="Ellipse 3224"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3972"
          data-name="Ellipse 3972"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2657"
          data-name="Ellipse 2657"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2788"
          data-name="Ellipse 2788"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1572"
          data-name="Ellipse 1572"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1586"
          data-name="Ellipse 1586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2112"
          data-name="Ellipse 2112"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6514"
          data-name="Ellipse 6514"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6737"
          data-name="Ellipse 6737"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7523"
          data-name="Ellipse 7523"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7913"
          data-name="Ellipse 7913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8303"
          data-name="Ellipse 8303"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6980"
          data-name="Ellipse 6980"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7425"
          data-name="Ellipse 7425"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7815"
          data-name="Ellipse 7815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8205"
          data-name="Ellipse 8205"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7426"
          data-name="Ellipse 7426"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7816"
          data-name="Ellipse 7816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8206"
          data-name="Ellipse 8206"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6799"
          data-name="Ellipse 6799"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9410"
          data-name="Ellipse 9410"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7628"
          data-name="Ellipse 7628"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9411"
          data-name="Ellipse 9411"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9531"
          data-name="Ellipse 9531"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8018"
          data-name="Ellipse 8018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9412"
          data-name="Ellipse 9412"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9532"
          data-name="Ellipse 9532"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9652"
          data-name="Ellipse 9652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9772"
          data-name="Ellipse 9772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9892"
          data-name="Ellipse 9892"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7097"
          data-name="Ellipse 7097"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7427"
          data-name="Ellipse 7427"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7817"
          data-name="Ellipse 7817"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8207"
          data-name="Ellipse 8207"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8597"
          data-name="Ellipse 8597"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7313"
          data-name="Ellipse 7313"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7428"
          data-name="Ellipse 7428"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7818"
          data-name="Ellipse 7818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8208"
          data-name="Ellipse 8208"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9058"
          data-name="Ellipse 9058"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8598"
          data-name="Ellipse 8598"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8928"
          data-name="Ellipse 8928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6863"
          data-name="Ellipse 6863"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7677"
          data-name="Ellipse 7677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9457"
          data-name="Ellipse 9457"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9577"
          data-name="Ellipse 9577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8067"
          data-name="Ellipse 8067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9458"
          data-name="Ellipse 9458"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9578"
          data-name="Ellipse 9578"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9698"
          data-name="Ellipse 9698"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9818"
          data-name="Ellipse 9818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9938"
          data-name="Ellipse 9938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3991"
          data-name="Ellipse 3991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3075"
          data-name="Ellipse 3075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2324"
          data-name="Ellipse 2324"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6418"
          data-name="Ellipse 6418"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3992"
          data-name="Ellipse 3992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4178"
          data-name="Ellipse 4178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3170"
          data-name="Ellipse 3170"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4318"
          data-name="Ellipse 4318"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3993"
          data-name="Ellipse 3993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2620"
          data-name="Ellipse 2620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2789"
          data-name="Ellipse 2789"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3317"
          data-name="Ellipse 3317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9260"
          data-name="Ellipse 9260"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9315"
          data-name="Ellipse 9315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9332"
          data-name="Ellipse 9332"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9349"
          data-name="Ellipse 9349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9384"
          data-name="Ellipse 9384"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9360"
          data-name="Ellipse 9360"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9385"
          data-name="Ellipse 9385"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9276"
          data-name="Ellipse 9276"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9313"
          data-name="Ellipse 9313"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9330"
          data-name="Ellipse 9330"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9347"
          data-name="Ellipse 9347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9374"
          data-name="Ellipse 9374"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9361"
          data-name="Ellipse 9361"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9380"
          data-name="Ellipse 9380"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9292"
          data-name="Ellipse 9292"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9309"
          data-name="Ellipse 9309"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9326"
          data-name="Ellipse 9326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9343"
          data-name="Ellipse 9343"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9375"
          data-name="Ellipse 9375"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9395"
          data-name="Ellipse 9395"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9362"
          data-name="Ellipse 9362"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9381"
          data-name="Ellipse 9381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9396"
          data-name="Ellipse 9396"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9308"
          data-name="Ellipse 9308"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9310"
          data-name="Ellipse 9310"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9327"
          data-name="Ellipse 9327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9397"
          data-name="Ellipse 9397"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3994"
          data-name="Ellipse 3994"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4180"
          data-name="Ellipse 4180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3318"
          data-name="Ellipse 3318"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2369"
          data-name="Ellipse 2369"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6420"
          data-name="Ellipse 6420"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6687"
          data-name="Ellipse 6687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7482"
          data-name="Ellipse 7482"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7872"
          data-name="Ellipse 7872"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8262"
          data-name="Ellipse 8262"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8652"
          data-name="Ellipse 8652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6931"
          data-name="Ellipse 6931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7429"
          data-name="Ellipse 7429"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7819"
          data-name="Ellipse 7819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8209"
          data-name="Ellipse 8209"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9059"
          data-name="Ellipse 9059"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9149"
          data-name="Ellipse 9149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9217"
          data-name="Ellipse 9217"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8599"
          data-name="Ellipse 8599"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8929"
          data-name="Ellipse 8929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7147"
          data-name="Ellipse 7147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7820"
          data-name="Ellipse 7820"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3995"
          data-name="Ellipse 3995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3171"
          data-name="Ellipse 3171"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3996"
          data-name="Ellipse 3996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4182"
          data-name="Ellipse 4182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2836"
          data-name="Ellipse 2836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1653"
          data-name="Ellipse 1653"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2079"
          data-name="Ellipse 2079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6421"
          data-name="Ellipse 6421"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6688"
          data-name="Ellipse 6688"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7515"
          data-name="Ellipse 7515"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7905"
          data-name="Ellipse 7905"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8295"
          data-name="Ellipse 8295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8685"
          data-name="Ellipse 8685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6932"
          data-name="Ellipse 6932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7431"
          data-name="Ellipse 7431"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7821"
          data-name="Ellipse 7821"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8211"
          data-name="Ellipse 8211"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9060"
          data-name="Ellipse 9060"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9130"
          data-name="Ellipse 9130"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8601"
          data-name="Ellipse 8601"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8947"
          data-name="Ellipse 8947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7148"
          data-name="Ellipse 7148"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7822"
          data-name="Ellipse 7822"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3997"
          data-name="Ellipse 3997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3172"
          data-name="Ellipse 3172"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3998"
          data-name="Ellipse 3998"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2908"
          data-name="Ellipse 2908"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2325"
          data-name="Ellipse 2325"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6422"
          data-name="Ellipse 6422"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3999"
          data-name="Ellipse 3999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4185"
          data-name="Ellipse 4185"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3173"
          data-name="Ellipse 3173"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2623"
          data-name="Ellipse 2623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2215"
          data-name="Ellipse 2215"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6549"
          data-name="Ellipse 6549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6766"
          data-name="Ellipse 6766"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7571"
          data-name="Ellipse 7571"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7961"
          data-name="Ellipse 7961"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8351"
          data-name="Ellipse 8351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6998"
          data-name="Ellipse 6998"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7433"
          data-name="Ellipse 7433"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7823"
          data-name="Ellipse 7823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8213"
          data-name="Ellipse 8213"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7214"
          data-name="Ellipse 7214"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7434"
          data-name="Ellipse 7434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7824"
          data-name="Ellipse 7824"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8214"
          data-name="Ellipse 8214"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8604"
          data-name="Ellipse 8604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8930"
          data-name="Ellipse 8930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6783"
          data-name="Ellipse 6783"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7678"
          data-name="Ellipse 7678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9460"
          data-name="Ellipse 9460"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9580"
          data-name="Ellipse 9580"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8068"
          data-name="Ellipse 8068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9461"
          data-name="Ellipse 9461"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9581"
          data-name="Ellipse 9581"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9701"
          data-name="Ellipse 9701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9821"
          data-name="Ellipse 9821"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9941"
          data-name="Ellipse 9941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6872"
          data-name="Ellipse 6872"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7691"
          data-name="Ellipse 7691"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9503"
          data-name="Ellipse 9503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8081"
          data-name="Ellipse 8081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9504"
          data-name="Ellipse 9504"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9624"
          data-name="Ellipse 9624"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9744"
          data-name="Ellipse 9744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9864"
          data-name="Ellipse 9864"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9984"
          data-name="Ellipse 9984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10001"
          data-name="Ellipse 10001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10005"
          data-name="Ellipse 10005"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4002"
          data-name="Ellipse 4002"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4188"
          data-name="Ellipse 4188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3126"
          data-name="Ellipse 3126"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2326"
          data-name="Ellipse 2326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6423"
          data-name="Ellipse 6423"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6689"
          data-name="Ellipse 6689"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7435"
          data-name="Ellipse 7435"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7825"
          data-name="Ellipse 7825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8215"
          data-name="Ellipse 8215"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8605"
          data-name="Ellipse 8605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6933"
          data-name="Ellipse 6933"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7436"
          data-name="Ellipse 7436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7826"
          data-name="Ellipse 7826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8216"
          data-name="Ellipse 8216"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9062"
          data-name="Ellipse 9062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8606"
          data-name="Ellipse 8606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8931"
          data-name="Ellipse 8931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7827"
          data-name="Ellipse 7827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4003"
          data-name="Ellipse 4003"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4189"
          data-name="Ellipse 4189"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3175"
          data-name="Ellipse 3175"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4004"
          data-name="Ellipse 4004"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2791"
          data-name="Ellipse 2791"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1483"
          data-name="Ellipse 1483"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1544"
          data-name="Ellipse 1544"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1605"
          data-name="Ellipse 1605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1501"
          data-name="Ellipse 1501"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1545"
          data-name="Ellipse 1545"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1589"
          data-name="Ellipse 1589"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2113"
          data-name="Ellipse 2113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6515"
          data-name="Ellipse 6515"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6738"
          data-name="Ellipse 6738"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7524"
          data-name="Ellipse 7524"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7914"
          data-name="Ellipse 7914"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8304"
          data-name="Ellipse 8304"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6981"
          data-name="Ellipse 6981"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7483"
          data-name="Ellipse 7483"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7873"
          data-name="Ellipse 7873"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8263"
          data-name="Ellipse 8263"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7484"
          data-name="Ellipse 7484"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7874"
          data-name="Ellipse 7874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8264"
          data-name="Ellipse 8264"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8654"
          data-name="Ellipse 8654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6800"
          data-name="Ellipse 6800"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9413"
          data-name="Ellipse 9413"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7629"
          data-name="Ellipse 7629"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9414"
          data-name="Ellipse 9414"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9534"
          data-name="Ellipse 9534"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9654"
          data-name="Ellipse 9654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9774"
          data-name="Ellipse 9774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8019"
          data-name="Ellipse 8019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9415"
          data-name="Ellipse 9415"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9535"
          data-name="Ellipse 9535"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9655"
          data-name="Ellipse 9655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9775"
          data-name="Ellipse 9775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9895"
          data-name="Ellipse 9895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7098"
          data-name="Ellipse 7098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7485"
          data-name="Ellipse 7485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7875"
          data-name="Ellipse 7875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8265"
          data-name="Ellipse 8265"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8655"
          data-name="Ellipse 8655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7314"
          data-name="Ellipse 7314"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7486"
          data-name="Ellipse 7486"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7876"
          data-name="Ellipse 7876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8266"
          data-name="Ellipse 8266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9069"
          data-name="Ellipse 9069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8656"
          data-name="Ellipse 8656"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8948"
          data-name="Ellipse 8948"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6862"
          data-name="Ellipse 6862"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7679"
          data-name="Ellipse 7679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9463"
          data-name="Ellipse 9463"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9583"
          data-name="Ellipse 9583"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9703"
          data-name="Ellipse 9703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9823"
          data-name="Ellipse 9823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8069"
          data-name="Ellipse 8069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9464"
          data-name="Ellipse 9464"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9584"
          data-name="Ellipse 9584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9704"
          data-name="Ellipse 9704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9824"
          data-name="Ellipse 9824"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9944"
          data-name="Ellipse 9944"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4022"
          data-name="Ellipse 4022"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3076"
          data-name="Ellipse 3076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2304"
          data-name="Ellipse 2304"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6398"
          data-name="Ellipse 6398"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4023"
          data-name="Ellipse 4023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3157"
          data-name="Ellipse 3157"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4300"
          data-name="Ellipse 4300"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4024"
          data-name="Ellipse 4024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2583"
          data-name="Ellipse 2583"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2752"
          data-name="Ellipse 2752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2210"
          data-name="Ellipse 2210"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3319"
          data-name="Ellipse 3319"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9242"
          data-name="Ellipse 9242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9325"
          data-name="Ellipse 9325"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9368"
          data-name="Ellipse 9368"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9369"
          data-name="Ellipse 9369"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9370"
          data-name="Ellipse 9370"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9390"
          data-name="Ellipse 9390"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9371"
          data-name="Ellipse 9371"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9342"
          data-name="Ellipse 9342"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9359"
          data-name="Ellipse 9359"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9392"
          data-name="Ellipse 9392"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9258"
          data-name="Ellipse 9258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9317"
          data-name="Ellipse 9317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9334"
          data-name="Ellipse 9334"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9351"
          data-name="Ellipse 9351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9386"
          data-name="Ellipse 9386"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9274"
          data-name="Ellipse 9274"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9318"
          data-name="Ellipse 9318"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9335"
          data-name="Ellipse 9335"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9352"
          data-name="Ellipse 9352"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9376"
          data-name="Ellipse 9376"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9290"
          data-name="Ellipse 9290"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9319"
          data-name="Ellipse 9319"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9336"
          data-name="Ellipse 9336"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9353"
          data-name="Ellipse 9353"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9372"
          data-name="Ellipse 9372"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9306"
          data-name="Ellipse 9306"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9320"
          data-name="Ellipse 9320"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9337"
          data-name="Ellipse 9337"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9398"
          data-name="Ellipse 9398"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9354"
          data-name="Ellipse 9354"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9399"
          data-name="Ellipse 9399"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4026"
          data-name="Ellipse 4026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4212"
          data-name="Ellipse 4212"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3320"
          data-name="Ellipse 3320"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2370"
          data-name="Ellipse 2370"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6400"
          data-name="Ellipse 6400"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6675"
          data-name="Ellipse 6675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7487"
          data-name="Ellipse 7487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7877"
          data-name="Ellipse 7877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8267"
          data-name="Ellipse 8267"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8657"
          data-name="Ellipse 8657"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6939"
          data-name="Ellipse 6939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7488"
          data-name="Ellipse 7488"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7878"
          data-name="Ellipse 7878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8268"
          data-name="Ellipse 8268"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9070"
          data-name="Ellipse 9070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9151"
          data-name="Ellipse 9151"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9219"
          data-name="Ellipse 9219"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8658"
          data-name="Ellipse 8658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8949"
          data-name="Ellipse 8949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7155"
          data-name="Ellipse 7155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7879"
          data-name="Ellipse 7879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8659"
          data-name="Ellipse 8659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4027"
          data-name="Ellipse 4027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3158"
          data-name="Ellipse 3158"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4214"
          data-name="Ellipse 4214"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2837"
          data-name="Ellipse 2837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1658"
          data-name="Ellipse 1658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2058"
          data-name="Ellipse 2058"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6401"
          data-name="Ellipse 6401"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6676"
          data-name="Ellipse 6676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7516"
          data-name="Ellipse 7516"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7906"
          data-name="Ellipse 7906"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8296"
          data-name="Ellipse 8296"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8686"
          data-name="Ellipse 8686"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6940"
          data-name="Ellipse 6940"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7490"
          data-name="Ellipse 7490"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7880"
          data-name="Ellipse 7880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8270"
          data-name="Ellipse 8270"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9071"
          data-name="Ellipse 9071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9132"
          data-name="Ellipse 9132"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8660"
          data-name="Ellipse 8660"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8950"
          data-name="Ellipse 8950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7156"
          data-name="Ellipse 7156"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7881"
          data-name="Ellipse 7881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8661"
          data-name="Ellipse 8661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4029"
          data-name="Ellipse 4029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3159"
          data-name="Ellipse 3159"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4030"
          data-name="Ellipse 4030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2909"
          data-name="Ellipse 2909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2305"
          data-name="Ellipse 2305"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4031"
          data-name="Ellipse 4031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4217"
          data-name="Ellipse 4217"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3160"
          data-name="Ellipse 3160"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2586"
          data-name="Ellipse 2586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2211"
          data-name="Ellipse 2211"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6555"
          data-name="Ellipse 6555"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6767"
          data-name="Ellipse 6767"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7572"
          data-name="Ellipse 7572"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7962"
          data-name="Ellipse 7962"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6999"
          data-name="Ellipse 6999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7492"
          data-name="Ellipse 7492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7882"
          data-name="Ellipse 7882"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8272"
          data-name="Ellipse 8272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7215"
          data-name="Ellipse 7215"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7493"
          data-name="Ellipse 7493"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7883"
          data-name="Ellipse 7883"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8273"
          data-name="Ellipse 8273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8663"
          data-name="Ellipse 8663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8951"
          data-name="Ellipse 8951"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6784"
          data-name="Ellipse 6784"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7680"
          data-name="Ellipse 7680"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9466"
          data-name="Ellipse 9466"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9586"
          data-name="Ellipse 9586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9706"
          data-name="Ellipse 9706"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9826"
          data-name="Ellipse 9826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8070"
          data-name="Ellipse 8070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9467"
          data-name="Ellipse 9467"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9587"
          data-name="Ellipse 9587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9707"
          data-name="Ellipse 9707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9827"
          data-name="Ellipse 9827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9947"
          data-name="Ellipse 9947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6873"
          data-name="Ellipse 6873"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7692"
          data-name="Ellipse 7692"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9506"
          data-name="Ellipse 9506"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9626"
          data-name="Ellipse 9626"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8082"
          data-name="Ellipse 8082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9507"
          data-name="Ellipse 9507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9627"
          data-name="Ellipse 9627"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9747"
          data-name="Ellipse 9747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9867"
          data-name="Ellipse 9867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9987"
          data-name="Ellipse 9987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4034"
          data-name="Ellipse 4034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4220"
          data-name="Ellipse 4220"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3127"
          data-name="Ellipse 3127"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2306"
          data-name="Ellipse 2306"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6403"
          data-name="Ellipse 6403"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6677"
          data-name="Ellipse 6677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7494"
          data-name="Ellipse 7494"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7884"
          data-name="Ellipse 7884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8274"
          data-name="Ellipse 8274"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8664"
          data-name="Ellipse 8664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6941"
          data-name="Ellipse 6941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7495"
          data-name="Ellipse 7495"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7885"
          data-name="Ellipse 7885"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8275"
          data-name="Ellipse 8275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9073"
          data-name="Ellipse 9073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8665"
          data-name="Ellipse 8665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8952"
          data-name="Ellipse 8952"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7886"
          data-name="Ellipse 7886"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4035"
          data-name="Ellipse 4035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3162"
          data-name="Ellipse 3162"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4036"
          data-name="Ellipse 4036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2792"
          data-name="Ellipse 2792"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1707"
          data-name="Ellipse 1707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1750"
          data-name="Ellipse 1750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1883"
          data-name="Ellipse 1883"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2070"
          data-name="Ellipse 2070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6436"
          data-name="Ellipse 6436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7099"
          data-name="Ellipse 7099"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6837"
          data-name="Ellipse 6837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7100"
          data-name="Ellipse 7100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6888"
          data-name="Ellipse 6888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2968"
          data-name="Ellipse 2968"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2969"
          data-name="Ellipse 2969"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2244"
          data-name="Ellipse 2244"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2910"
          data-name="Ellipse 2910"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4253"
          data-name="Ellipse 4253"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2169"
          data-name="Ellipse 2169"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3195"
          data-name="Ellipse 3195"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2315"
          data-name="Ellipse 2315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6585"
          data-name="Ellipse 6585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7101"
          data-name="Ellipse 7101"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2838"
          data-name="Ellipse 2838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1927"
          data-name="Ellipse 1927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1811"
          data-name="Ellipse 1811"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1777"
          data-name="Ellipse 1777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1884"
          data-name="Ellipse 1884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2050"
          data-name="Ellipse 2050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6404"
          data-name="Ellipse 6404"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6625"
          data-name="Ellipse 6625"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7102"
          data-name="Ellipse 7102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2912"
          data-name="Ellipse 2912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2913"
          data-name="Ellipse 2913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2236"
          data-name="Ellipse 2236"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2914"
          data-name="Ellipse 2914"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4241"
          data-name="Ellipse 4241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2156"
          data-name="Ellipse 2156"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6557"
          data-name="Ellipse 6557"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6768"
          data-name="Ellipse 6768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7103"
          data-name="Ellipse 7103"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6826"
          data-name="Ellipse 6826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6895"
          data-name="Ellipse 6895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6874"
          data-name="Ellipse 6874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6901"
          data-name="Ellipse 6901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3128"
          data-name="Ellipse 3128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3129"
          data-name="Ellipse 3129"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2293"
          data-name="Ellipse 2293"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6586"
          data-name="Ellipse 6586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7104"
          data-name="Ellipse 7104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2915"
          data-name="Ellipse 2915"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2793"
          data-name="Ellipse 2793"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1928"
          data-name="Ellipse 1928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1785"
          data-name="Ellipse 1785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1936"
          data-name="Ellipse 1936"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1940"
          data-name="Ellipse 1940"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1555"
          data-name="Ellipse 1555"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1575"
          data-name="Ellipse 1575"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2149"
          data-name="Ellipse 2149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6526"
          data-name="Ellipse 6526"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6749"
          data-name="Ellipse 6749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7525"
          data-name="Ellipse 7525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7915"
          data-name="Ellipse 7915"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6982"
          data-name="Ellipse 6982"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7402"
          data-name="Ellipse 7402"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7792"
          data-name="Ellipse 7792"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8182"
          data-name="Ellipse 8182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7198"
          data-name="Ellipse 7198"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7345"
          data-name="Ellipse 7345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7735"
          data-name="Ellipse 7735"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6801"
          data-name="Ellipse 6801"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9416"
          data-name="Ellipse 9416"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9536"
          data-name="Ellipse 9536"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7630"
          data-name="Ellipse 7630"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9417"
          data-name="Ellipse 9417"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9537"
          data-name="Ellipse 9537"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8020"
          data-name="Ellipse 8020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9426"
          data-name="Ellipse 9426"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9546"
          data-name="Ellipse 9546"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9906"
          data-name="Ellipse 9906"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8410"
          data-name="Ellipse 8410"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7105"
          data-name="Ellipse 7105"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7438"
          data-name="Ellipse 7438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7828"
          data-name="Ellipse 7828"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8218"
          data-name="Ellipse 8218"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7321"
          data-name="Ellipse 7321"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7346"
          data-name="Ellipse 7346"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7736"
          data-name="Ellipse 7736"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8126"
          data-name="Ellipse 8126"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9014"
          data-name="Ellipse 9014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8516"
          data-name="Ellipse 8516"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6884"
          data-name="Ellipse 6884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9468"
          data-name="Ellipse 9468"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7681"
          data-name="Ellipse 7681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9469"
          data-name="Ellipse 9469"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8071"
          data-name="Ellipse 8071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9470"
          data-name="Ellipse 9470"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9950"
          data-name="Ellipse 9950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8461"
          data-name="Ellipse 8461"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6572"
          data-name="Ellipse 6572"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6769"
          data-name="Ellipse 6769"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7573"
          data-name="Ellipse 7573"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7963"
          data-name="Ellipse 7963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7000"
          data-name="Ellipse 7000"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7403"
          data-name="Ellipse 7403"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7793"
          data-name="Ellipse 7793"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8183"
          data-name="Ellipse 8183"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7216"
          data-name="Ellipse 7216"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7347"
          data-name="Ellipse 7347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7737"
          data-name="Ellipse 7737"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8127"
          data-name="Ellipse 8127"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8517"
          data-name="Ellipse 8517"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6785"
          data-name="Ellipse 6785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9471"
          data-name="Ellipse 9471"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7682"
          data-name="Ellipse 7682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9472"
          data-name="Ellipse 9472"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8072"
          data-name="Ellipse 8072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9473"
          data-name="Ellipse 9473"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9953"
          data-name="Ellipse 9953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8462"
          data-name="Ellipse 8462"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6885"
          data-name="Ellipse 6885"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9508"
          data-name="Ellipse 9508"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7693"
          data-name="Ellipse 7693"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9509"
          data-name="Ellipse 9509"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8083"
          data-name="Ellipse 8083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9510"
          data-name="Ellipse 9510"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9870"
          data-name="Ellipse 9870"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9990"
          data-name="Ellipse 9990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3338"
          data-name="Ellipse 3338"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2698"
          data-name="Ellipse 2698"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6739"
          data-name="Ellipse 6739"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3077"
          data-name="Ellipse 3077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2386"
          data-name="Ellipse 2386"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6527"
          data-name="Ellipse 6527"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3339"
          data-name="Ellipse 3339"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2699"
          data-name="Ellipse 2699"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2839"
          data-name="Ellipse 2839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4303"
          data-name="Ellipse 4303"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3340"
          data-name="Ellipse 3340"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6528"
          data-name="Ellipse 6528"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3870"
          data-name="Ellipse 3870"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3321"
          data-name="Ellipse 3321"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2387"
          data-name="Ellipse 2387"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6529"
          data-name="Ellipse 6529"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6750"
          data-name="Ellipse 6750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7497"
          data-name="Ellipse 7497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7887"
          data-name="Ellipse 7887"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8277"
          data-name="Ellipse 8277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6925"
          data-name="Ellipse 6925"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7348"
          data-name="Ellipse 7348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7738"
          data-name="Ellipse 7738"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8128"
          data-name="Ellipse 8128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9016"
          data-name="Ellipse 9016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8518"
          data-name="Ellipse 8518"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7349"
          data-name="Ellipse 7349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7739"
          data-name="Ellipse 7739"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3871"
          data-name="Ellipse 3871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2701"
          data-name="Ellipse 2701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6603"
          data-name="Ellipse 6603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2840"
          data-name="Ellipse 2840"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1654"
          data-name="Ellipse 1654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2150"
          data-name="Ellipse 2150"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6530"
          data-name="Ellipse 6530"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6751"
          data-name="Ellipse 6751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7517"
          data-name="Ellipse 7517"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7907"
          data-name="Ellipse 7907"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8297"
          data-name="Ellipse 8297"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6934"
          data-name="Ellipse 6934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7350"
          data-name="Ellipse 7350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7740"
          data-name="Ellipse 7740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8130"
          data-name="Ellipse 8130"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9017"
          data-name="Ellipse 9017"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8520"
          data-name="Ellipse 8520"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7351"
          data-name="Ellipse 7351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7741"
          data-name="Ellipse 7741"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3342"
          data-name="Ellipse 3342"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3872"
          data-name="Ellipse 3872"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2702"
          data-name="Ellipse 2702"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2916"
          data-name="Ellipse 2916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2388"
          data-name="Ellipse 2388"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6531"
          data-name="Ellipse 6531"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3873"
          data-name="Ellipse 3873"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2703"
          data-name="Ellipse 2703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2841"
          data-name="Ellipse 2841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4242"
          data-name="Ellipse 4242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3344"
          data-name="Ellipse 3344"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3875"
          data-name="Ellipse 3875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2704"
          data-name="Ellipse 2704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6770"
          data-name="Ellipse 6770"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3130"
          data-name="Ellipse 3130"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2389"
          data-name="Ellipse 2389"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6532"
          data-name="Ellipse 6532"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6752"
          data-name="Ellipse 6752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7439"
          data-name="Ellipse 7439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7829"
          data-name="Ellipse 7829"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8219"
          data-name="Ellipse 8219"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6913"
          data-name="Ellipse 6913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7352"
          data-name="Ellipse 7352"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7742"
          data-name="Ellipse 7742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8132"
          data-name="Ellipse 8132"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9018"
          data-name="Ellipse 9018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8522"
          data-name="Ellipse 8522"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8912"
          data-name="Ellipse 8912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1489 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7353"
          data-name="Ellipse 7353"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3876"
          data-name="Ellipse 3876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3345"
          data-name="Ellipse 3345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2705"
          data-name="Ellipse 2705"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2842"
          data-name="Ellipse 2842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_747"
          data-name="Ellipse 747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1364"
          data-name="Ellipse 1364"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1422"
          data-name="Ellipse 1422"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1477"
          data-name="Ellipse 1477"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1546"
          data-name="Ellipse 1546"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1625"
          data-name="Ellipse 1625"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1502"
          data-name="Ellipse 1502"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1521"
          data-name="Ellipse 1521"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1626"
          data-name="Ellipse 1626"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2114"
          data-name="Ellipse 2114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6740"
          data-name="Ellipse 6740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7043"
          data-name="Ellipse 7043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6802"
          data-name="Ellipse 6802"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7106"
          data-name="Ellipse 7106"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7322"
          data-name="Ellipse 7322"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6853"
          data-name="Ellipse 6853"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3078"
          data-name="Ellipse 3078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3079"
          data-name="Ellipse 3079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2278"
          data-name="Ellipse 2278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2970"
          data-name="Ellipse 2970"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4304"
          data-name="Ellipse 4304"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4420"
          data-name="Ellipse 4420"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2754"
          data-name="Ellipse 2754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2200"
          data-name="Ellipse 2200"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3751"
          data-name="Ellipse 3751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3771"
          data-name="Ellipse 3771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3322"
          data-name="Ellipse 3322"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2371"
          data-name="Ellipse 2371"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6353"
          data-name="Ellipse 6353"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6609"
          data-name="Ellipse 6609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7044"
          data-name="Ellipse 7044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2971"
          data-name="Ellipse 2971"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2843"
          data-name="Ellipse 2843"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3496"
          data-name="Ellipse 3496"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1674"
          data-name="Ellipse 1674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2051"
          data-name="Ellipse 2051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6406"
          data-name="Ellipse 6406"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6627"
          data-name="Ellipse 6627"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7045"
          data-name="Ellipse 7045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2972"
          data-name="Ellipse 2972"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2917"
          data-name="Ellipse 2917"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3497"
          data-name="Ellipse 3497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2240"
          data-name="Ellipse 2240"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2973"
          data-name="Ellipse 2973"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4247"
          data-name="Ellipse 4247"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3498"
          data-name="Ellipse 3498"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2157"
          data-name="Ellipse 2157"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6771"
          data-name="Ellipse 6771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7046"
          data-name="Ellipse 7046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6803"
          data-name="Ellipse 6803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6875"
          data-name="Ellipse 6875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3131"
          data-name="Ellipse 3131"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3132"
          data-name="Ellipse 3132"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2294"
          data-name="Ellipse 2294"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6291"
          data-name="Ellipse 6291"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6610"
          data-name="Ellipse 6610"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7047"
          data-name="Ellipse 7047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2974"
          data-name="Ellipse 2974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2794"
          data-name="Ellipse 2794"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1723"
          data-name="Ellipse 1723"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1812"
          data-name="Ellipse 1812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1447"
          data-name="Ellipse 1447"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1480"
          data-name="Ellipse 1480"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1547"
          data-name="Ellipse 1547"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1606"
          data-name="Ellipse 1606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1567"
          data-name="Ellipse 1567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1607"
          data-name="Ellipse 1607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1637"
          data-name="Ellipse 1637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1503"
          data-name="Ellipse 1503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1535"
          data-name="Ellipse 1535"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1592"
          data-name="Ellipse 1592"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2115"
          data-name="Ellipse 2115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6518"
          data-name="Ellipse 6518"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6741"
          data-name="Ellipse 6741"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7526"
          data-name="Ellipse 7526"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7916"
          data-name="Ellipse 7916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8306"
          data-name="Ellipse 8306"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8696"
          data-name="Ellipse 8696"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6983"
          data-name="Ellipse 6983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7527"
          data-name="Ellipse 7527"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7917"
          data-name="Ellipse 7917"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8307"
          data-name="Ellipse 8307"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8697"
          data-name="Ellipse 8697"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7199"
          data-name="Ellipse 7199"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7528"
          data-name="Ellipse 7528"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7918"
          data-name="Ellipse 7918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8308"
          data-name="Ellipse 8308"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8698"
          data-name="Ellipse 8698"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6804"
          data-name="Ellipse 6804"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7631"
          data-name="Ellipse 7631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9418"
          data-name="Ellipse 9418"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9538"
          data-name="Ellipse 9538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9658"
          data-name="Ellipse 9658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9778"
          data-name="Ellipse 9778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8021"
          data-name="Ellipse 8021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9419"
          data-name="Ellipse 9419"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9539"
          data-name="Ellipse 9539"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9659"
          data-name="Ellipse 9659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9779"
          data-name="Ellipse 9779"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9899"
          data-name="Ellipse 9899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8411"
          data-name="Ellipse 8411"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9516"
          data-name="Ellipse 9516"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9636"
          data-name="Ellipse 9636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9756"
          data-name="Ellipse 9756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10021"
          data-name="Ellipse 10021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10039"
          data-name="Ellipse 10039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9996"
          data-name="Ellipse 9996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10061"
          data-name="Ellipse 10061"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10069"
          data-name="Ellipse 10069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7107"
          data-name="Ellipse 7107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7529"
          data-name="Ellipse 7529"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7919"
          data-name="Ellipse 7919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8309"
          data-name="Ellipse 8309"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8699"
          data-name="Ellipse 8699"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7323"
          data-name="Ellipse 7323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7530"
          data-name="Ellipse 7530"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7920"
          data-name="Ellipse 7920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8310"
          data-name="Ellipse 8310"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9080"
          data-name="Ellipse 9080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8700"
          data-name="Ellipse 8700"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8954"
          data-name="Ellipse 8954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6861"
          data-name="Ellipse 6861"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7683"
          data-name="Ellipse 7683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9474"
          data-name="Ellipse 9474"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9594"
          data-name="Ellipse 9594"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9714"
          data-name="Ellipse 9714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9834"
          data-name="Ellipse 9834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8073"
          data-name="Ellipse 8073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9475"
          data-name="Ellipse 9475"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9595"
          data-name="Ellipse 9595"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9715"
          data-name="Ellipse 9715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9835"
          data-name="Ellipse 9835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9955"
          data-name="Ellipse 9955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8463"
          data-name="Ellipse 8463"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9517"
          data-name="Ellipse 9517"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9637"
          data-name="Ellipse 9637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9757"
          data-name="Ellipse 9757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10023"
          data-name="Ellipse 10023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10040"
          data-name="Ellipse 10040"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9997"
          data-name="Ellipse 9997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10062"
          data-name="Ellipse 10062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10070"
          data-name="Ellipse 10070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3080"
          data-name="Ellipse 3080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2295"
          data-name="Ellipse 2295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6354"
          data-name="Ellipse 6354"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3134"
          data-name="Ellipse 3134"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4305"
          data-name="Ellipse 4305"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2755"
          data-name="Ellipse 2755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2201"
          data-name="Ellipse 2201"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3324"
          data-name="Ellipse 3324"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6355"
          data-name="Ellipse 6355"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9243"
          data-name="Ellipse 9243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9259"
          data-name="Ellipse 9259"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9275"
          data-name="Ellipse 9275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9291"
          data-name="Ellipse 9291"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3325"
          data-name="Ellipse 3325"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2372"
          data-name="Ellipse 2372"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6356"
          data-name="Ellipse 6356"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6669"
          data-name="Ellipse 6669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7531"
          data-name="Ellipse 7531"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7921"
          data-name="Ellipse 7921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8311"
          data-name="Ellipse 8311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8701"
          data-name="Ellipse 8701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6942"
          data-name="Ellipse 6942"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7532"
          data-name="Ellipse 7532"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7922"
          data-name="Ellipse 7922"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8312"
          data-name="Ellipse 8312"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9081"
          data-name="Ellipse 9081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8702"
          data-name="Ellipse 8702"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8955"
          data-name="Ellipse 8955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7158"
          data-name="Ellipse 7158"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7923"
          data-name="Ellipse 7923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8703"
          data-name="Ellipse 8703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3135"
          data-name="Ellipse 3135"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2844"
          data-name="Ellipse 2844"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1662"
          data-name="Ellipse 1662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2052"
          data-name="Ellipse 2052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6670"
          data-name="Ellipse 6670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7534"
          data-name="Ellipse 7534"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7924"
          data-name="Ellipse 7924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8314"
          data-name="Ellipse 8314"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8704"
          data-name="Ellipse 8704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6943"
          data-name="Ellipse 6943"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7535"
          data-name="Ellipse 7535"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7925"
          data-name="Ellipse 7925"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8315"
          data-name="Ellipse 8315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9082"
          data-name="Ellipse 9082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8705"
          data-name="Ellipse 8705"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8956"
          data-name="Ellipse 8956"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7159"
          data-name="Ellipse 7159"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7926"
          data-name="Ellipse 7926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8706"
          data-name="Ellipse 8706"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3136"
          data-name="Ellipse 3136"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2918"
          data-name="Ellipse 2918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2296"
          data-name="Ellipse 2296"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6357"
          data-name="Ellipse 6357"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3137"
          data-name="Ellipse 3137"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2554"
          data-name="Ellipse 2554"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2202"
          data-name="Ellipse 2202"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6560"
          data-name="Ellipse 6560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6772"
          data-name="Ellipse 6772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7574"
          data-name="Ellipse 7574"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7964"
          data-name="Ellipse 7964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8354"
          data-name="Ellipse 8354"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8744"
          data-name="Ellipse 8744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7001"
          data-name="Ellipse 7001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7537"
          data-name="Ellipse 7537"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7927"
          data-name="Ellipse 7927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8317"
          data-name="Ellipse 8317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7217"
          data-name="Ellipse 7217"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7538"
          data-name="Ellipse 7538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7928"
          data-name="Ellipse 7928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8318"
          data-name="Ellipse 8318"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8708"
          data-name="Ellipse 8708"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6786"
          data-name="Ellipse 6786"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7684"
          data-name="Ellipse 7684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9476"
          data-name="Ellipse 9476"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9596"
          data-name="Ellipse 9596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9716"
          data-name="Ellipse 9716"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9836"
          data-name="Ellipse 9836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8074"
          data-name="Ellipse 8074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9477"
          data-name="Ellipse 9477"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9597"
          data-name="Ellipse 9597"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9717"
          data-name="Ellipse 9717"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9837"
          data-name="Ellipse 9837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9957"
          data-name="Ellipse 9957"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8464"
          data-name="Ellipse 8464"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9518"
          data-name="Ellipse 9518"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9638"
          data-name="Ellipse 9638"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9758"
          data-name="Ellipse 9758"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10025"
          data-name="Ellipse 10025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10041"
          data-name="Ellipse 10041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9998"
          data-name="Ellipse 9998"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10063"
          data-name="Ellipse 10063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10071"
          data-name="Ellipse 10071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6876"
          data-name="Ellipse 6876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7694"
          data-name="Ellipse 7694"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9511"
          data-name="Ellipse 9511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9631"
          data-name="Ellipse 9631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9751"
          data-name="Ellipse 9751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8084"
          data-name="Ellipse 8084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9512"
          data-name="Ellipse 9512"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9632"
          data-name="Ellipse 9632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9752"
          data-name="Ellipse 9752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9872"
          data-name="Ellipse 9872"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8474"
          data-name="Ellipse 8474"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9639"
          data-name="Ellipse 9639"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9759"
          data-name="Ellipse 9759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10027"
          data-name="Ellipse 10027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9999"
          data-name="Ellipse 9999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10080"
          data-name="Ellipse 10080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10064"
          data-name="Ellipse 10064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10078"
          data-name="Ellipse 10078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10072"
          data-name="Ellipse 10072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3139"
          data-name="Ellipse 3139"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2297"
          data-name="Ellipse 2297"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6358"
          data-name="Ellipse 6358"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6671"
          data-name="Ellipse 6671"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7539"
          data-name="Ellipse 7539"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7929"
          data-name="Ellipse 7929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8319"
          data-name="Ellipse 8319"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8709"
          data-name="Ellipse 8709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6944"
          data-name="Ellipse 6944"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7540"
          data-name="Ellipse 7540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7930"
          data-name="Ellipse 7930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8320"
          data-name="Ellipse 8320"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9084"
          data-name="Ellipse 9084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8710"
          data-name="Ellipse 8710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8958"
          data-name="Ellipse 8958"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7931"
          data-name="Ellipse 7931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 255)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2795"
          data-name="Ellipse 2795"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1709"
          data-name="Ellipse 1709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1751"
          data-name="Ellipse 1751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1879"
          data-name="Ellipse 1879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2071"
          data-name="Ellipse 2071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6437"
          data-name="Ellipse 6437"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7117"
          data-name="Ellipse 7117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6838"
          data-name="Ellipse 6838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7118"
          data-name="Ellipse 7118"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6886"
          data-name="Ellipse 6886"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2975"
          data-name="Ellipse 2975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2976"
          data-name="Ellipse 2976"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2245"
          data-name="Ellipse 2245"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2890"
          data-name="Ellipse 2890"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4254"
          data-name="Ellipse 4254"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2170"
          data-name="Ellipse 2170"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3197"
          data-name="Ellipse 3197"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3198"
          data-name="Ellipse 3198"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2316"
          data-name="Ellipse 2316"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6587"
          data-name="Ellipse 6587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7119"
          data-name="Ellipse 7119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2845"
          data-name="Ellipse 2845"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1923"
          data-name="Ellipse 1923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1815"
          data-name="Ellipse 1815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1557"
          data-name="Ellipse 1557"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1576"
          data-name="Ellipse 1576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2143"
          data-name="Ellipse 2143"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6519"
          data-name="Ellipse 6519"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6742"
          data-name="Ellipse 6742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7542"
          data-name="Ellipse 7542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7932"
          data-name="Ellipse 7932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6984"
          data-name="Ellipse 6984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7404"
          data-name="Ellipse 7404"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7794"
          data-name="Ellipse 7794"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8184"
          data-name="Ellipse 8184"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7200"
          data-name="Ellipse 7200"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7354"
          data-name="Ellipse 7354"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7744"
          data-name="Ellipse 7744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 283)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6805"
          data-name="Ellipse 6805"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9420"
          data-name="Ellipse 9420"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9540"
          data-name="Ellipse 9540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7632"
          data-name="Ellipse 7632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9421"
          data-name="Ellipse 9421"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9541"
          data-name="Ellipse 9541"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8022"
          data-name="Ellipse 8022"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9424"
          data-name="Ellipse 9424"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9544"
          data-name="Ellipse 9544"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9904"
          data-name="Ellipse 9904"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8412"
          data-name="Ellipse 8412"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7108"
          data-name="Ellipse 7108"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7440"
          data-name="Ellipse 7440"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7830"
          data-name="Ellipse 7830"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8220"
          data-name="Ellipse 8220"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7324"
          data-name="Ellipse 7324"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7355"
          data-name="Ellipse 7355"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7745"
          data-name="Ellipse 7745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8135"
          data-name="Ellipse 8135"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9019"
          data-name="Ellipse 9019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8525"
          data-name="Ellipse 8525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6882"
          data-name="Ellipse 6882"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9478"
          data-name="Ellipse 9478"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7685"
          data-name="Ellipse 7685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9479"
          data-name="Ellipse 9479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8075"
          data-name="Ellipse 8075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9480"
          data-name="Ellipse 9480"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9600"
          data-name="Ellipse 9600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9960"
          data-name="Ellipse 9960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8465"
          data-name="Ellipse 8465"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3284"
          data-name="Ellipse 3284"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2682"
          data-name="Ellipse 2682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3081"
          data-name="Ellipse 3081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2373"
          data-name="Ellipse 2373"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6492"
          data-name="Ellipse 6492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3895"
          data-name="Ellipse 3895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4081"
          data-name="Ellipse 4081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3285"
          data-name="Ellipse 3285"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2683"
          data-name="Ellipse 2683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2846"
          data-name="Ellipse 2846"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4308"
          data-name="Ellipse 4308"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3326"
          data-name="Ellipse 3326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6493"
          data-name="Ellipse 6493"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3897"
          data-name="Ellipse 3897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3327"
          data-name="Ellipse 3327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2374"
          data-name="Ellipse 2374"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6494"
          data-name="Ellipse 6494"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6720"
          data-name="Ellipse 6720"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7498"
          data-name="Ellipse 7498"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7888"
          data-name="Ellipse 7888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8278"
          data-name="Ellipse 8278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6926"
          data-name="Ellipse 6926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7356"
          data-name="Ellipse 7356"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7746"
          data-name="Ellipse 7746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8136"
          data-name="Ellipse 8136"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9020"
          data-name="Ellipse 9020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8526"
          data-name="Ellipse 8526"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7142"
          data-name="Ellipse 7142"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7357"
          data-name="Ellipse 7357"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7747"
          data-name="Ellipse 7747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 267)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3899"
          data-name="Ellipse 3899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2685"
          data-name="Ellipse 2685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2847"
          data-name="Ellipse 2847"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1655"
          data-name="Ellipse 1655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2144"
          data-name="Ellipse 2144"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6495"
          data-name="Ellipse 6495"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6721"
          data-name="Ellipse 6721"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7518"
          data-name="Ellipse 7518"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7908"
          data-name="Ellipse 7908"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8298"
          data-name="Ellipse 8298"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6935"
          data-name="Ellipse 6935"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7358"
          data-name="Ellipse 7358"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7748"
          data-name="Ellipse 7748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8138"
          data-name="Ellipse 8138"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9021"
          data-name="Ellipse 9021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8528"
          data-name="Ellipse 8528"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7151"
          data-name="Ellipse 7151"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7359"
          data-name="Ellipse 7359"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7749"
          data-name="Ellipse 7749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 271)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3287"
          data-name="Ellipse 3287"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3901"
          data-name="Ellipse 3901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2686"
          data-name="Ellipse 2686"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2919"
          data-name="Ellipse 2919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2375"
          data-name="Ellipse 2375"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6496"
          data-name="Ellipse 6496"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3902"
          data-name="Ellipse 3902"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2687"
          data-name="Ellipse 2687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2848"
          data-name="Ellipse 2848"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4243"
          data-name="Ellipse 4243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2227"
          data-name="Ellipse 2227"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6561"
          data-name="Ellipse 6561"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6773"
          data-name="Ellipse 6773"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7575"
          data-name="Ellipse 7575"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7965"
          data-name="Ellipse 7965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7002"
          data-name="Ellipse 7002"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7405"
          data-name="Ellipse 7405"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7795"
          data-name="Ellipse 7795"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8185"
          data-name="Ellipse 8185"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7218"
          data-name="Ellipse 7218"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7360"
          data-name="Ellipse 7360"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7750"
          data-name="Ellipse 7750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8140"
          data-name="Ellipse 8140"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8530"
          data-name="Ellipse 8530"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 287)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6787"
          data-name="Ellipse 6787"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9481"
          data-name="Ellipse 9481"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7686"
          data-name="Ellipse 7686"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9482"
          data-name="Ellipse 9482"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8076"
          data-name="Ellipse 8076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9483"
          data-name="Ellipse 9483"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9603"
          data-name="Ellipse 9603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9963"
          data-name="Ellipse 9963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8466"
          data-name="Ellipse 8466"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6883"
          data-name="Ellipse 6883"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9513"
          data-name="Ellipse 9513"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7695"
          data-name="Ellipse 7695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9514"
          data-name="Ellipse 9514"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8085"
          data-name="Ellipse 8085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9515"
          data-name="Ellipse 9515"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9995"
          data-name="Ellipse 9995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3289"
          data-name="Ellipse 3289"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3904"
          data-name="Ellipse 3904"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3141"
          data-name="Ellipse 3141"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2376"
          data-name="Ellipse 2376"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6497"
          data-name="Ellipse 6497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6722"
          data-name="Ellipse 6722"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7441"
          data-name="Ellipse 7441"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7831"
          data-name="Ellipse 7831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8221"
          data-name="Ellipse 8221"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6914"
          data-name="Ellipse 6914"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7361"
          data-name="Ellipse 7361"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7751"
          data-name="Ellipse 7751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8141"
          data-name="Ellipse 8141"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9023"
          data-name="Ellipse 9023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8531"
          data-name="Ellipse 8531"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8913"
          data-name="Ellipse 8913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1485 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3905"
          data-name="Ellipse 3905"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4091"
          data-name="Ellipse 4091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1016 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3290"
          data-name="Ellipse 3290"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2689"
          data-name="Ellipse 2689"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2849"
          data-name="Ellipse 2849"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_584"
          data-name="Ellipse 584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 480)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_514"
          data-name="Ellipse 514"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6076"
          data-name="Ellipse 6076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4701"
          data-name="Ellipse 4701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5762"
          data-name="Ellipse 5762"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1159 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1188"
          data-name="Ellipse 1188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6077"
          data-name="Ellipse 6077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4681"
          data-name="Ellipse 4681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_798"
          data-name="Ellipse 798"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5935"
          data-name="Ellipse 5935"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_748"
          data-name="Ellipse 748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6001"
          data-name="Ellipse 6001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4703"
          data-name="Ellipse 4703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1189"
          data-name="Ellipse 1189"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6002"
          data-name="Ellipse 6002"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4608"
          data-name="Ellipse 4608"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5307"
          data-name="Ellipse 5307"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_566"
          data-name="Ellipse 566"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1190"
          data-name="Ellipse 1190"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4781"
          data-name="Ellipse 4781"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1094 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_893"
          data-name="Ellipse 893"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_812"
          data-name="Ellipse 812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4704"
          data-name="Ellipse 4704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5511"
          data-name="Ellipse 5511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 304)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_445"
          data-name="Ellipse 445"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_468"
          data-name="Ellipse 468"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_750"
          data-name="Ellipse 750"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1331"
          data-name="Ellipse 1331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 558)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1415"
          data-name="Ellipse 1415"
          cx="1"
          cy="1"
          r="1"
          transform="translate(988 558)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_622"
          data-name="Ellipse 622"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 504)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_661"
          data-name="Ellipse 661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1203 504)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1211"
          data-name="Ellipse 1211"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1199 504)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_540"
          data-name="Ellipse 540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_751"
          data-name="Ellipse 751"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_567"
          data-name="Ellipse 567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 468)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_485"
          data-name="Ellipse 485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1358"
          data-name="Ellipse 1358"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1372"
          data-name="Ellipse 1372"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_752"
          data-name="Ellipse 752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1359"
          data-name="Ellipse 1359"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_585"
          data-name="Ellipse 585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 484)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_515"
          data-name="Ellipse 515"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6078"
          data-name="Ellipse 6078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4755"
          data-name="Ellipse 4755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5763"
          data-name="Ellipse 5763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1159 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_813"
          data-name="Ellipse 813"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_753"
          data-name="Ellipse 753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6003"
          data-name="Ellipse 6003"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4757"
          data-name="Ellipse 4757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5783"
          data-name="Ellipse 5783"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1143 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_568"
          data-name="Ellipse 568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_894"
          data-name="Ellipse 894"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_815"
          data-name="Ellipse 815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4758"
          data-name="Ellipse 4758"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5551"
          data-name="Ellipse 5551"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 308)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_450"
          data-name="Ellipse 450"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_496"
          data-name="Ellipse 496"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_755"
          data-name="Ellipse 755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1347"
          data-name="Ellipse 1347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 546)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_623"
          data-name="Ellipse 623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_659"
          data-name="Ellipse 659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1203 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_516"
          data-name="Ellipse 516"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6079"
          data-name="Ellipse 6079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_756"
          data-name="Ellipse 756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_569"
          data-name="Ellipse 569"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_895"
          data-name="Ellipse 895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_463"
          data-name="Ellipse 463"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_757"
          data-name="Ellipse 757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1341"
          data-name="Ellipse 1341"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 550)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_624"
          data-name="Ellipse 624"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 496)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_660"
          data-name="Ellipse 660"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1203 496)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_668"
          data-name="Ellipse 668"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1203 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_520"
          data-name="Ellipse 520"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6080"
          data-name="Ellipse 6080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 543)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_758"
          data-name="Ellipse 758"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_570"
          data-name="Ellipse 570"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 460)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_896"
          data-name="Ellipse 896"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 460)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_486"
          data-name="Ellipse 486"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1368"
          data-name="Ellipse 1368"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1425"
          data-name="Ellipse 1425"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1468"
          data-name="Ellipse 1468"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1530"
          data-name="Ellipse 1530"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1611"
          data-name="Ellipse 1611"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1684"
          data-name="Ellipse 1684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2072"
          data-name="Ellipse 2072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6438"
          data-name="Ellipse 6438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6656"
          data-name="Ellipse 6656"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7717"
          data-name="Ellipse 7717"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8107"
          data-name="Ellipse 8107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8497"
          data-name="Ellipse 8497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8887"
          data-name="Ellipse 8887"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6989"
          data-name="Ellipse 6989"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7718"
          data-name="Ellipse 7718"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8108"
          data-name="Ellipse 8108"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8498"
          data-name="Ellipse 8498"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8888"
          data-name="Ellipse 8888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8996"
          data-name="Ellipse 8996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8109"
          data-name="Ellipse 8109"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8499"
          data-name="Ellipse 8499"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6839"
          data-name="Ellipse 6839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7720"
          data-name="Ellipse 7720"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8110"
          data-name="Ellipse 8110"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8500"
          data-name="Ellipse 8500"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8890"
          data-name="Ellipse 8890"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7025"
          data-name="Ellipse 7025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7721"
          data-name="Ellipse 7721"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8111"
          data-name="Ellipse 8111"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8501"
          data-name="Ellipse 8501"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8891"
          data-name="Ellipse 8891"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7241"
          data-name="Ellipse 7241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7722"
          data-name="Ellipse 7722"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8112"
          data-name="Ellipse 8112"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8502"
          data-name="Ellipse 8502"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8892"
          data-name="Ellipse 8892"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8997"
          data-name="Ellipse 8997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3049"
          data-name="Ellipse 3049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2977"
          data-name="Ellipse 2977"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3691"
          data-name="Ellipse 3691"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2265"
          data-name="Ellipse 2265"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6360"
          data-name="Ellipse 6360"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3050"
          data-name="Ellipse 3050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4274"
          data-name="Ellipse 4274"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2183"
          data-name="Ellipse 2183"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3199"
          data-name="Ellipse 3199"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3200"
          data-name="Ellipse 3200"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3695"
          data-name="Ellipse 3695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2317"
          data-name="Ellipse 2317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6361"
          data-name="Ellipse 6361"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6641"
          data-name="Ellipse 6641"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7723"
          data-name="Ellipse 7723"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8113"
          data-name="Ellipse 8113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8503"
          data-name="Ellipse 8503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8893"
          data-name="Ellipse 8893"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6990"
          data-name="Ellipse 6990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7724"
          data-name="Ellipse 7724"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8114"
          data-name="Ellipse 8114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8504"
          data-name="Ellipse 8504"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8894"
          data-name="Ellipse 8894"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8998"
          data-name="Ellipse 8998"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8115"
          data-name="Ellipse 8115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 259)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9224"
          data-name="Ellipse 9224"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 251)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3051"
          data-name="Ellipse 3051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2850"
          data-name="Ellipse 2850"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3697"
          data-name="Ellipse 3697"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1732"
          data-name="Ellipse 1732"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1829"
          data-name="Ellipse 1829"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1761"
          data-name="Ellipse 1761"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1868"
          data-name="Ellipse 1868"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1862"
          data-name="Ellipse 1862"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1489"
          data-name="Ellipse 1489"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1514"
          data-name="Ellipse 1514"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1612"
          data-name="Ellipse 1612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2089"
          data-name="Ellipse 2089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6466"
          data-name="Ellipse 6466"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6690"
          data-name="Ellipse 6690"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7726"
          data-name="Ellipse 7726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8116"
          data-name="Ellipse 8116"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8506"
          data-name="Ellipse 8506"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6991"
          data-name="Ellipse 6991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7727"
          data-name="Ellipse 7727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8117"
          data-name="Ellipse 8117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8507"
          data-name="Ellipse 8507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8897"
          data-name="Ellipse 8897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8999"
          data-name="Ellipse 8999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7207"
          data-name="Ellipse 7207"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7728"
          data-name="Ellipse 7728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8118"
          data-name="Ellipse 8118"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8508"
          data-name="Ellipse 8508"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6815"
          data-name="Ellipse 6815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7729"
          data-name="Ellipse 7729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8119"
          data-name="Ellipse 8119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8509"
          data-name="Ellipse 8509"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8899"
          data-name="Ellipse 8899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7057"
          data-name="Ellipse 7057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7730"
          data-name="Ellipse 7730"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8120"
          data-name="Ellipse 8120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8510"
          data-name="Ellipse 8510"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8900"
          data-name="Ellipse 8900"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7273"
          data-name="Ellipse 7273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7731"
          data-name="Ellipse 7731"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8121"
          data-name="Ellipse 8121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8511"
          data-name="Ellipse 8511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8901"
          data-name="Ellipse 8901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9000"
          data-name="Ellipse 9000"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3052"
          data-name="Ellipse 3052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2978"
          data-name="Ellipse 2978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3699"
          data-name="Ellipse 3699"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2266"
          data-name="Ellipse 2266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6362"
          data-name="Ellipse 6362"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3053"
          data-name="Ellipse 3053"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4275"
          data-name="Ellipse 4275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2184"
          data-name="Ellipse 2184"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3258"
          data-name="Ellipse 3258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6363"
          data-name="Ellipse 6363"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9233"
          data-name="Ellipse 9233"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9249"
          data-name="Ellipse 9249"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3259"
          data-name="Ellipse 3259"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3703"
          data-name="Ellipse 3703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2341"
          data-name="Ellipse 2341"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6364"
          data-name="Ellipse 6364"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6642"
          data-name="Ellipse 6642"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7732"
          data-name="Ellipse 7732"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8122"
          data-name="Ellipse 8122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8512"
          data-name="Ellipse 8512"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8902"
          data-name="Ellipse 8902"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6992"
          data-name="Ellipse 6992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7733"
          data-name="Ellipse 7733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1265 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8123"
          data-name="Ellipse 8123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1305 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8513"
          data-name="Ellipse 8513"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1345 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8903"
          data-name="Ellipse 8903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9001"
          data-name="Ellipse 9001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1453 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3054"
          data-name="Ellipse 3054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(936 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2851"
          data-name="Ellipse 2851"
          cx="1"
          cy="1"
          r="1"
          transform="translate(856 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3705"
          data-name="Ellipse 3705"
          cx="1"
          cy="1"
          r="1"
          transform="translate(808 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1667"
          data-name="Ellipse 1667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1716"
          data-name="Ellipse 1716"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1863"
          data-name="Ellipse 1863"
          cx="1"
          cy="1"
          r="1"
          transform="translate(964 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_759"
          data-name="Ellipse 759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1369"
          data-name="Ellipse 1369"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1416"
          data-name="Ellipse 1416"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1465"
          data-name="Ellipse 1465"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1531"
          data-name="Ellipse 1531"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1627"
          data-name="Ellipse 1627"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1685"
          data-name="Ellipse 1685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2073"
          data-name="Ellipse 2073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6439"
          data-name="Ellipse 6439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6657"
          data-name="Ellipse 6657"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7048"
          data-name="Ellipse 7048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6840"
          data-name="Ellipse 6840"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7049"
          data-name="Ellipse 7049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2979"
          data-name="Ellipse 2979"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2980"
          data-name="Ellipse 2980"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3503"
          data-name="Ellipse 3503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2246"
          data-name="Ellipse 2246"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2981"
          data-name="Ellipse 2981"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4255"
          data-name="Ellipse 4255"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3504"
          data-name="Ellipse 3504"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2171"
          data-name="Ellipse 2171"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3201"
          data-name="Ellipse 3201"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2318"
          data-name="Ellipse 2318"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6295"
          data-name="Ellipse 6295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6611"
          data-name="Ellipse 6611"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7050"
          data-name="Ellipse 7050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2982"
          data-name="Ellipse 2982"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2852"
          data-name="Ellipse 2852"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3508"
          data-name="Ellipse 3508"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1733"
          data-name="Ellipse 1733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1831"
          data-name="Ellipse 1831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1490"
          data-name="Ellipse 1490"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1510"
          data-name="Ellipse 1510"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1628"
          data-name="Ellipse 1628"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2090"
          data-name="Ellipse 2090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6467"
          data-name="Ellipse 6467"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6691"
          data-name="Ellipse 6691"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7051"
          data-name="Ellipse 7051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6816"
          data-name="Ellipse 6816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7058"
          data-name="Ellipse 7058"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7274"
          data-name="Ellipse 7274"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2983"
          data-name="Ellipse 2983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2984"
          data-name="Ellipse 2984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2258"
          data-name="Ellipse 2258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2985"
          data-name="Ellipse 2985"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4276"
          data-name="Ellipse 4276"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4392"
          data-name="Ellipse 4392"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2185"
          data-name="Ellipse 2185"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3752"
          data-name="Ellipse 3752"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3772"
          data-name="Ellipse 3772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3792"
          data-name="Ellipse 3792"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3260"
          data-name="Ellipse 3260"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2342"
          data-name="Ellipse 2342"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6365"
          data-name="Ellipse 6365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6612"
          data-name="Ellipse 6612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7052"
          data-name="Ellipse 7052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2986"
          data-name="Ellipse 2986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(920 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2853"
          data-name="Ellipse 2853"
          cx="1"
          cy="1"
          r="1"
          transform="translate(840 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3514"
          data-name="Ellipse 3514"
          cx="1"
          cy="1"
          r="1"
          transform="translate(792 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1675"
          data-name="Ellipse 1675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1724"
          data-name="Ellipse 1724"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1788"
          data-name="Ellipse 1788"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1442"
          data-name="Ellipse 1442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1481"
          data-name="Ellipse 1481"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1536"
          data-name="Ellipse 1536"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1593"
          data-name="Ellipse 1593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1686"
          data-name="Ellipse 1686"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2074"
          data-name="Ellipse 2074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6440"
          data-name="Ellipse 6440"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6672"
          data-name="Ellipse 6672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7543"
          data-name="Ellipse 7543"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7933"
          data-name="Ellipse 7933"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8323"
          data-name="Ellipse 8323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8713"
          data-name="Ellipse 8713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6945"
          data-name="Ellipse 6945"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7544"
          data-name="Ellipse 7544"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7934"
          data-name="Ellipse 7934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8324"
          data-name="Ellipse 8324"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9085"
          data-name="Ellipse 9085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9140"
          data-name="Ellipse 9140"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8714"
          data-name="Ellipse 8714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7161"
          data-name="Ellipse 7161"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7935"
          data-name="Ellipse 7935"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8715"
          data-name="Ellipse 8715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6841"
          data-name="Ellipse 6841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7611"
          data-name="Ellipse 7611"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8001"
          data-name="Ellipse 8001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8391"
          data-name="Ellipse 8391"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8781"
          data-name="Ellipse 8781"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7026"
          data-name="Ellipse 7026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7546"
          data-name="Ellipse 7546"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7936"
          data-name="Ellipse 7936"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8326"
          data-name="Ellipse 8326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8716"
          data-name="Ellipse 8716"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7242"
          data-name="Ellipse 7242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7547"
          data-name="Ellipse 7547"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7937"
          data-name="Ellipse 7937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8327"
          data-name="Ellipse 8327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9086"
          data-name="Ellipse 9086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8717"
          data-name="Ellipse 8717"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8961"
          data-name="Ellipse 8961"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3142"
          data-name="Ellipse 3142"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2987"
          data-name="Ellipse 2987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2298"
          data-name="Ellipse 2298"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6366"
          data-name="Ellipse 6366"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3143"
          data-name="Ellipse 3143"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2557"
          data-name="Ellipse 2557"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2203"
          data-name="Ellipse 2203"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3203"
          data-name="Ellipse 3203"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3204"
          data-name="Ellipse 3204"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2319"
          data-name="Ellipse 2319"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6367"
          data-name="Ellipse 6367"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6673"
          data-name="Ellipse 6673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7548"
          data-name="Ellipse 7548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7938"
          data-name="Ellipse 7938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8328"
          data-name="Ellipse 8328"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8718"
          data-name="Ellipse 8718"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6946"
          data-name="Ellipse 6946"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7549"
          data-name="Ellipse 7549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7939"
          data-name="Ellipse 7939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8329"
          data-name="Ellipse 8329"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9087"
          data-name="Ellipse 9087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8719"
          data-name="Ellipse 8719"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8962"
          data-name="Ellipse 8962"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7940"
          data-name="Ellipse 7940"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 259)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3144"
          data-name="Ellipse 3144"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2854"
          data-name="Ellipse 2854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1734"
          data-name="Ellipse 1734"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1762"
          data-name="Ellipse 1762"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1880"
          data-name="Ellipse 1880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2091"
          data-name="Ellipse 2091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6468"
          data-name="Ellipse 6468"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6692"
          data-name="Ellipse 6692"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7120"
          data-name="Ellipse 7120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6829"
          data-name="Ellipse 6829"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7121"
          data-name="Ellipse 7121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2988"
          data-name="Ellipse 2988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2989"
          data-name="Ellipse 2989"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2259"
          data-name="Ellipse 2259"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2892"
          data-name="Ellipse 2892"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4277"
          data-name="Ellipse 4277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2186"
          data-name="Ellipse 2186"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3741"
          data-name="Ellipse 3741"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3262"
          data-name="Ellipse 3262"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3263"
          data-name="Ellipse 3263"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2343"
          data-name="Ellipse 2343"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6588"
          data-name="Ellipse 6588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7122"
          data-name="Ellipse 7122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2855"
          data-name="Ellipse 2855"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1924"
          data-name="Ellipse 1924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1834"
          data-name="Ellipse 1834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1881"
          data-name="Ellipse 1881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1925"
          data-name="Ellipse 1925"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2116"
          data-name="Ellipse 2116"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6521"
          data-name="Ellipse 6521"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6744"
          data-name="Ellipse 6744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7123"
          data-name="Ellipse 7123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6830"
          data-name="Ellipse 6830"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7124"
          data-name="Ellipse 7124"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7340"
          data-name="Ellipse 7340"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6854"
          data-name="Ellipse 6854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6896"
          data-name="Ellipse 6896"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3082"
          data-name="Ellipse 3082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3083"
          data-name="Ellipse 3083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2279"
          data-name="Ellipse 2279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2894"
          data-name="Ellipse 2894"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4310"
          data-name="Ellipse 4310"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4426"
          data-name="Ellipse 4426"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2761"
          data-name="Ellipse 2761"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2204"
          data-name="Ellipse 2204"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3747"
          data-name="Ellipse 3747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3328"
          data-name="Ellipse 3328"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2377"
          data-name="Ellipse 2377"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6605"
          data-name="Ellipse 6605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7125"
          data-name="Ellipse 7125"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2895"
          data-name="Ellipse 2895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2856"
          data-name="Ellipse 2856"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1559"
          data-name="Ellipse 1559"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1582"
          data-name="Ellipse 1582"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1687"
          data-name="Ellipse 1687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2146"
          data-name="Ellipse 2146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6498"
          data-name="Ellipse 6498"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6723"
          data-name="Ellipse 6723"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7551"
          data-name="Ellipse 7551"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7941"
          data-name="Ellipse 7941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8331"
          data-name="Ellipse 8331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6947"
          data-name="Ellipse 6947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7363"
          data-name="Ellipse 7363"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7753"
          data-name="Ellipse 7753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8143"
          data-name="Ellipse 8143"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9024"
          data-name="Ellipse 9024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8533"
          data-name="Ellipse 8533"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7364"
          data-name="Ellipse 7364"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7754"
          data-name="Ellipse 7754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6842"
          data-name="Ellipse 6842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7612"
          data-name="Ellipse 7612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8002"
          data-name="Ellipse 8002"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8392"
          data-name="Ellipse 8392"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7027"
          data-name="Ellipse 7027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7442"
          data-name="Ellipse 7442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7832"
          data-name="Ellipse 7832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8222"
          data-name="Ellipse 8222"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7243"
          data-name="Ellipse 7243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7365"
          data-name="Ellipse 7365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7755"
          data-name="Ellipse 7755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8145"
          data-name="Ellipse 8145"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9025"
          data-name="Ellipse 9025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8535"
          data-name="Ellipse 8535"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3291"
          data-name="Ellipse 3291"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3908"
          data-name="Ellipse 3908"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2690"
          data-name="Ellipse 2690"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2990"
          data-name="Ellipse 2990"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2378"
          data-name="Ellipse 2378"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6499"
          data-name="Ellipse 6499"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3909"
          data-name="Ellipse 3909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3292"
          data-name="Ellipse 3292"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2691"
          data-name="Ellipse 2691"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2857"
          data-name="Ellipse 2857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4256"
          data-name="Ellipse 4256"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3293"
          data-name="Ellipse 3293"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3911"
          data-name="Ellipse 3911"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3205"
          data-name="Ellipse 3205"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2379"
          data-name="Ellipse 2379"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6500"
          data-name="Ellipse 6500"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6724"
          data-name="Ellipse 6724"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7472"
          data-name="Ellipse 7472"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7862"
          data-name="Ellipse 7862"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8252"
          data-name="Ellipse 8252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6915"
          data-name="Ellipse 6915"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7366"
          data-name="Ellipse 7366"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7756"
          data-name="Ellipse 7756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8146"
          data-name="Ellipse 8146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9026"
          data-name="Ellipse 9026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8536"
          data-name="Ellipse 8536"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8920"
          data-name="Ellipse 8920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1485 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3912"
          data-name="Ellipse 3912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3294"
          data-name="Ellipse 3294"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3913"
          data-name="Ellipse 3913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2693"
          data-name="Ellipse 2693"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2858"
          data-name="Ellipse 2858"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1494"
          data-name="Ellipse 1494"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1537"
          data-name="Ellipse 1537"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1594"
          data-name="Ellipse 1594"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2092"
          data-name="Ellipse 2092"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6469"
          data-name="Ellipse 6469"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6693"
          data-name="Ellipse 6693"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7552"
          data-name="Ellipse 7552"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7942"
          data-name="Ellipse 7942"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8332"
          data-name="Ellipse 8332"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8722"
          data-name="Ellipse 8722"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6963"
          data-name="Ellipse 6963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7553"
          data-name="Ellipse 7553"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7943"
          data-name="Ellipse 7943"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8333"
          data-name="Ellipse 8333"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9088"
          data-name="Ellipse 9088"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9141"
          data-name="Ellipse 9141"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8723"
          data-name="Ellipse 8723"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7179"
          data-name="Ellipse 7179"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7554"
          data-name="Ellipse 7554"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7944"
          data-name="Ellipse 7944"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8334"
          data-name="Ellipse 8334"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8724"
          data-name="Ellipse 8724"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6817"
          data-name="Ellipse 6817"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7619"
          data-name="Ellipse 7619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8009"
          data-name="Ellipse 8009"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8399"
          data-name="Ellipse 8399"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7059"
          data-name="Ellipse 7059"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7555"
          data-name="Ellipse 7555"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7945"
          data-name="Ellipse 7945"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8335"
          data-name="Ellipse 8335"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8725"
          data-name="Ellipse 8725"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7275"
          data-name="Ellipse 7275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7556"
          data-name="Ellipse 7556"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7946"
          data-name="Ellipse 7946"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8336"
          data-name="Ellipse 8336"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9089"
          data-name="Ellipse 9089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8726"
          data-name="Ellipse 8726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8964"
          data-name="Ellipse 8964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2991"
          data-name="Ellipse 2991"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2299"
          data-name="Ellipse 2299"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6370"
          data-name="Ellipse 6370"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3146"
          data-name="Ellipse 3146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4311"
          data-name="Ellipse 4311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2560"
          data-name="Ellipse 2560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2205"
          data-name="Ellipse 2205"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3264"
          data-name="Ellipse 3264"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6371"
          data-name="Ellipse 6371"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9235"
          data-name="Ellipse 9235"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9251"
          data-name="Ellipse 9251"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9267"
          data-name="Ellipse 9267"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9283"
          data-name="Ellipse 9283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3265"
          data-name="Ellipse 3265"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2344"
          data-name="Ellipse 2344"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6372"
          data-name="Ellipse 6372"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6674"
          data-name="Ellipse 6674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7557"
          data-name="Ellipse 7557"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7947"
          data-name="Ellipse 7947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8337"
          data-name="Ellipse 8337"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8727"
          data-name="Ellipse 8727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6948"
          data-name="Ellipse 6948"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1237 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7558"
          data-name="Ellipse 7558"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1277 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7948"
          data-name="Ellipse 7948"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8338"
          data-name="Ellipse 8338"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9090"
          data-name="Ellipse 9090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8728"
          data-name="Ellipse 8728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8965"
          data-name="Ellipse 8965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1465 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7949"
          data-name="Ellipse 7949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1317 263)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3147"
          data-name="Ellipse 3147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(948 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2859"
          data-name="Ellipse 2859"
          cx="1"
          cy="1"
          r="1"
          transform="translate(868 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1663"
          data-name="Ellipse 1663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1710"
          data-name="Ellipse 1710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1020 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1745"
          data-name="Ellipse 1745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1882"
          data-name="Ellipse 1882"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2054"
          data-name="Ellipse 2054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6409"
          data-name="Ellipse 6409"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6629"
          data-name="Ellipse 6629"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7126"
          data-name="Ellipse 7126"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2920"
          data-name="Ellipse 2920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2921"
          data-name="Ellipse 2921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2237"
          data-name="Ellipse 2237"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2896"
          data-name="Ellipse 2896"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4244"
          data-name="Ellipse 4244"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2158"
          data-name="Ellipse 2158"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6562"
          data-name="Ellipse 6562"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6774"
          data-name="Ellipse 6774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7127"
          data-name="Ellipse 7127"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6831"
          data-name="Ellipse 6831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6897"
          data-name="Ellipse 6897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6877"
          data-name="Ellipse 6877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6902"
          data-name="Ellipse 6902"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3148"
          data-name="Ellipse 3148"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3149"
          data-name="Ellipse 3149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2300"
          data-name="Ellipse 2300"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6589"
          data-name="Ellipse 6589"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7128"
          data-name="Ellipse 7128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2897"
          data-name="Ellipse 2897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(904 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2796"
          data-name="Ellipse 2796"
          cx="1"
          cy="1"
          r="1"
          transform="translate(824 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1926"
          data-name="Ellipse 1926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1790"
          data-name="Ellipse 1790"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1937"
          data-name="Ellipse 1937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1558"
          data-name="Ellipse 1558"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1577"
          data-name="Ellipse 1577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2147"
          data-name="Ellipse 2147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6501"
          data-name="Ellipse 6501"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6725"
          data-name="Ellipse 6725"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7560"
          data-name="Ellipse 7560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7950"
          data-name="Ellipse 7950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8340"
          data-name="Ellipse 8340"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6964"
          data-name="Ellipse 6964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7406"
          data-name="Ellipse 7406"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7796"
          data-name="Ellipse 7796"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8186"
          data-name="Ellipse 8186"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9027"
          data-name="Ellipse 9027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8576"
          data-name="Ellipse 8576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7180"
          data-name="Ellipse 7180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7368"
          data-name="Ellipse 7368"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7758"
          data-name="Ellipse 7758"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6818"
          data-name="Ellipse 6818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7620"
          data-name="Ellipse 7620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8010"
          data-name="Ellipse 8010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8400"
          data-name="Ellipse 8400"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7060"
          data-name="Ellipse 7060"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7443"
          data-name="Ellipse 7443"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7833"
          data-name="Ellipse 7833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8223"
          data-name="Ellipse 8223"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7276"
          data-name="Ellipse 7276"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7369"
          data-name="Ellipse 7369"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7759"
          data-name="Ellipse 7759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8149"
          data-name="Ellipse 8149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9028"
          data-name="Ellipse 9028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8539"
          data-name="Ellipse 8539"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3295"
          data-name="Ellipse 3295"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3915"
          data-name="Ellipse 3915"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2694"
          data-name="Ellipse 2694"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2992"
          data-name="Ellipse 2992"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2380"
          data-name="Ellipse 2380"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6502"
          data-name="Ellipse 6502"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3916"
          data-name="Ellipse 3916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3296"
          data-name="Ellipse 3296"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2695"
          data-name="Ellipse 2695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2860"
          data-name="Ellipse 2860"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4278"
          data-name="Ellipse 4278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3918"
          data-name="Ellipse 3918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3266"
          data-name="Ellipse 3266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2381"
          data-name="Ellipse 2381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6503"
          data-name="Ellipse 6503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6726"
          data-name="Ellipse 6726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7473"
          data-name="Ellipse 7473"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7863"
          data-name="Ellipse 7863"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8253"
          data-name="Ellipse 8253"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6920"
          data-name="Ellipse 6920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1257 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7370"
          data-name="Ellipse 7370"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1297 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7760"
          data-name="Ellipse 7760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1337 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8150"
          data-name="Ellipse 8150"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9029"
          data-name="Ellipse 9029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1445 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8540"
          data-name="Ellipse 8540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8934"
          data-name="Ellipse 8934"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1485 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3919"
          data-name="Ellipse 3919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3920"
          data-name="Ellipse 3920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(992 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2697"
          data-name="Ellipse 2697"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2861"
          data-name="Ellipse 2861"
          cx="1"
          cy="1"
          r="1"
          transform="translate(888 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1647"
          data-name="Ellipse 1647"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1040 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1639"
          data-name="Ellipse 1639"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1052 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_586"
          data-name="Ellipse 586"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 476)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_517"
          data-name="Ellipse 517"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6081"
          data-name="Ellipse 6081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4682"
          data-name="Ellipse 4682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5764"
          data-name="Ellipse 5764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1159 250)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1168"
          data-name="Ellipse 1168"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6082"
          data-name="Ellipse 6082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4683"
          data-name="Ellipse 4683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5205"
          data-name="Ellipse 5205"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_959"
          data-name="Ellipse 959"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6083"
          data-name="Ellipse 6083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4684"
          data-name="Ellipse 4684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1141"
          data-name="Ellipse 1141"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6084"
          data-name="Ellipse 6084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4685"
          data-name="Ellipse 4685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5207"
          data-name="Ellipse 5207"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_918"
          data-name="Ellipse 918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6085"
          data-name="Ellipse 6085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4686"
          data-name="Ellipse 4686"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1142"
          data-name="Ellipse 1142"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6086"
          data-name="Ellipse 6086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4687"
          data-name="Ellipse 4687"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5209"
          data-name="Ellipse 5209"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_946"
          data-name="Ellipse 946"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6087"
          data-name="Ellipse 6087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4688"
          data-name="Ellipse 4688"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1143"
          data-name="Ellipse 1143"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6088"
          data-name="Ellipse 6088"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5100"
          data-name="Ellipse 5100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_788"
          data-name="Ellipse 788"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5912"
          data-name="Ellipse 5912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_988"
          data-name="Ellipse 988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5876"
          data-name="Ellipse 5876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1041"
          data-name="Ellipse 1041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5812"
          data-name="Ellipse 5812"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1073"
          data-name="Ellipse 1073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1086"
          data-name="Ellipse 1086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1294"
          data-name="Ellipse 1294"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1097 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1100"
          data-name="Ellipse 1100"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_933"
          data-name="Ellipse 933"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5877"
          data-name="Ellipse 5877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1049"
          data-name="Ellipse 1049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5859"
          data-name="Ellipse 5859"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_989"
          data-name="Ellipse 989"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5860"
          data-name="Ellipse 5860"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1031"
          data-name="Ellipse 1031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5814"
          data-name="Ellipse 5814"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1062"
          data-name="Ellipse 1062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1079"
          data-name="Ellipse 1079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_760"
          data-name="Ellipse 760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6005"
          data-name="Ellipse 6005"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4661"
          data-name="Ellipse 4661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1169"
          data-name="Ellipse 1169"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6006"
          data-name="Ellipse 6006"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4609"
          data-name="Ellipse 4609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5211"
          data-name="Ellipse 5211"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_960"
          data-name="Ellipse 960"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6007"
          data-name="Ellipse 6007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4610"
          data-name="Ellipse 4610"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5387"
          data-name="Ellipse 5387"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1124"
          data-name="Ellipse 1124"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6008"
          data-name="Ellipse 6008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4611"
          data-name="Ellipse 4611"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5213"
          data-name="Ellipse 5213"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_922"
          data-name="Ellipse 922"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6009"
          data-name="Ellipse 6009"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4626"
          data-name="Ellipse 4626"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1133"
          data-name="Ellipse 1133"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6010"
          data-name="Ellipse 6010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4612"
          data-name="Ellipse 4612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5215"
          data-name="Ellipse 5215"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_950"
          data-name="Ellipse 950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6011"
          data-name="Ellipse 6011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4613"
          data-name="Ellipse 4613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5347"
          data-name="Ellipse 5347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1125"
          data-name="Ellipse 1125"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6012"
          data-name="Ellipse 6012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5102"
          data-name="Ellipse 5102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_571"
          data-name="Ellipse 571"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1170"
          data-name="Ellipse 1170"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4784"
          data-name="Ellipse 4784"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1094 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_961"
          data-name="Ellipse 961"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4785"
          data-name="Ellipse 4785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1094 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1156"
          data-name="Ellipse 1156"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_912"
          data-name="Ellipse 912"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1157"
          data-name="Ellipse 1157"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_943"
          data-name="Ellipse 943"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4789"
          data-name="Ellipse 4789"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1094 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1158"
          data-name="Ellipse 1158"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_897"
          data-name="Ellipse 897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_909"
          data-name="Ellipse 909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_816"
          data-name="Ellipse 816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5913"
          data-name="Ellipse 5913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4662"
          data-name="Ellipse 4662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5471"
          data-name="Ellipse 5471"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 300)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_978"
          data-name="Ellipse 978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5878"
          data-name="Ellipse 5878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4602"
          data-name="Ellipse 4602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5391"
          data-name="Ellipse 5391"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1042"
          data-name="Ellipse 1042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5879"
          data-name="Ellipse 5879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4505"
          data-name="Ellipse 4505"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5224"
          data-name="Ellipse 5224"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1076"
          data-name="Ellipse 1076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5880"
          data-name="Ellipse 5880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5226"
          data-name="Ellipse 5226"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1091"
          data-name="Ellipse 1091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5881"
          data-name="Ellipse 5881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5105"
          data-name="Ellipse 5105"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1223"
          data-name="Ellipse 1223"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5882"
          data-name="Ellipse 5882"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5107"
          data-name="Ellipse 5107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1228"
          data-name="Ellipse 1228"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5914"
          data-name="Ellipse 5914"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5109"
          data-name="Ellipse 5109"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1233"
          data-name="Ellipse 1233"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5941"
          data-name="Ellipse 5941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5111"
          data-name="Ellipse 5111"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1238"
          data-name="Ellipse 1238"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5971"
          data-name="Ellipse 5971"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5113"
          data-name="Ellipse 5113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1243"
          data-name="Ellipse 1243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5994"
          data-name="Ellipse 5994"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5115"
          data-name="Ellipse 5115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1248"
          data-name="Ellipse 1248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6013"
          data-name="Ellipse 6013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5117"
          data-name="Ellipse 5117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1253"
          data-name="Ellipse 1253"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6016"
          data-name="Ellipse 6016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5119"
          data-name="Ellipse 5119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1258"
          data-name="Ellipse 1258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6049"
          data-name="Ellipse 6049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5121"
          data-name="Ellipse 5121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5788"
          data-name="Ellipse 5788"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1151 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1263"
          data-name="Ellipse 1263"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6071"
          data-name="Ellipse 6071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5123"
          data-name="Ellipse 5123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5789"
          data-name="Ellipse 5789"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1155 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1268"
          data-name="Ellipse 1268"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5125"
          data-name="Ellipse 5125"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5790"
          data-name="Ellipse 5790"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1159 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1273"
          data-name="Ellipse 1273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5127"
          data-name="Ellipse 5127"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5791"
          data-name="Ellipse 5791"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1163 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1278"
          data-name="Ellipse 1278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5128"
          data-name="Ellipse 5128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5792"
          data-name="Ellipse 5792"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1167 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1283"
          data-name="Ellipse 1283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5129"
          data-name="Ellipse 5129"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5793"
          data-name="Ellipse 5793"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1171 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1288"
          data-name="Ellipse 1288"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1103"
          data-name="Ellipse 1103"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1221"
          data-name="Ellipse 1221"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1226"
          data-name="Ellipse 1226"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1231"
          data-name="Ellipse 1231"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1236"
          data-name="Ellipse 1236"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1241"
          data-name="Ellipse 1241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1246"
          data-name="Ellipse 1246"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1261"
          data-name="Ellipse 1261"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1266"
          data-name="Ellipse 1266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6904"
          data-name="Ellipse 6904"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1271"
          data-name="Ellipse 1271"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6905"
          data-name="Ellipse 6905"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1113"
          data-name="Ellipse 1113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1224"
          data-name="Ellipse 1224"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5916"
          data-name="Ellipse 5916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6155"
          data-name="Ellipse 6155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6143"
          data-name="Ellipse 6143"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6156"
          data-name="Ellipse 6156"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6149"
          data-name="Ellipse 6149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6142"
          data-name="Ellipse 6142"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1229"
          data-name="Ellipse 1229"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5943"
          data-name="Ellipse 5943"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6157"
          data-name="Ellipse 6157"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6144"
          data-name="Ellipse 6144"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6158"
          data-name="Ellipse 6158"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6150"
          data-name="Ellipse 6150"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1234"
          data-name="Ellipse 1234"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5973"
          data-name="Ellipse 5973"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6159"
          data-name="Ellipse 6159"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6145"
          data-name="Ellipse 6145"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6160"
          data-name="Ellipse 6160"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6151"
          data-name="Ellipse 6151"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6174"
          data-name="Ellipse 6174"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6179"
          data-name="Ellipse 6179"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1425 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10082"
          data-name="Ellipse 10082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_928"
          data-name="Ellipse 928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5887"
          data-name="Ellipse 5887"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4628"
          data-name="Ellipse 4628"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5431"
          data-name="Ellipse 5431"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1050"
          data-name="Ellipse 1050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5888"
          data-name="Ellipse 5888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4555"
          data-name="Ellipse 4555"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5311"
          data-name="Ellipse 5311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_979"
          data-name="Ellipse 979"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5889"
          data-name="Ellipse 5889"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4556"
          data-name="Ellipse 4556"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5351"
          data-name="Ellipse 5351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1036"
          data-name="Ellipse 1036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5890"
          data-name="Ellipse 5890"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4516"
          data-name="Ellipse 4516"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5232"
          data-name="Ellipse 5232"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1077"
          data-name="Ellipse 1077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5891"
          data-name="Ellipse 5891"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5131"
          data-name="Ellipse 5131"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1092"
          data-name="Ellipse 1092"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5892"
          data-name="Ellipse 5892"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5055"
          data-name="Ellipse 5055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1034 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1222"
          data-name="Ellipse 1222"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5893"
          data-name="Ellipse 5893"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5057"
          data-name="Ellipse 5057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1227"
          data-name="Ellipse 1227"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5917"
          data-name="Ellipse 5917"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5059"
          data-name="Ellipse 5059"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1232"
          data-name="Ellipse 1232"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5944"
          data-name="Ellipse 5944"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5061"
          data-name="Ellipse 5061"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1237"
          data-name="Ellipse 1237"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5974"
          data-name="Ellipse 5974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5063"
          data-name="Ellipse 5063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1050 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1242"
          data-name="Ellipse 1242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1141 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5065"
          data-name="Ellipse 5065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1054 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1247"
          data-name="Ellipse 1247"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5067"
          data-name="Ellipse 5067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1058 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5785"
          data-name="Ellipse 5785"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1143 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1252"
          data-name="Ellipse 1252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5069"
          data-name="Ellipse 5069"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5778"
          data-name="Ellipse 5778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1147 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1257"
          data-name="Ellipse 1257"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1153 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6050"
          data-name="Ellipse 6050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5070"
          data-name="Ellipse 5070"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1066 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5777"
          data-name="Ellipse 5777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1151 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1262"
          data-name="Ellipse 1262"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5071"
          data-name="Ellipse 5071"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1070 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5773"
          data-name="Ellipse 5773"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1155 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1267"
          data-name="Ellipse 1267"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5072"
          data-name="Ellipse 5072"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1074 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5765"
          data-name="Ellipse 5765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1159 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1272"
          data-name="Ellipse 1272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5073"
          data-name="Ellipse 5073"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5766"
          data-name="Ellipse 5766"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1163 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1277"
          data-name="Ellipse 1277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6906"
          data-name="Ellipse 6906"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5074"
          data-name="Ellipse 5074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5767"
          data-name="Ellipse 5767"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1167 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1282"
          data-name="Ellipse 1282"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5075"
          data-name="Ellipse 5075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1086 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5768"
          data-name="Ellipse 5768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1171 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1287"
          data-name="Ellipse 1287"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5076"
          data-name="Ellipse 5076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1090 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1104"
          data-name="Ellipse 1104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1121 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1220"
          data-name="Ellipse 1220"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1225"
          data-name="Ellipse 1225"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5918"
          data-name="Ellipse 5918"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6146"
          data-name="Ellipse 6146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6163"
          data-name="Ellipse 6163"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6152"
          data-name="Ellipse 6152"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6164"
          data-name="Ellipse 6164"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6178"
          data-name="Ellipse 6178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1230"
          data-name="Ellipse 1230"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5945"
          data-name="Ellipse 5945"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6165"
          data-name="Ellipse 6165"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6147"
          data-name="Ellipse 6147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6166"
          data-name="Ellipse 6166"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1393 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6153"
          data-name="Ellipse 6153"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6170"
          data-name="Ellipse 6170"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6176"
          data-name="Ellipse 6176"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1235"
          data-name="Ellipse 1235"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1137 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5975"
          data-name="Ellipse 5975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6167"
          data-name="Ellipse 6167"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1385 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6148"
          data-name="Ellipse 6148"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6168"
          data-name="Ellipse 6168"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6154"
          data-name="Ellipse 6154"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6181"
          data-name="Ellipse 6181"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6172"
          data-name="Ellipse 6172"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6173"
          data-name="Ellipse 6173"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6180"
          data-name="Ellipse 6180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10083"
          data-name="Ellipse 10083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_437"
          data-name="Ellipse 437"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_441"
          data-name="Ellipse 441"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_476"
          data-name="Ellipse 476"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_763"
          data-name="Ellipse 763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1327"
          data-name="Ellipse 1327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 562)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1381"
          data-name="Ellipse 1381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 570)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1389"
          data-name="Ellipse 1389"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 578)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_546"
          data-name="Ellipse 546"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_764"
          data-name="Ellipse 764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_587"
          data-name="Ellipse 587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 472)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_493"
          data-name="Ellipse 493"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_765"
          data-name="Ellipse 765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1354"
          data-name="Ellipse 1354"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 542)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_588"
          data-name="Ellipse 588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_521"
          data-name="Ellipse 521"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_766"
          data-name="Ellipse 766"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6018"
          data-name="Ellipse 6018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_589"
          data-name="Ellipse 589"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_884"
          data-name="Ellipse 884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_456"
          data-name="Ellipse 456"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_464"
          data-name="Ellipse 464"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_768"
          data-name="Ellipse 768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1337"
          data-name="Ellipse 1337"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 554)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_625"
          data-name="Ellipse 625"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 500)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_656"
          data-name="Ellipse 656"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1207 500)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_530"
          data-name="Ellipse 530"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_769"
          data-name="Ellipse 769"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_590"
          data-name="Ellipse 590"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 464)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_487"
          data-name="Ellipse 487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_770"
          data-name="Ellipse 770"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1365"
          data-name="Ellipse 1365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1434"
          data-name="Ellipse 1434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 534)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1423"
          data-name="Ellipse 1423"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1478"
          data-name="Ellipse 1478"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1548"
          data-name="Ellipse 1548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1621"
          data-name="Ellipse 1621"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1504"
          data-name="Ellipse 1504"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1522"
          data-name="Ellipse 1522"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1622"
          data-name="Ellipse 1622"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2117"
          data-name="Ellipse 2117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6745"
          data-name="Ellipse 6745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7033"
          data-name="Ellipse 7033"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6806"
          data-name="Ellipse 6806"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7109"
          data-name="Ellipse 7109"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7325"
          data-name="Ellipse 7325"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6855"
          data-name="Ellipse 6855"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3084"
          data-name="Ellipse 3084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3085"
          data-name="Ellipse 3085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2280"
          data-name="Ellipse 2280"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6300"
          data-name="Ellipse 6300"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2993"
          data-name="Ellipse 2993"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4312"
          data-name="Ellipse 4312"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4428"
          data-name="Ellipse 4428"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2763"
          data-name="Ellipse 2763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2206"
          data-name="Ellipse 2206"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3753"
          data-name="Ellipse 3753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3773"
          data-name="Ellipse 3773"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 418)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3793"
          data-name="Ellipse 3793"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 426)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3330"
          data-name="Ellipse 3330"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3538"
          data-name="Ellipse 3538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2382"
          data-name="Ellipse 2382"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6374"
          data-name="Ellipse 6374"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6613"
          data-name="Ellipse 6613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7034"
          data-name="Ellipse 7034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2994"
          data-name="Ellipse 2994"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2862"
          data-name="Ellipse 2862"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3540"
          data-name="Ellipse 3540"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1672"
          data-name="Ellipse 1672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2055"
          data-name="Ellipse 2055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6410"
          data-name="Ellipse 6410"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6630"
          data-name="Ellipse 6630"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7035"
          data-name="Ellipse 7035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2995"
          data-name="Ellipse 2995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2922"
          data-name="Ellipse 2922"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3542"
          data-name="Ellipse 3542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2247"
          data-name="Ellipse 2247"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2996"
          data-name="Ellipse 2996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4257"
          data-name="Ellipse 4257"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3543"
          data-name="Ellipse 3543"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2159"
          data-name="Ellipse 2159"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6775"
          data-name="Ellipse 6775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7036"
          data-name="Ellipse 7036"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6807"
          data-name="Ellipse 6807"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6878"
          data-name="Ellipse 6878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6911"
          data-name="Ellipse 6911"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3150"
          data-name="Ellipse 3150"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3151"
          data-name="Ellipse 3151"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2301"
          data-name="Ellipse 2301"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6303"
          data-name="Ellipse 6303"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6614"
          data-name="Ellipse 6614"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7037"
          data-name="Ellipse 7037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2997"
          data-name="Ellipse 2997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2797"
          data-name="Ellipse 2797"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1721"
          data-name="Ellipse 1721"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1816"
          data-name="Ellipse 1816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1429"
          data-name="Ellipse 1429"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 526)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1479"
          data-name="Ellipse 1479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 518)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1549"
          data-name="Ellipse 1549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1633"
          data-name="Ellipse 1633"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1505"
          data-name="Ellipse 1505"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 510)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1523"
          data-name="Ellipse 1523"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 494)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1634"
          data-name="Ellipse 1634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2118"
          data-name="Ellipse 2118"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6523"
          data-name="Ellipse 6523"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6746"
          data-name="Ellipse 6746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7078"
          data-name="Ellipse 7078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6808"
          data-name="Ellipse 6808"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7110"
          data-name="Ellipse 7110"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7326"
          data-name="Ellipse 7326"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6891"
          data-name="Ellipse 6891"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6856"
          data-name="Ellipse 6856"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6898"
          data-name="Ellipse 6898"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3086"
          data-name="Ellipse 3086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3087"
          data-name="Ellipse 3087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2281"
          data-name="Ellipse 2281"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2998"
          data-name="Ellipse 2998"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4313"
          data-name="Ellipse 4313"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4429"
          data-name="Ellipse 4429"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2764"
          data-name="Ellipse 2764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2207"
          data-name="Ellipse 2207"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3748"
          data-name="Ellipse 3748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3332"
          data-name="Ellipse 3332"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2383"
          data-name="Ellipse 2383"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6606"
          data-name="Ellipse 6606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7079"
          data-name="Ellipse 7079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2999"
          data-name="Ellipse 2999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2863"
          data-name="Ellipse 2863"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1694"
          data-name="Ellipse 1694"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2056"
          data-name="Ellipse 2056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6411"
          data-name="Ellipse 6411"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6631"
          data-name="Ellipse 6631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 367)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7080"
          data-name="Ellipse 7080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3000"
          data-name="Ellipse 3000"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2923"
          data-name="Ellipse 2923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2238"
          data-name="Ellipse 2238"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3001"
          data-name="Ellipse 3001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4248"
          data-name="Ellipse 4248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2160"
          data-name="Ellipse 2160"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6564"
          data-name="Ellipse 6564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6776"
          data-name="Ellipse 6776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7081"
          data-name="Ellipse 7081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 335)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6809"
          data-name="Ellipse 6809"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6899"
          data-name="Ellipse 6899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6879"
          data-name="Ellipse 6879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6903"
          data-name="Ellipse 6903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3152"
          data-name="Ellipse 3152"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3153"
          data-name="Ellipse 3153"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2302"
          data-name="Ellipse 2302"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6594"
          data-name="Ellipse 6594"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 351)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7082"
          data-name="Ellipse 7082"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3002"
          data-name="Ellipse 3002"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2798"
          data-name="Ellipse 2798"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1742"
          data-name="Ellipse 1742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 454)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1818"
          data-name="Ellipse 1818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 442)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_591"
          data-name="Ellipse 591"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 480)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_522"
          data-name="Ellipse 522"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6097"
          data-name="Ellipse 6097"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4708"
          data-name="Ellipse 4708"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5756"
          data-name="Ellipse 5756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1163 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1191"
          data-name="Ellipse 1191"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6098"
          data-name="Ellipse 6098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4709"
          data-name="Ellipse 4709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_800"
          data-name="Ellipse 800"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5937"
          data-name="Ellipse 5937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4710"
          data-name="Ellipse 4710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5588"
          data-name="Ellipse 5588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5620"
          data-name="Ellipse 5620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5652"
          data-name="Ellipse 5652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5684"
          data-name="Ellipse 5684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(954 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_771"
          data-name="Ellipse 771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6019"
          data-name="Ellipse 6019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4711"
          data-name="Ellipse 4711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1192"
          data-name="Ellipse 1192"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6020"
          data-name="Ellipse 6020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4630"
          data-name="Ellipse 4630"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5315"
          data-name="Ellipse 5315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_592"
          data-name="Ellipse 592"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1193"
          data-name="Ellipse 1193"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_885"
          data-name="Ellipse 885"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_832"
          data-name="Ellipse 832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4712"
          data-name="Ellipse 4712"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5519"
          data-name="Ellipse 5519"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 304)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1194"
          data-name="Ellipse 1194"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5896"
          data-name="Ellipse 5896"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4559"
          data-name="Ellipse 4559"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5317"
          data-name="Ellipse 5317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_446"
          data-name="Ellipse 446"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_469"
          data-name="Ellipse 469"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_773"
          data-name="Ellipse 773"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1332"
          data-name="Ellipse 1332"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 558)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_626"
          data-name="Ellipse 626"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 504)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_662"
          data-name="Ellipse 662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1207 504)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_541"
          data-name="Ellipse 541"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_774"
          data-name="Ellipse 774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_593"
          data-name="Ellipse 593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 468)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_488"
          data-name="Ellipse 488"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_775"
          data-name="Ellipse 775"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1360"
          data-name="Ellipse 1360"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 538)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_594"
          data-name="Ellipse 594"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 484)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_523"
          data-name="Ellipse 523"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6099"
          data-name="Ellipse 6099"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4759"
          data-name="Ellipse 4759"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5760"
          data-name="Ellipse 5760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1163 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_817"
          data-name="Ellipse 817"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4760"
          data-name="Ellipse 4760"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5592"
          data-name="Ellipse 5592"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_776"
          data-name="Ellipse 776"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6021"
          data-name="Ellipse 6021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4761"
          data-name="Ellipse 4761"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5780"
          data-name="Ellipse 5780"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1147 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_595"
          data-name="Ellipse 595"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_886"
          data-name="Ellipse 886"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_833"
          data-name="Ellipse 833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4762"
          data-name="Ellipse 4762"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5559"
          data-name="Ellipse 5559"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 308)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_451"
          data-name="Ellipse 451"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_497"
          data-name="Ellipse 497"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_778"
          data-name="Ellipse 778"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1348"
          data-name="Ellipse 1348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 546)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_627"
          data-name="Ellipse 627"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_653"
          data-name="Ellipse 653"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1207 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_666"
          data-name="Ellipse 666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1207 484)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_524"
          data-name="Ellipse 524"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_779"
          data-name="Ellipse 779"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_596"
          data-name="Ellipse 596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_465"
          data-name="Ellipse 465"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_780"
          data-name="Ellipse 780"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1342"
          data-name="Ellipse 1342"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 550)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1395"
          data-name="Ellipse 1395"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 550)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_628"
          data-name="Ellipse 628"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 496)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_654"
          data-name="Ellipse 654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1207 496)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_669"
          data-name="Ellipse 669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1207 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_525"
          data-name="Ellipse 525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_781"
          data-name="Ellipse 781"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_597"
          data-name="Ellipse 597"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 460)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_489"
          data-name="Ellipse 489"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_782"
          data-name="Ellipse 782"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1370"
          data-name="Ellipse 1370"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1432"
          data-name="Ellipse 1432"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1461"
          data-name="Ellipse 1461"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 530)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1417"
          data-name="Ellipse 1417"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1466"
          data-name="Ellipse 1466"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1532"
          data-name="Ellipse 1532"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1623"
          data-name="Ellipse 1623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1688"
          data-name="Ellipse 1688"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2075"
          data-name="Ellipse 2075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6442"
          data-name="Ellipse 6442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6659"
          data-name="Ellipse 6659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7038"
          data-name="Ellipse 7038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6843"
          data-name="Ellipse 6843"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7039"
          data-name="Ellipse 7039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7255"
          data-name="Ellipse 7255"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3003"
          data-name="Ellipse 3003"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3004"
          data-name="Ellipse 3004"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3549"
          data-name="Ellipse 3549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2248"
          data-name="Ellipse 2248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3005"
          data-name="Ellipse 3005"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4258"
          data-name="Ellipse 4258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3550"
          data-name="Ellipse 3550"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2172"
          data-name="Ellipse 2172"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3206"
          data-name="Ellipse 3206"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2320"
          data-name="Ellipse 2320"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6306"
          data-name="Ellipse 6306"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6615"
          data-name="Ellipse 6615"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7040"
          data-name="Ellipse 7040"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3006"
          data-name="Ellipse 3006"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2864"
          data-name="Ellipse 2864"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3554"
          data-name="Ellipse 3554"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1736"
          data-name="Ellipse 1736"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1835"
          data-name="Ellipse 1835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1491"
          data-name="Ellipse 1491"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1511"
          data-name="Ellipse 1511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1624"
          data-name="Ellipse 1624"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2093"
          data-name="Ellipse 2093"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6471"
          data-name="Ellipse 6471"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6695"
          data-name="Ellipse 6695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7041"
          data-name="Ellipse 7041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6819"
          data-name="Ellipse 6819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7061"
          data-name="Ellipse 7061"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7277"
          data-name="Ellipse 7277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3007"
          data-name="Ellipse 3007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3008"
          data-name="Ellipse 3008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2260"
          data-name="Ellipse 2260"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3009"
          data-name="Ellipse 3009"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4279"
          data-name="Ellipse 4279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4395"
          data-name="Ellipse 4395"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2187"
          data-name="Ellipse 2187"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3754"
          data-name="Ellipse 3754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3774"
          data-name="Ellipse 3774"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3794"
          data-name="Ellipse 3794"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 422)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3267"
          data-name="Ellipse 3267"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2345"
          data-name="Ellipse 2345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6376"
          data-name="Ellipse 6376"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6616"
          data-name="Ellipse 6616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7042"
          data-name="Ellipse 7042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3010"
          data-name="Ellipse 3010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(924 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2865"
          data-name="Ellipse 2865"
          cx="1"
          cy="1"
          r="1"
          transform="translate(844 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3560"
          data-name="Ellipse 3560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(796 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1673"
          data-name="Ellipse 1673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1722"
          data-name="Ellipse 1722"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1791"
          data-name="Ellipse 1791"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1932"
          data-name="Ellipse 1932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1443"
          data-name="Ellipse 1443"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1482"
          data-name="Ellipse 1482"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1550"
          data-name="Ellipse 1550"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1590"
          data-name="Ellipse 1590"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1689"
          data-name="Ellipse 1689"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2076"
          data-name="Ellipse 2076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6443"
          data-name="Ellipse 6443"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6678"
          data-name="Ellipse 6678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7561"
          data-name="Ellipse 7561"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7951"
          data-name="Ellipse 7951"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8341"
          data-name="Ellipse 8341"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8731"
          data-name="Ellipse 8731"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6949"
          data-name="Ellipse 6949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7499"
          data-name="Ellipse 7499"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7889"
          data-name="Ellipse 7889"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8279"
          data-name="Ellipse 8279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9074"
          data-name="Ellipse 9074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9121"
          data-name="Ellipse 9121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8669"
          data-name="Ellipse 8669"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7165"
          data-name="Ellipse 7165"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7890"
          data-name="Ellipse 7890"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8670"
          data-name="Ellipse 8670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6844"
          data-name="Ellipse 6844"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7613"
          data-name="Ellipse 7613"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8003"
          data-name="Ellipse 8003"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7028"
          data-name="Ellipse 7028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7501"
          data-name="Ellipse 7501"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7891"
          data-name="Ellipse 7891"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8281"
          data-name="Ellipse 8281"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7244"
          data-name="Ellipse 7244"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7502"
          data-name="Ellipse 7502"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7892"
          data-name="Ellipse 7892"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8282"
          data-name="Ellipse 8282"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9075"
          data-name="Ellipse 9075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8672"
          data-name="Ellipse 8672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8967"
          data-name="Ellipse 8967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4037"
          data-name="Ellipse 4037"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3163"
          data-name="Ellipse 3163"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4038"
          data-name="Ellipse 4038"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3011"
          data-name="Ellipse 3011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2307"
          data-name="Ellipse 2307"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6412"
          data-name="Ellipse 6412"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4039"
          data-name="Ellipse 4039"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4225"
          data-name="Ellipse 4225"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3164"
          data-name="Ellipse 3164"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2596"
          data-name="Ellipse 2596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2212"
          data-name="Ellipse 2212"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3208"
          data-name="Ellipse 3208"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4042"
          data-name="Ellipse 4042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4228"
          data-name="Ellipse 4228"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3209"
          data-name="Ellipse 3209"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2321"
          data-name="Ellipse 2321"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6413"
          data-name="Ellipse 6413"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6679"
          data-name="Ellipse 6679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7503"
          data-name="Ellipse 7503"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7893"
          data-name="Ellipse 7893"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8283"
          data-name="Ellipse 8283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8673"
          data-name="Ellipse 8673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6950"
          data-name="Ellipse 6950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7504"
          data-name="Ellipse 7504"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7894"
          data-name="Ellipse 7894"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8284"
          data-name="Ellipse 8284"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9076"
          data-name="Ellipse 9076"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8674"
          data-name="Ellipse 8674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8968"
          data-name="Ellipse 8968"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7895"
          data-name="Ellipse 7895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 259)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4043"
          data-name="Ellipse 4043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4229"
          data-name="Ellipse 4229"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3165"
          data-name="Ellipse 3165"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2866"
          data-name="Ellipse 2866"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1737"
          data-name="Ellipse 1737"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1766"
          data-name="Ellipse 1766"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1885"
          data-name="Ellipse 1885"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2094"
          data-name="Ellipse 2094"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6472"
          data-name="Ellipse 6472"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6696"
          data-name="Ellipse 6696"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7111"
          data-name="Ellipse 7111"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6827"
          data-name="Ellipse 6827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7112"
          data-name="Ellipse 7112"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3012"
          data-name="Ellipse 3012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3013"
          data-name="Ellipse 3013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2261"
          data-name="Ellipse 2261"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2924"
          data-name="Ellipse 2924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4280"
          data-name="Ellipse 4280"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2188"
          data-name="Ellipse 2188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3742"
          data-name="Ellipse 3742"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3762"
          data-name="Ellipse 3762"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3269"
          data-name="Ellipse 3269"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2346"
          data-name="Ellipse 2346"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6591"
          data-name="Ellipse 6591"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7113"
          data-name="Ellipse 7113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2925"
          data-name="Ellipse 2925"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2867"
          data-name="Ellipse 2867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1929"
          data-name="Ellipse 1929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1838"
          data-name="Ellipse 1838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1886"
          data-name="Ellipse 1886"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1930"
          data-name="Ellipse 1930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2119"
          data-name="Ellipse 2119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6524"
          data-name="Ellipse 6524"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1113 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6747"
          data-name="Ellipse 6747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7114"
          data-name="Ellipse 7114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6828"
          data-name="Ellipse 6828"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7115"
          data-name="Ellipse 7115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7331"
          data-name="Ellipse 7331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 299)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6857"
          data-name="Ellipse 6857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6900"
          data-name="Ellipse 6900"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3088"
          data-name="Ellipse 3088"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3089"
          data-name="Ellipse 3089"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2282"
          data-name="Ellipse 2282"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2926"
          data-name="Ellipse 2926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4315"
          data-name="Ellipse 4315"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4431"
          data-name="Ellipse 4431"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 258)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2768"
          data-name="Ellipse 2768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2208"
          data-name="Ellipse 2208"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 402)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3749"
          data-name="Ellipse 3749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 410)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3334"
          data-name="Ellipse 3334"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2384"
          data-name="Ellipse 2384"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6607"
          data-name="Ellipse 6607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7116"
          data-name="Ellipse 7116"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 315)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2927"
          data-name="Ellipse 2927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(908 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2868"
          data-name="Ellipse 2868"
          cx="1"
          cy="1"
          r="1"
          transform="translate(828 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1560"
          data-name="Ellipse 1560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1583"
          data-name="Ellipse 1583"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1690"
          data-name="Ellipse 1690"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2152"
          data-name="Ellipse 2152"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6533"
          data-name="Ellipse 6533"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6753"
          data-name="Ellipse 6753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7562"
          data-name="Ellipse 7562"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7952"
          data-name="Ellipse 7952"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8342"
          data-name="Ellipse 8342"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6951"
          data-name="Ellipse 6951"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7372"
          data-name="Ellipse 7372"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7762"
          data-name="Ellipse 7762"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8152"
          data-name="Ellipse 8152"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9030"
          data-name="Ellipse 9030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8542"
          data-name="Ellipse 8542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7373"
          data-name="Ellipse 7373"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7763"
          data-name="Ellipse 7763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6845"
          data-name="Ellipse 6845"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7614"
          data-name="Ellipse 7614"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8004"
          data-name="Ellipse 8004"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8394"
          data-name="Ellipse 8394"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7029"
          data-name="Ellipse 7029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7444"
          data-name="Ellipse 7444"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7834"
          data-name="Ellipse 7834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8224"
          data-name="Ellipse 8224"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7245"
          data-name="Ellipse 7245"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7374"
          data-name="Ellipse 7374"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7764"
          data-name="Ellipse 7764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8154"
          data-name="Ellipse 8154"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9031"
          data-name="Ellipse 9031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8544"
          data-name="Ellipse 8544"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3346"
          data-name="Ellipse 3346"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3878"
          data-name="Ellipse 3878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2706"
          data-name="Ellipse 2706"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3014"
          data-name="Ellipse 3014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2390"
          data-name="Ellipse 2390"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6534"
          data-name="Ellipse 6534"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3879"
          data-name="Ellipse 3879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2707"
          data-name="Ellipse 2707"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2869"
          data-name="Ellipse 2869"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4259"
          data-name="Ellipse 4259"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3348"
          data-name="Ellipse 3348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3881"
          data-name="Ellipse 3881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2708"
          data-name="Ellipse 2708"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3210"
          data-name="Ellipse 3210"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2391"
          data-name="Ellipse 2391"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6535"
          data-name="Ellipse 6535"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6754"
          data-name="Ellipse 6754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7474"
          data-name="Ellipse 7474"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7864"
          data-name="Ellipse 7864"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8254"
          data-name="Ellipse 8254"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6916"
          data-name="Ellipse 6916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7375"
          data-name="Ellipse 7375"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7765"
          data-name="Ellipse 7765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8155"
          data-name="Ellipse 8155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9032"
          data-name="Ellipse 9032"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8545"
          data-name="Ellipse 8545"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8921"
          data-name="Ellipse 8921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1489 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9226"
          data-name="Ellipse 9226"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 251)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3882"
          data-name="Ellipse 3882"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3349"
          data-name="Ellipse 3349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3883"
          data-name="Ellipse 3883"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2709"
          data-name="Ellipse 2709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6581"
          data-name="Ellipse 6581"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2870"
          data-name="Ellipse 2870"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1697"
          data-name="Ellipse 1697"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1052 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1506"
          data-name="Ellipse 1506"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1551"
          data-name="Ellipse 1551"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1591"
          data-name="Ellipse 1591"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2095"
          data-name="Ellipse 2095"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6473"
          data-name="Ellipse 6473"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6697"
          data-name="Ellipse 6697"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7563"
          data-name="Ellipse 7563"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7953"
          data-name="Ellipse 7953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8343"
          data-name="Ellipse 8343"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6965"
          data-name="Ellipse 6965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7506"
          data-name="Ellipse 7506"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7896"
          data-name="Ellipse 7896"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8286"
          data-name="Ellipse 8286"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9077"
          data-name="Ellipse 9077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9122"
          data-name="Ellipse 9122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8676"
          data-name="Ellipse 8676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7507"
          data-name="Ellipse 7507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7897"
          data-name="Ellipse 7897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8287"
          data-name="Ellipse 8287"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8677"
          data-name="Ellipse 8677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6820"
          data-name="Ellipse 6820"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7621"
          data-name="Ellipse 7621"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8011"
          data-name="Ellipse 8011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7062"
          data-name="Ellipse 7062"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7508"
          data-name="Ellipse 7508"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7898"
          data-name="Ellipse 7898"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8288"
          data-name="Ellipse 8288"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8678"
          data-name="Ellipse 8678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7278"
          data-name="Ellipse 7278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7509"
          data-name="Ellipse 7509"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7899"
          data-name="Ellipse 7899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8289"
          data-name="Ellipse 8289"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9078"
          data-name="Ellipse 9078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8679"
          data-name="Ellipse 8679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8970"
          data-name="Ellipse 8970"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4046"
          data-name="Ellipse 4046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3015"
          data-name="Ellipse 3015"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2308"
          data-name="Ellipse 2308"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6414"
          data-name="Ellipse 6414"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4047"
          data-name="Ellipse 4047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3167"
          data-name="Ellipse 3167"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4048"
          data-name="Ellipse 4048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2600"
          data-name="Ellipse 2600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2213"
          data-name="Ellipse 2213"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3271"
          data-name="Ellipse 3271"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9236"
          data-name="Ellipse 9236"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9321"
          data-name="Ellipse 9321"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9338"
          data-name="Ellipse 9338"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9355"
          data-name="Ellipse 9355"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9387"
          data-name="Ellipse 9387"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9252"
          data-name="Ellipse 9252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9322"
          data-name="Ellipse 9322"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9339"
          data-name="Ellipse 9339"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9356"
          data-name="Ellipse 9356"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9378"
          data-name="Ellipse 9378"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9268"
          data-name="Ellipse 9268"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9323"
          data-name="Ellipse 9323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9340"
          data-name="Ellipse 9340"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9357"
          data-name="Ellipse 9357"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9373"
          data-name="Ellipse 9373"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9284"
          data-name="Ellipse 9284"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9324"
          data-name="Ellipse 9324"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9341"
          data-name="Ellipse 9341"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1173 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9358"
          data-name="Ellipse 9358"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4050"
          data-name="Ellipse 4050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4236"
          data-name="Ellipse 4236"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3272"
          data-name="Ellipse 3272"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2347"
          data-name="Ellipse 2347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6416"
          data-name="Ellipse 6416"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1157 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6680"
          data-name="Ellipse 6680"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7510"
          data-name="Ellipse 7510"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7900"
          data-name="Ellipse 7900"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8290"
          data-name="Ellipse 8290"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8680"
          data-name="Ellipse 8680"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6952"
          data-name="Ellipse 6952"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1241 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7511"
          data-name="Ellipse 7511"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1281 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7901"
          data-name="Ellipse 7901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8291"
          data-name="Ellipse 8291"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1361 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9079"
          data-name="Ellipse 9079"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1429 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8681"
          data-name="Ellipse 8681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1401 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8971"
          data-name="Ellipse 8971"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1469 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7902"
          data-name="Ellipse 7902"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1321 263)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4051"
          data-name="Ellipse 4051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(976 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4237"
          data-name="Ellipse 4237"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1000 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3168"
          data-name="Ellipse 3168"
          cx="1"
          cy="1"
          r="1"
          transform="translate(952 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2871"
          data-name="Ellipse 2871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(872 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1659"
          data-name="Ellipse 1659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1024 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1556"
          data-name="Ellipse 1556"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1574"
          data-name="Ellipse 1574"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1044 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2153"
          data-name="Ellipse 2153"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6536"
          data-name="Ellipse 6536"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6755"
          data-name="Ellipse 6755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7564"
          data-name="Ellipse 7564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7954"
          data-name="Ellipse 7954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6966"
          data-name="Ellipse 6966"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7407"
          data-name="Ellipse 7407"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7797"
          data-name="Ellipse 7797"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8187"
          data-name="Ellipse 8187"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9033"
          data-name="Ellipse 9033"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8577"
          data-name="Ellipse 8577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7182"
          data-name="Ellipse 7182"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7377"
          data-name="Ellipse 7377"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7767"
          data-name="Ellipse 7767"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6821"
          data-name="Ellipse 6821"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7622"
          data-name="Ellipse 7622"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8012"
          data-name="Ellipse 8012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8402"
          data-name="Ellipse 8402"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7063"
          data-name="Ellipse 7063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7445"
          data-name="Ellipse 7445"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7835"
          data-name="Ellipse 7835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8225"
          data-name="Ellipse 8225"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7279"
          data-name="Ellipse 7279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7378"
          data-name="Ellipse 7378"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7768"
          data-name="Ellipse 7768"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8158"
          data-name="Ellipse 8158"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9034"
          data-name="Ellipse 9034"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8548"
          data-name="Ellipse 8548"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3350"
          data-name="Ellipse 3350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3884"
          data-name="Ellipse 3884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2710"
          data-name="Ellipse 2710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6698"
          data-name="Ellipse 6698"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3016"
          data-name="Ellipse 3016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2392"
          data-name="Ellipse 2392"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6537"
          data-name="Ellipse 6537"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3351"
          data-name="Ellipse 3351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2711"
          data-name="Ellipse 2711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2872"
          data-name="Ellipse 2872"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4281"
          data-name="Ellipse 4281"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3352"
          data-name="Ellipse 3352"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6538"
          data-name="Ellipse 6538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3887"
          data-name="Ellipse 3887"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3273"
          data-name="Ellipse 3273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2393"
          data-name="Ellipse 2393"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6539"
          data-name="Ellipse 6539"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6756"
          data-name="Ellipse 6756"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7475"
          data-name="Ellipse 7475"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7865"
          data-name="Ellipse 7865"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8255"
          data-name="Ellipse 8255"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6921"
          data-name="Ellipse 6921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1261 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7379"
          data-name="Ellipse 7379"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1301 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7769"
          data-name="Ellipse 7769"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1341 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8159"
          data-name="Ellipse 8159"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9035"
          data-name="Ellipse 9035"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1449 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8549"
          data-name="Ellipse 8549"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1421 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3888"
          data-name="Ellipse 3888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3353"
          data-name="Ellipse 3353"
          cx="1"
          cy="1"
          r="1"
          transform="translate(972 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3889"
          data-name="Ellipse 3889"
          cx="1"
          cy="1"
          r="1"
          transform="translate(996 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2713"
          data-name="Ellipse 2713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6592"
          data-name="Ellipse 6592"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1181 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2873"
          data-name="Ellipse 2873"
          cx="1"
          cy="1"
          r="1"
          transform="translate(892 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1484"
          data-name="Ellipse 1484"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1552"
          data-name="Ellipse 1552"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1587"
          data-name="Ellipse 1587"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1691"
          data-name="Ellipse 1691"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2080"
          data-name="Ellipse 2080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6445"
          data-name="Ellipse 6445"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6699"
          data-name="Ellipse 6699"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7565"
          data-name="Ellipse 7565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7955"
          data-name="Ellipse 7955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8345"
          data-name="Ellipse 8345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6953"
          data-name="Ellipse 6953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7446"
          data-name="Ellipse 7446"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7836"
          data-name="Ellipse 7836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8226"
          data-name="Ellipse 8226"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9063"
          data-name="Ellipse 9063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9119"
          data-name="Ellipse 9119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8616"
          data-name="Ellipse 8616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7169"
          data-name="Ellipse 7169"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7837"
          data-name="Ellipse 7837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8617"
          data-name="Ellipse 8617"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6846"
          data-name="Ellipse 6846"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7615"
          data-name="Ellipse 7615"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8005"
          data-name="Ellipse 8005"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7030"
          data-name="Ellipse 7030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7448"
          data-name="Ellipse 7448"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7838"
          data-name="Ellipse 7838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8228"
          data-name="Ellipse 8228"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7246"
          data-name="Ellipse 7246"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7449"
          data-name="Ellipse 7449"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7839"
          data-name="Ellipse 7839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8229"
          data-name="Ellipse 8229"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9064"
          data-name="Ellipse 9064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8619"
          data-name="Ellipse 8619"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8936"
          data-name="Ellipse 8936"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3176"
          data-name="Ellipse 3176"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4006"
          data-name="Ellipse 4006"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3017"
          data-name="Ellipse 3017"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2327"
          data-name="Ellipse 2327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6424"
          data-name="Ellipse 6424"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4007"
          data-name="Ellipse 4007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4193"
          data-name="Ellipse 4193"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3177"
          data-name="Ellipse 3177"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2627"
          data-name="Ellipse 2627"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2799"
          data-name="Ellipse 2799"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2216"
          data-name="Ellipse 2216"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4010"
          data-name="Ellipse 4010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4196"
          data-name="Ellipse 4196"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3212"
          data-name="Ellipse 3212"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2328"
          data-name="Ellipse 2328"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6425"
          data-name="Ellipse 6425"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6700"
          data-name="Ellipse 6700"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7476"
          data-name="Ellipse 7476"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7866"
          data-name="Ellipse 7866"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8256"
          data-name="Ellipse 8256"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8646"
          data-name="Ellipse 8646"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6936"
          data-name="Ellipse 6936"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7450"
          data-name="Ellipse 7450"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7840"
          data-name="Ellipse 7840"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8230"
          data-name="Ellipse 8230"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9065"
          data-name="Ellipse 9065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8620"
          data-name="Ellipse 8620"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8937"
          data-name="Ellipse 8937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7841"
          data-name="Ellipse 7841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 259)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4011"
          data-name="Ellipse 4011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3178"
          data-name="Ellipse 3178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4012"
          data-name="Ellipse 4012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2874"
          data-name="Ellipse 2874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1739"
          data-name="Ellipse 1739"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1573"
          data-name="Ellipse 1573"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 502)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1608"
          data-name="Ellipse 1608"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 486)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1507"
          data-name="Ellipse 1507"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1553"
          data-name="Ellipse 1553"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1588"
          data-name="Ellipse 1588"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2096"
          data-name="Ellipse 2096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6475"
          data-name="Ellipse 6475"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6701"
          data-name="Ellipse 6701"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7566"
          data-name="Ellipse 7566"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7956"
          data-name="Ellipse 7956"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8346"
          data-name="Ellipse 8346"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6967"
          data-name="Ellipse 6967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7452"
          data-name="Ellipse 7452"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7842"
          data-name="Ellipse 7842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8232"
          data-name="Ellipse 8232"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9066"
          data-name="Ellipse 9066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9120"
          data-name="Ellipse 9120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8622"
          data-name="Ellipse 8622"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7453"
          data-name="Ellipse 7453"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7843"
          data-name="Ellipse 7843"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8233"
          data-name="Ellipse 8233"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8623"
          data-name="Ellipse 8623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6822"
          data-name="Ellipse 6822"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7623"
          data-name="Ellipse 7623"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8013"
          data-name="Ellipse 8013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7064"
          data-name="Ellipse 7064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7454"
          data-name="Ellipse 7454"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7844"
          data-name="Ellipse 7844"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8234"
          data-name="Ellipse 8234"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7280"
          data-name="Ellipse 7280"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7455"
          data-name="Ellipse 7455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7845"
          data-name="Ellipse 7845"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8235"
          data-name="Ellipse 8235"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9067"
          data-name="Ellipse 9067"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8625"
          data-name="Ellipse 8625"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3179"
          data-name="Ellipse 3179"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4014"
          data-name="Ellipse 4014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3018"
          data-name="Ellipse 3018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2329"
          data-name="Ellipse 2329"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6426"
          data-name="Ellipse 6426"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4015"
          data-name="Ellipse 4015"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3180"
          data-name="Ellipse 3180"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4016"
          data-name="Ellipse 4016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2631"
          data-name="Ellipse 2631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2800"
          data-name="Ellipse 2800"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2217"
          data-name="Ellipse 2217"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3274"
          data-name="Ellipse 3274"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9245"
          data-name="Ellipse 9245"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9316"
          data-name="Ellipse 9316"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9333"
          data-name="Ellipse 9333"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9350"
          data-name="Ellipse 9350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9388"
          data-name="Ellipse 9388"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9367"
          data-name="Ellipse 9367"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9389"
          data-name="Ellipse 9389"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 347)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9261"
          data-name="Ellipse 9261"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9314"
          data-name="Ellipse 9314"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9331"
          data-name="Ellipse 9331"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9348"
          data-name="Ellipse 9348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9379"
          data-name="Ellipse 9379"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9364"
          data-name="Ellipse 9364"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9382"
          data-name="Ellipse 9382"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9277"
          data-name="Ellipse 9277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9311"
          data-name="Ellipse 9311"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9328"
          data-name="Ellipse 9328"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9345"
          data-name="Ellipse 9345"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9377"
          data-name="Ellipse 9377"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9365"
          data-name="Ellipse 9365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9383"
          data-name="Ellipse 9383"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 331)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9293"
          data-name="Ellipse 9293"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9312"
          data-name="Ellipse 9312"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9329"
          data-name="Ellipse 9329"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1177 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4018"
          data-name="Ellipse 4018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4204"
          data-name="Ellipse 4204"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1004 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3275"
          data-name="Ellipse 3275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2348"
          data-name="Ellipse 2348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6428"
          data-name="Ellipse 6428"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1161 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6702"
          data-name="Ellipse 6702"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7477"
          data-name="Ellipse 7477"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7867"
          data-name="Ellipse 7867"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8257"
          data-name="Ellipse 8257"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8647"
          data-name="Ellipse 8647"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6937"
          data-name="Ellipse 6937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1245 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7456"
          data-name="Ellipse 7456"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1285 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7846"
          data-name="Ellipse 7846"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8236"
          data-name="Ellipse 8236"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1365 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9068"
          data-name="Ellipse 9068"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1433 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8626"
          data-name="Ellipse 8626"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1405 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8939"
          data-name="Ellipse 8939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1473 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7847"
          data-name="Ellipse 7847"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1325 263)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4019"
          data-name="Ellipse 4019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3181"
          data-name="Ellipse 3181"
          cx="1"
          cy="1"
          r="1"
          transform="translate(956 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4020"
          data-name="Ellipse 4020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(980 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2875"
          data-name="Ellipse 2875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(876 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1656"
          data-name="Ellipse 1656"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1028 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1430"
          data-name="Ellipse 1430"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1467"
          data-name="Ellipse 1467"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1533"
          data-name="Ellipse 1533"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1635"
          data-name="Ellipse 1635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1695"
          data-name="Ellipse 1695"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2077"
          data-name="Ellipse 2077"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6446"
          data-name="Ellipse 6446"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6661"
          data-name="Ellipse 6661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7083"
          data-name="Ellipse 7083"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6847"
          data-name="Ellipse 6847"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7084"
          data-name="Ellipse 7084"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6893"
          data-name="Ellipse 6893"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3019"
          data-name="Ellipse 3019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3020"
          data-name="Ellipse 3020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2249"
          data-name="Ellipse 2249"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3021"
          data-name="Ellipse 3021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4261"
          data-name="Ellipse 4261"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 266)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2173"
          data-name="Ellipse 2173"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3213"
          data-name="Ellipse 3213"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2322"
          data-name="Ellipse 2322"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6595"
          data-name="Ellipse 6595"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7085"
          data-name="Ellipse 7085"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3022"
          data-name="Ellipse 3022"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2876"
          data-name="Ellipse 2876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1743"
          data-name="Ellipse 1743"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1839"
          data-name="Ellipse 1839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1492"
          data-name="Ellipse 1492"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1512"
          data-name="Ellipse 1512"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1636"
          data-name="Ellipse 1636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2097"
          data-name="Ellipse 2097"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6476"
          data-name="Ellipse 6476"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1117 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6703"
          data-name="Ellipse 6703"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7086"
          data-name="Ellipse 7086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6823"
          data-name="Ellipse 6823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7087"
          data-name="Ellipse 7087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6894"
          data-name="Ellipse 6894"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3023"
          data-name="Ellipse 3023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3024"
          data-name="Ellipse 3024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2262"
          data-name="Ellipse 2262"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3025"
          data-name="Ellipse 3025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4283"
          data-name="Ellipse 4283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2189"
          data-name="Ellipse 2189"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3743"
          data-name="Ellipse 3743"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 406)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3763"
          data-name="Ellipse 3763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 414)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3276"
          data-name="Ellipse 3276"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2349"
          data-name="Ellipse 2349"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6596"
          data-name="Ellipse 6596"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7088"
          data-name="Ellipse 7088"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3026"
          data-name="Ellipse 3026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(912 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2877"
          data-name="Ellipse 2877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(832 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1696"
          data-name="Ellipse 1696"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1744"
          data-name="Ellipse 1744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1793"
          data-name="Ellipse 1793"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1935"
          data-name="Ellipse 1935"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 434)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1441"
          data-name="Ellipse 1441"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 522)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1469"
          data-name="Ellipse 1469"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 514)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1534"
          data-name="Ellipse 1534"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1599"
          data-name="Ellipse 1599"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1692"
          data-name="Ellipse 1692"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2078"
          data-name="Ellipse 2078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6447"
          data-name="Ellipse 6447"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6662"
          data-name="Ellipse 6662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7651"
          data-name="Ellipse 7651"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8041"
          data-name="Ellipse 8041"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8431"
          data-name="Ellipse 8431"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6972"
          data-name="Ellipse 6972"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7652"
          data-name="Ellipse 7652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8042"
          data-name="Ellipse 8042"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8432"
          data-name="Ellipse 8432"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8822"
          data-name="Ellipse 8822"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9007"
          data-name="Ellipse 9007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7188"
          data-name="Ellipse 7188"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8043"
          data-name="Ellipse 8043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8823"
          data-name="Ellipse 8823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6848"
          data-name="Ellipse 6848"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7654"
          data-name="Ellipse 7654"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8044"
          data-name="Ellipse 8044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8434"
          data-name="Ellipse 8434"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8824"
          data-name="Ellipse 8824"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7031"
          data-name="Ellipse 7031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7655"
          data-name="Ellipse 7655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8045"
          data-name="Ellipse 8045"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8435"
          data-name="Ellipse 8435"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8825"
          data-name="Ellipse 8825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7247"
          data-name="Ellipse 7247"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7656"
          data-name="Ellipse 7656"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8046"
          data-name="Ellipse 8046"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8436"
          data-name="Ellipse 8436"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8826"
          data-name="Ellipse 8826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9008"
          data-name="Ellipse 9008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3090"
          data-name="Ellipse 3090"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3027"
          data-name="Ellipse 3027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3723"
          data-name="Ellipse 3723"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2283"
          data-name="Ellipse 2283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6381"
          data-name="Ellipse 6381"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3091"
          data-name="Ellipse 3091"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2190"
          data-name="Ellipse 2190"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3215"
          data-name="Ellipse 3215"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3216"
          data-name="Ellipse 3216"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3727"
          data-name="Ellipse 3727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2323"
          data-name="Ellipse 2323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6382"
          data-name="Ellipse 6382"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6646"
          data-name="Ellipse 6646"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7657"
          data-name="Ellipse 7657"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8047"
          data-name="Ellipse 8047"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8437"
          data-name="Ellipse 8437"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8827"
          data-name="Ellipse 8827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6973"
          data-name="Ellipse 6973"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7658"
          data-name="Ellipse 7658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8048"
          data-name="Ellipse 8048"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8438"
          data-name="Ellipse 8438"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8828"
          data-name="Ellipse 8828"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9009"
          data-name="Ellipse 9009"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8049"
          data-name="Ellipse 8049"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 259)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9227"
          data-name="Ellipse 9227"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 251)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3092"
          data-name="Ellipse 3092"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2878"
          data-name="Ellipse 2878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3729"
          data-name="Ellipse 3729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1740"
          data-name="Ellipse 1740"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1841"
          data-name="Ellipse 1841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1767"
          data-name="Ellipse 1767"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 458)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1872"
          data-name="Ellipse 1872"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2098"
          data-name="Ellipse 2098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6477"
          data-name="Ellipse 6477"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6704"
          data-name="Ellipse 6704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3028"
          data-name="Ellipse 3028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3029"
          data-name="Ellipse 3029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2263"
          data-name="Ellipse 2263"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2879"
          data-name="Ellipse 2879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4285"
          data-name="Ellipse 4285"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2771"
          data-name="Ellipse 2771"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2191"
          data-name="Ellipse 2191"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3278"
          data-name="Ellipse 3278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3279"
          data-name="Ellipse 3279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2350"
          data-name="Ellipse 2350"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6593"
          data-name="Ellipse 6593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2880"
          data-name="Ellipse 2880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2881"
          data-name="Ellipse 2881"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1842"
          data-name="Ellipse 1842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 446)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1873"
          data-name="Ellipse 1873"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1917"
          data-name="Ellipse 1917"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 478)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2120"
          data-name="Ellipse 2120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 386)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6525"
          data-name="Ellipse 6525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1101 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6748"
          data-name="Ellipse 6748"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 379)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3093"
          data-name="Ellipse 3093"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3094"
          data-name="Ellipse 3094"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 322)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2284"
          data-name="Ellipse 2284"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 354)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2882"
          data-name="Ellipse 2882"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4317"
          data-name="Ellipse 4317"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 274)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2772"
          data-name="Ellipse 2772"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 290)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3336"
          data-name="Ellipse 3336"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3337"
          data-name="Ellipse 3337"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 338)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2385"
          data-name="Ellipse 2385"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 370)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6608"
          data-name="Ellipse 6608"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 363)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2883"
          data-name="Ellipse 2883"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2884"
          data-name="Ellipse 2884"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 306)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1570"
          data-name="Ellipse 1570"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 498)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1584"
          data-name="Ellipse 1584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 482)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1693"
          data-name="Ellipse 1693"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 470)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2101"
          data-name="Ellipse 2101"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6455"
          data-name="Ellipse 6455"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6709"
          data-name="Ellipse 6709"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7567"
          data-name="Ellipse 7567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7957"
          data-name="Ellipse 7957"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8347"
          data-name="Ellipse 8347"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 371)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6954"
          data-name="Ellipse 6954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7458"
          data-name="Ellipse 7458"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7848"
          data-name="Ellipse 7848"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8238"
          data-name="Ellipse 8238"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9052"
          data-name="Ellipse 9052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9117"
          data-name="Ellipse 9117"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8628"
          data-name="Ellipse 8628"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 323)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7170"
          data-name="Ellipse 7170"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7459"
          data-name="Ellipse 7459"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7849"
          data-name="Ellipse 7849"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8629"
          data-name="Ellipse 8629"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 275)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6849"
          data-name="Ellipse 6849"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7616"
          data-name="Ellipse 7616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8006"
          data-name="Ellipse 8006"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8396"
          data-name="Ellipse 8396"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7032"
          data-name="Ellipse 7032"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7460"
          data-name="Ellipse 7460"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7850"
          data-name="Ellipse 7850"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8240"
          data-name="Ellipse 8240"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7248"
          data-name="Ellipse 7248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7461"
          data-name="Ellipse 7461"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7851"
          data-name="Ellipse 7851"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8241"
          data-name="Ellipse 8241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9053"
          data-name="Ellipse 9053"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8631"
          data-name="Ellipse 8631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8940"
          data-name="Ellipse 8940"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1477 291)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3225"
          data-name="Ellipse 3225"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3974"
          data-name="Ellipse 3974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2658"
          data-name="Ellipse 2658"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 378)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3030"
          data-name="Ellipse 3030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 314)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2333"
          data-name="Ellipse 2333"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6456"
          data-name="Ellipse 6456"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 339)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3975"
          data-name="Ellipse 3975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4161"
          data-name="Ellipse 4161"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3226"
          data-name="Ellipse 3226"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2659"
          data-name="Ellipse 2659"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 346)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2801"
          data-name="Ellipse 2801"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 282)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2220"
          data-name="Ellipse 2220"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 394)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3978"
          data-name="Ellipse 3978"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4164"
          data-name="Ellipse 4164"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3217"
          data-name="Ellipse 3217"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 330)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2334"
          data-name="Ellipse 2334"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6457"
          data-name="Ellipse 6457"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6710"
          data-name="Ellipse 6710"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7478"
          data-name="Ellipse 7478"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7868"
          data-name="Ellipse 7868"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8258"
          data-name="Ellipse 8258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 355)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6927"
          data-name="Ellipse 6927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7462"
          data-name="Ellipse 7462"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7852"
          data-name="Ellipse 7852"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8242"
          data-name="Ellipse 8242"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9054"
          data-name="Ellipse 9054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8632"
          data-name="Ellipse 8632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8941"
          data-name="Ellipse 8941"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1477 307)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3979"
          data-name="Ellipse 3979"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3228"
          data-name="Ellipse 3228"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3980"
          data-name="Ellipse 3980"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2661"
          data-name="Ellipse 2661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 362)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2885"
          data-name="Ellipse 2885"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 298)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1493"
          data-name="Ellipse 1493"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 506)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1515"
          data-name="Ellipse 1515"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1600"
          data-name="Ellipse 1600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2099"
          data-name="Ellipse 2099"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6478"
          data-name="Ellipse 6478"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6705"
          data-name="Ellipse 6705"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7660"
          data-name="Ellipse 7660"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8050"
          data-name="Ellipse 8050"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8440"
          data-name="Ellipse 8440"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6974"
          data-name="Ellipse 6974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7661"
          data-name="Ellipse 7661"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8051"
          data-name="Ellipse 8051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8441"
          data-name="Ellipse 8441"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8831"
          data-name="Ellipse 8831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9010"
          data-name="Ellipse 9010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7190"
          data-name="Ellipse 7190"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7662"
          data-name="Ellipse 7662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8052"
          data-name="Ellipse 8052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8442"
          data-name="Ellipse 8442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8832"
          data-name="Ellipse 8832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6824"
          data-name="Ellipse 6824"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7663"
          data-name="Ellipse 7663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8053"
          data-name="Ellipse 8053"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8443"
          data-name="Ellipse 8443"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8833"
          data-name="Ellipse 8833"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7065"
          data-name="Ellipse 7065"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7664"
          data-name="Ellipse 7664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8054"
          data-name="Ellipse 8054"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8444"
          data-name="Ellipse 8444"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8834"
          data-name="Ellipse 8834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7281"
          data-name="Ellipse 7281"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7665"
          data-name="Ellipse 7665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8055"
          data-name="Ellipse 8055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8445"
          data-name="Ellipse 8445"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8835"
          data-name="Ellipse 8835"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9011"
          data-name="Ellipse 9011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3095"
          data-name="Ellipse 3095"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3031"
          data-name="Ellipse 3031"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3731"
          data-name="Ellipse 3731"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2285"
          data-name="Ellipse 2285"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6385"
          data-name="Ellipse 6385"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3096"
          data-name="Ellipse 3096"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4286"
          data-name="Ellipse 4286"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3733"
          data-name="Ellipse 3733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2192"
          data-name="Ellipse 2192"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3280"
          data-name="Ellipse 3280"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6386"
          data-name="Ellipse 6386"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9234"
          data-name="Ellipse 9234"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 319)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9250"
          data-name="Ellipse 9250"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9266"
          data-name="Ellipse 9266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 303)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3281"
          data-name="Ellipse 3281"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3735"
          data-name="Ellipse 3735"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2351"
          data-name="Ellipse 2351"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6387"
          data-name="Ellipse 6387"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1145 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6647"
          data-name="Ellipse 6647"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7666"
          data-name="Ellipse 7666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8056"
          data-name="Ellipse 8056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8446"
          data-name="Ellipse 8446"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8836"
          data-name="Ellipse 8836"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6975"
          data-name="Ellipse 6975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7667"
          data-name="Ellipse 7667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1269 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8057"
          data-name="Ellipse 8057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1309 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8447"
          data-name="Ellipse 8447"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1349 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8837"
          data-name="Ellipse 8837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1389 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9012"
          data-name="Ellipse 9012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1457 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3097"
          data-name="Ellipse 3097"
          cx="1"
          cy="1"
          r="1"
          transform="translate(940 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2886"
          data-name="Ellipse 2886"
          cx="1"
          cy="1"
          r="1"
          transform="translate(860 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3737"
          data-name="Ellipse 3737"
          cx="1"
          cy="1"
          r="1"
          transform="translate(812 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1665"
          data-name="Ellipse 1665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1714"
          data-name="Ellipse 1714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1012 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1753"
          data-name="Ellipse 1753"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 450)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1874"
          data-name="Ellipse 1874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 466)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2057"
          data-name="Ellipse 2057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 374)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2928"
          data-name="Ellipse 2928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2929"
          data-name="Ellipse 2929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 310)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2239"
          data-name="Ellipse 2239"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 342)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2887"
          data-name="Ellipse 2887"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 278)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4245"
          data-name="Ellipse 4245"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 262)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2161"
          data-name="Ellipse 2161"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 390)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6777"
          data-name="Ellipse 6777"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 383)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3154"
          data-name="Ellipse 3154"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3155"
          data-name="Ellipse 3155"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 326)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2303"
          data-name="Ellipse 2303"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 358)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2888"
          data-name="Ellipse 2888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(896 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2802"
          data-name="Ellipse 2802"
          cx="1"
          cy="1"
          r="1"
          transform="translate(816 294)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1796"
          data-name="Ellipse 1796"
          cx="1"
          cy="1"
          r="1"
          transform="translate(968 438)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1571"
          data-name="Ellipse 1571"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 490)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1585"
          data-name="Ellipse 1585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 474)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2102"
          data-name="Ellipse 2102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6479"
          data-name="Ellipse 6479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6711"
          data-name="Ellipse 6711"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7568"
          data-name="Ellipse 7568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7958"
          data-name="Ellipse 7958"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8348"
          data-name="Ellipse 8348"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 375)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6968"
          data-name="Ellipse 6968"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7464"
          data-name="Ellipse 7464"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7854"
          data-name="Ellipse 7854"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8244"
          data-name="Ellipse 8244"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9055"
          data-name="Ellipse 9055"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9118"
          data-name="Ellipse 9118"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8634"
          data-name="Ellipse 8634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 327)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7465"
          data-name="Ellipse 7465"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7855"
          data-name="Ellipse 7855"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8245"
          data-name="Ellipse 8245"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8635"
          data-name="Ellipse 8635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 279)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6825"
          data-name="Ellipse 6825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7624"
          data-name="Ellipse 7624"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8014"
          data-name="Ellipse 8014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8404"
          data-name="Ellipse 8404"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7066"
          data-name="Ellipse 7066"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7466"
          data-name="Ellipse 7466"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7856"
          data-name="Ellipse 7856"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8246"
          data-name="Ellipse 8246"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7282"
          data-name="Ellipse 7282"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7467"
          data-name="Ellipse 7467"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7857"
          data-name="Ellipse 7857"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8247"
          data-name="Ellipse 8247"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9056"
          data-name="Ellipse 9056"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8637"
          data-name="Ellipse 8637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 295)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3229"
          data-name="Ellipse 3229"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3982"
          data-name="Ellipse 3982"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2662"
          data-name="Ellipse 2662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 382)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3032"
          data-name="Ellipse 3032"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 318)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2335"
          data-name="Ellipse 2335"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6458"
          data-name="Ellipse 6458"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 343)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3983"
          data-name="Ellipse 3983"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4169"
          data-name="Ellipse 4169"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3230"
          data-name="Ellipse 3230"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2663"
          data-name="Ellipse 2663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 350)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2803"
          data-name="Ellipse 2803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 286)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4287"
          data-name="Ellipse 4287"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 270)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2221"
          data-name="Ellipse 2221"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 398)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3986"
          data-name="Ellipse 3986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4172"
          data-name="Ellipse 4172"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1008 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3283"
          data-name="Ellipse 3283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 334)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2352"
          data-name="Ellipse 2352"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6459"
          data-name="Ellipse 6459"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6712"
          data-name="Ellipse 6712"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7479"
          data-name="Ellipse 7479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7869"
          data-name="Ellipse 7869"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8259"
          data-name="Ellipse 8259"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 359)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6928"
          data-name="Ellipse 6928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1249 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7468"
          data-name="Ellipse 7468"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1289 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_7858"
          data-name="Ellipse 7858"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1329 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8248"
          data-name="Ellipse 8248"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1369 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_9057"
          data-name="Ellipse 9057"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1437 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_8638"
          data-name="Ellipse 8638"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1409 311)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3987"
          data-name="Ellipse 3987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3231"
          data-name="Ellipse 3231"
          cx="1"
          cy="1"
          r="1"
          transform="translate(960 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_3988"
          data-name="Ellipse 3988"
          cx="1"
          cy="1"
          r="1"
          transform="translate(984 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2665"
          data-name="Ellipse 2665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 366)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_2889"
          data-name="Ellipse 2889"
          cx="1"
          cy="1"
          r="1"
          transform="translate(880 302)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1657"
          data-name="Ellipse 1657"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1032 462)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_598"
          data-name="Ellipse 598"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 476)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_526"
          data-name="Ellipse 526"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6102"
          data-name="Ellipse 6102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4713"
          data-name="Ellipse 4713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5757"
          data-name="Ellipse 5757"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1163 250)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1171"
          data-name="Ellipse 1171"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6103"
          data-name="Ellipse 6103"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4714"
          data-name="Ellipse 4714"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5239"
          data-name="Ellipse 5239"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_963"
          data-name="Ellipse 963"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6104"
          data-name="Ellipse 6104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4715"
          data-name="Ellipse 4715"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1144"
          data-name="Ellipse 1144"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4716"
          data-name="Ellipse 4716"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5241"
          data-name="Ellipse 5241"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_917"
          data-name="Ellipse 917"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6106"
          data-name="Ellipse 6106"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4717"
          data-name="Ellipse 4717"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1145"
          data-name="Ellipse 1145"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6107"
          data-name="Ellipse 6107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4718"
          data-name="Ellipse 4718"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5243"
          data-name="Ellipse 5243"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_945"
          data-name="Ellipse 945"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6108"
          data-name="Ellipse 6108"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1413 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4719"
          data-name="Ellipse 4719"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1146"
          data-name="Ellipse 1146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1165 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5137"
          data-name="Ellipse 5137"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1078 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_790"
          data-name="Ellipse 790"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5919"
          data-name="Ellipse 5919"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4663"
          data-name="Ellipse 4663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5584"
          data-name="Ellipse 5584"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5616"
          data-name="Ellipse 5616"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5648"
          data-name="Ellipse 5648"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5680"
          data-name="Ellipse 5680"
          cx="1"
          cy="1"
          r="1"
          transform="translate(954 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5712"
          data-name="Ellipse 5712"
          cx="1"
          cy="1"
          r="1"
          transform="translate(938 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_986"
          data-name="Ellipse 986"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5897"
          data-name="Ellipse 5897"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4603"
          data-name="Ellipse 4603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5568"
          data-name="Ellipse 5568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5600"
          data-name="Ellipse 5600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5632"
          data-name="Ellipse 5632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5664"
          data-name="Ellipse 5664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(954 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1043"
          data-name="Ellipse 1043"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5815"
          data-name="Ellipse 5815"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4832"
          data-name="Ellipse 4832"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1098 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1074"
          data-name="Ellipse 1074"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5816"
          data-name="Ellipse 5816"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1087"
          data-name="Ellipse 1087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5138"
          data-name="Ellipse 5138"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1101"
          data-name="Ellipse 1101"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5818"
          data-name="Ellipse 5818"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_932"
          data-name="Ellipse 932"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5898"
          data-name="Ellipse 5898"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4631"
          data-name="Ellipse 4631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5576"
          data-name="Ellipse 5576"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5608"
          data-name="Ellipse 5608"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5640"
          data-name="Ellipse 5640"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5672"
          data-name="Ellipse 5672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(954 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5704"
          data-name="Ellipse 5704"
          cx="1"
          cy="1"
          r="1"
          transform="translate(938 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1051"
          data-name="Ellipse 1051"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5861"
          data-name="Ellipse 5861"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4560"
          data-name="Ellipse 4560"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5566"
          data-name="Ellipse 5566"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5736"
          data-name="Ellipse 5736"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5598"
          data-name="Ellipse 5598"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5730"
          data-name="Ellipse 5730"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5630"
          data-name="Ellipse 5630"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5731"
          data-name="Ellipse 5731"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5662"
          data-name="Ellipse 5662"
          cx="1"
          cy="1"
          r="1"
          transform="translate(954 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4871"
          data-name="Ellipse 4871"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1098 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_987"
          data-name="Ellipse 987"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5862"
          data-name="Ellipse 5862"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4561"
          data-name="Ellipse 4561"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5567"
          data-name="Ellipse 5567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5743"
          data-name="Ellipse 5743"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1002 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5599"
          data-name="Ellipse 5599"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5744"
          data-name="Ellipse 5744"
          cx="1"
          cy="1"
          r="1"
          transform="translate(986 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5631"
          data-name="Ellipse 5631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5745"
          data-name="Ellipse 5745"
          cx="1"
          cy="1"
          r="1"
          transform="translate(970 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5663"
          data-name="Ellipse 5663"
          cx="1"
          cy="1"
          r="1"
          transform="translate(954 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1032"
          data-name="Ellipse 1032"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5819"
          data-name="Ellipse 5819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1063"
          data-name="Ellipse 1063"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5820"
          data-name="Ellipse 5820"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5140"
          data-name="Ellipse 5140"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1080"
          data-name="Ellipse 1080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5078"
          data-name="Ellipse 5078"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1018 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_783"
          data-name="Ellipse 783"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6023"
          data-name="Ellipse 6023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4664"
          data-name="Ellipse 4664"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1172"
          data-name="Ellipse 1172"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6024"
          data-name="Ellipse 6024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4632"
          data-name="Ellipse 4632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5252"
          data-name="Ellipse 5252"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_964"
          data-name="Ellipse 964"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6025"
          data-name="Ellipse 6025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4633"
          data-name="Ellipse 4633"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1126"
          data-name="Ellipse 1126"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6026"
          data-name="Ellipse 6026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4634"
          data-name="Ellipse 4634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 220)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5254"
          data-name="Ellipse 5254"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_921"
          data-name="Ellipse 921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6027"
          data-name="Ellipse 6027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4635"
          data-name="Ellipse 4635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1134"
          data-name="Ellipse 1134"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6028"
          data-name="Ellipse 6028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4636"
          data-name="Ellipse 4636"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5256"
          data-name="Ellipse 5256"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_949"
          data-name="Ellipse 949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6029"
          data-name="Ellipse 6029"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4637"
          data-name="Ellipse 4637"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1127"
          data-name="Ellipse 1127"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1149 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6030"
          data-name="Ellipse 6030"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1397 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5142"
          data-name="Ellipse 5142"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1062 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_599"
          data-name="Ellipse 599"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1173"
          data-name="Ellipse 1173"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_965"
          data-name="Ellipse 965"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_913"
          data-name="Ellipse 913"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1160"
          data-name="Ellipse 1160"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_940"
          data-name="Ellipse 940"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1185 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_888"
          data-name="Ellipse 888"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_936"
          data-name="Ellipse 936"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1213"
          data-name="Ellipse 1213"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1002"
          data-name="Ellipse 1002"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1006"
          data-name="Ellipse 1006"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1013"
          data-name="Ellipse 1013"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1020"
          data-name="Ellipse 1020"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1005"
          data-name="Ellipse 1005"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1007"
          data-name="Ellipse 1007"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1014"
          data-name="Ellipse 1014"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1021"
          data-name="Ellipse 1021"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_995"
          data-name="Ellipse 995"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1215"
          data-name="Ellipse 1215"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1000"
          data-name="Ellipse 1000"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1008"
          data-name="Ellipse 1008"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1015"
          data-name="Ellipse 1015"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1022"
          data-name="Ellipse 1022"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_834"
          data-name="Ellipse 834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5920"
          data-name="Ellipse 5920"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4665"
          data-name="Ellipse 4665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5479"
          data-name="Ellipse 5479"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 300)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1174"
          data-name="Ellipse 1174"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5899"
          data-name="Ellipse 5899"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4528"
          data-name="Ellipse 4528"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5258"
          data-name="Ellipse 5258"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_976"
          data-name="Ellipse 976"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5900"
          data-name="Ellipse 5900"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4604"
          data-name="Ellipse 4604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5399"
          data-name="Ellipse 5399"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1118"
          data-name="Ellipse 1118"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5901"
          data-name="Ellipse 5901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5260"
          data-name="Ellipse 5260"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_927"
          data-name="Ellipse 927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5902"
          data-name="Ellipse 5902"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4639"
          data-name="Ellipse 4639"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5439"
          data-name="Ellipse 5439"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1135"
          data-name="Ellipse 1135"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5903"
          data-name="Ellipse 5903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4530"
          data-name="Ellipse 4530"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5262"
          data-name="Ellipse 5262"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_977"
          data-name="Ellipse 977"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5904"
          data-name="Ellipse 5904"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4562"
          data-name="Ellipse 4562"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5359"
          data-name="Ellipse 5359"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1114"
          data-name="Ellipse 1114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1125 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5905"
          data-name="Ellipse 5905"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1373 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5144"
          data-name="Ellipse 5144"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1038 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_442"
          data-name="Ellipse 442"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_477"
          data-name="Ellipse 477"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_547"
          data-name="Ellipse 547"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_600"
          data-name="Ellipse 600"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 472)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_672"
          data-name="Ellipse 672"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 472)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_498"
          data-name="Ellipse 498"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 487)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_601"
          data-name="Ellipse 601"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_684"
          data-name="Ellipse 684"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_531"
          data-name="Ellipse 531"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_602"
          data-name="Ellipse 602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_880"
          data-name="Ellipse 880"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_673"
          data-name="Ellipse 673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_840"
          data-name="Ellipse 840"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 451)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_457"
          data-name="Ellipse 457"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_470"
          data-name="Ellipse 470"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_532"
          data-name="Ellipse 532"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 463)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_603"
          data-name="Ellipse 603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 464)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_674"
          data-name="Ellipse 674"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 464)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_499"
          data-name="Ellipse 499"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_604"
          data-name="Ellipse 604"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 480)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_675"
          data-name="Ellipse 675"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 480)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_533"
          data-name="Ellipse 533"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4726"
          data-name="Ellipse 4726"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5754"
          data-name="Ellipse 5754"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1167 254)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1195"
          data-name="Ellipse 1195"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6115"
          data-name="Ellipse 6115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10086"
          data-name="Ellipse 10086"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1443 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10104"
          data-name="Ellipse 10104"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 531)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10112"
          data-name="Ellipse 10112"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 543)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10128"
          data-name="Ellipse 10128"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1448 555)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4727"
          data-name="Ellipse 4727"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_802"
          data-name="Ellipse 802"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5939"
          data-name="Ellipse 5939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4721"
          data-name="Ellipse 4721"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5589"
          data-name="Ellipse 5589"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5621"
          data-name="Ellipse 5621"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5653"
          data-name="Ellipse 5653"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5685"
          data-name="Ellipse 5685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(958 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_605"
          data-name="Ellipse 605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1196"
          data-name="Ellipse 1196"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_877"
          data-name="Ellipse 877"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_901"
          data-name="Ellipse 901"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_837"
          data-name="Ellipse 837"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4722"
          data-name="Ellipse 4722"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5525"
          data-name="Ellipse 5525"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 304)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1197"
          data-name="Ellipse 1197"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5921"
          data-name="Ellipse 5921"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4563"
          data-name="Ellipse 4563"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5323"
          data-name="Ellipse 5323"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_676"
          data-name="Ellipse 676"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_874"
          data-name="Ellipse 874"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_841"
          data-name="Ellipse 841"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 443)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5946"
          data-name="Ellipse 5946"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4723"
          data-name="Ellipse 4723"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 252)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5527"
          data-name="Ellipse 5527"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 304)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1198"
          data-name="Ellipse 1198"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5947"
          data-name="Ellipse 5947"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4564"
          data-name="Ellipse 4564"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5325"
          data-name="Ellipse 5325"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_447"
          data-name="Ellipse 447"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_471"
          data-name="Ellipse 471"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_542"
          data-name="Ellipse 542"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 467)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_606"
          data-name="Ellipse 606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 468)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_677"
          data-name="Ellipse 677"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 468)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_500"
          data-name="Ellipse 500"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 483)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_607"
          data-name="Ellipse 607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 484)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_678"
          data-name="Ellipse 678"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 484)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_534"
          data-name="Ellipse 534"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4763"
          data-name="Ellipse 4763"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_819"
          data-name="Ellipse 819"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4764"
          data-name="Ellipse 4764"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5593"
          data-name="Ellipse 5593"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_608"
          data-name="Ellipse 608"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_878"
          data-name="Ellipse 878"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_838"
          data-name="Ellipse 838"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4765"
          data-name="Ellipse 4765"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5565"
          data-name="Ellipse 5565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 308)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_679"
          data-name="Ellipse 679"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_876"
          data-name="Ellipse 876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_842"
          data-name="Ellipse 842"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 447)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4766"
          data-name="Ellipse 4766"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 256)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_452"
          data-name="Ellipse 452"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_501"
          data-name="Ellipse 501"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_631"
          data-name="Ellipse 631"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_685"
          data-name="Ellipse 685"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_652"
          data-name="Ellipse 652"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1211 492)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_667"
          data-name="Ellipse 667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1211 484)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_535"
          data-name="Ellipse 535"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_609"
          data-name="Ellipse 609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_680"
          data-name="Ellipse 680"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 455)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_472"
          data-name="Ellipse 472"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_632"
          data-name="Ellipse 632"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 496)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_655"
          data-name="Ellipse 655"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1211 496)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_670"
          data-name="Ellipse 670"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1211 488)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_536"
          data-name="Ellipse 536"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 459)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_610"
          data-name="Ellipse 610"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 460)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_681"
          data-name="Ellipse 681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 460)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_502"
          data-name="Ellipse 502"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_611"
          data-name="Ellipse 611"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 476)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_682"
          data-name="Ellipse 682"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 476)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_537"
          data-name="Ellipse 537"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6119"
          data-name="Ellipse 6119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4728"
          data-name="Ellipse 4728"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5755"
          data-name="Ellipse 5755"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1167 250)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1175"
          data-name="Ellipse 1175"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4729"
          data-name="Ellipse 4729"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5264"
          data-name="Ellipse 5264"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_967"
          data-name="Ellipse 967"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6121"
          data-name="Ellipse 6121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4730"
          data-name="Ellipse 4730"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1147"
          data-name="Ellipse 1147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5266"
          data-name="Ellipse 5266"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_916"
          data-name="Ellipse 916"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6123"
          data-name="Ellipse 6123"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4732"
          data-name="Ellipse 4732"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1148"
          data-name="Ellipse 1148"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4733"
          data-name="Ellipse 4733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5268"
          data-name="Ellipse 5268"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_944"
          data-name="Ellipse 944"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6125"
          data-name="Ellipse 6125"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1417 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10087"
          data-name="Ellipse 10087"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1443 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10105"
          data-name="Ellipse 10105"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 535)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10113"
          data-name="Ellipse 10113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 547)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10134"
          data-name="Ellipse 10134"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1456 547)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10129"
          data-name="Ellipse 10129"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1448 559)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10098"
          data-name="Ellipse 10098"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1443 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10106"
          data-name="Ellipse 10106"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 527)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10114"
          data-name="Ellipse 10114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10132"
          data-name="Ellipse 10132"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1468 539)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10122"
          data-name="Ellipse 10122"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1460 551)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10131"
          data-name="Ellipse 10131"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1456 551)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_10130"
          data-name="Ellipse 10130"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1448 551)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4734"
          data-name="Ellipse 4734"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1149"
          data-name="Ellipse 1149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1169 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5146"
          data-name="Ellipse 5146"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1082 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_803"
          data-name="Ellipse 803"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5922"
          data-name="Ellipse 5922"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4666"
          data-name="Ellipse 4666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5585"
          data-name="Ellipse 5585"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5617"
          data-name="Ellipse 5617"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5649"
          data-name="Ellipse 5649"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5681"
          data-name="Ellipse 5681"
          cx="1"
          cy="1"
          r="1"
          transform="translate(958 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5713"
          data-name="Ellipse 5713"
          cx="1"
          cy="1"
          r="1"
          transform="translate(942 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_984"
          data-name="Ellipse 984"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5906"
          data-name="Ellipse 5906"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4605"
          data-name="Ellipse 4605"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5569"
          data-name="Ellipse 5569"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5601"
          data-name="Ellipse 5601"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5633"
          data-name="Ellipse 5633"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5665"
          data-name="Ellipse 5665"
          cx="1"
          cy="1"
          r="1"
          transform="translate(958 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5403"
          data-name="Ellipse 5403"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1044"
          data-name="Ellipse 1044"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5822"
          data-name="Ellipse 5822"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5271"
          data-name="Ellipse 5271"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4834"
          data-name="Ellipse 4834"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1102 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1075"
          data-name="Ellipse 1075"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5823"
          data-name="Ellipse 5823"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5273"
          data-name="Ellipse 5273"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1088"
          data-name="Ellipse 1088"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 403)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5147"
          data-name="Ellipse 5147"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 264)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1102"
          data-name="Ellipse 1102"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 395)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5825"
          data-name="Ellipse 5825"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 479)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1107"
          data-name="Ellipse 1107"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 387)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5826"
          data-name="Ellipse 5826"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 471)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_931"
          data-name="Ellipse 931"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5907"
          data-name="Ellipse 5907"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4640"
          data-name="Ellipse 4640"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5577"
          data-name="Ellipse 5577"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5609"
          data-name="Ellipse 5609"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5641"
          data-name="Ellipse 5641"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5673"
          data-name="Ellipse 5673"
          cx="1"
          cy="1"
          r="1"
          transform="translate(958 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5705"
          data-name="Ellipse 5705"
          cx="1"
          cy="1"
          r="1"
          transform="translate(942 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1052"
          data-name="Ellipse 1052"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5863"
          data-name="Ellipse 5863"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 507)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4565"
          data-name="Ellipse 4565"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5570"
          data-name="Ellipse 5570"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5602"
          data-name="Ellipse 5602"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5733"
          data-name="Ellipse 5733"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5634"
          data-name="Ellipse 5634"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5732"
          data-name="Ellipse 5732"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5666"
          data-name="Ellipse 5666"
          cx="1"
          cy="1"
          r="1"
          transform="translate(958 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5327"
          data-name="Ellipse 5327"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 284)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4876"
          data-name="Ellipse 4876"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1102 232)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_985"
          data-name="Ellipse 985"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5864"
          data-name="Ellipse 5864"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4566"
          data-name="Ellipse 4566"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5571"
          data-name="Ellipse 5571"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5749"
          data-name="Ellipse 5749"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1006 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5603"
          data-name="Ellipse 5603"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5746"
          data-name="Ellipse 5746"
          cx="1"
          cy="1"
          r="1"
          transform="translate(990 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5635"
          data-name="Ellipse 5635"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5747"
          data-name="Ellipse 5747"
          cx="1"
          cy="1"
          r="1"
          transform="translate(974 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5667"
          data-name="Ellipse 5667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(958 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5363"
          data-name="Ellipse 5363"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1033"
          data-name="Ellipse 1033"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5827"
          data-name="Ellipse 5827"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5275"
          data-name="Ellipse 5275"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1064"
          data-name="Ellipse 1064"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5828"
          data-name="Ellipse 5828"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5149"
          data-name="Ellipse 5149"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1081"
          data-name="Ellipse 1081"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 399)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5080"
          data-name="Ellipse 5080"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1022 260)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1095"
          data-name="Ellipse 1095"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1109 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5830"
          data-name="Ellipse 5830"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1357 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1293"
          data-name="Ellipse 1293"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1105 391)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5831"
          data-name="Ellipse 5831"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1353 475)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_612"
          data-name="Ellipse 612"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1178"
          data-name="Ellipse 1178"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_968"
          data-name="Ellipse 968"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_914"
          data-name="Ellipse 914"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_939"
          data-name="Ellipse 939"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1181"
          data-name="Ellipse 1181"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1189 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1205"
          data-name="Ellipse 1205"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1197 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1207"
          data-name="Ellipse 1207"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_879"
          data-name="Ellipse 879"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_937"
          data-name="Ellipse 937"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1214"
          data-name="Ellipse 1214"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1001"
          data-name="Ellipse 1001"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1009"
          data-name="Ellipse 1009"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1016"
          data-name="Ellipse 1016"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1023"
          data-name="Ellipse 1023"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_996"
          data-name="Ellipse 996"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_999"
          data-name="Ellipse 999"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1010"
          data-name="Ellipse 1010"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1017"
          data-name="Ellipse 1017"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1024"
          data-name="Ellipse 1024"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1004"
          data-name="Ellipse 1004"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6909"
          data-name="Ellipse 6909"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1012"
          data-name="Ellipse 1012"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1028"
          data-name="Ellipse 1028"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6908"
          data-name="Ellipse 6908"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1019"
          data-name="Ellipse 1019"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1027"
          data-name="Ellipse 1027"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1218"
          data-name="Ellipse 1218"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1225 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1217"
          data-name="Ellipse 1217"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1229 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6907"
          data-name="Ellipse 6907"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1233 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1026"
          data-name="Ellipse 1026"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_997"
          data-name="Ellipse 997"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_6910"
          data-name="Ellipse 6910"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1221 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_998"
          data-name="Ellipse 998"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 423)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1011"
          data-name="Ellipse 1011"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1018"
          data-name="Ellipse 1018"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1025"
          data-name="Ellipse 1025"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1217 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_903"
          data-name="Ellipse 903"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1216"
          data-name="Ellipse 1216"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1213 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_839"
          data-name="Ellipse 839"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5923"
          data-name="Ellipse 5923"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4667"
          data-name="Ellipse 4667"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5485"
          data-name="Ellipse 5485"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 300)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1176"
          data-name="Ellipse 1176"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5924"
          data-name="Ellipse 5924"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4536"
          data-name="Ellipse 4536"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5277"
          data-name="Ellipse 5277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_974"
          data-name="Ellipse 974"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5925"
          data-name="Ellipse 5925"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4606"
          data-name="Ellipse 4606"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5405"
          data-name="Ellipse 5405"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1119"
          data-name="Ellipse 1119"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5926"
          data-name="Ellipse 5926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5279"
          data-name="Ellipse 5279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_926"
          data-name="Ellipse 926"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5927"
          data-name="Ellipse 5927"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4641"
          data-name="Ellipse 4641"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5445"
          data-name="Ellipse 5445"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1136"
          data-name="Ellipse 1136"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5928"
          data-name="Ellipse 5928"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4538"
          data-name="Ellipse 4538"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5281"
          data-name="Ellipse 5281"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_975"
          data-name="Ellipse 975"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5929"
          data-name="Ellipse 5929"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4567"
          data-name="Ellipse 4567"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5365"
          data-name="Ellipse 5365"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1115"
          data-name="Ellipse 1115"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1129 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5930"
          data-name="Ellipse 5930"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1377 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5151"
          data-name="Ellipse 5151"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1042 268)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_683"
          data-name="Ellipse 683"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_970"
          data-name="Ellipse 970"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1201"
          data-name="Ellipse 1201"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_910"
          data-name="Ellipse 910"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1202"
          data-name="Ellipse 1202"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_938"
          data-name="Ellipse 938"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1203"
          data-name="Ellipse 1203"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1193 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1208"
          data-name="Ellipse 1208"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1205 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1204"
          data-name="Ellipse 1204"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1201 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_875"
          data-name="Ellipse 875"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1209 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_843"
          data-name="Ellipse 843"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 439)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5948"
          data-name="Ellipse 5948"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 523)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4668"
          data-name="Ellipse 4668"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 248)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5487"
          data-name="Ellipse 5487"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 300)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1177"
          data-name="Ellipse 1177"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 419)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5949"
          data-name="Ellipse 5949"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 503)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4568"
          data-name="Ellipse 4568"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 228)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5283"
          data-name="Ellipse 5283"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 280)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_972"
          data-name="Ellipse 972"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 431)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5950"
          data-name="Ellipse 5950"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 515)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4607"
          data-name="Ellipse 4607"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 240)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5407"
          data-name="Ellipse 5407"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 292)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1120"
          data-name="Ellipse 1120"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 411)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5951"
          data-name="Ellipse 5951"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 495)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5285"
          data-name="Ellipse 5285"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 272)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_925"
          data-name="Ellipse 925"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 435)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5952"
          data-name="Ellipse 5952"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 519)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4642"
          data-name="Ellipse 4642"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 244)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5447"
          data-name="Ellipse 5447"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 296)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1137"
          data-name="Ellipse 1137"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 415)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5953"
          data-name="Ellipse 5953"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 499)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4570"
          data-name="Ellipse 4570"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 224)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5287"
          data-name="Ellipse 5287"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 276)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_973"
          data-name="Ellipse 973"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 427)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5954"
          data-name="Ellipse 5954"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 511)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_4571"
          data-name="Ellipse 4571"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 236)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5367"
          data-name="Ellipse 5367"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 288)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_1121"
          data-name="Ellipse 1121"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1133 407)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5955"
          data-name="Ellipse 5955"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1381 491)"
          fill="#cdcdcd"
        />
        <circle
          id="Ellipse_5153"
          data-name="Ellipse 5153"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1046 268)"
          fill="#cdcdcd"
        />
      </g>
      <g id="Group_249" data-name="Group 249" transform="translate(-322.3 -192.546)">
        {/* US West */}
        <g
          id="Ellipse_150"
          data-name="Ellipse 171"
          transform="translate(1186.957 546.118) rotate(-45)"
          fill={regions.includes(NSP_REGIONS.US_WEST.value) ? '#1bf6a1' : '#26c9ea'}
          stroke={regions.includes(NSP_REGIONS.US_WEST.value) ? '#1bf6a1' : '#26c9ea'}
          strokeWidth="1"
          onClick={() => props.onClick(NSP_REGIONS.US_WEST.value)}
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_151"
          data-name="Ellipse 170"
          transform="translate(1182.062 546.118) rotate(-45)"
          fill={regions.includes(NSP_REGIONS.US_WEST.value) ? '#1bf6a1' : 'none'}
          stroke={regions.includes(NSP_REGIONS.US_WEST.value) ? '#1bf6a1' : '#26c9ea'}
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_152"
          data-name="Ellipse 175"
          transform="translate(1176.457 546.118) rotate(-45)"
          fill="none"
          stroke={regions.includes(NSP_REGIONS.US_WEST.value) ? '#1bf6a1' : '#26c9ea'}
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {regions.includes(NSP_REGIONS.US_WEST.value) && (
          <g
            id="Ellipse_171"
            data-name="Ellipse 171"
            transform="translate(1166.857 546.118) rotate(-45)"
            fill="none"
            stroke="#1bf6a1"
            strokeWidth="1"
          >
            <circle cx="21.61" cy="21.61" r="21.61" stroke="none" />
            <circle cx="21.61" cy="21.61" r="21.11" fill="none" />
          </g>
        )}
        {/* US East */}
        <g
          id="Ellipse_153"
          data-name="Ellipse 166"
          transform="translate(1288.162 546.118) rotate(-45)"
          fill={regions.includes(NSP_REGIONS.US_EAST.value) ? '#1bf6a1' : '#26c9ea'}
          stroke={regions.includes(NSP_REGIONS.US_EAST.value) ? '#1bf6a1' : '#26c9ea'}
          strokeWidth="1"
          onClick={() => props.onClick(NSP_REGIONS.US_EAST.value)}
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_154"
          data-name="Ellipse 163"
          transform="translate(1283.268 546.118) rotate(-45)"
          fill={regions.includes(NSP_REGIONS.US_EAST.value) ? '#1bf6a1' : 'none'}
          stroke={regions.includes(NSP_REGIONS.US_EAST.value) ? '#1bf6a1' : '#26c9ea'}
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_155"
          data-name="Ellipse 172"
          transform="translate(1277.662 546.118) rotate(-45)"
          fill="none"
          stroke={regions.includes(NSP_REGIONS.US_EAST.value) ? '#1bf6a1' : '#26c9ea'}
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {regions.includes(NSP_REGIONS.US_EAST.value) && (
          <g
            id="Ellipse_166"
            data-name="Ellipse 166"
            transform="translate(1268.062 546.118) rotate(-45)"
            fill="none"
            stroke="#1bf6a1"
            strokeWidth="1"
          >
            <circle cx="21.61" cy="21.61" r="21.61" stroke="none" />
            <circle cx="21.61" cy="21.61" r="21.11" fill="none" />
          </g>
        )}
        {/* Canada */}
        <g
          id="Ellipse_156"
          data-name="Ellipse 177"
          transform="translate(1239.513 483.796) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_157"
          data-name="Ellipse 178"
          transform="translate(1234.618 483.796) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_158"
          data-name="Ellipse 176"
          transform="translate(1229.013 483.796) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {/* LatAm */}
        <g
          id="Ellipse_159"
          data-name="Ellipse 156"
          transform="translate(1327.017 681.199) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_160"
          data-name="Ellipse 154"
          transform="translate(1322.125 681.199) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_161"
          data-name="Ellipse 158"
          transform="translate(1316.518 681.199) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {/* EU West */}
        <g
          id="Ellipse_162"
          data-name="Ellipse 167"
          transform="translate(1452.018 546.118) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_163"
          data-name="Ellipse 164"
          transform="translate(1447.124 546.118) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_164"
          data-name="Ellipse 173"
          transform="translate(1441.518 546.118) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {/* EU East */}
        <g
          id="Ellipse_165"
          data-name="Ellipse 168"
          transform="translate(1544.643 546.118) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_166"
          data-name="Ellipse 165"
          transform="translate(1539.749 546.118) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_167"
          data-name="Ellipse 174"
          transform="translate(1534.143 546.118) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {/* Asia */}
        <g
          id="Ellipse_168"
          data-name="Ellipse 157"
          transform="translate(1675.801 590.696) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_169"
          data-name="Ellipse 155"
          transform="translate(1670.906 590.696) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_170"
          data-name="Ellipse 159"
          transform="translate(1665.3 590.696) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {/* Australia */}
        <g
          id="Ellipse_171"
          data-name="Ellipse 150"
          transform="translate(1706.363 708.879) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_172"
          data-name="Ellipse 149"
          transform="translate(1701.468 708.879) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_173"
          data-name="Ellipse 151"
          transform="translate(1695.862 708.879) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
          onClick={() => props.onClick(NSP_REGIONS.US_TEST.value)}
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        {/* Africa */}
        <g
          id="Ellipse_174"
          data-name="Ellipse 156"
          transform="translate(1487.017 681.199) rotate(-45)"
          fill="#ccc"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="7.61" cy="7.61" r="7.61" stroke="none" />
          <circle cx="7.61" cy="7.61" r="7.11" fill="none" />
        </g>
        <g
          id="Ellipse_175"
          data-name="Ellipse 154"
          transform="translate(1482.125 681.199) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="10.822" cy="10.822" r="10.822" stroke="none" />
          <circle cx="10.822" cy="10.822" r="10.322" fill="none" />
        </g>
        <g
          id="Ellipse_176"
          data-name="Ellipse 158"
          transform="translate(1476.518 681.199) rotate(-45)"
          fill="none"
          stroke="#ccc"
          strokeWidth="1"
        >
          <circle cx="14.822" cy="14.822" r="14.822" stroke="none" />
          <circle cx="14.822" cy="14.822" r="14.322" fill="none" />
        </g>
        <text
          id="US_West"
          data-name="US West"
          transform="translate(1131.054 532.684)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#478cc9"
          onClick={() => props.onClick(NSP_REGIONS.US_WEST.value)}
        >
          <tspan x="0" y="0">
            US West
          </tspan>
        </text>
        <text
          id="US_East"
          data-name="US East"
          transform="translate(1314.004 537.122)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#478cc9"
          onClick={() => props.onClick(NSP_REGIONS.US_EAST.value)}
        >
          <tspan x="0" y="0">
            US East
          </tspan>
        </text>
        <text
          id="Canada"
          transform="translate(1187.997 470.362)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            Canada
          </tspan>
        </text>
        <text
          id="LatAm"
          transform="translate(1352.422 672.443)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            LatAm
          </tspan>
        </text>
        <text
          id="EU_West"
          data-name="EU West"
          transform="translate(1390.243 537.122)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            EU West
          </tspan>
        </text>
        <text
          id="EU_East"
          data-name="EU East"
          transform="translate(1570.16 537.122)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            EU East
          </tspan>
        </text>
        <text
          id="Asia"
          transform="translate(1700.289 580.889)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            Asia
          </tspan>
        </text>
        <text
          id="Australia"
          transform="translate(1732.323 704.162)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            Australia
          </tspan>
        </text>
        <text
          id="Africa"
          transform="translate(1512.422 672.443)"
          fontSize="16"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fill="#6f6f6f"
        >
          <tspan x="0" y="0">
            Africa
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

NSPMap.defaultProps = {
  regions: [],
};

NSPMap.propTypes = {
  onClick: PropTypes.func,
  regions: PropTypes.array,
};

export default NSPMap;
