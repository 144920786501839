export const SET_USER = 'SET_USER';
export const SET_EMAIL = 'SET_EMAIL';
export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_PASSWORD_ERROR = 'CLEAR_PASSWORD_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const SET_INIT_COMPANY_NAME = 'SET_INIT_CPMPANY_NAME';
export const COMPLETE_USER = 'COMPLETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const EMAIL_CONFIRM_FAILURE = 'EMAIL_CONFIRM_FAILURE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CONFIG_OTP = 'CONFIG_OTP';
export const SET_USER_OTP = 'SET_USER_OTP';
