/**
 * NAT Table fields
 */

export default [
  { name: 'Name', center: false },
  { name: 'Source', center: false },
  { name: 'Destination', center: false },
  { name: 'Destination Port', center: false },
  { name: '', center: false },
  { name: 'Source', center: false },
  { name: 'Destination', center: false },
  { name: 'Destination Port', center: false },
];
