/**
 * Event Actions for Policy
 */

export default {
  ALLOW: 'Allow',
  BLOCK: 'Block',
  ALERT: 'Alert',
  DENIED: 'Access denied',
  CONNECT: 'Connected',
  DISCONNECT: 'Disconnected',
  DROP: 'Drop',
  ERROR: 'Error',
  TRYING: 'Trying',
};
