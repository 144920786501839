import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import Files from 'react-files';
import imageCompression from 'browser-image-compression';
import ReactFlagsSelect from 'react-flags-select';
import { Row, Col, Button } from 'react-bootstrap';
import ReactPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import CountryList from 'country-list';

import history from '../../../history';
import { updateCustomerInfo } from '../senario-actions';

import '../user-profile.scss';
import 'react-flags-select/scss/react-flags-select.scss';
import 'react-phone-number-input/style.css';

const countries = CountryList.getData();

class Organization extends React.Component {
  constructor(props) {
    super(props);
    const { curCustomer } = this.props;
    const orgForm = this.setOrgForm(curCustomer);
    this.state = {
      orgForm,
      originalForm: { ...orgForm },
      unitedError: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { curCustomer } = this.props;

    if (curCustomer && prevProps.curCustomer !== curCustomer) {
      const orgForm = this.setOrgForm(curCustomer);
      this.setState({
        orgForm,
        originalForm: { ...orgForm },
        unitedError: '',
      });
    }
  }

  setOrgForm = (curCustomer) => ({
    logo: curCustomer.logo,
    companyName: curCustomer.name,
    address: curCustomer.address.line1,
    country: CountryList.getName(curCustomer.address.country.toUpperCase()),
    city: curCustomer.address.city,
    zipCode: curCustomer.address.postal_code,
    state: curCustomer.address.state,
    name: curCustomer.contact.name,
    phone: curCustomer.contact.phone,
  });

  isFormChanged = () => {
    const { originalForm, orgForm } = this.state;
    return JSON.stringify(originalForm) !== JSON.stringify(orgForm);
  };

  handleChange = (event) => {
    const { orgForm } = this.state;
    this.setState(
      {
        orgForm: {
          ...orgForm,
          [event.target.name]: event.target.value,
        },
        unitedError: '',
      },
      () => {
        this.checkFormChanged();
      },
    );
  };

  handleSelectFlag = (countryCode) => {
    const { orgForm } = this.state;
    this.setState(
      {
        orgForm: {
          ...orgForm,
          country: countries.find((k) => k.code === countryCode).name,
        },
      },
      () => {
        this.checkFormChanged();
      },
    );
  };

  handleFilesError = (err) => {
    this.setState({ unitedError: err });
  };

  handleFilesChange = async (files) => {
    const { orgForm } = this.state;
    const file = files[files.length - 1];
    const options = {
      maxSizeMB: 10000 / 1024 / 1024 / 1024,
    };

    const compressedFile = await imageCompression(file, options);
    const base64 = await imageCompression.getDataUrlFromFile(compressedFile);
    this.setState({ orgForm: { ...orgForm, logo: base64 }, unitedError: '' }, () => {
      this.checkFormChanged();
    });
  };

  handleCancel = () => {
    history.goBack();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.isValid()) {
      return;
    }
    if (!this.isFormChanged()) {
      this.handleCancel();
      return;
    }
    const { orgForm } = this.state;
    const customer = {
      address: {
        city: orgForm.city,
        country: CountryList.getCode(orgForm.country).toLowerCase(),
        line1: orgForm.address,
        postal_code: orgForm.zipCode,
        state: orgForm.state,
      },
      contact: {
        name: orgForm.name,
        phone: orgForm.phone,
      },
      logo: orgForm.logo,
      name: orgForm.companyName,
    };
    this.props.updateCustomerInfo({ customer });
  };

  isValid() {
    const { phone } = this.state.orgForm;
    const regex = /^\+\d+\s*(\(\d+\))?[- \d]+$/;

    if (!phone) {
      this.setState({ unitedError: 'Phone Number Is Required' });
      return false;
    }
    if (!regex.test(phone) || !isValidPhoneNumber(phone)) {
      this.setState({ unitedError: 'Invalid Phone Number' });
      return false;
    }
    return true;
  }

  checkFormChanged() {
    this.props.onFormChanged(this.isFormChanged());
  }

  render() {
    const {
      orgForm,
      orgForm: { logo, companyName, address, country, city, zipCode, state, name, phone },
      unitedError,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row organization-profile-container">
          <div className="col col-xs-12 col-sm-3 col-md-2 col-lg-2 avatar-container">
            <div className="left-align">
              <Avatar src={logo} size="132" round />
              <Files
                className="files-dropzone"
                onChange={this.handleFilesChange}
                onError={this.handleFilesError}
                accepts={['image/*']}
                multiple={false}
              >
                <Button className="button button-change button-change-avatar">Change</Button>
              </Files>
              <div
                className="button-remove"
                onClick={() => {
                  this.setState(
                    {
                      orgForm: { ...orgForm, logo: '' },
                      unitedError: '',
                    },
                    () => {
                      this.checkFormChanged();
                    },
                  );
                }}
              >
                Remove
              </div>
            </div>
          </div>
          <div className="col col-xs-12 col-sm-9 col-md-10 form-container">
            <div className="company-info-form">
              <Row>
                {unitedError && (
                  <Col md={12}>
                    <div className="error-msg">{unitedError}</div>
                  </Col>
                )}
                <Col md={12} className="col">
                  <label>
                    <span className="labelForm">COMPANY NAME</span>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      className="inputField"
                      onChange={this.handleChange}
                      name="companyName"
                      value={companyName}
                      maxLength={250}
                      required
                    />
                  </label>
                </Col>
                <Col md={12} className="col">
                  <label>
                    <span className="labelForm">STREET ADDRESS</span>
                    <input
                      type="text"
                      placeholder="Enter Street Address"
                      className="inputField"
                      onChange={this.handleChange}
                      name="address"
                      value={address}
                      required
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">COUNTRY</span>
                    <ReactFlagsSelect
                      name="country"
                      defaultCountry={countries.find((k) => k.name === country).code}
                      searchable
                      onSelect={this.handleSelectFlag}
                      className="inputField countrySelect"
                    />
                  </label>
                </Col>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">CITY</span>
                    <input
                      type="text"
                      placeholder="Enter City"
                      className="inputField"
                      onChange={this.handleChange}
                      name="city"
                      value={city}
                      required
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">STATE</span>
                    <input
                      type="text"
                      placeholder="Enter State"
                      className="inputField"
                      onChange={this.handleChange}
                      name="state"
                      value={state}
                      required
                    />
                  </label>
                </Col>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">ZIPCODE</span>
                    <input
                      placeholder="Enter Zipcode"
                      className="inputField"
                      onChange={this.handleChange}
                      minLength={5}
                      maxLength={6}
                      name="zipCode"
                      value={zipCode}
                      required
                    />
                  </label>
                </Col>
              </Row>
            </div>
            <div className="contact-form">
              <h4 className="title">Administrative Contact</h4>
              <div className="divider" />
              <Row>
                <Col xs={12} md={6}>
                  <label>
                    <span className="labelForm">NAME</span>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="inputField"
                      onChange={this.handleChange}
                      name="name"
                      value={name}
                      required
                    />
                  </label>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <span className="labelForm">PHONE</span>
                    <ReactPhoneInput
                      country="US"
                      placeholder="Enter Number"
                      className="inputField"
                      onChange={(value) => {
                        this.setState({ orgForm: { ...orgForm, phone: value } }, () => {
                          this.checkFormChanged();
                        });
                      }}
                      name="phone"
                      value={phone}
                      required
                    />
                  </label>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="button-container">
          <Button className="button button-cancel" onClick={() => this.handleCancel()}>
            Cancel
          </Button>
          <Button className="button button-update" type="submit">
            Update
          </Button>
        </div>
      </form>
    );
  }
}

Organization.propTypes = {
  updateCustomerInfo: PropTypes.func.isRequired,
  curCustomer: PropTypes.object.isRequired,
  onFormChanged: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  curCustomer: state.customers.currentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  updateCustomerInfo: (info) => dispatch(updateCustomerInfo(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
