import { createAction } from 'redux-actions';
import {
  CREATE_ECOSYSTEM_FAILURE,
  CREATE_ECOSYSTEM_REQUESTED,
  CREATE_ECOSYSTEM_SUCCESS,
  CREATE_ECOSYSTEM_GROUP_REQUESTED,
  CREATE_ECOSYSTEM_GROUP_SUCCESS,
  CREATE_ECOSYSTEM_GROUP_FAILURE,
  CREATE_SERVICE_FAILURE,
  CREATE_SERVICE_REQUESTED,
  CREATE_SERVICE_SUCCESS,
  FETCHING_ECOSYSTEMS_FAILURE,
  FETCHING_ECOSYSTEMS_REQUESTED,
  FETCHING_ECOSYSTEMS_SUCCESS,
  FETCHING_NSP_FAILURE,
  FETCHING_NSP_SUCCESS,
  FETCHING_NSP_REQUESTED,
  LOAD_ECOSYSTEM_REQUESTED,
  LOAD_ECOSYSTEM_SUCCESS,
  LOAD_ECOSYSTEM_FAILURE,
  REMOVE_ECOSYSTEM_FAILURE,
  REMOVE_ECOSYSTEM_REQUESTED,
  REMOVE_ECOSYSTEM_SUCCESS,
  REFRESH_ECOSYSTEM_REQUESTED,
  REFRESH_ECOSYSTEM_SUCCESS,
  RESET_REQUEST_ID,
  SAVE_COMMIT_REQUEST,
  SAVE_REVERT_REQUEST,
  SET_CURRENT_ECOSYSTEM,
  SET_ECOSYSTEM_STATUS,
  GRANT_ACCESS_REQUESTED,
  GRANT_ACCESS_SUCCESS,
  GRANT_ACCESS_FAILURE,
  EDIT_ECOSYSTEM_REQUESTED,
  EDIT_ECOSYSTEM_SUCCESS,
  EDIT_ECOSYSTEM_FAILURE,
  UPGRADE_ECOSYSTEM_REQUESTED,
  UPGRADE_ECOSYSTEM_SUCCESS,
  UPGRADE_ECOSYSTEM_FAILURE,
  RECOVER_ECOSYSTEM_REQUESTED,
  RECOVER_ECOSYSTEM_SUCCESS,
  RECOVER_ECOSYSTEM_FAILURE,
  CREATE_ECOSYSTEM_ROLE_REQUESTED,
  CREATE_ECOSYSTEM_ROLE_SUCCESS,
  CREATE_ECOSYSTEM_ROLE_FAILURE,
  GET_ECOSYSTEM_USER_REQUESTED,
  GET_ECOSYSTEM_USER_SUCCESS,
  GET_ECOSYSTEM_USER_FAILURE,
} from './action-types';

export const createEcosystemGroupRequested = createAction(CREATE_ECOSYSTEM_GROUP_REQUESTED);
export const createEcosystemGroupSuccess = createAction(CREATE_ECOSYSTEM_GROUP_SUCCESS);
export function createEcosystemGroupFailure(err) {
  return {
    type: CREATE_ECOSYSTEM_GROUP_FAILURE,
    payload: {
      message: err,
    },
  };
}
export const createServiceRequested = createAction(CREATE_SERVICE_REQUESTED);
export const createServiceSuccess = createAction(CREATE_SERVICE_SUCCESS);
export function createServiceFailure(err) {
  return {
    type: CREATE_SERVICE_FAILURE,
    payload: {
      message: err,
    },
  };
}
export function createEcosystemStarted() {
  return {
    type: CREATE_ECOSYSTEM_REQUESTED,
  };
}
export function createEcosystemSuccess(result) {
  return {
    type: CREATE_ECOSYSTEM_SUCCESS,
    payload: result,
  };
}
export function createEcosystemFailed(err) {
  return {
    type: CREATE_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function fetchingEcosystemsStarted() {
  return {
    type: FETCHING_ECOSYSTEMS_REQUESTED,
  };
}
export function fetchingEcosystemsSuccess(results) {
  return {
    type: FETCHING_ECOSYSTEMS_SUCCESS,
    payload: results,
  };
}
export function fetchingEcosystemsFailure(err) {
  return {
    type: FETCHING_ECOSYSTEMS_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function fetchingNSPStarted() {
  return {
    type: FETCHING_NSP_REQUESTED,
  };
}
export function fetchingNSPSuccess(results) {
  return {
    type: FETCHING_NSP_SUCCESS,
    payload: results,
  };
}
export function fetchingNSPFailure(err) {
  return {
    type: FETCHING_NSP_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function loadEcosystemStarted() {
  return {
    type: LOAD_ECOSYSTEM_REQUESTED,
  };
}
export function loadEcosystemSuccess(data) {
  return {
    type: LOAD_ECOSYSTEM_SUCCESS,
    payload: data,
  };
}
export function loadEcosystemFailed(err) {
  return {
    type: LOAD_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function refreshEcosystemStarted() {
  return {
    type: REFRESH_ECOSYSTEM_REQUESTED,
  };
}
export function refreshEcosystemSuccess(data) {
  return {
    type: REFRESH_ECOSYSTEM_SUCCESS,
    payload: data,
  };
}

export function removeEcosystemStarted() {
  return {
    type: REMOVE_ECOSYSTEM_REQUESTED,
  };
}
export function removeEcosystemSuccess() {
  return {
    type: REMOVE_ECOSYSTEM_SUCCESS,
  };
}
export function removeEcosystemFailed(err) {
  return {
    type: REMOVE_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function upgradeEcosystemStarted() {
  return {
    type: UPGRADE_ECOSYSTEM_REQUESTED,
  };
}
export function upgradeEcosystemSuccess() {
  return {
    type: UPGRADE_ECOSYSTEM_SUCCESS,
  };
}
export function upgradeEcosystemFailed(err) {
  return {
    type: UPGRADE_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function recoverEcosystemStarted() {
  return {
    type: RECOVER_ECOSYSTEM_REQUESTED,
  };
}
export function recoverEcosystemSuccess() {
  return {
    type: RECOVER_ECOSYSTEM_SUCCESS,
  };
}
export function recoverEcosystemFailed(err) {
  return {
    type: RECOVER_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function editEcosystemStarted() {
  return {
    type: EDIT_ECOSYSTEM_REQUESTED,
  };
}
export function editEcosystemSuccess(ecosystem) {
  return {
    type: EDIT_ECOSYSTEM_SUCCESS,
    payload: ecosystem,
  };
}
export function editEcosystemFailed(err) {
  return {
    type: EDIT_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function getEcoUserStarted() {
  return { type: GET_ECOSYSTEM_USER_REQUESTED };
}
export function getEcoUserSuccess(users) {
  return { type: GET_ECOSYSTEM_USER_SUCCESS, payload: users };
}
export function getEcoUserFailed(err) {
  return { type: GET_ECOSYSTEM_USER_FAILURE, payload: { message: err } };
}

export function setCurrentEcosystem(ecosystem) {
  return {
    type: SET_CURRENT_ECOSYSTEM,
    payload: ecosystem,
  };
}
export function saveCommitID(payload) {
  return {
    type: SAVE_COMMIT_REQUEST,
    payload,
  };
}
export function saveRevertID(payload) {
  return {
    type: SAVE_REVERT_REQUEST,
    payload,
  };
}

export function setEcosystemStatus(status) {
  return {
    type: SET_ECOSYSTEM_STATUS,
    payload: status,
  };
}

export function grantAccessStarted() {
  return {
    type: GRANT_ACCESS_REQUESTED,
  };
}
export function grantAccessSuccess() {
  return {
    type: GRANT_ACCESS_SUCCESS,
  };
}
export function grantACcessFailed() {
  return {
    type: GRANT_ACCESS_FAILURE,
  };
}

export const resetRequestID = createAction(RESET_REQUEST_ID);

export const createEcosystemRoleRequested = createAction(CREATE_ECOSYSTEM_ROLE_REQUESTED);
export const createEcosystemRoleSuccess = createAction(CREATE_ECOSYSTEM_ROLE_SUCCESS);
export const createEcosystemRoleFailure = createAction(CREATE_ECOSYSTEM_ROLE_FAILURE);
