import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export class Card extends PureComponent {
  render() {
    const { category, children, className, hCenter, header, plain, title } = this.props;
    return (
      <div className={cx('card', { 'card-plain': plain }, className)}>
        {header && (
          <div className={`header${hCenter ? ' text-center' : ''}`}>
            <h4 className="title">{title}</h4>
            <p className="category">{category}</p>
          </div>
        )}
        <div className="content">{children}</div>
      </div>
    );
  }
}

Card.propTypes = {
  className: PropTypes.string,
  header: PropTypes.bool,
  plain: PropTypes.bool,
  hCenter: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
  children: PropTypes.any,
};

Card.defaultProps = {
  header: true,
};

export default Card;
