import { object, string } from 'yup';

export const COMPANY_NAME = 'companyName';
export const ACRETO_CONTRACT_ID = 'acretoContractId';

const personalInfoValidationSchema = object({
  [COMPANY_NAME]: string('Company Name')
    .min(2, 'Company Name should be more than 2 characters.')
    .max(100, 'Company Name should be less than 100 characters.')
    .required('Company Name is required.'),
  [ACRETO_CONTRACT_ID]: string('Acreto Contract Id').required('Acreto Contract ID is required.'),
});

export default personalInfoValidationSchema;
