import { CONSTANTS } from '../../enum';
import { rest } from '../../api/rest';

export const createUser = ({ email }) => rest.post('/v2/users', { email });

export const loginUserForToken = (email) =>
  rest.post(`v2/auth/login`, {
    username: email,
    password: 'VeryLongDefP@SS',
  });

export const resetPasswordEmail = ({ username }) => rest.post('/v2/auth/resetpassword', { username });

export const updatePassword = (payload) => {
  const token = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
  return rest.post(`/v2/auth/updatepassword?_token=${token}`, payload);
};

export const fulfillUser = ({ email, firstName, lastName }) => {
  const path = `/v2/users/${email}`;
  return rest.put(path, { email, firstName, lastName });
};

export const readUserData = (username) => rest.get(`v2/users/${username}`);
export const readUserAvatar = (username) =>
  rest.get(`/v2/users/${username}/avatar?noerror=true`, { headers: { Accept: 'text/plain' } });

export const createCustomer = (requestBody) => rest.post('v2/customers', requestBody);

export const patchBilling = ({ requestBody, customerUUID }) => rest.patch(`v2/customers/${customerUUID}`, requestBody);

export const registerPasswordCall = ({ email, password }) => {
  const path = `v2/users/${email}/password`;
  return rest.post(path, { password });
};

export const updatePasswordCall = ({ email, password }) => {
  const path = `v2/users/${email}/password`;
  return rest.post(path, { password });
};

export const loginUserCall = ({ password, username }) =>
  rest.post('v2/auth/login', {
    username,
    password,
  });

export const updateUserCall = (payload) =>
  rest.put(`v2/users/${payload.email}`, payload).then((response) => response.data);

export const getThingToken = ({ customerUUID, ecosystemUUID, thingUUID }) =>
  rest
    .post(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/devices/${thingUUID}/token`)
    .then((response) => response.data);

export const getLDAPToken = ({ customerUUID, ecosystemUUID, userUUID }) =>
  rest
    .post(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/users/${userUUID}/token`)
    .then((response) => response.data);
