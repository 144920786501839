import { object, string, ref } from 'yup';

const personalInfoValidationSchema = object({
  firstName: string('First Name')
    .max(100, 'First Name be less than 100 characters.')
    .required('First name is required.'),
  lastName: string('Last Name')
    .max(100, 'Last Name should be less than 100 characters.')
    .required('Last name is required.'),
  password: string('Password')
    .min(8, 'Password should be more than 8 characters.')
    .max(100, 'Password should be less than 100 characters.')
    .required('Passwrod is required.'),
  passwordConfirmation: string()
    .oneOf([ref('password')], 'Passwords do not match.')
    .required('Password confirmation is required.'),
});

export default personalInfoValidationSchema;
