/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import cx from 'classnames';

import { openEcosystem } from '../../scenario-actions';

import { ECOSYSTEM_STATUS, NSP_REGIONS } from '../../../../enum';
import { ICON_MORE } from '../../../../assets/Icons';
import PencilSVG from '../../../../assets/img/SVG/pencil-edit.svg';
import NSPMap from '../../../../assets/NSPMap';

import './styles.scss';

const CustomSlider = withStyles({
  root: {
    marginBottom: 8,
    paddingTop: 0,
  },
  thumb: {
    backgroundColor: '#612ba3',
    marginTop: -2,
    marginLeft: -12,
    width: 24,
    height: 24,
  },
  mark: {
    backgroundColor: '#612ba3',
    height: 8,
    marginTop: -8,
  },
  markActive: {
    backgroundColor: '#612ba3',
  },
  markLabel: {
    top: -20,
    fontSize: 12,
  },
  track: {
    height: 20,
    borderRadius: 10,
    backgroundColor: '#612ba3',
  },
  rail: {
    height: 20,
    borderRadius: 10,
  },
})(Slider);

const bandWidthRange = [
  { value: 100, label: '100' },
  { value: 250, label: '250' },
  { value: 500, label: '500' },
  { value: 750, label: '750' },
  { value: 1000, label: '1000' },
  { value: 1500, label: '1500' },
  { value: 2000, label: '2000' },
];

const EcosystemCard = ({ data, isNew = false, onSave, onCancel, onOpen, onRecover, onUpgrade, onRemove }) => {
  const [isOpen, setOpenToggle] = useState(false);
  const [name, setName] = useState(data.name);
  const [error, setError] = useState(null);
  const [nameEditable, setNameEditable] = useState(!!isNew);
  const [regions, setRegions] = useState(data.regions.map((region) => region.name));
  const [bandWidth, setBandWidth] = useState(bandWidthRange[0].value);

  const nameRef = useRef(null);

  const isDisabled = [
    ECOSYSTEM_STATUS.DISABLED,
    ECOSYSTEM_STATUS.ECOSYSTEM_ERROR,
    ECOSYSTEM_STATUS.COMMIT_ERROR,
    ECOSYSTEM_STATUS.DELETED,
  ].includes(data.status);
  const isOutdated = data.status === ECOSYSTEM_STATUS.OUTDATED;
  const isDeleted = data.status === ECOSYSTEM_STATUS.DELETED;
  const isError = [ECOSYSTEM_STATUS.COMMIT_ERROR, ECOSYSTEM_STATUS.ECOSYSTEM_ERROR].includes(data.status);
  const helpLink = `${process.env.REACT_APP_KB_URL}/err/${data.status}`;

  useEffect(() => {
    if (nameEditable) {
      nameRef.current.focus();
    }
  }, [nameEditable]);

  const regionLabel = (value) => {
    const regKey = Object.keys(NSP_REGIONS).find((key) => NSP_REGIONS[key].value === value);
    return NSP_REGIONS[regKey].label;
  };

  const handleHelp = () => {
    if (isDisabled) {
      window.open(helpLink, '_blank');
    }
  };

  const handleToggle = (open, event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenToggle(open);
  };

  const handleClickNameEdit = (event) => {
    event.preventDefault();
    setNameEditable(true);
  };

  const handleChangeName = (event) => {
    event.preventDefault();
    setName(event.target.value);
    setError({ ...error, name: '' });
  };

  const handleBlurName = (event) => {
    event.preventDefault();
    if (name.length > 35) {
      setError({ ...error, name: 'Name should be less than 36 characters.' });
      nameRef.current.focus();
      return;
    }
    if (!isNew) {
      if (!name) {
        setName(data.name);
      }
      setNameEditable(false);
      onSave({ ...data, name, regions });
    }
  };

  const handleChangeRegion = (region) => {
    if (!isNew) return;
    setError({ ...error, region: '' });
    setRegions([region]);
  };

  const handleChangeBandwidth = (event, value) => {
    event.preventDefault();
    setBandWidth(value);
  };

  const handleSave = () => {
    setError(null);
    if (!name || name.length > 35) {
      setError({ ...error, name: 'Name should be less than 36 characters.' });
      nameRef.current.focus();
      return;
    }
    if (!regions.length) {
      setError({ ...error, region: 'Please select a region associated with your ecosystem.' });
      return;
    }
    const regionPayload = regions.map((region) => ({
      name: region,
      bandwidth: bandWidth,
    }));
    onSave({ name, regions: regionPayload });
  };

  const renderFooterButtons = () => {
    if (isNew) {
      return (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button bsStyle="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      );
    }
    if (isDeleted) {
      return <p className="delete_label">Delete in progress</p>;
    }
    if (isError) {
      return (
        <Button bsStyle="danger" onClick={onRecover}>
          Recover
        </Button>
      );
    }
    return (
      <Button bsStyle="primary" onClick={() => onOpen(data)} disabled={isDisabled}>
        Open
      </Button>
    );
  };

  return (
    <div className="ecosystemCard item">
      <div className={cx('ecosystemCard__header', { disabled: isDisabled })}>
        <div className="ecosystem-name">
          {error && error.name ? <p className="label error">{error.name}</p> : <p className="label">Ecosystem Name</p>}
          <div className="name">
            {nameEditable ? (
              <input ref={nameRef} value={name} onChange={handleChangeName} onBlur={handleBlurName} />
            ) : (
              <span title={name}>{name}</span>
            )}
            <img src={PencilSVG} alt="edit" onClick={handleClickNameEdit} />
          </div>
          {!isNew && !isDeleted && (
            <div className="ecosystem-options">
              <Dropdown id="eco-options" onToggle={handleToggle} pullRight open={isOpen}>
                <Dropdown.Toggle noCaret className="eco-options__toggle">
                  <img src={ICON_MORE} alt="ecosystem options" />
                </Dropdown.Toggle>
                <Dropdown.Menu pullRight>
                  <MenuItem
                    disabled={isDisabled}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!isDisabled) onOpen(data);
                    }}
                  >
                    Open
                  </MenuItem>
                  <MenuItem
                    disabled={!isOutdated}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (isOutdated) onUpgrade();
                    }}
                  >
                    Upgrade
                  </MenuItem>
                  <MenuItem
                    disabled={!isError}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (isError) onRecover();
                    }}
                  >
                    Recover
                  </MenuItem>
                  <MenuItem onClick={() => onRemove()}>Delete</MenuItem>
                  <MenuItem
                    disabled={!isDisabled}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleHelp();
                    }}
                  >
                    Help
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="ecosystem-date">
          {moment(data.created).format('D MMMM, YYYY')}
          &nbsp;|&nbsp;
          {moment(data.created).format('h:mm A')}
        </div>
      </div>
      <div className="ecosystemCard__body">
        {/* <div className="nsp-map">
          <NSPMap regions={regions} onClick={handleChangeRegion} />
        </div> */}
        <div className="ecosystem-nsps">
          {isNew ? (
            <p className="label">Please select a region where your users and offices are located</p>
          ) : (
            <>
              <p>Associated NSP</p>
              <div className="regions">
                {regions.map((region) => (
                  <div key={region}>{regionLabel(region)}</div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="nsp-map">
          <NSPMap regions={regions} onClick={handleChangeRegion} />
        </div>
        {isNew && (
          <>
            <div className="selected-region">
              <p>Selected region:</p>
              <div className="regions">
                {error && error.region && <p className="label error">{error.region}</p>}
                {regions.map((region) => (
                  <div key={region} onClick={() => handleChangeRegion(region)}>
                    {regionLabel(region)}
                  </div>
                ))}
              </div>
            </div>
            <div className="bandwidth">
              <p className="bandwidth__label">
                Starting bandwidth for selected region:&nbsp;
                <span className="bandwidth_value">{bandWidth} Mbps</span>
              </p>
              <div className="slider-container">
                <CustomSlider
                  value={bandWidth}
                  step={null}
                  min={100}
                  max={2000}
                  marks={bandWidthRange}
                  onChange={handleChangeBandwidth}
                />
              </div>
              <p className="best-match">- Best for {bandWidth / 1} users for email, office applications</p>
              <p className="best-match">- Best for {parseInt(bandWidth / 3, 10)} users for video, file transfers</p>
              <p className="note">You can add additional regions and bandwidth under &apos;Ecosystem Settings&apos;</p>
            </div>
          </>
        )}
      </div>
      <div className={cx('ecosystemCard__footer', { deleted: isDeleted })}>{renderFooterButtons()}</div>
    </div>
  );
};

EcosystemCard.propTypes = {
  data: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onOpen: PropTypes.func,
  onRecover: PropTypes.func,
  onUpgrade: PropTypes.func,
  onRemove: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onOpen: (ecosystem) => dispatch(openEcosystem(ecosystem)),
});

export default connect(null, mapDispatchToProps)(EcosystemCard);
