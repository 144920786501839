import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import WedgeModal from '../../components/WedgeModal';
import ExpiryWarning from '../../components/ExpiryWarning/ExpiryWarning';
import Header from '../../components/Header/Header';
import TabsHeader from '../../components/TabsHeader/TabsHeader';
import Loader from '../../components/Loader/Loader';
import { DiscardChanges } from '../../Modals/DiscardChanges';

import User from './components/User';
import Organization from './components/Organization';
import Billing from './components/Billing';
import { fecthCustomerDetail } from './senario-actions';
import './user-profile.scss';

const profileTabs = [
  {
    name: 'User Profile',
  },
  {
    name: 'Organization Profile',
  },
  {
    name: 'Billing',
  },
];

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      nextNavigationTab: 0,
      isFormChanged: false,
      showChangesModal: false,
    };
    const { selectedCustomer } = props;
    this.tabs = selectedCustomer ? profileTabs : [profileTabs[0]];
  }

  componentDidMount() {
    this.props.fecthCustomerDetail();
  }

  selectTab = (index) => {
    if (this.state.selectedIndex !== index) {
      if (!this.state.isFormChanged) {
        this.setState(() => ({
          selectedIndex: index,
          isFormChanged: false,
          showChangesModal: false,
        }));
      } else {
        this.setState(() => ({
          showChangesModal: true,
          nextNavigationTab: index,
        }));
      }
    }
  };

  renderForm = () => {
    const { selectedIndex } = this.state;
    if (selectedIndex === 0) {
      return <User onFormChanged={this.setFormChangedStatus} />;
    }
    if (selectedIndex === 1) {
      return <Organization onFormChanged={this.setFormChangedStatus} />;
    }
    if (selectedIndex === 2) {
      return <Billing onFormChanged={this.setFormChangedStatus} />;
    }
    return null;
  };

  setFormChangedStatus = (isFormChanged) => {
    this.setState({ isFormChanged });
  };

  discardChanges = () => {
    this.setState((prev) => ({
      isFormChanged: false,
      showChangesModal: false,
      selectedIndex: prev.nextNavigationTab,
    }));
  };

  closeModal = () => {
    this.setState({ showChangesModal: false });
  };

  render() {
    const { selectedIndex } = this.state;
    const { location, isLoading } = this.props;

    return (
      <div>
        <div className="wrapper profile-wrapper">
          <ExpiryWarning />

          <WedgeModal
            isOpen={this.state.showChangesModal}
            onClose={this.closeModal}
            title="Discard changes ?"
            size="tiny"
          >
            <DiscardChanges onYes={this.discardChanges} onNo={this.closeModal} />
          </WedgeModal>
          <Header brandText="Edit Profile" location={location} />
          <div className="profile-container">
            {isLoading ? (
              <div className="loader-container">
                <Loader />
              </div>
            ) : (
              <div className="container profile-form ">
                <TabsHeader selectedIndex={selectedIndex} onSelect={this.selectTab} tabs={this.tabs} />
                {this.renderForm()}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  location: PropTypes.object.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
  fecthCustomerDetail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  selectedCustomer: state.auth.selectedCustomer,
  isLoading: state.customers.fetchingCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  fecthCustomerDetail: () => dispatch(fecthCustomerDetail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
