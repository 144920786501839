import { CLEAR_DATA } from '../common-action-types';
import {
  CREATION_OBJECT_SUCCESS,
  CREATE_POLICY_OBJECT,
  FETCHING_OBJECT_SUCCESS,
  FETCHING_OBJECT_FAILURE,
  FETCHING_ADDRESS_SUCCESS,
  UPDATE_OBJECT_SUCCESS,
  CREATION_OBJECT_FAILURE,
  UPDATE_OBJECT_FAILURE,
  REGEN_PSK_SUCCESS,
  READ_GATEWAY_SUCCESS,
  GENERATE_IMAGE_STARTED,
  GENERATE_IMAGE_SUCCESS,
  GENERATE_IMAGE_FAILURE,
  GET_LDAP_TOKEN_SUCCESS,
  LDAP_STATUS_REQUESTED,
  LDAP_STATUS_SUCCESS,
} from './action-types';
import { LDAP_STATUS_LOADING } from '../../enum/constants';

const initialState = {
  isLoading: true,
  success: true,
  createdObject: null,
  psk: '',
  imgGenerating: {},
};

export function objectsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCHING_OBJECT_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: payload.results,
        },
        isLoading: payload.isLoading,
        success: payload.success,
      };
    }
    case FETCHING_OBJECT_FAILURE: {
      return {
        ...state,
        isLoading: payload.isLoading,
        success: payload.success,
      };
    }
    case FETCHING_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: payload.results,
      };
    }
    case GENERATE_IMAGE_STARTED: {
      return {
        ...state,
        imgGenerating: {
          ...state.imgGenerating,
          [payload.uuid]: {
            ...state.imgGenerating[payload.uuid],
            [payload.platform]: true,
          },
        },
      };
    }
    case GENERATE_IMAGE_SUCCESS:
    case GENERATE_IMAGE_FAILURE:
      return {
        ...state,
        imgGenerating: {
          ...state.imgGenerating,
          [payload.uuid]: {
            ...state.imgGenerating[payload.uuid],
            [payload.platform]: false,
          },
        },
      };

    case CREATION_OBJECT_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: [payload.result, ...state[payload.ecosystem.uuid].objects],
        },
      };
    }
    case CREATE_POLICY_OBJECT: {
      return {
        ...state,
        createdObject: { object: payload.object, field: payload.field },
      };
    }
    case CREATION_OBJECT_FAILURE: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case UPDATE_OBJECT_FAILURE: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case UPDATE_OBJECT_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: state[payload.ecosystem.uuid].objects.map((ob) =>
            ob.uuid === payload.result.uuid ? payload.result : ob,
          ),
        },
      };
    }
    case READ_GATEWAY_SUCCESS: {
      return {
        ...state,
        psk: '',
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: state[payload.ecosystem.uuid].objects.map((obj) =>
            obj.uuid === payload.result.uuid ? payload.result : obj,
          ),
        },
      };
    }
    case REGEN_PSK_SUCCESS: {
      return {
        ...state,
        psk: payload.psk,
      };
    }
    case GET_LDAP_TOKEN_SUCCESS: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...(state[payload.ecosystemUUID] ? state[payload.ecosystemUUID] : { objects: [] }),
          token: payload.token,
        },
      };
    }
    case LDAP_STATUS_REQUESTED: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...state[payload.ecosystemUUID],
          objects: state[payload.ecosystemUUID].objects.map((obj) =>
            obj.uuid === payload.uuid
              ? { ...obj, status: LDAP_STATUS_LOADING }
              : obj,
          ),
        },
      };
    }
    case LDAP_STATUS_SUCCESS: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...state[payload.ecosystemUUID],
          objects: state[payload.ecosystemUUID].objects.map((obj) =>
            obj.uuid === payload.uuid
              ? { ...obj, status: payload.status }
              : obj,
          ),
        },
      };
    }
    case CLEAR_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
