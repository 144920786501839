import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import { withFormik } from 'formik';
import Spinner from 'react-spinner-material';

import { CARD_NAME, CARD_NUMBER, CVV, EXPIRY } from '../../../enum/Billing';
import ErrorPanel from '../../../components/ErrorPanel/ErrorPanel';
import history from '../../../history';
import '../user-profile.scss';

import { clearStripeError, updateStripeSource, setStripe } from '../../../store/payment/actions';
import { initialCompanyNameSelector, isLoadingSelector } from '../../../store/user/selectors';
import { stripeErrorSelector } from '../../../store/payment/selectors';
import billingValidationSchema from '../../../validationSchemas/billingValidationSchema';

const styleCustomFields = {
  fontSize: '18px',
  color: '#333',
  fontWeight: 'normal',
  '::-webkit-input-placeholder': {
    color: '#ccc',
    fontStyle: 'normal',
  },
  '::placeholder': {
    color: '#ccc',
    fontStyle: 'normal',
  },
};
class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldErrorBeDisplayed: false,
    };
  }

  componentDidMount() {
    this.props.setStripe();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      shouldErrorBeDisplayed: true,
    });
    const { isValid, values, user, customer } = this.props;
    if (!isValid) {
      return;
    }
    this.props.updateStripeSource({
      billingInfo: {
        type: 'card',
        owner: {
          name: values[CARD_NAME],
          email: user.email,
          phone: user.mobilePhone,
        },
      },
      customerUUID: customer.uuid,
    });
  };

  handleCancel = () => {
    history.goBack();
  };

  handleBlur = ({ target: { name } }) => {
    const { setFieldTouched } = this.props;
    setFieldTouched(name);
  };

  setFormChanged(event, field) {
    this.props.setFieldValue(field, !event.empty);
    this.checkFormChanged();
  }

  checkFormChanged = () =>
    this.props.onFormChanged(
      !!this.props.values[CARD_NAME] ||
        this.props.values[CARD_NUMBER] ||
        this.props.values[EXPIRY] ||
        this.props.values[CVV],
    );

  cleanUpError = () => {
    const { shouldErrorBeDisplayed } = this.state;
    if (shouldErrorBeDisplayed === true) {
      this.setState(({ shouldErrorBeDisplayed }) => ({
        shouldErrorBeDisplayed: !shouldErrorBeDisplayed,
      }));
    }
    const { stripeError, clearStripeError } = this.props;
    if (stripeError) clearStripeError();
  };

  render() {
    const { values, errors, stripeError, isLoading } = this.props;
    const isFormEmpty = Object.values(values).some((entry) => entry === '');
    const { shouldErrorBeDisplayed } = this.state;

    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <div className="row billing-profile-container">
          <div className="col col-xs-12 form-container billing">
            <div className="user-info-form">
              {isFormEmpty && shouldErrorBeDisplayed && (
                <Row>
                  <Col xs={12} className="col">
                    <ErrorPanel message="Please, Enter All The Fields" buttonClickHandler={this.cleanUpError} />
                  </Col>
                </Row>
              )}
              {!!stripeError && (
                <Row>
                  <Col xs={12} className="col">
                    <ErrorPanel message={stripeError} buttonClickHandler={this.cleanUpError} />
                  </Col>
                </Row>
              )}
              {shouldErrorBeDisplayed &&
                Object.values(errors).map((error) => (
                  <Row key={error}>
                    <Col xs={12} className="col">
                      <ErrorPanel message={error} buttonClickHandler={this.cleanUpError} />
                    </Col>
                  </Row>
                ))}
              <Row>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">Name On Card</span>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      className="inputField"
                      onChange={(e) => {
                        this.props.handleChange(e);
                        setTimeout(() => {
                          this.checkFormChanged();
                        }, 0);
                      }}
                      onBlur={this.handleBlur}
                      name={CARD_NAME}
                      value={values[CARD_NAME]}
                    />
                  </label>
                </Col>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">Card Number</span>
                    <div onClick={this.cleanUpError}>
                      <CardNumberElement
                        className="inputField"
                        style={{ base: styleCustomFields }}
                        onFocus={this.cleanUpError}
                        onChange={(event) => this.setFormChanged(event, CARD_NUMBER)}
                      />
                    </div>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">Expiry Date</span>
                    <CardExpiryElement
                      className="inputField"
                      style={{ base: styleCustomFields }}
                      onFocus={this.cleanUpError}
                      onChange={(event) => this.setFormChanged(event, EXPIRY)}
                    />
                  </label>
                </Col>
                <Col xs={12} md={6} className="col">
                  <label>
                    <span className="labelForm">CVV</span>
                    <CardCVCElement
                      className="inputField"
                      placeholder="CVV"
                      style={{ base: styleCustomFields }}
                      onFocus={this.cleanUpError}
                      onChange={(event) => this.setFormChanged(event, CVV)}
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="submitHolder">
                  {isLoading && <Spinner spinnerColor="#4a0b96" />}
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="button-container">
          <Button className="button button-cancel" onClick={() => this.handleCancel()}>
            Cancel
          </Button>
          <Button type="submit" className="button button-update">
            Update
          </Button>
        </div>
      </form>
    );
  }
}

Billing.propTypes = {
  updateStripeSource: func.isRequired,
  setStripe: func.isRequired,
  stripeError: string.isRequired,
  customer: object.isRequired,
  user: object.isRequired,
  values: object.isRequired,
  isValid: bool.isRequired,
  clearStripeError: func.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  errors: object.isRequired,
  setFieldValue: func.isRequired,
  isLoading: bool.isRequired,
  onFormChanged: func.isRequired,
};

const mapStateToProps = (state) => ({
  stripeError: stripeErrorSelector(state),
  customer: state.auth.selectedCustomer,
  user: state.user,
  isLoading: isLoadingSelector(state),
  initialCompanyName: initialCompanyNameSelector(state),
});

const mapDispatchToProps = (dispatch, { stripe }) => ({
  updateStripeSource: (payload) => dispatch(updateStripeSource(payload)),
  clearStripeError: () => dispatch(clearStripeError()),
  setStripe: () => dispatch(setStripe(stripe)),
});

export default compose(
  injectStripe,
  withFormik({
    mapPropsToValues: () => ({
      [CARD_NAME]: '',
      [CARD_NUMBER]: false,
      [CVV]: false,
      [EXPIRY]: false,
    }),
    validationSchema: billingValidationSchema,
    displayName: 'stripeSourceBillingProfileForm',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(Billing);
