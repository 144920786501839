import React from 'react';
import './global-loader.scss';
import LOGO from '../../assets/customer/extreme/logo.svg';

export default function GlobalLoader() {
  return (
    <div className="global-loader">
      <img className="global-loader--logo" alt="acreto" src={LOGO} />
    </div>
  );
}
