import { assign } from 'lodash';
import { handleActions } from 'redux-actions';
import { CLEAR_DATA } from '../common-action-types';

import {
  SET_USER,
  SET_EMAIL,
  START_LOADING,
  FINISH_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  SET_PASSWORD_ERROR,
  CLEAR_PASSWORD_ERROR,
  SET_TOKEN,
  CONFIRM_EMAIL,
  SET_INIT_COMPANY_NAME,
  UPDATE_USER,
  SET_USER_OTP,
} from './actionTypes';

const initialState = {
  email: '',
  uuid: '',
  firstName: '',
  lastName: '',
  mfa: '',
  tokenTTL: 600,
  isActivated: false,
  isLoading: false,
  error: '',
  pwdError: '',
  isEmailConfirmed: false,
  initialCompanyName: '',
  otp: null,
};

export const userReducer = handleActions(
  {
    [SET_USER]: (state, { payload }) => assign({}, state, payload),
    [UPDATE_USER]: (state, { payload }) => assign({}, state, payload),
    [SET_EMAIL]: (state, { payload: email }) => assign({}, state, { email }),
    [START_LOADING]: (state) => assign({}, state, { isLoading: true }),
    [FINISH_LOADING]: (state) => assign({}, state, { isLoading: false }),
    [SET_ERROR]: (state, { payload }) => assign({}, state, { error: payload }),
    [CLEAR_ERROR]: (state) => assign({}, state, { error: '' }),
    [SET_PASSWORD_ERROR]: (state, { payload }) => assign({}, state, { pwdError: payload }),
    [CLEAR_PASSWORD_ERROR]: (state) => assign({}, state, { pwdError: '' }),
    [SET_TOKEN]: (state, { payload }) => assign({}, state, { token: payload }),
    [CONFIRM_EMAIL]: (state) => assign({}, state, { isEmailConfirmed: true }),
    [SET_INIT_COMPANY_NAME]: (state, { payload }) => assign({}, state, { initialCompanyName: payload }),
    [SET_USER_OTP]: (state, { payload }) => assign({}, state, { otp: payload }),
    [CLEAR_DATA]: (state) => assign({}, state, { ...initialState, customers: [] }),
  },
  initialState,
);
