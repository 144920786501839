import moment from 'moment';
import { CONSTANTS } from '../../enum';
import { extractUsernameFromToken } from '../../utils/utils';
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  RENEW_TOKEN,
  SET_CUSTOMER,
  UPDATE_CUSTOMER,
  CLEAR_LOG_IN_MESSAGE,
  REMOVE_CUSTOMER,
} from './action-types';

const initialState = {
  isAuthenticated: false,
  tokenExpireAt: '',
  customers: [],
  selectedCustomer: null,
  username: '',
  needsLogInMessage: false,
};

export function authReducer(state = initialState, { type, payload }) {
  const expiryTime = moment().add(process.env.REACT_APP_TOKEN_EXPIRATION_TIME, 'minutes').toISOString();

  switch (type) {
    case LOGIN_FAILURE:
      localStorage.removeItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      return {
        ...state,
        isAuthenticated: false,
        selectedCustomer: null,
        customers: [],
      };
    case LOGIN_SUCCESS:
      localStorage.setItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY, payload.accessToken);
      return {
        ...state,
        isAuthenticated: true,
        tokenExpireAt: expiryTime,
        customers: payload.customers,
        username: extractUsernameFromToken(payload.accessToken),
      };
    case LOGOUT_USER:
      localStorage.removeItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      return {
        ...state,
        isAuthenticated: false,
        selectedCustomer: null,
        tokenExpireAt: '',
        customers: [],
        username: '',
        needsLogInMessage: false,
      };
    case RENEW_TOKEN:
      return {
        ...state,
        tokenExpireAt: expiryTime,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        selectedCustomer: payload,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((customer) => (customer.uuid === payload.uuid ? payload : customer)),
        selectedCustomer:
          state.selectedCustomer && state.selectedCustomer.uuid === payload.uuid ? payload : state.selectedCustomer,
      };
    case REMOVE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter((customer) => customer.uuid !== payload.uuid),
        selectedCustomer:
          state.selectedCustomer && state.selectedCustomer.uuid === payload.uuid ? null : state.selectedCustomer,
      };
    case CLEAR_LOG_IN_MESSAGE:
      return {
        ...state,
        needsLogInMessage: false,
      };
    default:
      return state;
  }
}
