export const LDAP_CONNECTIVITY_METHOD = `
Decide how Acreto can reach your identity provider. Choose between connecting through a [Gateway](${process.env.REACT_APP_KB_URL}/connecting/ipsec/), or over the Internet..`;
export const LDAP_URL = `Provide address of your Server, together with port number.
Example: 10.0.0.1:123`;
export const LDAP_CUSTOM_CA_CERT = `If your server uses a certificate issued by a custom CA, please upload that CA certificate here.`;
export const LDAP_CLIENT_CA_CERT = `If your server verifies client certificate, you need to generate private key, get a certificate and upload it here.`;
export const LDAP_CREATE_SECURITY_POLICY = `We will create a linked Security Policy to allow communication between Acreto and your identity provider.`;
export const LDAP_CREATE_SECURITY_GROUP = `We will create Security Groups for each role receieved from your server and automatically assign users to these Security Groups.`;
export const LDAP_CONNECT_EXIST_DB = `Identity provider is a database which stores your users. Ecosystem will authenticate your users using provided identity provider.`;
export const LDAP_AUTH_BIND_DN = `Please provide Bind DN (distinguished name) and password for service account with permissions to authorize users and fetch list of users from your LDAP server. [Read more](${process.env.REACT_APP_KB_URL}/connecting/ipsec/)`;
export const LDAP_AUTH_BASE_DN = `Let us know where we should search for your users. We'll prepend user attribute (defined below) to this and expect it to be your user's DN.`;
export const LDAP_USER_SCHEMA = `Let us know which attribute of LDAP user object contains username (user login). Some example values are: cn, uid, userPrincipalName, sAMAccountName.`;
