import * as REST from '../api/rest';
import {
  createEcosystemGroupRequested,
  createEcosystemGroupSuccess,
  createEcosystemGroupFailure,
  createServiceRequested,
  createServiceSuccess,
  createServiceFailure,
  createEcosystemRoleRequested,
  createEcosystemRoleSuccess,
  createEcosystemRoleFailure,
} from './ecosystems/actions';
import { createPolicyObject } from './objects/actions';

export function createGroup({ groupName, field }) {
  return async (dispatch, getState) => {
    try {
      dispatch(createEcosystemGroupRequested());
      const result = await REST.createGroup({
        customer: getState().auth.selectedCustomer,
        ecosystem: getState().ecosystems.currentEcosystem,
        name: groupName,
      });
      const { groups } = getState().ecosystems.dictionaries;
      dispatch(createEcosystemGroupSuccess([...groups, result]));
      if (field) {
        dispatch(
          createPolicyObject({
            object: { ...result, element: 'group', type: 'group' },
            field,
          }),
        );
      }
    } catch (error) {
      dispatch(createEcosystemGroupFailure(error));
    }
  };
}

export function createService(service) {
  return async (dispatch, getState) => {
    try {
      dispatch(createServiceRequested());
      const result = await REST.createService({
        customer: getState().auth.selectedCustomer,
        ecosystem: getState().ecosystems.currentEcosystem,
        service,
      });
      dispatch(createServiceSuccess(result));
    } catch (error) {
      dispatch(createServiceFailure(error));
    }
  };
}

export function createRole({ roleName, field }) {
  return async (dispatch, getState) => {
    try {
      dispatch(createEcosystemRoleRequested());
      const result = await REST.createRole({
        customer: getState().auth.selectedCustomer,
        ecosystem: getState().ecosystems.currentEcosystem,
        name: roleName,
      });
      dispatch(createEcosystemRoleSuccess(result));
      if (field) {
        dispatch(
          createPolicyObject({
            object: { ...result, element: 'role', type: 'role' },
            field,
          }),
        );
      }
    } catch (error) {
      dispatch(createEcosystemRoleFailure(error));
    }
  };
}
