import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export default class CommitFinishModal extends React.Component {
  render() {
    const { action } = this.props;
    const successMsg = `Your ecosystem has been successfully ${action === 'Commit' ? 'committed' : 'reverted'}`;
    const failedMsg = `Your ecosystem has been failed to ${action.toLowerCase()}`;
    const { ecosystem, success, requestID } = this.props;
    return (
      <div className="commit-finish">
        <div className="commit-result">
          <p className="result-header">{success ? successMsg : failedMsg}</p>
          <div className="result-body">
            <p>{`Ecosystem Name: ${ecosystem.name}`}</p>
            <p>{`Ecosystem UUID: ${ecosystem.uuid}`}</p>
            <p>{`Request-ID: ${requestID}`}</p>
          </div>
        </div>
        <div className="wedge-modal__footer">
          <Button bsStyle="primary" onClick={this.props.onClose}>
            OK
          </Button>
        </div>
      </div>
    );
  }
}

CommitFinishModal.propTypes = {
  ecosystem: PropTypes.object,
  success: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  requestID: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};
