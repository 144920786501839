import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { bool, func, object, shape, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Spinner from 'react-spinner-material';

import EmailDisplay from '../../../components/EmailDisplay/EmailDisplay';
import ErrorPanel from '../../../components/ErrorPanel/ErrorPanel';
import SuccessPanel from '../../../components/SuccessPanel/SuccessPanel';
import { personalInfoValidationSchema } from '../../../validationSchemas';
import { LOGIN_PASSWORD, PERSON } from '../../../assets/Icons';
import { clearError, completeUser } from '../../../store/user/actions';
import { emailSelector, errorSelector, isLoadingSelector } from '../../../store/user/selectors';
import '../sign-up-form.scss';

class PersonalInfoSubForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailVerificationMessage: true,
      showError: true,
      unitedErrors: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverError !== this.props.serverError) {
      this.setState({
        showError: true,
      });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    let { unitedErrors } = this.state;
    const { isValid, completeUser, serverError, errors, touched } = this.props;
    const { values } = this.props;
    if (isValid !== true) {
      unitedErrors = [serverError];
      Object.keys(errors).forEach((error) => {
        if (touched[error]) {
          unitedErrors.push(errors[error]);
        }
      });
      this.setState({
        showError: true,
        unitedErrors,
      });
      return;
    }
    completeUser(values);
  };

  closeEmailVerificationMessage = () => {
    this.setState(() => ({
      showEmailVerificationMessage: false,
    }));
  };

  handleInputChange = (event) => {
    const { handleChange, clearError, serverError } = this.props;
    if (serverError) clearError();
    const { showEmailVerificationMessage, showError } = this.state;
    if (showEmailVerificationMessage) this.closeEmailVerificationMessage();
    if (showError) this.closeErrorPanel();
    handleChange(event);
  };

  closeErrorPanel = () => {
    const { clearError, serverError } = this.props;
    if (serverError) clearError();
    this.setState({
      showError: false,
      unitedErrors: [],
    });
  };

  render() {
    const { values, setFieldTouched, isLoading, serverError } = this.props;
    const { unitedErrors, showEmailVerificationMessage } = this.state;

    return (
      <form onSubmit={this.onSubmit} className="form-container">
        {showEmailVerificationMessage && (
          <>
            <SuccessPanel
              message="Your email has been successfully verified"
              buttonClickHandler={this.closeEmailVerificationMessage}
            />
            <EmailDisplay />
          </>
        )}
        {serverError ? (
          <ErrorPanel message={serverError} buttonClickHandler={this.closeErrorPanel} key={serverError} />
        ) : (
          unitedErrors.map((error) =>
            error ? <ErrorPanel message={error} buttonClickHandler={this.closeErrorPanel} key={error} /> : null,
          )
        )}
        <div className="input-container">
          <div className="icon-container">
            <img src={PERSON} className="person-icon" alt="person-icon" />
          </div>
          <input
            value={values.firstName}
            name="firstName"
            placeholder="First Name"
            required
            onChange={this.handleInputChange}
            onBlur={() => setFieldTouched('firstName')}
          />
        </div>
        <div className="input-container">
          <div className="icon-container">
            <img src={PERSON} className="person-icon" alt="person-icon" />
          </div>
          <input
            value={values.lastName}
            name="lastName"
            placeholder="Last Name"
            required
            onChange={this.handleInputChange}
            onBlur={() => setFieldTouched('lastName')}
          />
        </div>
        <div className="input-container">
          <div className="icon-container">
            <img src={LOGIN_PASSWORD} className="person-icon" alt="person-icon" />
          </div>
          <input
            type="password"
            value={values.password}
            name="password"
            placeholder="Password"
            required
            onChange={this.handleInputChange}
            onBlur={() => setFieldTouched('password')}
          />
        </div>
        <div className="input-container">
          <div className="icon-container">
            <img src={LOGIN_PASSWORD} className="person-icon" alt="person-icon" />
          </div>
          <input
            type="password"
            value={values.passwordConfirmation}
            name="passwordConfirmation"
            placeholder="Confirm Your Password"
            required
            onChange={this.handleInputChange}
            onBlur={() => setFieldTouched('passwordConfirmation')}
          />
        </div>
        {isLoading ? (
          <Spinner spinnerColor="#4a0b96" className="spinner" />
        ) : (
          <div className="submit-button-container">
            <input type="submit" className="signup-button" value="Next" />
          </div>
        )}
      </form>
    );
  }
}

PersonalInfoSubForm.defaultProps = {
  isLoading: true,
};

PersonalInfoSubForm.propTypes = {
  values: shape({
    firstName: string,
    lastName: string,
  }).isRequired,
  errors: shape({
    firstName: string,
    lastName: string,
  }),
  isValid: bool.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  isLoading: bool,
  completeUser: func.isRequired,
  serverError: string.isRequired,
  clearError: func.isRequired,
  touched: object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state),
  serverError: errorSelector(state),
  email: emailSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      completeUser,
      clearError,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    }),
    validationSchema: personalInfoValidationSchema,
  }),
)(PersonalInfoSubForm);
