import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import Markdown from 'react-markdown';

import { HELP_GATEWAY_CONFIG_OPTIONS, LDAP_CONFIG_OPTIONS, USER_CREATION } from '../../dictionary';

import './style.scss';

const dictionary = {
  HELP_GATEWAY_CONFIG_OPTIONS,
  LDAP_CONNECTIVITY_METHOD: LDAP_CONFIG_OPTIONS.LDAP_CONNECTIVITY_METHOD,
  LDAP_URL: LDAP_CONFIG_OPTIONS.LDAP_URL,
  LDAP_CUSTOM_CA_CERT: LDAP_CONFIG_OPTIONS.LDAP_CUSTOM_CA_CERT,
  LDAP_CLIENT_CA_CERT: LDAP_CONFIG_OPTIONS.LDAP_CLIENT_CA_CERT,
  LDAP_CREATE_SECURITY_POLICY: LDAP_CONFIG_OPTIONS.LDAP_CREATE_SECURITY_POLICY,
  LDAP_CREATE_SECURITY_GROUP: LDAP_CONFIG_OPTIONS.LDAP_CREATE_SECURITY_GROUP,
  LDAP_CONNECT_EXIST_DB: LDAP_CONFIG_OPTIONS.LDAP_CONNECT_EXIST_DB,
  LDAP_USER_SCHEMA: LDAP_CONFIG_OPTIONS.LDAP_USER_SCHEMA,
  LDAP_GENERIC_LDAP: LDAP_CONFIG_OPTIONS.LDAP_GENERIC_LDAP,
  LDAP_AUTH_BIND_DN: LDAP_CONFIG_OPTIONS.LDAP_AUTH_BIND_DN,
  LDAP_AUTH_BASE_DN: LDAP_CONFIG_OPTIONS.LDAP_AUTH_BASE_DN,
  USER_IMPORT_CSV: USER_CREATION.USER_IMPORT_CSV,
};

export default class ContextHelpPopOver extends React.PureComponent {
  render() {
    const { helpId, customMDText, trigger = 'click' } = this.props;
    const helpPopover = (
      <Popover id={Math.random()}>
        <div className="help-content">
          <Markdown linkTarget="blank" source={!customMDText ? dictionary[helpId] : customMDText} />
        </div>
      </Popover>
    );

    return (
      <OverlayTrigger trigger={trigger} rootClose placement="bottom" overlay={helpPopover}>
        {customMDText ? this.props.children : <Button bsClass="help-icon">?</Button>}
      </OverlayTrigger>
    );
  }
}

ContextHelpPopOver.propTypes = {
  helpId: PropTypes.string,
  customMDText: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  children: PropTypes.node,
};
