import { call, put, select } from 'redux-saga/effects';
import * as REST from '../../api/rest';
import { setUserOTP } from '../user/actions';

function* configUserOTP() {
  const globalState = yield select();
  const { email } = globalState.user;
  const response = yield call(() => REST.configOTP(email));
  yield put(setUserOTP(response));
}

export { configUserOTP };
