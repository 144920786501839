import { object, string } from 'yup';

const UserValidationSchema = object({
  firstName: string('Enter First Name')
    .max(35, 'First Name should be less than 36 characters.')
    .min(3, 'First Name should be more than 3 characters.')
    .required('First Name is required.'),
  lastName: string('Enter Last Name')
    .max(35, 'Last Name should be less than 36 characters.')
    .min(3, 'Last Name should be more than 3 characters.')
    .required('Last Name is required.'),
  email: string('Enter Email').email().required(),
  password: string('Enter Password').required('Password is required'),
});

export default UserValidationSchema;
