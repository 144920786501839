import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/fp/get';
import { ROUTES } from '../../enum';
import { logoutUser } from '../auth/actions';
import { startLoading, finishLoading, setError, setUser } from '../user/actions';
import history from '../../history';
import { logout } from '../../api/rest';
import { registerPasswordCall, updatePassword, loginUserCall, updateUserCall } from './apiCalls';
import { extractResponseErrorStatus, createResponseErrorMessage } from '../../utils/responseErrorHandler';

function* completeUserSaga({ payload }) {
  yield put(startLoading());
  const { firstName, lastName, password } = payload;
  const {
    user: { email },
  } = yield select();
  try {
    yield call(() =>
      registerPassword({
        email,
        password,
      }),
    );
    const responseAfterLogin = yield call(() =>
      loginUser({
        username: email,
        password,
      }),
    );
    const token = get(['data', 'accessToken'], responseAfterLogin);
    localStorage.setItem('wedge_access_token', token);
    const userInfo = yield call(() =>
      updateUserCall({
        firstName,
        lastName,
        email,
      }),
    );
    yield put(setUser(userInfo));
    const customers = get(['data', 'customers'], responseAfterLogin);
    if (customers && customers.length) {
      yield logout();
      yield put(setError('Please login again to access your organization'));
      history.replace(ROUTES.LOGIN);
    } else history.replace(ROUTES.ORG_INFO);
  } catch (err) {
    yield put(setError(err));
  } finally {
    yield put(finishLoading());
  }
}

export default completeUserSaga;

export function* registerPassword({ email, password }) {
  try {
    const response = yield call(() => registerPasswordCall({ email, password }));
    return response;
  } catch (error) {
    const status = extractResponseErrorStatus(error);
    const specificErrorHandler = {
      400: 'Wrong payload',
      401: 'Token Is Required!',
      403: 'You Don`t Have Permission To Provide This Operation',
      404: 'User Not Found!',
      default: 'Error While Registering Password!',
    };
    const errorMessage = createResponseErrorMessage({
      specificErrorHandler,
      status,
    });
    yield call(() => {
      throw errorMessage;
    });
  }
  return null;
}

export function* loginUser({ username, password }) {
  try {
    const response = yield call(() => loginUserCall({ username, password }));
    return response;
  } catch (error) {
    const status = extractResponseErrorStatus(error);
    const specificErrorHandler = {
      400: 'Wrong payload',
      401: 'Token Is Required!',
      403: 'You Don`t Have Permission To Provide This Operation',
      404: 'User Not Found!',
      default: 'Error While Registering Password!',
    };
    const errorMessage = createResponseErrorMessage({
      specificErrorHandler,
      status,
    });
    yield call(() => {
      throw errorMessage;
    });
  }
  return null;
}

export function* updateUser({ email, firstName, lastName, mobilePhone }) {
  try {
    yield call(() => updateUserCall({ email, firstName, lastName, mobilePhone }));
  } catch (error) {
    const status = extractResponseErrorStatus(error);
    const specificErrorHandler = {
      400: 'Wrong payload',
      401: 'Token Is Required!',
      403: 'You Don`t Have Permission To Provide This Operation',
      404: 'User Not Found!',
      default: 'Error While Registering Password!',
    };
    const errorMessage = createResponseErrorMessage({
      specificErrorHandler,
      status,
    });
    yield call(() => {
      throw errorMessage;
    });
  }
}

export function* resetPasswordUser({ payload }) {
  yield put(startLoading());
  const { password } = payload;
  const {
    user: { email: username },
  } = yield select();
  try {
    yield call(() =>
      updatePassword({
        username,
        password,
      }),
    );
    yield put(logoutUser());
    history.replace(ROUTES.LOGIN);
    yield put(setError('Your password has been successfully reset. Please login again.'));
  } catch (error) {
    const status = extractResponseErrorStatus(error);
    const specificErrorHandler = {
      400: 'Wrong payload',
      401: 'Token is required!',
      403: 'You don`t have permission to provide this operation',
      404: 'User not found!',
      default: 'Error while resetting password!',
    };
    const errorMessage = createResponseErrorMessage({
      specificErrorHandler,
      status,
    });
    yield put(setError(errorMessage));
  } finally {
    yield put(finishLoading());
  }
}
