/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import _ from 'lodash';
import './select-with-create.scss';

export default class SelectWithSearch extends React.Component {
  constructor(props) {
    super(props);
    const { options, selected } = this.props;
    const selectedLabel = selected && selected.label ? selected.label : '';
    this.state = {
      label: '',
      selectedLabel,
      options,
      open: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    let { options } = this.props;
    let { label, selectedLabel } = this.state;
    if (selected === '' && selected !== prevProps.selected) {
      this.setState({
        label: '',
        selectedLabel: '',
        options,
      });
    } else if (selected && selected !== prevProps.selected) {
      label = '';
      selectedLabel = selected.label;
      options = options.filter((opt) => opt.value !== selected.value);
      this.setState({
        label,
        selectedLabel,
        options,
      });
    } else if (
      this.props.options &&
      this.props.options.length !== prevProps.options.length
    ) {
      this.setState({
        options: this.props.options,
      });
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    let { options } = this.props;
    const { selectedLabel } = this.state;
    let { label } = this.state;
    if (!label) {
      label = event.target.value.substr(selectedLabel.length);
    } else {
      label = event.target.value;
    }
    options = options.filter((opt) =>
      opt.label.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    this.setState({
      label,
      options,
    });
  };

  onFocus = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  onToggle = (isOpen, event) => {
    const { placeholder, selected } = this.props;
    if (event.target.id !== `${_.kebabCase(placeholder)}-input`) {
      let { options } = this.props;
      if (selected && selected.value) {
        options = options.filter((opt) => opt.value !== selected.value);
      }
      this.setState({
        open: isOpen,
        label: '',
        options,
      });
    }
  };

  render() {
    const { label, selectedLabel, options } = this.state;
    const { placeholder, field, createFormComponent: CreateForm } = this.props;
    return (
      <Dropdown
        id={_.kebabCase(placeholder)}
        className="select-with-create"
        open={this.state.open}
        onToggle={this.onToggle}
      >
        <Dropdown.Toggle>
          <input
            id={`${_.kebabCase(placeholder)}-input`}
            className="input-no-border"
            value={label || selectedLabel}
            placeholder={placeholder}
            onChange={this.handleChange}
            onFocus={this.onFocus}
            autoComplete="off"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {options.map((opt, index) => (
            <MenuItem
              key={`select-item-${index}-${opt.value}`}
              className="wedge-menu-item"
              onSelect={() => this.props.onChange(opt)}
              disabled={opt.disabled || false}
            >
              {opt.icon ? (
                <div>
                  <div className="imgContainer">
                    <img height={24} src={opt.icon} alt={opt.label} />
                  </div>
                  <span>{opt.label}</span>
                </div>
              ) : (
                <span title={opt.label}>{opt.label}</span>
              )}
            </MenuItem>
          ))}
          {this.props.groupCreative && this.props.createFormComponent && (
            <CreateForm field={field} closeField={this.onClose} />
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

SelectWithSearch.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array.isRequired,
  groupCreative: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string,
  createFormComponent: PropTypes.func,
};
