export const FETCHING_POLICIES_REQUESTED = 'FETCHING_POLICIES_REQUESTED';
export const FETCHING_POLICIES_SUCCESS = 'FETCHING_POLICIES_SUCCESS';
export const FETCHING_POLICIES_FAILURE = 'FETCHING_POLICIES_FAILURE';

export const CREATION_POLICY_FAILURE = 'CREATION_POLICY_FAILURE';
export const CREATION_POLICY_REQUESTED = 'CREATION_POLICY_REQUESTED';
export const CREATION_POLICY_SUCCESS = 'CREATION_POLICY_SUCCESS';

export const UPDATE_POLICY_FAILURE = 'UPDATE_POLICY_FAILURE';
export const UPDATE_POLICY_REQUESTED = 'UPDATE_POLICY_REQUESTED';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';

export const DELETE_POLICY_FAILURE = 'DELETE_POLICY_FAILURE';
export const DELETE_POLICY_REQUESTED = 'DELETE_POLICY_REQUESTED';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';

export const REORDER_POLICY_FAILURE = 'REORDER_POLICY_FAILURE';
export const REORDER_POLICY_REQUESTED = 'REORDER_POLICY_REQUESTED';
export const REORDER_POLICY_SUCCESS = 'REORDER_POLICY_SUCCESS';

export const GET_URL_CATEGORIES_REQUEST = 'GET_URL_CATEGORIES_REQUEST';
export const GET_URL_CATEGORIES_SUCCESS = 'GET_URL_CATEGORIES_SUCCESS';
export const GET_URL_CATEGORIES_FAILURE = 'GET_URL_CATEGORIES_FAILURE';

export const GET_URL_LIST_REQUEST = 'GET_URL_LIST_REQUEST';
export const GET_URL_LIST_SUCCESS = 'GET_URL_LIST_SUCCESS';
export const GET_URL_LIST_FAILURE = 'GET_URL_LIST_FAILURE';

export const GET_SINGLE_URL_LIST_REQUEST = 'GET_SINGLE_URL_LIST_REQUEST';
export const GET_SINGLE_URL_LIST_SUCCESS = 'GET_SINGLE_URL_LIST_SUCCESS';
export const GET_SINGLE_URL_LIST_FAILURE = 'GET_SINGLE_URL_LIST_FAILURE';

export const GET_URL_FILTERING_PROFILES_REQUEST = 'GET_URL_FILTERING_PROFILES_REQUEST';
export const GET_URL_FILTERING_PROFILES_SUCCESS = 'GET_URL_FILTERING_PROFILES_SUCCESS';
export const GET_URL_FILTERING_PROFILES_FAILURE = 'GET_URL_FILTERING_PROFILES_FAILURE';

export const GET_SINGLIE_URL_FILTERING_PROFILES_REQUEST = 'GET_SINGLIE_URL_FILTERING_PROFILES_REQUEST';
export const GET_SINGLIE_URL_FILTERING_PROFILES_SUCCESS = 'GET_SINGLIE_URL_FILTERING_PROFILES_SUCCESS';
export const GET_SINGLIE_URL_FILTERING_PROFILES_FAILURE = 'GET_SINGLIE_URL_FILTERING_PROFILES_FAILURE';

export const CREATE_URL_FILTERING_PROFILES_REQUEST = 'CREATE_URL_FILTERING_PROFILES_REQUEST';
export const CREATE_URL_FILTERING_PROFILES_SUCCESS = 'CREATE_URL_FILTERING_PROFILES_SUCCESS';
export const CREATE_URL_FILTERING_PROFILES_FAILURE = 'CREATE_URL_FILTERING_PROFILES_FAILURE';

export const UPDATE_URL_FILTERING_PROFILES_REQUEST = 'UPDATE_URL_FILTERING_PROFILES_REQUEST';
export const UPDATE_URL_FILTERING_PROFILES_SUCCESS = 'UPDATE_URL_FILTERING_PROFILES_SUCCESS';
export const UPDATE_URL_FILTERING_PROFILES_FAILURE = 'UPDATE_URL_FILTERING_PROFILES_FAILURE';

export const DELETE_URL_FILTERING_PROFILES_REQUEST = 'DELETE_URL_FILTERING_PROFILES_REQUEST';
export const DELETE_URL_FILTERING_PROFILES_SUCCESS = 'DELETE_URL_FILTERING_PROFILES_SUCCESS';
export const DELETE_URL_FILTERING_PROFILES_FAILURE = 'DELETE_URL_FILTERING_PROFILES_FAILURE';
