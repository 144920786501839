import { object, string } from 'yup';

const publicAddressValidationSchema = object({
  name: string('Enter Allocated IP name')
    .max(35, 'Name should be less than 36 characters.')
    .min(3, 'Name should be more than 3 characters.')
    .required('Name is required.'),
  desc: string().max(255, 'Description should be less than 256 characters'),
});

export default publicAddressValidationSchema;
