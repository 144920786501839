import IoTIcon from 'assets/img/PNG/category/IOT.png';
import CloudIcon from 'assets/img/PNG/category/CloudInstance.png';
import SaasIcon from 'assets/img/PNG/category/Saas.png';
import PremiseIcon from 'assets/img/PNG/category/Premise.png';
import IoMDIcon from 'assets/img/PNG/category/IoMD.png';
import DataIcon from 'assets/img/PNG/category/Datacenter.png';
import MobileIcon from 'assets/img/PNG/category/MobileDevice.png';
import AppIcon from 'assets/img/PNG/category/Application.png';
import ComputerIcon from 'assets/img/PNG/category/Computerlaptop.png';
import ThingIcon from 'assets/img/PNG/device.png';
import AddressIcon from 'assets/img/PNG/address.png';
import GatewayIcon from 'assets/img/PNG/gateway.png';
import UserIcon from 'assets/img/SVG/ecosystem-user.svg';

import AddressDetailsModal from 'Modals/AddressDetailsModal';
import ThingDetailsModal from 'Modals/ThingDetailsModal';
import GatewayDetailsModal from 'Modals/GatewayDetailsModal';
import AllocatedIPDetail from 'Modals/AllocatedIPDetail';
import NewAddressSurvey from 'Modals/NewAddressSurvey';
import NewThingSurvey from 'Modals/NewThingSurvey';
import NewGatewaySurvey from 'Modals/NewGatewaySurvey';
import NewAllocatedIP from 'Modals/NewAllocatedIP';
import NewUser from 'Modals/NewUser';
import { LinkEdit, StatusCol } from 'components/AdvancedTable/index';
import { LDAP_EXTERNAL_SYSTEM } from '../LDAPConfig';

export const OBJECT_ASSET_VALUES = Array.from(Array(101).keys()).map((n) => ({
  label: String(n),
  value: n,
}));

export const OBJECT_CATEGORIES = [
  { label: 'IoT', value: 'IoT', icon: IoTIcon },
  { label: 'Cloud Instance', value: 'Cloud Instance', icon: CloudIcon },
  { label: 'Saas', value: 'Saas', icon: SaasIcon },
  { label: 'Premise', value: 'Premise', icon: PremiseIcon },
  { label: 'IoMD', value: 'IoMD', icon: IoMDIcon },
  { label: 'Data Center', value: 'Data Center', icon: DataIcon },
  { label: 'Mobile Device', value: 'Mobile Device', icon: MobileIcon },
  { label: 'Application', value: 'Application', icon: AppIcon },
  {
    label: 'Computer / Laptop',
    value: 'Computer / Laptop',
    icon: ComputerIcon,
  },
];

export const OBJECT_TYPES = {
  ADDRESS: 'address',
  GATEWAY: 'gateway',
  PUBLIC_ADDRESS: 'publicAddress',
  THING: 'thing',
  USER: 'user',
  SERVER: 'server',
};

export const OBJECT_MENU_CONFIG = [
  {
    type: 'address',
    url: 'addresses',
    title: 'Addresses',
    label: 'Address',
    icon: AddressIcon,
    iconStyle: { width: 14 },
  },
  {
    type: 'publicAddress',
    url: 'publicAddresses',
    title: 'Allocated IPs',
    label: 'Allocated IP',
    icon: AddressIcon,
    iconStyle: { width: 14 },
  },
  {
    type: 'gateway',
    url: 'gateways',
    title: 'Gateways',
    label: 'Gateway',
    icon: GatewayIcon,
    iconStyle: { width: 20 },
  },
  {
    type: 'server',
    url: 'servers',
    title: 'Identity Providers',
    label: 'server',
    icon: ThingIcon,
    iconStyle: { width: 14 },
  },
  {
    type: 'thing',
    url: 'things',
    title: 'Things',
    label: 'Thing',
    icon: ThingIcon,
    iconStyle: { width: 12 },
  },
  {
    type: 'user',
    url: 'users',
    title: 'Users',
    label: 'User',
    icon: UserIcon,
    iconStyle: { width: 14 },
  },
];

export const OBJECT_TABLE_FIELDS = {
  object: [
    { name: 'OBJECT', center: false },
    { name: 'DETAILS', center: false },
    { name: 'REGION', center: false },
    { name: 'STATUS', center: false },
  ],
  address: [
    { name: 'OBJECT', center: false },
    { name: 'ADDRESS', center: false },
    { name: 'DETAILS', center: false },
  ],
  gateway: [
    { name: 'OBJECT', center: false },
    { name: 'NETWORKS', center: false },
    { name: 'REGION', center: false },
    { name: 'DETAILS', center: false },
  ],
  thing: [
    { name: 'OBJECT', center: false },
    { name: 'PROFILE GROUP', center: false },
    { name: 'REGION', center: false },
    { name: 'DETAILS', center: false },
  ],
  user: [
    { text: 'First name', dataField: 'firstName' },
    { text: 'Last name', dataField: 'lastName' },
    { text: 'Role', dataField: 'role' },
    { text: 'Profile Group', dataField: 'profileGroup' },
    { text: 'E-mail', dataField: 'email' },
    { text: 'Source', dataField: 'source' },
    { text: 'Onboarding Status', dataField: 'status' },
  ],
  server: [
    { text: 'Priority', dataField: 'priority' },
    { text: 'Name', dataField: 'name' },
    { text: 'Type', dataField: 'type' },
    { text: 'Server', dataField: 'server' },
    {
      text: 'Status',
      dataField: 'status',
      formatter: StatusCol,
    },
    {
      text: 'Action',
      dataField: 'action',
      formatter: LinkEdit,
    },
  ],
};

export const OBJECT_TYPE_OPTIONS = [
  { label: 'Thing', value: 'thing' },
  { label: 'Address', value: 'address' },
  { label: 'IPSec', value: 'gateway' },
];

export const OBJECT_TYPES_CONFIG = [
  {
    name: 'thing',
    urlType: 'devices',
    title: 'Create New Thing',
    createComponent: NewThingSurvey,
    detailComponent: ThingDetailsModal,
  },
  {
    name: 'gateway',
    urlType: 'gateways',
    title: 'Create New Gateway',
    createComponent: NewGatewaySurvey,
    detailComponent: GatewayDetailsModal,
  },
  {
    name: 'address',
    urlType: 'addresses',
    title: 'Create New Address',
    createComponent: NewAddressSurvey,
    detailComponent: AddressDetailsModal,
  },
  {
    name: 'publicAddress',
    urlType: 'publicAddresses',
    title: 'Create New Allocated IP',
    createComponent: NewAllocatedIP,
    detailComponent: AllocatedIPDetail,
  },
  {
    name: 'user',
    urlType: 'users',
    title: 'Create New Users',
    createComponent: NewUser,
    detailComponent: AllocatedIPDetail,
  },
  {
    name: 'server',
    urlType: 'idp/ldap',
    title: 'Create New Server',
    createComponent: NewUser,
    detailComponent: AllocatedIPDetail,
  },
];

export const OBJECT_STATUS_OPTIONS = [
  { value: true, label: 'Enabled', disabled: true },
  /* Hide until API is ready 
		{ value: false, label: 'Disabled', disabled:true }
	*/
];

export const LDAP_CONN_TYPE = {
  THROUGH_GATEWAY: '1',
  THROUGH_INTERNET: '2',
};

export const LDAP_CONN_TYPE_OPTIONS = [
  {
    summaryLabel: 'Connected through Gateway',
    label: 'Server is connected to this ecosystem through a Gateway',
    value: LDAP_CONN_TYPE.THROUGH_GATEWAY,
  },
  {
    summaryLabel: 'Available publicly over the internet',
    label: 'Server is available publicly over the internet',
    value: LDAP_CONN_TYPE.THROUGH_INTERNET,
  },
];
export const LDAP_URL_SCHEMES = {
  LDAP: 'ldap://',
  LDAPS: 'ldaps://',
};
export const LDAP_URL_SCHEMES_OPTIONS = [
  { value: LDAP_URL_SCHEMES.LDAP, label: LDAP_URL_SCHEMES.LDAP },
  { value: LDAP_URL_SCHEMES.LDAPS, label: LDAP_URL_SCHEMES.LDAPS },
];
export const LDAP_USER_SCHEMA_OPTIONS = [
  { value: 'cn', label: 'cn' },
  { value: 'uid', label: 'uid' },
  { value: 'userPrincipalName', label: 'userPrincipalName' },
  { value: 'sAMAccountName', label: 'sAMAccountName' },
];
export const FILE_IMPORT = {
  FILE_CONTENT: 'content',
  FILE_UPLOAD: 'upload',
};
export const FILE_IMPORT_OPTIONS = [
  { value: FILE_IMPORT.FILE_CONTENT, label: 'Paste file Content' },
  { value: FILE_IMPORT.FILE_UPLOAD, label: 'File Upload' },
];
export const USER_ADD_OPTIONS = [
  { value: '1', label: 'Add Manually' },
  { value: '2', label: 'Import from Comma Separated Value(csv)' },
  // { value: '3', label: 'Connect to existing identity provider (LDAP, AD)' }
];

export const USER_MODAL_TYPE = {
  CREATE_USER: 'CREATE_USER',
  IMPORT_USER: 'IMPORT_USER',
  LDAP_CONF: 'LDAP_CONF',
};

export const CREATE_USER_MODAL_TITLE_MAPPING = {
  CREATE_USER: 'Add Users',
  IMPORT_USER: 'Import Users',
  LDAP_CONF: 'Identity Provider',
  [LDAP_EXTERNAL_SYSTEM.GENERIC_LDAP]: 'Identity Provider: Generic LDAP',
  [LDAP_EXTERNAL_SYSTEM.AZURE_AD]: 'Identity Provider: Azure AD',
  [LDAP_EXTERNAL_SYSTEM.MICROSOFT_AD]: 'Identity Provider: Microsoft AD',
  [LDAP_EXTERNAL_SYSTEM.GOOGLE_G_SUITE]: 'Identity Provider: Goole G Suite',
};
