import React from 'react';
import ExpiryWarning from '../../components/ExpiryWarning/ExpiryWarning';
import Header from '../../components/Header/Header';

import EcosystemsPage from '../../views/Ecosystems/Ecosystems';
import './ecosystems.scss';

const Ecosystems = () => (
  <div className="wrapper ecosystems-wrapper">
    <ExpiryWarning />
    <Header brandText="All Ecosystems" />
    <div style={{ width: '100%' }}>
      <EcosystemsPage />
    </div>
  </div>
);

export default Ecosystems;
