/**
 * Location Type
 */

export const LOCATION_TYPE = {
  COORDINATES: 'coordinate',
  AUTO: 'auto',
  REGION: 'region',
  UNDEFINED: 'undefined',
};

/**
 * Location Type Options
 */

export const LOCATION_TYPE_OPTIONS = [
  { label: 'Coordinates', value: LOCATION_TYPE.COORDINATES },
  { label: 'Undefined', value: LOCATION_TYPE.UNDEFINED },
];

export const DEFAULT_LOCATION = {
  LAT: 40.752257,
  LNG: -74.049107,
};
