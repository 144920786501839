import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field, Form, Formik } from 'formik';
import { createRole } from '../../store/creational-scenario-actions';
import roleValidationSchema from '../../validationSchemas/roleValidationSchema';
import './style.scss';

const CreateRoleForm = (props) => (
  <Formik
    initialValues={{
      name: '',
    }}
    validationSchema={roleValidationSchema}
    onSubmit={(values, { resetForm }) => {
      props.createRole({
        roleName: values.name,
        field: props.field,
      });
      props.closeField();
      resetForm();
    }}
  >
    {({ errors }) => (
      <Form className="create-group-form">
        <div className="group-input-field">
          <Field
            name="name"
            placeholder="New Role"
            onSelect={(e) => e.stopPropagation()}
            onFocus={(e) => e.preventDefault()}
            className="form__group-input"
          />
          <p className="error-text">{errors.name}</p>
        </div>
        <button
          disabled={!_.isEmpty(errors)}
          type="submit"
          className="save-button btn"
        >
          Save
        </button>
      </Form>
    )}
  </Formik>
);

CreateRoleForm.propTypes = {
  createRole: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  closeField: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createRole: (role) => dispatch(createRole(role)),
});

export default connect(null, mapDispatchToProps)(memo(CreateRoleForm));
