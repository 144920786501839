/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Collapse, OverlayTrigger, Popover, Tabs, Tab } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { findDOMNode } from 'react-dom';
import ReactTooltip from 'react-tooltip';

import Card from '../components/Card';
import CustomMap from '../components/CustomMap';
import Field from '../components/Field/Field';
import GatewayDevices from '../components/GatewayDevices';

import { GATEWAY_TYPE } from '../enum/gateway';
import { IP_MODES } from '../enum/ip';
import { LOCATION_TYPE_OPTIONS } from '../enum/location';
import Translator from '../utils/enumTranslator';
import { regeneratePSK, readGateway } from '../views/Objects/scenario-actions';

import './modals.scss';

const hiddenToken = '********************************';

class GatewayDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false,
      pskShow: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.props.readGateway(data);
  }

  handlePSKPopClose = () => {
    if (this.refs.overlayShow) {
      this.refs.overlayShow.hide();
    }
    if (this.refs.overlayCopy) {
      this.refs.overlayCopy.hide();
    }
  };

  handlePSKShowClick = () => {
    const { psk } = this.props;
    if (psk) {
      this.setState((prev) => ({ pskShow: !prev.pskShow }));
    }
  };

  onCollapse = (event) => {
    event.preventDefault();
    this.setState((prev) => ({ isCollapsed: !prev.isCollapsed }));
  };

  onCopyPSK = (text, result) => {
    if (text && result) {
      ReactTooltip.show(findDOMNode(this.refs.psk));
    }
  };

  onRegenerate = async (event) => {
    const { data } = this.props;
    event.preventDefault();
    this.handlePSKPopClose();
    await this.props.regeneratePSK(data.uuid);
  };

  renderConfig() {
    const { data, psk } = this.props;
    const { isCollapsed, pskShow } = this.state;
    const type = Translator.type(data.element, data.gateway_type);
    const category = Translator.category(data.category);
    const model = Translator.model(data.model);

    const renderPSKPop = (
      <Popover id="popover-psk">
        <div className="popover-psk">
          <p>This will invalidate current Pre-Shared Key and generate new one.</p>
          <p>Are you sure?</p>
          <div className="refresh__buttons">
            <Button bsStyle="primary" onClick={this.onRegenerate}>
              Yes
            </Button>
            <Button onClick={this.handlePSKPopClose}>No</Button>
          </div>
        </div>
      </Popover>
    );

    return (
      <div className="modal__content padded new-gateway-survey">
        <Card header={false}>
          <div className="form-row gateway__detail">
            <div className="gateway__detail-info flex-one">
              <div className="form-row">
                <Field.Group label="Name">
                  <Field.Text text={data.name} />
                </Field.Group>
              </div>
              <div className="form-row">
                <Field.Group label="Category / Type">
                  <Field.Text text={`${category.label} / ${type.label}`} />
                </Field.Group>
              </div>
            </div>
            <div className="gateway__detail-vpn flex-one">
              <div>
                <Field.Group label="Model">
                  <Field.Text text={`${model.label}`} />
                </Field.Group>
                <span className="vpn_title" onClick={this.onCollapse}>
                  <i className="material-icons">{isCollapsed ? 'arrow_drop_down' : 'arrow_right'}</i>
                  VPN Parameters
                </span>
                <Collapse in={isCollapsed}>
                  <div className="vpn-collapse">
                    <Field.Group extraClass="psk-info">
                      <div className="psk-info__options">
                        <span className="psk-label">Pre-Shared Key: </span>
                        <div className="psk-data">
                          <input className="psk-text" disabled value={pskShow ? psk : hiddenToken} />
                          <div className="psk-actions">
                            <OverlayTrigger
                              ref="overlayCopy"
                              trigger="click"
                              placement="bottom"
                              rootClose
                              overlay={renderPSKPop}
                              onEnter={() => !!psk && this.refs.overlayCopy.hide()}
                            >
                              <div className="psk-copy psk-option">
                                <span className="psk__copied" ref="psk" data-tip="Copied!" data-for="psk-copied" />
                                <CopyToClipboard text={psk} onCopy={this.onCopyPSK}>
                                  <i className="fa fa-clone" />
                                </CopyToClipboard>
                                <ReactTooltip
                                  id="psk-copied"
                                  delayHide={1000}
                                  type="info"
                                  afterShow={() => ReactTooltip.hide(findDOMNode(this.refs.psk))}
                                />
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              ref="overlayShow"
                              trigger="click"
                              placement="bottom"
                              rootClose
                              overlay={renderPSKPop}
                              onEnter={() => !!psk && this.refs.overlayShow.hide()}
                            >
                              <div className="psk-show psk-option">
                                <i className="material-icons-outlined" onClick={this.handlePSKShowClick}>
                                  {pskShow ? 'visibility_off' : 'visibility'}
                                </i>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </Field.Group>
                    <Field.Group label="Recommended ciphers">
                      <Field.Text text="1 - IKE: aes256gcm16-sha256-ecp256" />
                      <Field.Text text="2 - ESP: aes256gcm16-sha256-ecp256" />
                    </Field.Group>
                    <Field.Group label="Gateway Address">
                      <Field.Text text={data.server_address} />
                    </Field.Group>
                    <Field.Group label="Peer ID">
                      <Field.Text text={data.peer_id} />
                    </Field.Group>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="form-row gateway__detail">
            <div className="gateway__detail-ip flex-one">
              <div className="space-above">
                <Field.Group label="Connections from:">
                  <Field.Text text={`${data.peer_address}`} />
                </Field.Group>
              </div>
              {data.gateway_type === GATEWAY_TYPE.VGATEWAY && data.gateway_internet_proto === IP_MODES.STATIC && (
                <div className="gateway__detail-internet">
                  <Field.Group label="vGateway WAN IP" extraClass="flex-one">
                    <Field.Text text={`${data.gateway_internet_ip}`} />
                  </Field.Group>
                  <Field.Group label="vGateway Default Route" extraClass="flex-one">
                    <Field.Text text={`${data.gateway_internet_gw}`} />
                  </Field.Group>
                </div>
              )}
              {data.gateway_type === GATEWAY_TYPE.VGATEWAY && (
                <div className="gateway__detail-local">
                  <Field.Group label="vGateway LAN IP">
                    <Field.Text text={`${data.gateway_local_ip}`} />
                  </Field.Group>
                </div>
              )}
              <div className="space-above">
                <Field.Group label="Additional Local Networks">
                  <div className="local-networks--container">
                    <table>
                      <tbody>
                        <tr>
                          <th className="network">Network</th>
                        </tr>
                        {data.peer_networks &&
                          data.peer_networks.map((net, index) => (
                            <tr key={`additional-network-index-${index}`}>
                              <td className="network">{net.network}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Field.Group>
              </div>
            </div>
            <div className="gateway__detail-config flex-one space-above">
              <Field.Group label="Configuration Options">
                <GatewayDevices data={data} onDownload={this.handleDownload} />
              </Field.Group>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { data } = this.props;
    const locaton = data.geolocation ? LOCATION_TYPE_OPTIONS[0].label : LOCATION_TYPE_OPTIONS[1].label;

    return (
      <div className="modal__content padded new-gateway-survey">
        <Card header={false}>
          {data.description && (
            <Field.Group label="Description" full>
              <Field.Text text={data.description} />
            </Field.Group>
          )}
          <Field.Group label="Location">
            <Field.Text text={locaton} />
          </Field.Group>
          {!!data.geolocation && (
            <React.Fragment>
              <div className="form-row">
                <Field.Group label="Latitude" extraClass="flex-3">
                  <Field.Text text={data.geolocation.latitude} />
                </Field.Group>
                <Field.Group label="Longitude" extraClass="flex-2">
                  <Field.Text text={data.geolocation.longitude} />
                </Field.Group>
              </div>
              <div>
                <CustomMap
                  center={{
                    lat: data.geolocation.latitude,
                    lng: data.geolocation.longitude,
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }

  render() {
    return (
      <Tabs defaultActiveKey={1} id="object__tabs">
        <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
          {this.renderConfig()}
        </Tab>
        <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
          {this.renderDesc()}
        </Tab>
      </Tabs>
    );
  }
}

GatewayDetailsModal.propTypes = {
  data: PropTypes.object.isRequired,
  readGateway: PropTypes.func.isRequired,
  regeneratePSK: PropTypes.func.isRequired,
  psk: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  psk: state.objects.psk,
});

const mapDispatchToProps = (dispatch) => ({
  readGateway: (entity) => dispatch(readGateway(entity)),
  regeneratePSK: (gatewayUUID) => dispatch(regeneratePSK(gatewayUUID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GatewayDetailsModal);
