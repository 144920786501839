/**
 * NSP Regiosn
 */

export default {
  US_EAST: { value: 'us-east', label: 'US East' },
  US_WEST: { value: 'us-west', label: 'US West' },
  US_TEST: { value: 'us-test', label: 'US Test' },
  EU_EAST: { value: 'eu-east', label: 'EU East' },
  EU_WEST: { value: 'eu-west', label: 'EU West' },
  CANADA: { value: 'canada', label: 'Canada' },
  LATAM: { value: 'latam', label: 'LatAm' },
  ASIA: { value: 'asia', label: 'Asia' },
  AUS: { value: 'aus', label: 'Australia' },
};
