export const REMOTE_DEVICES = [
  {
    title: 'Mobile Devices',
    options: [
      {
        label: 'iOS OpenVPN config',
        value: 'openvpn',
      },
      {
        label: 'Android OpenVPN config',
        value: 'openvpn',
      },
    ],
  },
  {
    title: 'Personal Computers and Servers',
    options: [
      {
        label: 'Windows OpenVPN config',
        value: 'openvpn',
      },
      {
        label: 'macOS OpenVPN config',
        value: 'openvpn',
      },
      {
        label: 'Linux OpenVPN config',
        value: 'openvpn',
      },
    ],
  },
  {
    title: 'Acreto Connect Client Installer',
    options: [
      {
        label: 'Acreto Connect Client for Ubuntu 18.04 LTS',
        value: 'tlsvpn',
      },
      {
        label: 'Acreto Connect Client for ArchLinux',
        value: 'tlsvpn',
      },
    ],
  },
];
