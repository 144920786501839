import * as REST from '../../api/rest';
import { createResponseErrorMessage, extractResponseErrorStatus } from '../../utils/responseErrorHandler';
import { updateUserCall, readUserAvatar, updatePasswordCall, loginUserCall } from '../../store/sagas/apiCalls';
import {
  fecthCustomerStarted,
  fetchCustomerSuccess,
  fetchCustomerFailed,
  updateCustomerSuccess,
  updateCustomerFailed,
} from '../../store/customers/actions';
import { logoutUser, updateCustomer } from '../../store/auth/actions';
import {
  updateUser,
  updateUserFailed,
  startLoading,
  finishLoading,
  setError,
  setPasswordError,
  clearPasswordError,
} from '../../store/user/actions';
import { loading } from '../../store/global/actions';
import * as ACTION_TYPES from '../../store/auth/action-types';
import history from '../../history';

export function fecthCustomerDetail() {
  return async (dispatch, getState) => {
    try {
      const { uuid } = getState().auth.selectedCustomer;
      dispatch(fecthCustomerStarted());
      const customer = await REST.fecthCustomerDetail({ customer: uuid });
      const logo = await REST.getCustomerLogo(customer.uuid);
      customer.logo = logo;
      dispatch(fetchCustomerSuccess(customer));
    } catch (error) {
      dispatch(fetchCustomerFailed(error));
    }
  };
}

export const updateCustomerInfo = ({ customer }) => async (dispatch, getState) => {
  try {
    const { uuid } = getState().auth.selectedCustomer;
    const result = await REST.updateCustomerInfo({
      customerUUID: uuid,
      customer,
    });
    dispatch(loading(true));
    result.logo = await REST.getCustomerLogo(uuid);
    dispatch(updateCustomerSuccess(result));
    dispatch(updateCustomer({ name: result.name, uuid: result.uuid }));
    history.goBack();
  } catch (error) {
    dispatch(updateCustomerFailed(error));
  } finally {
    dispatch(loading(false));
  }
};

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    const result = await updateUserCall(user);
    dispatch(loading(true));
    const { data: avatar } = await readUserAvatar(user.email);
    result.avatar = avatar;
    dispatch(updateUser(result));
    history.goBack();
  } catch (error) {
    dispatch(updateUserFailed(error));
  } finally {
    dispatch(loading(false));
  }
};

export const updateUserPassword = ({ email, currentPassword, newPassword }) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await loginUserCall({ username: email, password: currentPassword });
  } catch (error) {
    const status = extractResponseErrorStatus(error);
    const specificErrorHandler = {
      400: 'Current Password Is Incorrect',
      401: 'Token Is Required!',
      403: 'You Don`t Have Permission To Provide This Operation',
      404: 'User Not Found!',
      default: 'Internal Server Error',
    };
    const errorMessage = createResponseErrorMessage({
      status,
      specificErrorHandler,
    });
    dispatch(setPasswordError(errorMessage));
    return;
  }
  try {
    await updatePasswordCall({ email, password: newPassword });
    await REST.logout();
    dispatch(logoutUser());
    dispatch(setError('Please login again with new password'));
    dispatch(clearPasswordError());
  } catch (error) {
    const status = extractResponseErrorStatus(error);
    const specificErrorHandler = {
      400: 'Wrong payload',
      401: 'Token Is Required!',
      403: 'You Don`t Have Permission To Provide This Operation',
      404: 'User Not Found!',
      default: 'Internal Server Error',
    };
    const errorMessage = createResponseErrorMessage({
      status,
      specificErrorHandler,
    });
    dispatch(setPasswordError(errorMessage));
  } finally {
    dispatch(finishLoading());
  }
};

export const activate2FA = (code) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACTION_TYPES.ACTIVATE_2FA_REQUEST,
    });
    const { email } = getState().user;
    await REST.activate2FA(email, code);
    dispatch({ type: ACTION_TYPES.ACTIVATE_2FA_SUCCESS });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.ACTIVATE_2FA_FAILURE,
      payload: { message: error },
    });
  }
};

export const deactivate2FA = (code) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.DEACTIVATE_2FA_REQUEST });
    const { email } = getState().user;
    await REST.deactivate2FA(email, code);
    dispatch({ type: ACTION_TYPES.DEACTIVATE_2FA_SUCCESS });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.DEACTIVATE_2FA_FAILURE,
      payload: { message: error },
    });
  }
};

export const logout2FA = (message) => async (dispatch) => {
  dispatch(logoutUser());
  dispatch(setError(message));
};
