import React from 'react';
import PropTypes from 'prop-types';
import { ClapSpinner } from 'react-spinners-kit';

import './blocked-ecosystem.scss';
import { ECOSYSTEM_STATUS, CONSTANTS } from '../../enum';

const errMsg = {
  [ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS]: {
    label: 'Commit is in Progress...',
    desc: 'In some cases, commit can take up to 5 minutes.',
  },
  [ECOSYSTEM_STATUS.COMMIT_ERROR]: {
    label: 'Commit error',
    desc: 'Last commit failed. Please contact support',
  },
  [ECOSYSTEM_STATUS.ECOSYSTEM_ERROR]: {
    label: 'Ecosystem error',
    desc: 'There is an issue with your ecosystem. Please contact support',
  },
  [ECOSYSTEM_STATUS.DISABLED]: {
    label: 'Disabled',
    desc: 'This ecosystem is disabled. Please contact support',
  },
  [CONSTANTS.INACCESSIBLE]: {
    label: "You don't have access to this resource.",
    desc:
      ' If you consider this invalid, please contact your Organization admin or contact us, providing incident ID: xxxxxx.',
  },
};

export default class BlockedEcosystem extends React.Component {
  render() {
    const { status } = this.props;
    return (
      <div className="ecosystem-block content">
        {status === ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS && (
          <div className="loader-container">
            <ClapSpinner
              size={30}
              frontColor="#f68b1e"
              backColor="#fbb040"
              loading
            />
          </div>
        )}
        <p className="block-label">{errMsg[status].label}</p>
        <p className="block-desc">{errMsg[status].desc}</p>
      </div>
    );
  }
}

BlockedEcosystem.propTypes = {
  status: PropTypes.string.isRequired,
};
