export const ADDRESS_SCOPES = {
  ANY_PRIVATE: 'any-private',
  ANY_PUBLIC: 'any-public',
  EXTERNAL_PUBLIC: 'external-public',
  DEFAULT: 'default',
  PRIVATE: 'private',
  PUBLIC: 'public',
};

export const ADDRESS_TYPE = {
  INTERNAL: 0,
  EXTERNAL: 1,
};

export const ADDRESS_TYPES_OPTIONS = [
  { value: ADDRESS_TYPE.INTERNAL, label: 'Internal' },
  { value: ADDRESS_TYPE.EXTERNAL, label: 'External' },
];

export const ADDRESS_TRANSLATION_TYPES = {
  DISABLE: false,
  ENABLE: true,
};

export const ADDRESS_TRANSLATION_OPTIONS = [
  { value: ADDRESS_TRANSLATION_TYPES.ENABLE, label: 'Enable' },
  { value: ADDRESS_TRANSLATION_TYPES.DISABLE, label: 'Disable' },
];

export const SYSTEM_PUBLICS = ['Default Exit', 'IPv6 Default Exit', 'Default Tunnel', 'IPv6 Default Tunnel'];
