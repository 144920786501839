import React from 'react';
import PropTypes from 'prop-types';
import './add-ecosystem.scss';

const AddEcosystem = ({ onClick }) => (
  <div className="add-ecosystem item" onClick={onClick}>
    <div className="oval">
      <span>+</span>
    </div>
    <p className="add-ecosystem__text">Add New</p>
  </div>
);

AddEcosystem.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddEcosystem;
