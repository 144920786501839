import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  SET_CUR_CUSTOMER,
  REMOVE_CUR_CUSTOMER,
} from './action-types';

export function fecthCustomerStarted() {
  return {
    type: FETCH_CUSTOMER_REQUEST,
  };
}
export function fetchCustomerSuccess(result) {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: result,
  };
}
export function fetchCustomerFailed(error) {
  return {
    type: FETCH_CUSTOMER_FAILURE,
    payload: {
      message: error,
    },
  };
}

export function setCurrentCustomer(result) {
  return {
    type: SET_CUR_CUSTOMER,
    payload: result,
  };
}
export function removeCurrentCustomer() {
  return {
    type: REMOVE_CUR_CUSTOMER,
  };
}

export function updateCustomerStarted() {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
  };
}
export function updateCustomerSuccess(result) {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: result,
  };
}
export function updateCustomerFailed(error) {
  return {
    type: UPDATE_CUSTOMER_FAILURE,
    payload: {
      message: error,
    },
  };
}
