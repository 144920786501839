import React, { Component } from 'react';
import cx from 'classnames';
import { number, func, array } from 'prop-types';

import './tabs-header.scss';

class TabsHeader extends Component {
  render() {
    const { tabs, selectedIndex, onSelect } = this.props;
    return (
      <div className="row tabs-header-container">
        {tabs.map(({ name }, index) => {
          const isActive = selectedIndex === index;
          const verifiedSingleTabContainer = cx('col single-tab-container', {
            '--active': isActive,
          });
          return (
            <div key={name} onClick={() => onSelect(index)} className={verifiedSingleTabContainer}>
              <span>{name}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

TabsHeader.propTypes = {
  selectedIndex: number.isRequired,
  onSelect: func.isRequired,
  tabs: array.isRequired,
};

export default TabsHeader;
