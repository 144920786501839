import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import SuccessPanel from '../../components/SuccessPanel/SuccessPanel';
import EmailPanel from '../../components/EmailDisplay/EmailDisplay';
import { emailSelector } from '../../store/user/selectors';

const EmailSuccessfullyEntered = ({ email, message = '' }) => {
  const title =
    message || 'You should receive an email at following email address';
  return (
    <div className="form-container">
      <SuccessPanel message={title} />
      <EmailPanel email={email} />
    </div>
  );
};

EmailSuccessfullyEntered.propTypes = {
  email: string.isRequired,
  message: string,
};

const mapStateToProps = (state) => ({
  email: emailSelector(state),
});

export default connect(mapStateToProps)(EmailSuccessfullyEntered);
