import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmFooter } from './commons';

export const DiscardChanges = ({ onYes, onNo }) => (
  <div>
    <div className="action__confirm">
      <p>If you navigate away from this page, your unsaved changes will be lost. Are you sure?</p>
    </div>
    <div className="wedge-modal__footer">
      <ConfirmFooter onYes={onYes} onNo={onNo} />
    </div>
  </div>
);

DiscardChanges.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};
