import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import Card from '../components/Card';
import CustomMap from '../components/CustomMap';
import Field from '../components/Field/Field';

import { ADDRESS_SCOPES } from '../enum/address';
import { LOCATION_TYPE_OPTIONS } from '../enum/location';
import Translator from '../utils/enumTranslator';

import './modals.scss';

class AddressDetailsModal extends React.Component {
  renderConfig() {
    const { data } = this.props;
    const category = Translator.category(data.category);
    const ipType = Translator.protocolType(data.type);

    return (
      <div className="modal__content padded new-address-survey">
        <Card header={false}>
          <div className="form-row">
            <Field.Group label="Name" extraClass="flex-3">
              <Field.Text text={data.name} />
            </Field.Group>
            <Field.Group label={ipType.label} extraClass="flex-2">
              <Field.Text text={data.address} />
            </Field.Group>
          </div>
          <div className="form-row">
            <Field.Group label="Category">
              <Field.Text text={`${category.label}`} />
            </Field.Group>
          </div>
          <div className="form-row">
            <Field.Group>
              <Field.Text copy text={`${data.uuid}`} />
            </Field.Group>
          </div>
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { data } = this.props;
    const locaton = data.geolocation ? LOCATION_TYPE_OPTIONS[0].label : LOCATION_TYPE_OPTIONS[1].label;

    return (
      <div className="modal__content padded new-address-survey">
        <Card header={false}>
          {data.description && (
            <Field.Group label="Description" full>
              <Field.Text text={data.description} />
            </Field.Group>
          )}
          <Field.Group label="Location">
            <Field.Text text={locaton} />
          </Field.Group>
          {!!data.geolocation && (
            <React.Fragment>
              <div className="form-row">
                <Field.Group label="Latitude" extraClass="flex-3">
                  <Field.Text text={data.geolocation.latitude} />
                </Field.Group>
                <Field.Group label="Longitude" extraClass="flex-2">
                  <Field.Text text={data.geolocation.longitude} />
                </Field.Group>
              </div>
              <div>
                <CustomMap
                  center={{
                    lat: data.geolocation.latitude,
                    lng: data.geolocation.longitude,
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <Tabs defaultActiveKey={1} id="object__tabs">
        <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
          {this.renderConfig()}
        </Tab>
        {data.scope === ADDRESS_SCOPES.DEFAULT && (
          <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
            {this.renderDesc()}
          </Tab>
        )}
      </Tabs>
    );
  }
}

AddressDetailsModal.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AddressDetailsModal;
