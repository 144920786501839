export const IP_TYPE = {
  IPv4: 'ipv4',
  IPv6: 'ipv6',
  FQDN: 'fqdn',
};

export const IP_TYPE_OPTIONS = [
  { value: IP_TYPE.IPv4, label: 'IPv4' },
  { value: IP_TYPE.IPv6, label: 'IPv6' },
  { value: IP_TYPE.FQDN, label: 'FQDN' },
];

export const IP_MODES = {
  DHCP: 'dhcp',
  STATIC: 'static',
};
export const IP_MODES_OPTIONS = [
  { value: IP_MODES.DHCP, label: 'DHCP' },
  { value: IP_MODES.STATIC, label: 'Static' },
];

export const MASKS_V4 = Array.from(Array(32), (x, index) => ({
  value: index + 1,
  label: `${index + 1}`,
}));

export const MASKS_V6 = Array.from(Array(128), (x, index) => ({
  value: index + 1,
  label: `${index + 1}`,
}));
