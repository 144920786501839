import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { ClapSpinner } from 'react-spinners-kit';

import history from '../../history';
import Table from '../CustomTable';
import { EMPTY_EMAIL_ERROR, LDAP_STATUS_LOADING } from '../../enum/constants';
import CaretUp from '../../assets/img/SVG/caret-up.svg';
import CaretDown from '../../assets/img/SVG/caret-down.svg';

export const AdvancedTable = ({
  data,
  columnData,
  hiddenColumns,
  searchText,
  onSelect,
  onSelectAll,
  clickToSelect = true,
}) => {
  const sortCaret = (order) => {
    switch (order) {
      case 'asc':
        return <img src={CaretDown} alt="caret-down" className="caret__asc" />;
      case 'desc':
        return <img src={CaretUp} alt="caret-up" className="caret__desc" />;
      default:
        return null;
    }
  };

  const columns = columnData.map((col) => {
    const column = {
      headerAlign: 'center',
      sort: true,
      searchable: true,
      sortCaret,
      hidden: hiddenColumns.includes(col.dataField),
      ...col,
    };
    if (col.dataField === 'email') {
      column.style = (cell) => {
        if (cell === EMPTY_EMAIL_ERROR) {
          const color = '#ff4a55';
          return { color };
        }
        return null;
      };
    }
    return column;
  });

  const nonSelectable = [];
  data.forEach((userData, index) => {
    if (userData.email === EMPTY_EMAIL_ERROR) nonSelectable.push(index);
  });

  return (
    <Table
      columns={columns}
      data={data}
      keyField="key"
      searchText={searchText}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      nonSelectable={nonSelectable}
      clickToSelect={clickToSelect}
    />
  );
};

AdvancedTable.propTypes = {
  data: PropTypes.array,
  columnData: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array,
  searchText: PropTypes.string,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  clickToSelect: PropTypes.bool,
};

// eslint-disable-next-line no-unused-vars
export const LinkEdit = (cell, row, rowIndex, formatExtraData) => (
  <Button
    onClick={() => {
      history.push(`${row.location.pathname}/${row.uuid}/edit`);
    }}
  >
    Edit
  </Button>
);

// eslint-disable-next-line no-unused-vars
export const StatusCol = (cell, row, rowIndex, formatExtraData) => {
  if (row.status === LDAP_STATUS_LOADING) {
    return (
      <div className="loader_ldap-status">
        <ClapSpinner
          size={12}
          frontColor="#f68b1e"
          backColor="#fbb040"
          loading
        />
      </div>
    );
  }
  return ['ok', 'pending'].includes(row.status.toLowerCase()) ? (
    <span>{row.status}</span>
  ) : (
    <OverlayTrigger
      trigger="click"
      placement="top"
      rootClose
      overlay={<Popover id={Math.random()}>{row.status_message}</Popover>}
    >
      <Button variant="link" className="btn btn-link ldap-status-link">
        {row.status}
      </Button>
    </OverlayTrigger>
  );
};
