import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ROUTES } from '../../enum';
import ProtectedRoute from '../../components/ProtectedRoute';
import UnauthorizedRoute from '../../components/UnauthorizedRoute';

import { createErrorMessageSelector } from '../../store/utils/selectors';
import { isAuthenticatedSelector } from '../../store/user/selectors';

import CustomersForm from '../../views/CustomersForm/CustomersForm';
import LoginForm from '../../views/LoginForm/LoginForm';
import Eula from '../../views/LoginForm/Eula/eula';
import ResetPwdEmailConfirmation from '../../views/LoginForm/ResetPassword/EmailConfirmation';
import ResetPwdResetEmailSubForm from '../../views/LoginForm/ResetPassword/EmailSubForm';
import ResetPwdEmailEntered from '../../views/LoginForm/ResetPassword/EmailEntered';
import ResetPwdForm from '../../views/LoginForm/ResetPassword/ResetForm';
import SignUpForm from '../../views/SignUpForm/SignUpForm';

import ACRETO_LOGO from '../../assets/img/SVG/white_logo.svg';
import EXTREME_LOGO from '../../assets/customer/extreme/logo.svg';

import './login.scss';

const configs = {
  signup: {
    linkRoute: ROUTES.SIGN_UP_EMAIL,
    formTitle: 'Sign up',
    authButtonTitle: 'Sign up',
  },
  login: {
    linkRoute: ROUTES.LOGIN,
    formTitle: 'Log in',
    authButtonTitle: 'Log in',
  },
  resetPwd: {
    linkRoute: ROUTES.PWD_RESET,
    formTitle: 'Log in',
    authButtonTitle: 'Log in',
  },
};
class Login extends Component {
  render() {
    const {
      location: { pathname },
      isAuthenticated,
    } = this.props;
    let linkFlag = false;
    if (
      [configs.signup.linkRoute, configs.login.linkRoute, configs.resetPwd.linkRoute].some((str) =>
        pathname.includes(str),
      )
    ) {
      linkFlag = true;
    }
    const currentConfig = [configs.signup.linkRoute, configs.resetPwd.linkRoute].some((str) => pathname.includes(str))
      ? configs.login
      : configs.signup;
    const { linkRoute, formTitle, authButtonTitle } = currentConfig;

    return (
      <div className="login-page">
        <div className="login-page--header header">
          <img src={EXTREME_LOGO} alt="acreto-logo" className="logo" />
          {linkFlag && (
            <Link
              to={linkRoute}
              className={cx('button', {
                'signup-btn': currentConfig === configs.signup,
                'login-btn': currentConfig === configs.login,
              })}
            >
              {authButtonTitle}
            </Link>
          )}
        </div>

        <Switch>
          <Route exact path={ROUTES.LOGIN} render={LoginForm} />
          <ProtectedRoute exact path={ROUTES.CUSTOMERS} component={CustomersForm} />
          <ProtectedRoute exact path={ROUTES.EULA} component={Eula} />
          <UnauthorizedRoute exact path={ROUTES.PWD_RESET_EMAIL} component={ResetPwdResetEmailSubForm} />
          <UnauthorizedRoute path={ROUTES.PWD_RESET_EMAIL_CONFIRMATION} component={ResetPwdEmailConfirmation} />
          <UnauthorizedRoute exact path={ROUTES.PWD_RESET_RESET} component={ResetPwdForm} />
          <UnauthorizedRoute path={ROUTES.PWD_RESET_EMAIL_ENTERED} component={ResetPwdEmailEntered} />
          <UnauthorizedRoute path={ROUTES.SIGN_UP} component={SignUpForm} formTitle={formTitle} />
          <Redirect to={`${!isAuthenticated ? ROUTES.LOGIN : ROUTES.NOT_FOUND}`} />
        </Switch>
        <div className="login-page--footer">
          Powered by <img src={ACRETO_LOGO} alt="logo" />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
};

const errorSelector = createErrorMessageSelector(['LOGIN']);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
});

const ConnectedLogin = connect(mapStateToProps, null)(Login);

export default withRouter(ConnectedLogin);
