import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmFooter } from './commons';

const RecoverEcosystem = ({ onYes, onNo }) => (
  <div>
    <div className="action__confirm">
      <p>
        Recovery operation allows you to recover from a failed commit. It will restore your ecosystem to the last known
        working configuration. It can also remove your changes made in the failed commit.
      </p>
      <p>If ecosystem recovery fails, please contact support.</p>
      <p>Do you want to continue?</p>
    </div>

    <div className="wedge-modal__footer">
      <ConfirmFooter onYes={onYes} onNo={onNo} />
    </div>
  </div>
);

RecoverEcosystem.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};

export default RecoverEcosystem;
