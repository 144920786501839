/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Card from '../components/Card';
import Form from '../components/Form';
import { USER_ADD_OPTIONS, USER_MODAL_TYPE } from '../enum/object';
import { StepFooter } from './commons';
import './modals.scss';

class UserCreationSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      method: '',
      errors: [],
    };
  }

  changeField = (field, val) => {
    this.setState(
      (prev) => ({
        ...prev,
        [field]: val,
      }),
      () => {
        if (this.state.method === '1') {
          this.props.onNext(USER_MODAL_TYPE.CREATE_USER);
        } else if (this.state.method === '2') {
          this.props.onNext(USER_MODAL_TYPE.IMPORT_USER);
        }
      },
    );
  };

  renderUserMethodSelection() {
    const { errors } = this.state;

    return (
      <div className="modal__content padded ldap-config user-creation-selection">
        {errors.map((err, index) => (
          <p className="error" key={index}>
            {err}
          </p>
        ))}
        <Card header={false}>
          <div className="ldap-config-connection">
            <div className="form-header desc-header">
              <h5 className="form-header desc-header">
                Add users to your ecosystem to let them connect using their access credentials, like username and
                password.
              </h5>
              <div className="form-body">
                <div className="header-block">
                  <h5>Please select source of your users</h5>
                </div>
                <div className="selection-method">
                  <div className="form__input form__radioGroup">
                    {USER_ADD_OPTIONS.map((option, i) => (
                      <div className="form-row" key={i}>
                        <Form.RadioButton
                          key={i}
                          {...option}
                          selected={this.state.method}
                          onChange={(val) => {
                            this.changeField('method', val);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  render() {
    const { method } = this.state;
    return (
      <div>
        {this.renderUserMethodSelection()}
        {!method && (
          <div className="wedge-modal__footer">
            <StepFooter submit={false} onBack={this.props.navigateBack} forwardNav={false} backwardNav />
          </div>
        )}
      </div>
    );
  }
}
UserCreationSelection.propTypes = {
  onNext: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default UserCreationSelection;
