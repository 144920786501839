import { object, string } from 'yup';

const addressValidationSchema = object({
  name: string('Enter thing name').max(35, 'Name should be less than 36 characters.').required('Name is required.'),
  desc: string().max(255, 'Description should be less than 256 characters'),
  category: object().required('Category is required.').typeError('Category is required.'),
  address: string().required('Address is required.'),
  type: object().required(),
});

export default addressValidationSchema;
