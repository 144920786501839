export const FETCHING_REPORTS_REQUESTED = 'FETCHING_REPORTS_REQUESTED';
export const FETCHING_REPORTS_SUCCESS = 'FETCHING_REPORTS_SUCCESS';
export const FETCHING_REPORTS_FAILURE = 'FETCHING_REPORTS_FAILURE';
export const FLUSH_REPORTS = 'FLUSH_REPORTS';

export const FETCHING_LOG_SETTINGS_SUCCESS = 'FETCHING_LOG_SETTINGS_SUCCESS';
export const FETCHING_LOG_SETTINGS_FAILURE = 'FETCHING_LOG_SETTINGS_FAILURE';
export const FETCHING_LOG_SETTINGS_REQUESTED = 'FETCHING_LOG_SETTINGS_REQUESTED';

export const CREATION_LOG_SETTINGS_REQUESTED = 'CREATION_LOG_SETTINGS_REQUESTED';
export const CREATION_LOG_SETTINGS_SUCCESS = 'CREATION_LOG_SETTINGS_SUCCESS';
export const CREATION_LOG_SETTINGS_FAILURE = 'CREATION_LOG_SETTINGS_FAILURE';

export const UPDATE_LOG_SETTINGS_REQUESTED = 'UPDATE_LOG_SETTINGS_REQUESTED';
export const UPDATE_LOG_SETTINGS_SUCCESS = 'UPDATE_LOG_SETTINGS_SUCCESS';
export const UPDATE_LOG_SETTINGS_FAILURE = 'UPDATE_LOG_SETTINGS_FAILURE';

export const DELETE_LOG_SETTING_REQUESTED = 'DELETE_LOG_SETTING_REQUESTED';
export const DELETE_LOG_SETTING_SUCCESS = 'DELETE_LOG_SETTING_SUCCESS';
export const DELETE_LOG_SETTING_FAILURE = 'DELETE_LOG_SETTING_FAILURE';
