import ActiveIcon from '../assets/img/PNG/Acreto_Icon 20.png';
import CompletedIcon from '../assets/img/PNG/Acreto_Icon 27.png';
import TerminatedIcon from '../assets/img/PNG/Acreto_Icon 19.png';
import TimetoutIcon from '../assets/img/PNG/Acreto_Icon 28.png';

/**
 * Report status
 */

export default [
  { slug: 'active', name: 'Active', icon: ActiveIcon },
  { slug: 'completed', name: 'Completed', icon: CompletedIcon },
  { slug: 'terminated', name: 'Terminated', icon: TerminatedIcon },
  { slug: 'timeout', name: 'Timed out', icon: TimetoutIcon },
];
