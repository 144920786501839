/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-string-refs */
import React from 'react';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClapSpinner } from 'react-spinners-kit';

import ContextHelp from '../ContextHelp';
import { generateToken, generateImgTimeout } from '../../views/Objects/scenario-actions';

// Image expiry day
const EXPIRY_DAY = 1;
// Image generation limit time (minute)
const LIMIT_TIME = 10;

class ConfigOptions extends React.Component {
  constructor(props) {
    super(props);
    const { gateway, option } = this.props;
    let configOption = localStorage.getItem(gateway.uuid)
      ? JSON.parse(localStorage.getItem(gateway.uuid))[option.value]
      : null;
    let generating = false;
    if (configOption) {
      if (
        configOption.updatedAt &&
        new Date(configOption.updatedAt) < new Date(new Date().getTime() - EXPIRY_DAY * 24 * 60 * 60 * 1000)
      ) {
        const storeGateway = JSON.parse(localStorage.getItem(gateway.uuid));
        storeGateway[option.value] = null;
        configOption = null;
        localStorage.setItem(gateway.uuid, JSON.stringify(storeGateway));
      } else {
        configOption.error = '';
        generating = configOption.url
          ? false
          : new Date(configOption.createdAt) > new Date(new Date().getTime() - LIMIT_TIME * 60 * 1000);
      }
    }
    this.state = {
      configOption,
      generating,
    };
  }

  componentDidMount() {
    const { gateway, option } = this.props;
    const { generating, configOption } = this.state;
    if (generating) {
      this.timer = setTimeout(() => {
        this.props.generateImgTimeout(gateway, option);
      }, new Date(configOption.createdAt).getTime() + LIMIT_TIME * 60 * 1000 - new Date().getTime());
    }
  }

  componentDidUpdate(prevProps) {
    const { option, gateway, imgGenerating } = this.props;
    const prevImgGenerating = prevProps.imgGenerating[gateway.uuid]
      ? prevProps.imgGenerating[gateway.uuid][option.value]
      : null;
    if (imgGenerating[gateway.uuid] && imgGenerating[gateway.uuid][option.value] !== prevImgGenerating) {
      const configOption = JSON.parse(localStorage.getItem(gateway.uuid))[option.value];
      this.setState({
        configOption,
        generating: imgGenerating[gateway.uuid][option.value],
      });
      if (imgGenerating[gateway.uuid][option.value]) {
        this.timer = setTimeout(() => {
          this.props.generateImgTimeout(gateway, option);
        }, new Date(configOption.createdAt).getTime() + LIMIT_TIME * 60 * 1000 - new Date().getTime());
      } else {
        clearTimeout(this.timer);
      }
    }
    ReactTooltip.rebuild();
  }

  handleDownload = (event) => {
    event.preventDefault();
    const { configOption } = this.state;
    const link = document.createElement('a');
    link.download = configOption.platform;
    link.href = configOption.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleGenerate = async (event) => {
    event.preventDefault();
    const { gateway, option } = this.props;
    await this.props.generateToken(gateway, option);
  };

  renderOptions = () => {
    const {
      option: { value },
    } = this.props;
    const { configOption, generating } = this.state;

    const isError = configOption && configOption.error;

    if (configOption && configOption.url) {
      return (
        <>
          <i className="material-icons" onClick={this.handleGenerate} data-tip="Generate" data-for={`${value}-gen`}>
            replay
          </i>
          <CopyToClipboard
            text={configOption.url}
            onCopy={() => {
              ReactTooltip.show(findDOMNode(this.refs[value]));
            }}
          >
            <i className="config-copy fa fa-clone" data-tip="Copy URL" data-for={`${value}-url`}>
              <span ref={value} data-tip="Copied" data-for="isCopied" />
            </i>
          </CopyToClipboard>
          <i className="fa fa-download" onClick={this.handleDownload} data-tip="Download" data-for={`${value}-url`} />
          <ReactTooltip id={`${value}-gen`} effect="solid" type="info" />
          <ReactTooltip id={`${value}-url`} effect="solid" type="info" />
          <ReactTooltip
            id="isCopied"
            effect="solid"
            type="success"
            delayHide={1000}
            afterShow={() => ReactTooltip.hide(this.refs[value])}
          />
        </>
      );
    }
    if (configOption && generating) {
      return (
        <>
          <div className="config-spinner" data-tip="Generating..." data-for={`${value}-generating`}>
            <ClapSpinner size={12} frontColor="#f68b1e" backColor="#fbb040" loading />
          </div>
          <ReactTooltip id={`${value}-generating`} effect="solid" type="info" />
        </>
      );
    }
    return (
      <>
        <i
          className="material-icons"
          onClick={this.handleGenerate}
          data-tip={isError ? configOption.error : 'Generate'}
          data-for={`${value}-gen`}
        >
          {isError ? 'replay' : 'play_arrow'}
        </i>
        <ReactTooltip id={`${value}-gen`} effect="solid" type="info" />
      </>
    );
  };

  render() {
    const { option } = this.props;

    return (
      <div className="download__option">
        <span>{option.label}</span>
        <div className="download__functions">
          {this.renderOptions()}
          <ContextHelp helpLink={option.help} />
        </div>
      </div>
    );
  }
}

ConfigOptions.propTypes = {
  option: PropTypes.object.isRequired,
  gateway: PropTypes.object.isRequired,
  generateToken: PropTypes.func.isRequired,
  generateImgTimeout: PropTypes.func.isRequired,
  imgGenerating: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  imgGenerating: state.objects.imgGenerating,
});

const mapDispatchToProps = (dispatch) => ({
  generateToken: (gateway, option) => dispatch(generateToken(gateway, option)),
  generateImgTimeout: (gateway, option) => dispatch(generateImgTimeout(gateway, option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigOptions);
