import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './styles.scss';

const { ExportCSVButton } = CSVExport;

const Table = ({ keyField, data, columns, searchText, onSelect, onSelectAll, nonSelectable, clickToSelect = true }) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect,
    onSelect: (row, isSelect) => {
      onSelect(row, isSelect);
    },
    onSelectAll: (isSelect) => {
      onSelectAll(isSelect);
    },
    nonSelectable,
  };

  const searching = (item) => {
    const term = searchText.toLowerCase();
    if (item) {
      if (typeof item === 'string' && item.toLowerCase().includes(term)) {
        return true;
      }
    }
    return false;
  };

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data.filter((item) => Object.keys(item).some((key) => searching(_.get(item, key, ''))))}
      columns={columns}
      exportCSV={{ fileName: 'data.csv' }}
    >
      {(props) => (
        <>
          <BootstrapTable
            selectRow={selectRow}
            headerWrapperClasses="header__wrapper"
            columnToggle
            pagination={paginationFactory()}
            noDataIndication="No data to display"
            {...props.baseProps}
          />
          <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton>
        </>
      )}
    </ToolkitProvider>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  nonSelectable: PropTypes.array,
  keyField: PropTypes.string,
  searchText: PropTypes.string,
  baseProps: PropTypes.any,
  csvProps: PropTypes.any,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  clickToSelect: PropTypes.bool,
};

export default Table;
