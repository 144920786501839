import { AVAILABLE_REGIONS } from '../enum';
import { ADDRESS_TYPES_OPTIONS } from '../enum/address';
import { EXPIRATION_TYPE_OPTIONS } from '../enum/expiration';
import { GATEWAY_TYPE_OPTIONS, MODEL_TYPE_OPTIONS } from '../enum/gateway';
import { IP_MODES_OPTIONS, IP_TYPE_OPTIONS } from '../enum/ip';
import { LOCATION_TYPE_OPTIONS } from '../enum/location';
import { OBJECT_CATEGORIES, OBJECT_TYPES, OBJECT_TYPE_OPTIONS, OBJECT_TYPES_CONFIG } from '../enum/object';

export default {
  category: (id) => find(OBJECT_CATEGORIES, id),
  model: (id) => find(MODEL_TYPE_OPTIONS, id),
  type: (id, sub = '') => findTypes(id, sub),
  expirationType: (id) => find(EXPIRATION_TYPE_OPTIONS, id),
  location: (id) => find(LOCATION_TYPE_OPTIONS, id),
  region: (id) => find(AVAILABLE_REGIONS, id),
  protocolType: (id) => find(IP_TYPE_OPTIONS, id),
  addressType: (id) => find(ADDRESS_TYPES_OPTIONS, id),
  mask: (id) => ({ value: id, label: `${id}` }),
  mode: (id) => find(IP_MODES_OPTIONS, id),
  urlType: (type) => getUrlType(type),
};

function find(enums, id) {
  return enums.find((c) => c.value === id) || { label: 'unkn' };
}

function findTypes(type, subType) {
  return type === OBJECT_TYPES.GATEWAY
    ? GATEWAY_TYPE_OPTIONS.find((option) => option.value === subType) || {
        label: 'unkn',
      }
    : OBJECT_TYPE_OPTIONS.find((option) => option.value === type) || {
        label: 'unkn',
      };
}

function getUrlType(type) {
  return OBJECT_TYPES_CONFIG.find((el) => el.name === type).urlType;
}
