/**
 * Available Regions
 */

export default [
  { label: 'US East', value: 0 },
  { label: 'US West', value: 1 },
  { label: 'US Central', value: 2 },
  { label: 'SA East', value: 3 },
];
