import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import WedgeModal from '../../components/WedgeModal';
import { ROUTES } from '../../enum';
import ConfirmAction from '../../Modals/ConfirmAction';
import { deleteCustomer } from './scenario-actions';
import { BLUE_FORWARD_ARROW } from '../../assets/Icons';

import history from '../../history';

import './customers-form.scss';

class CustomersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeOpen: false,
      removeCustomer: null,
    };
  }

  handleSelectCustomer = (customer) => {
    history.push(`${ROUTES.CUSTOMERS}/${customer.uuid}/ecosystems`);
  };

  handleRemoveCustomer = async () => {
    const { removeCustomer } = this.state;
    await this.props.deleteCustomer(removeCustomer);
    this.closeRemoveModal();
  };

  openRemoveModal = (event, customer) => {
    event.stopPropagation();
    this.setState({
      removeOpen: true,
      removeCustomer: customer,
    });
  };

  closeRemoveModal = () => {
    this.setState({
      removeOpen: false,
      removeCustomer: null,
    });
  };

  renderCustomers = () => {
    const { customers } = this.props;
    const length = customers ? customers.length : 0;
    if (length) {
      return (
        <div className="customers-container">
          {customers.map((customer, index) => {
            const last = index + 1 === length;
            return (
              <React.Fragment key={`customer-index-${customer.uuid}`}>
                <div className="single-customer" onClick={() => this.handleSelectCustomer(customer)}>
                  <div className="single-customer-info">
                    <p className="single-customer-info__name">{customer.name}</p>
                    <p className="single-customer-info__uuid">{customer.uuid}</p>
                  </div>
                  <div className="customer-actions">
                    <i className="fa fa-trash-o" onClick={(event) => this.openRemoveModal(event, customer)} />
                    <img src={BLUE_FORWARD_ARROW} alt="blue-forward" className="forward-icon" />
                  </div>
                </div>
                {!last && <div className="divider" />}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    return <h4>You have no customers!</h4>;
  };

  render() {
    const { removeOpen, removeCustomer } = this.state;

    return (
      <div className="customers-form-page--content">
        <div className="customers-form">{this.renderCustomers()}</div>
        {removeCustomer && (
          <WedgeModal title="Are you sure?" onClose={this.closeRemoveModal} isOpen={removeOpen} size="tiny">
            <ConfirmAction
              item={{ type: 'Organization', name: removeCustomer.name }}
              action="delete"
              onYes={this.handleRemoveCustomer}
              onNo={this.closeRemoveModal}
              confirmByTyping
            />
          </WedgeModal>
        )}
      </div>
    );
  }
}

CustomersForm.defaultProps = {
  customers: [],
};

CustomersForm.propTypes = {
  deleteCustomer: PropTypes.func.isRequired,
  customers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  customers: state.auth.customers,
});

const mapDispatchToProps = (dispatch) => ({
  deleteCustomer: (customer) => dispatch(deleteCustomer(customer)),
});

const ConnectedCustomersForm = connect(mapStateToProps, mapDispatchToProps)(CustomersForm);
export default withRouter(ConnectedCustomersForm);
