/* eslint-disable camelcase */
/* eslint-disable no-throw-literal */
import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { CONSTANTS, ROUTES } from '../../enum';
import { setStripeError, setContractIdError } from '../payment/actions';
import { STRIPE } from '../payment/propertiesNames';
import { startLoading, finishLoading, setError, setInitCompanyName } from '../user/actions';
import { createCustomer, patchBilling } from './apiCalls';
import history from '../../history';
import { extractResponseErrorStatus, createResponseErrorMessage } from '../../utils/responseErrorHandler';
import { loginSuccess, setCustomer, clearData, logoutUser } from '../auth/actions';
import { removeCurrentCustomer } from '../customers/actions';
import * as REST from '../../api/rest';

const stripeInSagaSelector = (state) => {
  const stripeInstance = get(state, [CONSTANTS.PAYMENT, STRIPE]);
  return stripeInstance;
};

const customerSelector = (state) => {
  const customers = get(state, ['auth', 'customers']);
  return customers;
};
const currentCustomer = (state) => {
  const customer = get(state, ['customers', 'currentCustomer']);
  return customer;
};

export function* stripeSourceSaga({ payload }) {
  yield put(startLoading());
  yield put(setStripeError(''));
  let userData = payload;
  try {
    const curCustomer = yield select(currentCustomer);
    userData = {
      ...userData,
      owner: {
        ...userData.owner,
        phone: curCustomer.phone,
      },
    };
    const responseAfterCallingStripeApi = yield call(createStripeSource, userData);
    const { source: userDataVerifiedByStripe } = responseAfterCallingStripeApi;
    const responseAfterRegisteringStripeSource = yield call(registerStripeSource, userDataVerifiedByStripe);
    const { data: customer } = responseAfterRegisteringStripeSource;
    yield call(finishRegistrationProcedure, customer);
  } catch (error) {
    if (typeof error === 'string') {
      yield put(setStripeError(error));
    } else {
      const errorStatus = extractResponseErrorStatus(error);
      const errorMessage = createResponseErrorMessage({
        specificErrorHandler: {
          default: 'Error while generating Stripe Source Id',
        },
        status: errorStatus,
      });
      yield put(setStripeError(errorMessage));
    }
  } finally {
    yield put(finishLoading());
  }
}

export function* updateBillingSaga({ payload: userData }) {
  yield put(startLoading());
  try {
    const responseAfterCallingStripeApi = yield call(createStripeSource, userData.billingInfo);
    const { source: userDataVerifiedByStripe } = responseAfterCallingStripeApi;
    userDataVerifiedByStripe.customerUUID = userData.customerUUID;
    yield call(patchBillingInfo, userDataVerifiedByStripe);
    history.goBack();
  } catch (error) {
    if (typeof error === 'string') {
      yield put(setStripeError(error));
    } else {
      const errorStatus = extractResponseErrorStatus(error);
      const errorMessage = createResponseErrorMessage({
        specificErrorHandler: {
          default: 'Error while generating Stripe Source Id',
        },
        status: errorStatus,
      });
      yield put(setStripeError(errorMessage));
    }
  } finally {
    yield put(finishLoading());
  }
}

export function* createStripeSource(userData) {
  const stripe = yield select(stripeInSagaSelector);
  const response = yield stripe.createSource(userData);
  const { error } = response;
  if (error) {
    const { message } = error;
    yield call(() => {
      throw message;
    });
  }
  return response;
}

export function* registerStripeSource(userData) {
  // ATTENTION!! With payment_channel I specify,
  // if the Stripe Source Id or Contract Id is provided
  // as payment_ref
  const payment_channel = STRIPE;
  const { id: payment_ref } = userData;
  const curCustomer = yield select(currentCustomer);
  try {
    const requestBody = {
      address: curCustomer.address,
      ...(curCustomer.contact.name && {
        contact: {
          name: curCustomer.contact.name,
          ...(curCustomer.contact.phone && {
            phone: curCustomer.contact.phone,
          }),
        },
      }),
      name: curCustomer.name,
      payment_channel,
      payment_ref,
    };
    yield put(setInitCompanyName(curCustomer.name));
    const response = yield call(createCustomer, requestBody);
    return response;
  } catch (error) {
    const message = error.response.data.message || 'Error While Creating Customer';
    throw message;
  }
}

export function* patchBillingInfo(userData) {
  const payment_channel = STRIPE;
  const { id: payment_ref } = userData;

  try {
    const requestBody = {
      payment_channel,
      payment_ref,
    };
    const response = yield call(patchBilling, {
      requestBody,
      customerUUID: userData.customerUUID,
    });
    return response;
  } catch (error) {
    const message = error.message || 'Error While Update Billing';
    throw message;
  }
}

export function* contractIdSaga({ payload: companyData }) {
  const curCustomer = yield select(currentCustomer);
  yield put(startLoading());
  yield put(setContractIdError());
  try {
    const requestBody = {
      address: curCustomer.address,
      ...(curCustomer.contact.name && {
        contact: {
          name: curCustomer.contact.name,
          ...(curCustomer.contact.phone && {
            phone: curCustomer.contact.phone,
          }),
        },
      }),
      ...companyData,
    };
    const { data: customer } = yield call(createCustomer, requestBody);
    yield call(finishRegistrationProcedure, customer);
  } catch (error) {
    if (error.response && error.response.data.message) {
      yield put(setContractIdError(error.response.data.message));
    } else {
      const { field } = error;
      const errorStatus = extractResponseErrorStatus(error);
      const errorMessage = field
        ? `Invalid Data Passed As "${field}"`
        : createResponseErrorMessage({
            specificErrorHandler: {
              default: 'Error while generating Stripe Source Id',
            },
            status: errorStatus,
          });
      yield put(setContractIdError(errorMessage));
    }
  } finally {
    yield put(finishLoading());
  }
}

export function* finishRegistrationProcedure(customer) {
  const customers = yield select(customerSelector);

  if (customers && customers.length) {
    yield REST.logout();
    yield put(removeCurrentCustomer());
    yield put(logoutUser());
    yield put(setError('Please login again to access your new organization'));
    yield put(clearData());
    history.replace(ROUTES.LOGIN);
  } else {
    const accessToken = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
    yield put(setCustomer(customer));
    yield put(
      loginSuccess({
        accessToken,
        customers: [customer],
      }),
    );
    history.replace({
      pathname: `${ROUTES.CUSTOMERS}/${customer.uuid}`,
      state: { openEcosystemDialog: true },
    });
  }
}
