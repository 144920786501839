export const CREATE_ECOSYSTEM_REQUESTED = 'CREATE_ECOSYSTEM_REQUESTED';
export const CREATE_ECOSYSTEM_SUCCESS = 'CREATE_ECOSYSTEM_SUCCESS';
export const CREATE_ECOSYSTEM_FAILURE = 'CREATE_ECOSYSTEM_FAILURE';

export const CREATE_ECOSYSTEM_GROUP_REQUESTED = 'CREATE_ECOSYSTEM_GROUP_REQUESTED';
export const CREATE_ECOSYSTEM_GROUP_SUCCESS = 'CREATE_ECOSYSTEM_GROUP_SUCCESS';
export const CREATE_ECOSYSTEM_GROUP_FAILURE = 'CREATE_ECOSYSTEM_GROUP_FAILURE';

export const CREATE_SERVICE_REQUESTED = 'CREATE_SERVICE_REQUESTED';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILURE = 'CREATE_SERVICE_FAILURE';

export const FETCHING_ECOSYSTEMS_REQUESTED = 'FETCHING_ECOSYSTEMS_REQUESTED';
export const FETCHING_ECOSYSTEMS_SUCCESS = 'FETCHING_ECOSYSTEMS_SUCCESS';
export const FETCHING_ECOSYSTEMS_FAILURE = 'FETCHING_ECOSYSTEMS_FAILURE';

export const FETCHING_NSP_REQUESTED = 'FETCHING_NSP_REQUESTED';
export const FETCHING_NSP_SUCCESS = 'FETCHING_NSP_SUCCESS';
export const FETCHING_NSP_FAILURE = 'FETCHING_NSP_FAILURE';

export const LOAD_ECOSYSTEM_REQUESTED = 'LOAD_ECOSYSTEM_REQUESTED';
export const LOAD_ECOSYSTEM_SUCCESS = 'LOAD_ECOSYSTEM_SUCCESS';
export const LOAD_ECOSYSTEM_FAILURE = 'LOAD_ECOSYSTEM_FAILURE';

export const EDIT_ECOSYSTEM_REQUESTED = 'EDIT_ECOSYSTEM_REQUESTED';
export const EDIT_ECOSYSTEM_SUCCESS = 'EDIT_ECOSYSTEM_SUCCESS';
export const EDIT_ECOSYSTEM_FAILURE = 'EDIT_ECOSYSTEM_FAILURE';

export const REMOVE_ECOSYSTEM_REQUESTED = 'REMOVE_ECOSYSTEM_REQUESTED';
export const REMOVE_ECOSYSTEM_SUCCESS = 'REMOVE_ECOSYSTEM_SUCCESS';
export const REMOVE_ECOSYSTEM_FAILURE = 'REMOVE_ECOSYSTEM_FAILURE';

export const UPGRADE_ECOSYSTEM_REQUESTED = 'UPGRADE_ECOSYSTEM_REQUESTED';
export const UPGRADE_ECOSYSTEM_SUCCESS = 'UPGRADE_ECOSYSTEM_SUCCESS';
export const UPGRADE_ECOSYSTEM_FAILURE = 'UPGRADE_ECOSYSTEM_FAILURE';

export const RECOVER_ECOSYSTEM_REQUESTED = 'RECOVER_ECOSYSTEM_REQUESTED';
export const RECOVER_ECOSYSTEM_SUCCESS = 'RECOVER_ECOSYSTEM_SUCCESS';
export const RECOVER_ECOSYSTEM_FAILURE = 'RECOVER_ECOSYSTEM_FAILURE';

export const REFRESH_ECOSYSTEM_REQUESTED = 'REFRESH_ECOSYSTEM_REQUESTED';
export const REFRESH_ECOSYSTEM_SUCCESS = 'REFRESH_ECOSYSTEM_SUCCESS';

export const RESET_REQUEST_ID = 'RESET_REQUEST_ID';

export const SAVE_COMMIT_REQUEST = 'SAVE_COMMIT_REQUEST';
export const SAVE_REVERT_REQUEST = 'SAVE_REVERT_REQUEST';
export const SET_CURRENT_ECOSYSTEM = 'SET_CURRENT_ECOSYSTEM';
export const SET_ECOSYSTEM_STATUS = 'SET_ECOSYSTEM_STATUS';

export const GRANT_ACCESS_REQUESTED = 'GRANT_ACCESS_REQUESTED';
export const GRANT_ACCESS_SUCCESS = 'GRANT_ACCESS_SUCCESS';
export const GRANT_ACCESS_FAILURE = 'GRANT_ACCESS_FAILURE';

export const REMOVE_ACCESS_REQUESTED = 'REMOVE_ACCESS_REQUESTED';
export const REMOVE_ACCESS_SUCCESS = 'REMOVE_ACCESS_SUCCESS';
export const REMOVE_ACCESS_FAILURE = 'REMOVE_ACCESS_FAILURE';

export const GET_ACCESS_USER_REQUESTED = 'GET_ACCESS_USER_REQUESTED';
export const GET_ACCESS_USER_SUCCESS = 'GET_ACCESS_USER_SUCCESS';
export const GET_ACCESS_USER_FAILURE = 'GET_ACCESS_USER_FAILURE';

export const UPDATE_ACCESS_USER_REQUESTED = 'UPDATE_ACCESS_USER_REQUESTED';
export const UPDATE_ACCESS_USER_SUCCESS = 'UPDATE_ACCESS_USER_SUCCESS';
export const UPDATE_ACCESS_USER_FAILURE = 'UPDATE_ACCESS_USER_FAILURE';

export const CREATE_ECOSYSTEM_ROLE_REQUESTED = 'CREATE_ECOSYSTEM_ROLE_REQUESTED';
export const CREATE_ECOSYSTEM_ROLE_SUCCESS = 'CREATE_ECOSYSTEM_ROLE_SUCCESS';
export const CREATE_ECOSYSTEM_ROLE_FAILURE = 'CREATE_ECOSYSTEM_ROLE_FAILURE';

export const GET_ECOSYSTEM_USER_REQUESTED = 'GET_ECOSYSTEM_USER_REQUESTED';
export const GET_ECOSYSTEM_USER_SUCCESS = 'GET_ECOSYSTEM_USER_SUCCESS';
export const GET_ECOSYSTEM_USER_FAILURE = 'GET_ECOSYSTEM_USER_FAILURE';

export const SET_ECOSYSTEM_CODE = 'SET_ECOSYSTEM_CODE';
